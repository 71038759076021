import React from "react"
import styles from "../../style"
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import Subpage from "../components/Subpage";
import papertrading from "../gif/papertrading.gif";
import img21 from "../images/img9.svg";
import Translations from "../components/Translations";

function PaperTrading() {
    const offerIncludes = [
        {description: <Translations category={"paperTrading"} id={8}/>},
        {description: <Translations category={"paperTrading"} id={9}/>},
        {description: <Translations category={"paperTrading"} id={10}/>},
        {description: <Translations category={"paperTrading"} id={11}/>},
        {description: <Translations category={"paperTrading"} id={12}/>},
        {description: <Translations category={"paperTrading"} id={13}/>},
        {description: <Translations category={"paperTrading"} id={14}/>},
        {description: <Translations category={"paperTrading"} id={15}/>},
        {description: <Translations category={"paperTrading"} id={16}/>},
        {description: <Translations category={"paperTrading"} id={17}/>},
        {description: <Translations category={"paperTrading"} id={18}/>},
        {description: <Translations category={"paperTrading"} id={19}/>},
        {description: <Translations category={"paperTrading"} id={20}/>},
        {description: <Translations category={"paperTrading"} id={21}/>},
        {description: <Translations category={"paperTrading"} id={22}/>},
        {description: <Translations category={"paperTrading"} id={23}/>},
        {description: <Translations category={"paperTrading"} id={24}/>},
        {description: <Translations category={"paperTrading"} id={25}/>},
    ];

    const configIconSection = {
        iconOne: <MoneyOffIcon sx={{fontSize: 50}}/>,
        iconTwo: <AccessTimeIcon sx={{fontSize: 50}}/>,
        iconThree: <AutoGraphIcon sx={{fontSize: 50}}/>,
        textOne: <Translations category={"paperTrading"} id={2}/>,
        textTwo: <Translations category={"paperTrading"} id={3}/>,
        textThree: <Translations category={"paperTrading"} id={4}/>,
    }

    const Content = () => {
        return (
            <div>
                <div className={`grid grid-cols-1 lg:grid-cols-6 gap-5`}>
                    <div className={`col-span-4 flex flex-col justify-between`}>
                        <div></div>
                        <div>
                            <p className={`${styles.textParagraph}`}>
                                <Translations category={"paperTrading"} id={5}/>
                            </p>
                            <p className={`${styles.textParagraph} mt-10`}>
                                <Translations category={"paperTrading"} id={6}/>
                            </p>
                        </div>
                    </div>
                    <img src={img21} alt='/'
                         className={`col-span-2 w-[500px] m-auto`}/>
                </div>
                <p className={`${styles.textParagraph} mt-10`}>
                    <Translations category={"paperTrading"} id={7}/>
                </p>
            </div>
        )
    }

    const config = {
        configIconSection: configIconSection,
        title: <Translations category={"menu"} id={13}/>,
        subtitle: <Translations category={"paperTrading"} id={1}/>,
        offerIncludes: offerIncludes,
        content: Content(),
        gif: papertrading,
    }

    return (
        <Subpage config={config}/>
    )
}

export default PaperTrading