import React from 'react'
import {Link} from "react-router-dom"
import Translations from "./Translations"
import styles, {color} from "../../style"

const Footer = () => {
    return (
        <div className={`${styles.backgroundMain}`}>
            <div className={`${styles.maxWidth} grid-cols-2 py-8 px-4 grid md:grid-cols-5 gap-8 text-[#f3f2ee]`}>
                <div className='col-span-1 hidden md:flex flex-col'>
                    <a href="/" className="text-2xl tracking-tighter text-center">crypto<span
                        className={`text-[#10a7d0] font-bold`}>deputy</span>.</a>
                    <p className='pt-2 text-sm text-[#f3f2ee] text-center'><Translations category={"footer"} id={2}/>
                    </p>
                    <a href={"https://freepik.com"}>
                        <p className='pt-2 text-sm text-[#f3f2ee] text-center'>Images by storyset on Freepik.</p>
                    </a>
                </div>
                <div className='col-span-1'>
                    <h3 className={`text-2xl text-[${color.accent}] flex justify-center`}>
                        <Translations category={"menu"} id={5}/>
                    </h3>
                    <ul>
                        <li className='pt-4 text-base flex justify-center'>
                            <Link to="/about"><Translations category={"menu"} id={7}/></Link>
                        </li>
                        {/*<li className='pt-4 text-base flex justify-center'><Link to="/terms"><Translations*/}
                        {/*    category={"menu"}*/}
                        {/*    id={11}/></Link></li>*/}
                        <li className='pt-4 text-base flex justify-center'>
                            <Link to="/contact"><Translations category={"menu"} id={12}/></Link>
                        </li>
                    </ul>
                </div>
                <div className='col-span-1 flex flex-col'>
                    <h3 className={`text-2xl text-[${color.accent}] flex justify-center`}>
                        <Translations category={"menu"} id={3}/>
                    </h3>
                    <ul>
                        <li className='pt-4 text-base flex justify-center'>
                            <Link to="/backtests"><Translations category={"menu"} id={9}/></Link>
                        </li>
                        <li className='pt-4 text-base flex justify-center'>
                            <Link to="/paper-trading"><Translations category={"menu"} id={13}/></Link>
                        </li>
                        <li className='pt-4 text-base flex justify-center'>
                            <Link to="/automate-trading"><Translations category={"menu"} id={14}/></Link>
                        </li>
                        <li className='pt-4 text-base flex justify-center'>
                            <Link to="/statistics"><Translations category={"menu"} id={15}/></Link>
                        </li>
                    </ul>
                </div>
                <div className='col-span-1 flex flex-col'>
                    <h3 className={`text-2xl text-[${color.accent}] flex justify-center`}>
                        <Translations category={"menu"} id={4}/>
                    </h3>
                    <ul>
                        <li className='pt-4 text-base flex justify-center'>
                            <Link to="/strategy-creator"><Translations category={"menu"} id={16}/></Link>
                        </li>
                        <li className='pt-4 text-base flex justify-center'>
                            <Link to="/trailing-stop"><Translations category={"menu"} id={18}/></Link>
                        </li>
                        <li className='pt-4 text-base flex justify-center'>
                            <Link to="/indicators"><Translations category={"menu"} id={17}/></Link>
                        </li>
                    </ul>
                </div>
                <div className='col-span-1'>
                    <h3 className={`text-2xl text-[${color.accent}] flex justify-center`}>
                        <Translations category={"menu"} id={6}/>
                    </h3>
                    <ul>
                        <li className='pt-4 text-base flex justify-center'>
                            <Link to="/pricing"><Translations category={"menu"} id={1}/></Link>
                        </li>
                    </ul>
                </div>
                <p className='pt-2 text-xs text-[#ffffff] text-opacity-20 col-span-2 md:col-span-5 text-center'>
                    <Translations category={"footer"} id={1}/>
                </p>
                <div className='col-span-2 flex md:hidden flex-col'>
                    <p className='text-sm text-[#f3f2ee] text-opacity-20 text-center'>
                        <Translations category={"footer"} id={2}/>
                    </p>
                    <a href="https://www.freepik.com/">
                        <p className='text-xs text-[#ffffff] text-opacity-20 text-center'>
                            Images by storyset on Freepik.
                        </p>
                    </a>
                </div>
            </div>
        </div>

    )
}

export default Footer
