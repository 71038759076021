import styles from "../../../style";
import LineChart from "../charts/LineChart";
import TextWithColor from "../TextWithColor";
import PieChart from "../charts/PieChart";
import BarChart from "../charts/BarChart";
import React, {useEffect, useState} from "react";
import {useWebSocket} from "../../../globals/socket/Socket";
import {isLocalhost} from "../../../globals/auth/IsLocalhost";
import full_stats_pt_both from "../../mocks/full_stats_pt_both.json";
import full_stats_at from "../../mocks/full_stats_at.json";
import full_stats_bt_rsi from "../../mocks/full_stats_bt_rsi.json";
import EventEmitter from "../EventEmitter";
import full_stats_pt_mana from "../../mocks/full_stats_pt_mana.json";
import full_stats_pt_ordi from "../../mocks/full_stats_pt_ordi.json";
import full_stats_bt_emac from "../../mocks/full_stats_bt_emac.json";
import full_stats_bt_minmax from "../../mocks/full_stats_bt_minmax.json";

const TradingResult = ({source}) => {
    const [tradingResults, setTradingResults] = useState()
    const isEmpty = tradingResults && tradingResults.sumLost === 0 && tradingResults.sumWon === 0
    const ws = useWebSocket()
    useEffect(() => {
        if (ws) {
            let data = JSON.parse(ws)
            if (data.key === "FullStats" && data.source === source) {
                setTradingResults(JSON.parse(data.value).strategy)
            }
        }
    }, [ws])

    useEffect(() => {
        if (!isLocalhost()) {
            demoInitData();
            demoEventConsumer();
        }
    }, [])

    function demoEventConsumer() {
        const callback = (id) => chooseDemoData(id);
        EventEmitter.subscribe('demoFullStats', callback)
        return () => {
            EventEmitter.unsubscribe('demoFullStats', callback);
        }
    }

    function demoInitData() {
        if (source === "PaperTrading") {
            setTradingResults(full_stats_pt_both.strategy)
        } else if (source === "AutomateTrading") {
            setTradingResults(full_stats_at.strategy)
        } else if (source === "BackTest") {
            setTradingResults(full_stats_bt_rsi.strategy)
        }
    }

    function chooseDemoData(id) {
        if (id === 342) {
            setTradingResults(full_stats_at.strategy)
        } else if (id === 343) {
            setTradingResults(full_stats_pt_mana.strategy)
        } else if (id === 344) {
            setTradingResults(full_stats_pt_ordi.strategy)
        } else if (id === 347) {
            setTradingResults(full_stats_bt_emac.strategy)
        } else if (id === 348) {
            setTradingResults(full_stats_bt_minmax.strategy)
        } else if (id === 349) {
            setTradingResults(full_stats_bt_rsi.strategy)
        }
    }

    return (
        <>
            <div className={`${styles.rowBetween} hidden 2xl:col-span-12 2xl:flex 2xl:flex-row items-center`}>
                <p className="text-2xl mr-10">Trading results</p>
                {tradingResults?.dtTo ?
                    <p className="text-base">Analyzed dates up to: {tradingResults.dtTo} </p> : <></>}
            </div>
            <div className="col-span-2 lg:col-span-6 2xl:col-span-4 flex flex-col">
                <div className={`${styles.rowBetween}  block 2xl:hidden items-center mb-4`}>
                    <p className="text-2xl mr-10">Trading results</p>
                    {tradingResults?.dtTo ?
                        <p className="text-base">Analyzed dates up to: {tradingResults.dtTo} </p> : <></>}
                </div>
                <div className="flex-grow grid grid-col-1 sm:grid-cols-2 gap-4">
                    <div className={`${styles.roundedBox} ${styles.colBetween} col-span-1`}>
                        <p className="text-base">Balance</p>
                        {tradingResults ? <LineChart
                            axisY={[tradingResults.startingBalance, tradingResults.maxBalance, tradingResults.minBalance, tradingResults.endingBalance]}
                            axisX={['Starting', 'Max', 'Min', 'Ending']}
                        /> : <LineChart axisY={[0, 0, 0, 0]} axisX={['Starting', 'Max', 'Min', 'Ending']}/>}
                    </div>
                    <div className={`${styles.roundedBox} col-span-1`}>
                        <div className="flex flex-row gap-3 items-center">
                            <TextWithColor title={"Profit"}
                                           description={tradingResults ? tradingResults.profit + " $" : "-"}
                                           color={tradingResults?.profit >= 0 ? "text-green-500" : "text-red-500"}
                            />
                        </div>
                        <div className="mt-2">
                            <div className="flex flex-col mt-2">
                                <TextWithColor title={"Average profit"} color={"text-green-500"}
                                               description={tradingResults ? tradingResults.avgProfit + " $" : '-'}/>
                                <TextWithColor title={"Average loss"} color={"text-red-500"}
                                               description={tradingResults ? tradingResults.avgLoss + " $" : '-'}/>
                            </div>
                            <div className="flex flex-col mt-2">
                                <TextWithColor title={"Max trade profit"} color={"text-green-500"}
                                               description={tradingResults ? tradingResults.maxProfit + " $" : '-'}/>
                                <TextWithColor title={"Max trade loss"} color={"text-red-500"}
                                               description={tradingResults ? tradingResults.maxLoss + " $" : '-'}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className={`${styles.roundedBox} flex-grow col-span-2 lg:col-span-6 2xl:col-span-4 flex-col grid grid-cols-11`}>
                <div className="col-span-4 flex flex-col justify-between">
                    <div className="flex flex-col">
                        <TextWithColor title={"Winnings"} color={"text-green-500"}
                                       description={tradingResults ? tradingResults.wonTrades : '-'}/>
                        <TextWithColor title={"Losing"} color={"text-red-500"}
                                       description={tradingResults ? tradingResults.lostTrades : '-'}/>
                        <TextWithColor title={"Liquidations"} color={"text-yellow-500"}
                                       description={tradingResults ? tradingResults.liquidations : '-'}/>
                    </div>
                    <div className="flex flex-col mt-3">
                        <TextWithColor title={"All"} color={"text-yellow-500"}
                                       description={tradingResults ? tradingResults.allTrades : '-'}/>
                        <TextWithColor title={"Long"} color={"text-green-500"}
                                       description={tradingResults ? tradingResults.longs : '-'}/>
                        <TextWithColor title={"Short"} color={"text-red-500"}
                                       description={tradingResults ? tradingResults.shorts : '-'}/>
                    </div>
                </div>
                <div className="col-span-3 flex justify-center flex-col">
                    {isEmpty === undefined || isEmpty ?
                        <PieChart formatter={
                            function () {
                                return ''
                            }
                        } config={{
                            labels: ['No data'],
                            datasets: [
                                {
                                    data: [1],
                                    backgroundColor: ['rgba(0,0,0,0)'],
                                    borderWidth: 2,
                                    borderColor: 'rgb(16,166,206)',
                                },
                            ],
                        }} labelBackground={''}/> :
                        <PieChart formatter={
                            function (value) {
                                return `${value} $`
                            }} config={{
                            labels: ['Losing Trades', 'Winning Trades'],
                            datasets: [
                                {
                                    data: [tradingResults.sumLost, tradingResults.sumWon],
                                    backgroundColor: [
                                        'rgb(208,57,53)',
                                        'rgb(35,193,87)',
                                    ],
                                    borderWidth: 0,
                                },
                            ],
                        }} labelBackground={'rgb(53,53,53,0.7)'}/>}
                </div>
                <div className="col-span-4 flex flex-col justify-between justify-self-end">
                    <div className="flex flex-col">
                        <TextWithColor title={"Winnings"} color={"text-green-500"} revers={true}
                                       description={tradingResults ? tradingResults.sumWon + " $" : '-'}/>
                        <TextWithColor title={"Losses"} color={"text-red-500"} revers={true}
                                       description={tradingResults ? tradingResults.sumLost + " $" : '-'}/>
                        <TextWithColor title={"Fees"} color={"text-yellow-500"} revers={true}
                                       description={tradingResults ? tradingResults.sumFees + " $" : '-'}/>
                    </div>
                    <div className="flex flex-col mt-3">
                        <TextWithColor title={"Total risk"} color={"text-yellow-500"} revers={true}
                                       description={tradingResults ? tradingResults.totalRisk + " $" : '-'}/>
                        <TextWithColor title={"Max bet size"} color={"text-green-500"} revers={true}
                                       description={tradingResults ? tradingResults.highestBet + " $" : '-'}/>
                        <TextWithColor title={"Min bet size"} color={"text-red-500"} revers={true}
                                       description={tradingResults ? tradingResults.lowestBet + " $" : '-'}/>
                    </div>
                </div>
            </div>
            <div
                className={`${styles.roundedBox} col-span-2 sm:col-span-1 lg:col-span-3 2xl:col-span-2 flex flex-col flex-grow`}>
                <TextWithColor title={"Effectiveness"} color={"text-yellow-500"}
                               description={tradingResults ? tradingResults.effectiveness.toFixed() + " %" : '-'}/>
                <div className="mt-2">
                    <div className="flex flex-col mt-2">
                        <TextWithColor title={"Max winning streak"} color={"text-green-500"}
                                       description={tradingResults ? tradingResults.maxWinStreak : '-'}/>
                        <TextWithColor title={"Max losing streak"} color={"text-red-500"}
                                       description={tradingResults ? tradingResults.maxLossStreak : '-'}/>
                    </div>
                    <div className="flex flex-col mt-2">
                        <TextWithColor title={"Return of equity"}
                                       description={tradingResults ? tradingResults.returnOfEquity + " %" : '-'}
                                       color={tradingResults?.returnOfEquity > 0 ? "text-green-500" : "text-red-500"}/>
                        <TextWithColor title={"Expected rate of return"}
                                       description={tradingResults ? tradingResults.expectedRateOfReturn : '-'}
                                       color={tradingResults?.expectedRateOfReturn > 0 ? "text-green-500" : "text-red-500"}/>
                    </div>
                </div>
            </div>
            <div
                className={`${styles.roundedBox} ${styles.colBetween} flex-grow col-span-2 sm:col-span-1 lg:col-span-3 2xl:col-span-2 flex flex-col`}>
                <div>
                    <TextWithColor title={"Days"} color={"text-yellow-500"}
                                   description={tradingResults ? tradingResults.days : '-'}/>
                    <TextWithColor title={"Trades per day"} color={"text-yellow-500"}
                                   description={tradingResults ? tradingResults.avgTradesPerDay : '-'}/>
                </div>
                {tradingResults ? <BarChart
                        axisY={[tradingResults.monday, tradingResults.tuesday, tradingResults.wednesday, tradingResults.thursday, tradingResults.friday, tradingResults.saturday, tradingResults.sunday]}
                        axisX={['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']} isPie={false}/> :
                    <BarChart
                        axisY={["", "", "", "", "", "", ""]}
                        axisX={['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']} isPie={false}/>}
            </div>
        </>
    )
}

export default TradingResult



