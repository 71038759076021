import React, {useEffect} from "react"
import styles, {color} from "../../style"
import CreateIcon from '@mui/icons-material/Create';
import StarIcon from '@mui/icons-material/Star';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import Subpage from "../components/Subpage";
import indicatorsGif from "../gif/indicators.gif";
import img22 from "../images/img10.svg";
import Translations from "../components/Translations";

function Indicators() {
    useEffect(() => window.scrollTo(0, 0), [])

    const offerIncludes = [
        {description: <Translations category={"indicators"} id={21}/>},
        {description: <Translations category={"indicators"} id={22}/>},
        {description: <Translations category={"indicators"} id={23}/>},
        {description: <Translations category={"indicators"} id={24}/>},
        {description: <Translations category={"indicators"} id={15}/>},
        {description: <Translations category={"indicators"} id={25}/>},
        {description: <Translations category={"indicators"} id={20}/>},
        {description: <Translations category={"indicators"} id={19}/>},
        {description: <Translations category={"indicators"} id={26}/>},
    ];

    const configIconSection = {
        iconOne: <StarIcon sx={{fontSize: 50}}/>,
        iconTwo: <CreateIcon sx={{fontSize: 50}}/>,
        iconThree: <SyncAltIcon sx={{fontSize: 50}}/>,
        textOne: <Translations category={"indicators"} id={2}/>,
        textTwo: <Translations category={"indicators"} id={3}/>,
        textThree: <Translations category={"indicators"} id={4}/>,
    }

    const traders = [
        {
            name: "John J. Murphy",
            book: <Translations category={"indicators"} id={6}/>,
            description: <Translations category={"indicators"} id={7}/>
        },
        {
            name: "Martin J. Pring",
            book: <Translations category={"indicators"} id={8}/>,
            description: <Translations category={"indicators"} id={9}/>
        },
        {
            name: "Brent Penfold",
            book: <Translations category={"indicators"} id={10}/>,
            description: <Translations category={"indicators"} id={11}/>
        },
    ]

    const indicators = [
        {name: "EMA", description: <Translations category={"indicators"} id={13}/>},
        {name: "EMA cross", description: <Translations category={"indicators"} id={14}/>},
        {name: "HMM", description: <Translations category={"indicators"} id={15}/>},
        {name: "MACD", description: <Translations category={"indicators"} id={16}/>},
        {name: "RSI", description: <Translations category={"indicators"} id={17}/>},
        {name: <Translations category={"indicators"} id={18}/>, description: "Crypto Bubbles"},
        {name: <Translations category={"indicators"} id={19}/>, description: ""},
        {name: <Translations category={"indicators"} id={20}/>, description: ""},
    ]

    const Content = () => {
        return (
            <div>
                <div className={`grid grid-cols-1 lg:grid-cols-3 gap-5`}>
                    <div className={`col-span-2 flex flex-col justify-between`}>
                        <p className={`${styles.textParagraph}`}>
                            <Translations category={"indicators"} id={5}/>
                        </p>
                    </div>
                    <img src={img22} alt='/' className={`w-[500px] m-auto`}/>
                </div>
                <div className={`col-span-2 grid lg:grid-cols-3 gap-5 mt-10`}>
                    {traders.map((trader, index) => (
                        <div className={`bg-[${color.base}] ${styles.shadow} p-8 rounded`}>
                            <div className={`text-2xl mb-2 font-bold`}>{trader.name}</div>
                            <p className={`text-blue-100 text-base mb-4`}>{trader.book}</p>
                            <p className={`${styles.textParagraph}`}>{trader.description}</p>
                        </div>
                    ))}
                </div>
                <p className={`${styles.textParagraph} mt-10 lg:mt-32`}>
                    <Translations category={"indicators"} id={12}/>
                </p>
                <div className={`grid md:grid-cols-2 lg:grid-cols-4 gap-5 mt-6`}>
                    {indicators.map((indicator, index) => (
                        <div
                            className={`bg-[${color.base}] ${styles.shadow} p-8 flex flex-col items-center justify-center`}>
                            <p className={`text-2xl pb-4`}>{indicator.name}</p>
                            <p className={`${styles.textParagraph} text-center`}>{indicator.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    const config = {
        configIconSection: configIconSection,
        title: <Translations category={"menu"} id={17}/>,
        subtitle: <Translations category={"indicators"} id={1}/>,
        offerIncludes: offerIncludes,
        content: Content(),
        gif: indicatorsGif,
    }

    return (
        <Subpage config={config}/>
    )
}

export default Indicators