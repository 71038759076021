import React from "react"
import Template from "../../components/Template"
import Translations from "../../../components/Translations"

function Security() {
    const Content = () => {
        return (
            <div className={``}>
                <p className={"mb-5"}><Translations category={"docsSecurity"} id={1}/></p>
                <p className={"mb-5 text-yellow-500"}><Translations category={"docsSecurity"} id={2}/></p>
                <p className={"mb-5"}><Translations category={"docsSecurity"} id={3}/></p>
                <div className={"mb-5"}>
                    <li><Translations category={"docsSecurity"} id={4}/></li>
                    <li><Translations category={"docsSecurity"} id={5}/></li>
                    <li><Translations category={"docsSecurity"} id={6}/></li>
                    <li><Translations category={"docsSecurity"} id={7}/></li>
                </div>
                <p className={"mb-5"}><Translations category={"docsSecurity"} id={8}/></p>
                <div className={"mb-5"}>
                    <li><Translations category={"docsSecurity"} id={9}/></li>
                    <li><Translations category={"docsSecurity"} id={10}/></li>
                </div>
                <p className={"mb-5"}><Translations category={"docsSecurity"} id={11}/></p>
                <div className={"mb-5"}>
                    <Translations category={"docsSecurity"} id={12}/>
                    <li><Translations category={"docsSecurity"} id={13}/></li>
                    <li><Translations category={"docsSecurity"} id={14}/></li>
                    <li><Translations category={"docsSecurity"} id={15}/></li>
                    <li><Translations category={"docsSecurity"} id={16}/></li>
                </div>
            </div>
        )
    }
    const config = {
        title: <Translations category={"docs"} id={6}/>,
        previousLink: '/docs/how-to-connect',
        previousTitle: <Translations category={"docs"} id={5}/>,
        nextLink: '/docs/dashboard',
        nextTitle: <Translations category={"docs"} id={8}/>,
        content: Content(),
    }

    return (
        <Template config={config}/>
    )
}

export default Security