import React, {useEffect, useState} from 'react'
import styles, {color} from "../../style"
import axios from "axios"
import {BASEURL} from "../../globals/Constants"
import EditIcon from "@mui/icons-material/Edit"
import ButtonWithPopup from "../components/ButtonWithPopup"
import TimestampToDateTime from "../components/trading/TimestampToDateTime"
import CreateIcon from "@mui/icons-material/Create";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import {IsLocalhost, isLocalhost} from "../../globals/auth/IsLocalhost";
import notes from "../mocks/notes.json";
import {useDispatch} from "react-redux";
import {setMessage} from "../components/redux/GlobalState";
import DeleteIcon from "@mui/icons-material/Delete";

const Notes = () => {
    const dispatch = useDispatch()
    const [editingNote, setEditingNote] = useState(null)
    const [title, setTitle] = useState("")
    const [category, setCategory] = useState("")
    const [description, setDescription] = useState("")
    const [state, setState] = useState()
    const uniqueCategories = state?.length > 0 && [...new Set(state.map(item => item.category))]
    const [noteCategory, setNoteCategory] = useState("all")
    const [selectMode, setSelectMode] = useState(true)
    const fetchData = async () => {
        axios.get(`${BASEURL}/user_notes`)
            .then(res => setState(res.data))
            .catch(_ => _)
    }

    useEffect(() => {
        IsLocalhost(() => fetchData(), () => setState(notes))
    }, [])

    const handleSaveNote = () => {
        if (isLocalhost()) {
            if (editingNote) {
                axios.put(`${BASEURL}/user_notes/${editingNote}`, {
                    title: title,
                    note: description,
                    category: category,
                    seq: 1
                }).then(() => {
                    fetchData()
                }).catch(_ => _)
            } else {
                axios.post(`${BASEURL}/user_notes`, {
                    title: title,
                    note: description,
                    category: category,
                    seq: 1
                }).then(() => {
                    fetchData()
                }).catch(_ => _)
            }
            setEditingNote(null)
            setTitle("")
            setCategory("")
            setDescription("")
        } else {
            dispatch(setMessage({key: 'Alert', value: 'You cannot edit notes in demo version'}))
        }
    }

    const handleEditNote = (note) => {
        setEditingNote(note.id)
        setTitle(note.title)
        setCategory(note.category)
        setDescription(note.note)
    }

    const filteredNotes = noteCategory === 'all'
        ? state
        : state?.filter(note => note.category === noteCategory)
    return (
        <div className="flex flex-col w-full h-screen px-4 gap-2">
            <div>
                <p className="text-3xl pt-20 pb-2 text-white">Notes</p>
                {uniqueCategories?.length > 0 &&
                    <div className={`${styles.roundedBox} mb-2`}>
                        <select onChange={(e) => setNoteCategory(e.target.value)}
                                className="bg-[#353535] text-white text-sm block w-full outline-none">
                            <option value={"all"}>All</option>
                            {uniqueCategories.map((a) => <option value={a} key={a}>{a}</option>)}
                        </select>
                    </div>
                }
            </div>
            <ul className="grid grid-cols-1 gap-2 overflow-y-auto">
                {filteredNotes?.map((note, index) => (
                    <div
                        className={`${styles.roundedBox} col-span-1 mr-2`} key={index}>
                        <div className="flex justify-center w-full">
                            <div className="w-full">
                                <ul className={`${styles.rowBetween} flex items-center border-b border-[#10a7d0] mb-2`}>
                                    <div className="flex flex-row">
                                        {note.category && <span
                                            className="bg-orange-100 text-orange-800 text-sm font-semibold rounded mr-5 p-1 items-center flex">{note.category}</span>}
                                        <p className="text-lg font-bold text-[#f3f2ee] flex items-center break-words">{note.title}</p>
                                    </div>
                                    <div className="mr-2 last:mr-0 items-center flex flex-row" key={3}>
                                        <button type="button" onClick={() => handleEditNote(note)}>
                                            <EditIcon style={{fontSize: '25px'}}
                                                      className="text-white focus:outline-none font-medium rounded-full -m-1"/>
                                        </button>
                                        <ButtonWithPopup actionOnButtonYes={true}
                                                         title={"Do you really want to delete this note?"}
                                                         icon={<DeleteIcon style={{fontSize: '25px'}}
                                                                           className="text-white focus:outline-none font-medium rounded-full -m-1"/>}
                                                         onClick={() => axios.delete(`${BASEURL}/user_notes/${note.id}`).then(() => {
                                                             fetchData()
                                                         }).catch(_ => _)}/>
                                    </div>
                                </ul>
                                <div className="relative flex flex-col min-w-0 break-words w-full">
                                    <span className="text-white">{note.note}</span>
                                    <p className="text-xs flex justify-end mt-2 text-white">{TimestampToDateTime(note.time)}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </ul>

            <div className={`${styles.roundedBox} mb-2 pb-2 mt-auto`}>
                <div className="flex flex-row justify-between mb-2">
                    <p className="text-xl font-bold flex items-center text-white">
                        {editingNote !== null ? "Edit note" : "Add note"}
                    </p>
                    <button onClick={handleSaveNote}
                            className="bg-[#10a7d0] text-white p-0 px-3 items-center flex rounded">
                        {editingNote !== null ? "Save" : "Add"}
                    </button>
                </div>
                {uniqueCategories?.length > 0 ? selectMode ? (
                        <div className="flex-row flex bg-[#353535] border mb-2 py-2 pl-1">
                            {uniqueCategories?.length > 0 &&
                                <select id="states" label="Select Version" onChange={(e) => setCategory(e.target.value)}
                                        className="bg-[#353535] text-white text-base block w-full outline-none">
                                    {uniqueCategories.map((a) => <option value={a} key={a}>{a}</option>)}
                                </select>}
                            <CreateIcon onClick={() => {
                                setSelectMode(false)
                            }} className={`border-l border-[${color.accent}] cursor-pointer mx-1 pl-1 text-white`}/>
                        </div>
                    ) : (
                        <div className="flex flex-row gap-1 bg-[#353535] border items-center mb-2 pl-1">
                            <input
                                maxLength={20}
                                type="text"
                                placeholder="Category"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                                className="w-full p-2 bg-[#353535] outline-none border-0"
                            />
                            <FormatListBulletedIcon onClick={() => {
                                setSelectMode(true)
                            }} className={`border-l border-[${color.accent}] cursor-pointer mx-1 pl-1  text-white`}/>
                        </div>
                    ) :
                    <input
                        maxLength={20}
                        type="text"
                        placeholder="Category"
                        value={category}
                        onChange={(e) => setCategory(e.target.value)}
                        className="w-full p-2 mb-2 bg-[#353535] outline-none"
                    />
                }
                <input
                    maxLength={50}
                    type="text"
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="w-full p-2 border mb-2 bg-[#353535] rounded-none outline-none"
                />
                <textarea
                    placeholder="Description"
                    spellCheck="false"
                    value={description}
                    maxLength={500}
                    onChange={(e) => setDescription(e.target.value)}
                    className="w-full p-2 border bg-[#353535] text-white outline-none"
                />
            </div>
        </div>
    )
}

export default Notes