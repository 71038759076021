import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Navbar from '../components/Navbar'
import styles, {color} from "../../style";
import img1 from '../images/img1.svg'
import img2 from '../images/img2.svg'
import img5 from '../images/img3.svg'
import img6 from '../images/img4.svg'
import img7 from '../images/img5.svg'
import img10 from '../images/img6.svg'
import img16 from '../images/img7.svg'
import img19 from '../images/img8.svg'
import {Link} from "react-router-dom";
import SlideBox from "../components/slideBox";
import Translations from "../components/Translations";
import chart from '../gif/chart.gif'
import chart3 from '../gif/chart3.gif'
import CTA from "../components/CTA";

function LandingPage() {

    const slideBoxContentOne = [
        {description: <Translations category={"landingPage"} id={60}/>},
        {description: <Translations category={"landingPage"} id={61}/>},
        {description: <Translations category={"landingPage"} id={62}/>},
        {description: <Translations category={"landingPage"} id={63}/>},
        {description: <Translations category={"landingPage"} id={64}/>},
    ];

    const slideBoxContentTwo = [
        {description: <Translations category={"landingPage"} id={9}/>},
        {description: <Translations category={"landingPage"} id={10}/>},
        {description: <Translations category={"landingPage"} id={11}/>},
        {description: <Translations category={"landingPage"} id={12}/>},
        {description: <Translations category={"landingPage"} id={13}/>},
        {description: <Translations category={"landingPage"} id={14}/>},
    ];

    const slideBoxContentThree = [
        {description: <Translations category={"landingPage"} id={16}/>},
        {description: <Translations category={"landingPage"} id={17}/>},
        {description: <Translations category={"landingPage"} id={18}/>},
    ];

    const advantages = [24, 25, 26, 27, 28, 29, 30, 31, 32, 33];

    return (
        <div className={`container2 bg-[${color.main}]`}>
            <div className="h-screen w-screen overflow-scroll overflow-x-hidden lg:scroll-smooth ">
                <Navbar/>
                <Header/>
                <div className={`px-5 w-100 flex flex-col items-center justify-center`}>
                    <div className={`lg:mt-20 ${styles.maxWidth}`}>
                        <div className={`flex flex-col lg:flex-row justify-between items-center`}>
                            <h2 className={`w-fit ${styles.textHeading} lg:my-10 text-center lg:text-left`}>
                                <Translations category={"landingPage"} id={1}/>
                            </h2>
                            <img src={img6} alt='/' className={`h-[350px]`}/>
                        </div>
                        <div className={`grid md:grid-cols-2 xl:grid-cols-4 gap-3 md:gap-7`}>
                            <div
                                className={`bg-[${color.base}] ${styles.shadow} p-5 rounded hover:scale-105 duration-300`}>
                                <Link to="/strategy-creator">
                                    <p className={`text-3xl sm:text-5xl justify-end flex`}>01.</p>
                                    <h3 className={`pb-5 ${styles.textSubheading} border-b-4 border-[${color.accent}]`}>
                                        <Translations category={"menu"} id={16}/>
                                    </h3>
                                    <p className={`${styles.textParagraph}`}>
                                        <Translations category={"landingPage"} id={2}/>
                                    </p>
                                </Link>
                            </div>
                            <div
                                className={`bg-[${color.base}] ${styles.shadow} p-5 rounded hover:scale-105 duration-300`}>
                                <Link to="/backtests">
                                    <p className={`text-3xl sm:text-5xl justify-end flex`}>02.</p>
                                    <h3 className={`pb-5 ${styles.textSubheading} border-b-4 border-[${color.accent}]`}>
                                        <Translations category={"menu"} id={9}/>
                                    </h3>
                                    <p className={`${styles.textParagraph}`}>
                                        <Translations category={"landingPage"} id={3}/>
                                    </p>
                                </Link>
                            </div>
                            <div
                                className={`bg-[${color.base}] ${styles.shadow} p-5 rounded hover:scale-105 duration-300`}>
                                <Link to="/paper-trading">
                                    <p className={`text-3xl sm:text-5xl justify-end flex`}>03.</p>
                                    <h3 className={`pb-5 ${styles.textSubheading} border-b-4 border-[${color.accent}]`}>
                                        <Translations category={"menu"} id={13}/>
                                    </h3>
                                    <p className={`${styles.textParagraph}`}>
                                        <Translations category={"landingPage"} id={4}/>
                                    </p>
                                </Link>
                            </div>
                            <div
                                className={`bg-[${color.base}] ${styles.shadow} p-5 rounded hover:scale-105 duration-300`}>
                                <Link to="/automate-trading">
                                    <p className={`text-3xl sm:text-5xl justify-end flex`}>04.</p>
                                    <h3 className={`pb-5 ${styles.textSubheading} border-b-4 border-[${color.accent}]`}>
                                        <Translations category={"menu"} id={14}/>
                                    </h3>
                                    <p className={`${styles.textParagraph}`}>
                                        <Translations category={"landingPage"} id={5}/>
                                    </p>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.maxWidth} lg:py-12 2xl:py-160`}>
                        <div className={`flex flex-row items-center justify-between mb-10`}>
                            <div className={`flex justify-center items-center md:mr-20`}>
                                <img src={img5} alt='/' className={`h-[300px]`}/>
                            </div>
                            <h2 className={`${styles.textHeading}`}>
                                <Translations category={"landingPage"} id={6}/>
                            </h2>
                        </div>
                        <div className={`grid lg:grid-cols-2 gap-7`}>
                            <div className={`rounded`}>
                                <h2 className={`${styles.textSubheading}`}>
                                    <Translations category={"landingPage"} id={7}/>
                                </h2>
                                <p className={`${styles.textParagraph} mb-10 flex justify-end`}>~Warren Buffet</p>
                                <p className={`${styles.textParagraph}`}>
                                    <Translations category={"landingPage"} id={8}/>
                                </p>
                                <div className={`mt-10`}>
                                    <SlideBox content={slideBoxContentTwo}/>
                                </div>
                            </div>
                            <div className={`flex w-100 items-center`}>
                                <img src={chart} alt='/'
                                     className={`w-full h-full justify-self-start`}/>
                            </div>
                        </div>
                    </div>
                </div>
                <CTA/>
                <div className={`pl-6 pr-4 sm:px-16 w-100 flex flex-col items-center justify-center`}>
                    <div className={`mb-5 lg:mb-16`}>
                        <div
                            className={`${styles.maxWidth} rounded my-auto grid lg:grid-cols-4 gap-7 py-10 lg:py-12 2xl:py-16`}>
                            <div className={`hidden lg:flex flex-col gap-7`}>
                                <img src={img1} alt='/' className={`w-[300px]`}/>
                                <SlideBox content={slideBoxContentThree}/>
                            </div>
                            <div className={`col-span-3`}>
                                <h2 className={`${styles.textHeading} text-center lg:text-left mb-10`}>
                                    <Translations category={"landingPage"} id={19}/>
                                </h2>
                                <p className={`${styles.textParagraph}`}><br/>
                                    <Translations category={"landingPage"} id={20}/>
                                </p>
                                <p className={`${styles.textParagraph} mt-3`}>
                                    <Translations category={"landingPage"} id={21}/>
                                </p>
                                <p className={`${styles.textParagraph} mt-3`}>
                                    <Translations category={"landingPage"} id={22}/>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={`mb-5 lg:mb-16`}>
                        <div className={`${styles.maxWidth} rounded pb-10 lg:pb-12 2xl:pb-16`}>
                            <div className={`flex flex-row justify-between`}>
                                <h2 className={`${styles.textHeading} flex my-10`}>
                                    <Translations category={"landingPage"} id={23}/>
                                </h2>
                                <img src={img7} alt='/' className={`h-[200px] lg:h-[250px]`}/>
                            </div>
                            <div className={`hidden md:grid md:grid-cols-2 xl:grid-cols-4 gap-7`}>
                                {advantages.map((number) => (
                                    <div
                                        className={`${styles.colBetween} p-5 rounded bg-[${color.base}] ${styles.shadow}`}>
                                        <h3 className={`${styles.textParagraph} ${styles.blueLineLeft}`}>
                                            <Translations category={"landingPage"} id={number}/>
                                        </h3>
                                    </div>
                                ))}
                            </div>
                            <div
                                className={`md:hidden grid grid-cols-1 gap-3 p-5 rounded bg-[${color.base}] ${styles.shadow}`}>
                                {advantages.map((number) => (
                                    <h3 className={`${styles.textParagraph} ${styles.blueLineLeft}`}>
                                        <Translations category={"landingPage"} id={number}/>
                                    </h3>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={`mb-5 lg:mb-16`}>
                        <div className={`${styles.maxWidth} rounded pb-10 lg:pb-12 2xl:pb-16`}>
                            <div className={`flex flex-col lg:flex-row justify-between items-center`}>
                                <img src={img2} alt='/' className={`h-[300px]`}/>
                                <h2 className={`w-full ${styles.textHeading} my-10 lg:ml-24 lg:text-left text-center`}>
                                    <Translations category={"landingPage"} id={34}/>
                                </h2>
                            </div>
                            <div className={`grid lg:grid-cols-3 gap-3 md:gap-7`}>
                                <div
                                    className={`${styles.colBetween} p-5 rounded bg-[${color.base}] ${styles.shadow} hover:scale-105 duration-300`}>
                                    <div>
                                        <h3 className={`font-semibold text-center mb-10 ${styles.textSubheading}`}>
                                            <Translations category={"landingPage"} id={35}/>
                                        </h3>
                                        <p className={`font-normal ${styles.textParagraph}  mt-2 ${styles.blueLineLeft}`}>
                                            <Translations category={"landingPage"} id={36}/>
                                        </p>
                                        <p className={`font-normal ${styles.textParagraph}  mt-2 ${styles.blueLineLeft}`}>
                                            <Translations category={"landingPage"} id={37}/>
                                        </p>
                                        <p className={`font-normal ${styles.textParagraph}  mt-2 ${styles.blueLineLeft}`}>
                                            <Translations category={"landingPage"} id={38}/>
                                        </p>
                                        <p className={`font-normal ${styles.textParagraph}  mt-2 ${styles.blueLineLeft}`}>
                                            <Translations category={"landingPage"} id={39}/>
                                        </p>
                                        <p className={`font-normal ${styles.textParagraph}  mt-2 ${styles.blueLineLeft}`}>
                                            <Translations category={"landingPage"} id={40}/>
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={`${styles.colBetween} p-5 rounded bg-[${color.base}] ${styles.shadow} hover:scale-105 duration-300`}>
                                    <div>
                                        <h3 className={`font-semibold text-center mb-10 ${styles.textSubheading} leading-[32px]`}>
                                            <Translations category={"landingPage"} id={41}/>
                                        </h3>
                                        <p className={`font-normal ${styles.textParagraph}  mt-2 ${styles.blueLineLeft}`}>
                                            <Translations category={"landingPage"} id={42}/>
                                        </p>
                                        <p className={`font-normal ${styles.textParagraph}  mt-2 ${styles.blueLineLeft}`}>
                                            <Translations category={"landingPage"} id={43}/>
                                        </p>
                                        <p className={`font-normal ${styles.textParagraph}  mt-2 ${styles.blueLineLeft}`}>
                                            <Translations category={"landingPage"} id={44}/>
                                        </p>
                                        <p className={`font-normal ${styles.textParagraph}  mt-2 ${styles.blueLineLeft}`}>
                                            <Translations category={"landingPage"} id={45}/>
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className={`${styles.colBetween} p-5 rounded bg-[${color.base}] ${styles.shadow} hover:scale-105 duration-300`}>
                                    <div>
                                        <h3 className={`font-semibold text-center mb-10 ${styles.textSubheading} leading-[32px]`}>
                                            <Translations category={"landingPage"} id={46}/>
                                        </h3>
                                        <p className={`font-normal ${styles.textParagraph}  mt-2 ${styles.blueLineLeft}`}>
                                            <Translations category={"landingPage"} id={47}/>
                                        </p>
                                        <p className={`font-normal ${styles.textParagraph}  mt-2 ${styles.blueLineLeft}`}>
                                            <Translations category={"landingPage"} id={48}/>
                                        </p>
                                        <p className={`font-normal ${styles.textParagraph}  mt-2 ${styles.blueLineLeft}`}>
                                            <Translations category={"landingPage"} id={49}/>
                                        </p>
                                        <p className={`font-normal ${styles.textParagraph}  mt-2 ${styles.blueLineLeft}`}>
                                            <Translations category={"landingPage"} id={50}/>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`border-y border-[${color.accent}] ${styles.shadow} mb-5 lg:mb-16`}>
                    <div className={`${styles.maxWidth}`}>
                        <div className={`grid lg:grid-cols-3 items-center`}>
                            <h2 className={`text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl col-span-2 text-center mx-10 mt-12 lg:mt-0 lg:text-left`}>
                                <Translations category={"landingPage"} id={51}/>
                            </h2>
                            <div className={`flex flex-row items-center justify-center`}>
                                <Link to="/app/dashboard">
                                    <button className={`bg-[${color.accent}] ml-[100px] text-2xl w-[300px]`}>
                                        <Translations category={"header"} id={3}/>
                                    </button>
                                </Link>
                                <img src={img10} alt='/' className={`w-[100px] relative -left-16 pt-32`}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`pl-6 pr-4 sm:px-16 w-100 flex flex-col items-center justify-center`}>
                    <div className={`mb-5 lg:mb-16`}>
                        <div className={`${styles.maxWidth} rounded py-10 lg:py-12 2xl:py-16 gap-7 flex flex-col`}>
                            <div className={`flex flex-col lg:flex-row gap-10 items-center`}>
                                <img src={img16} alt='/' className={`h-[250px] transform`}/>
                                <div className="relative w-full h-[250px]">
                                    <img src={chart3} alt="Opis obrazka"
                                         className="w-full flex h-[250px] justify-self-end"/>
                                    <div className="absolute top-0">
                                        <h2 className={`${styles.textHeading} flex items-center text-center`}>
                                            <Translations category={"landingPage"} id={52}/>
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className={`grid lg:grid-cols-3 gap-7`}>
                                <div className={`bg-[${color.base}] ${styles.shadow} p-5 col-span-2 rounded`}>
                                    <h2 className={`${styles.textSubheading}`}>
                                        <Translations category={"landingPage"} id={53}/>
                                    </h2>
                                    <p className={`${styles.textParagraph} mb-10 flex justify-end`}>~Anthony Robbins</p>
                                    <p className={`${styles.textParagraph}`}>
                                        <h3 className={`${styles.blueLineLeft} mb-1`}>
                                            <Translations category={"landingPage"} id={54}/>
                                        </h3>
                                        <h3 className={`${styles.blueLineLeft} mb-1`}>
                                            <Translations category={"landingPage"} id={55}/>
                                        </h3>
                                        <h3 className={`${styles.blueLineLeft} mb-1`}>
                                            <Translations category={"landingPage"} id={56}/>
                                        </h3>
                                        <h3 className={`${styles.blueLineLeft} mb-1`}>
                                            <Translations category={"landingPage"} id={57}/>
                                        </h3>
                                        <h3 className={`${styles.blueLineLeft} mb-1`}>
                                            <Translations category={"landingPage"} id={58}/>
                                        </h3>
                                        <h3 className={`${styles.blueLineLeft} mb-1`}>
                                            <Translations category={"landingPage"} id={59}/>
                                        </h3>
                                    </p>
                                </div>
                                <div className={`flex justify-center items-center col-span-2 lg:col-span-1`}>
                                    <SlideBox content={slideBoxContentOne}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`mb-5 lg:mb-16`}>
                        <div className={`${styles.maxWidth} rounded py-10 lg:py-12 2xl:py-16 gap-7`}>
                            <div className={`flex flex-row justify-center items-center`}>
                                <h2 className={`flex ${styles.textHeading} relative left-10`}>
                                    <Translations category={"landingPage"} id={65}/>
                                </h2>
                                <img src={img19} alt='/' className={`h-[250px] lg:h-[400px] relative right-10`}/>
                            </div>
                            <div
                                className={`${styles.maxWidth} rounded py-10 lg:py-12 2xl:py-16 grid lg:grid-cols-3 gap-7`}>
                                <div>
                                    <p className={`${styles.textSubheading} flex justify-center`}>
                                        Eddy Crowell
                                    </p>
                                    <p className={`${styles.textParagraph} bg-[${color.base}] ${styles.shadow} p-5 items-center flex-grow`}>
                                        <Translations category={"landingPage"} id={66}/>
                                    </p>
                                </div>
                                <div>
                                    <p className={`${styles.textSubheading} flex justify-center`}>
                                        Susan Rush
                                    </p>
                                    <p className={`${styles.textParagraph} bg-[${color.base}] ${styles.shadow} p-5 items-center flex-grow`}>
                                        <Translations category={"landingPage"} id={67}/>
                                    </p>
                                </div>
                                <div>
                                    <p className={`${styles.textSubheading} flex justify-center`}>
                                        Mark Bergman
                                    </p>
                                    <p className={`${styles.textParagraph} bg-[${color.base}] ${styles.shadow} p-5 items-center flex-grow`}>
                                        <Translations category={"landingPage"} id={68}/>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`w-100 flex flex-col items-center justify-center border-t border-[${color.accent}] pt-8`}>
                    <Footer/>
                </div>
            </div>
        </div>)
}

export default LandingPage
