import React, {useEffect} from "react"
import styles, {color} from "../../style"
import WaterfallChartIcon from "@mui/icons-material/WaterfallChart";
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import Subpage from "../components/Subpage";
import automatetrading from "../gif/automatetrading.gif";
import img24 from "../images/img12.svg";
import Translations from "../components/Translations";

function AutomatedTrading() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const offerIncludes = [
        {description: <Translations category={"automateTrading"} id={11}/>},
        {description: <Translations category={"automateTrading"} id={12}/>},
        {description: <Translations category={"automateTrading"} id={13}/>},
        {description: <Translations category={"automateTrading"} id={14}/>},
        {description: <Translations category={"automateTrading"} id={15}/>},
        {description: <Translations category={"automateTrading"} id={16}/>},
        {description: <Translations category={"automateTrading"} id={17}/>},
        {description: <Translations category={"automateTrading"} id={18}/>},
        {description: <Translations category={"automateTrading"} id={19}/>},
        {description: <Translations category={"automateTrading"} id={20}/>},
        {description: <Translations category={"automateTrading"} id={21}/>},
        {description: <Translations category={"automateTrading"} id={22}/>},
        {description: <Translations category={"automateTrading"} id={23}/>},
        {description: <Translations category={"automateTrading"} id={24}/>},
        {description: <Translations category={"automateTrading"} id={25}/>},
        {description: <Translations category={"automateTrading"} id={26}/>},
        {description: <Translations category={"automateTrading"} id={27}/>},
    ];


    const configIconSection = {
        iconOne: <WaterfallChartIcon sx={{fontSize: 50}}/>,
        iconTwo: <WorkspacesIcon sx={{fontSize: 50}}/>,
        iconThree: <AutoGraphIcon sx={{fontSize: 50}}/>,
        textOne: <Translations category={"automateTrading"} id={2}/>,
        textTwo: <Translations category={"automateTrading"} id={3}/>,
        textThree: <Translations category={"automateTrading"} id={4}/>,
    }

    const quotes= [
        <Translations category={"automateTrading"} id={8}/>,
        <Translations category={"automateTrading"} id={9}/>,
        <Translations category={"automateTrading"} id={10}/>
    ]

    const Content = () => {
        return (
            <div>
                <div className={`grid grid-cols-1 lg:grid-cols-6 gap-5`}>
                    <div className={`col-span-4 flex flex-col justify-between`}>
                        <div></div>
                        <div>
                            <p className={`${styles.textParagraph}`}>
                                <Translations category={"automateTrading"} id={5}/>
                            </p>
                            <p className={`${styles.textParagraph} my-10`}>
                                <Translations category={"automateTrading"} id={6}/>
                            </p>
                            <p className={`${styles.textParagraph}`}>
                                <Translations category={"automateTrading"} id={7}/>
                            </p>
                        </div>
                    </div>
                    <img src={img24} alt='/' className={`col-span-2 w-[500px]  m-auto`}/>
                </div>
                <div className={`col-span-2 mt-10`}>
                    <div className={`grid lg:grid-cols-3 gap-5`}>
                        {quotes.map((quote, index) => (
                            <div className={`bg-[${color.base}] p-8 rounded ${styles.shadow}`}>
                                <p className={`${styles.textParagraph} text-center`}>
                                    {quote}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }

    const config = {
        configIconSection: configIconSection,
        title: <Translations category={"menu"} id={14}/>,
        subtitle: <Translations category={"automateTrading"} id={1}/>,
        offerIncludes: offerIncludes,
        content: Content(),
        gif: automatetrading,
    }

    return (
        <Subpage config={config}/>
    )
}

export default AutomatedTrading