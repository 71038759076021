import React, {useEffect} from "react"
import Navbar from "./Navbar"
import CTA from "./CTA"
import Footer from "./Footer"
import styles, {color} from "../../style"
import Header from "./features/Header"
import OtherFeatures from "./otherFeatures"
import IconSection from "./IconSection";
import OfferIncludes from "./OfferIncludes";

function Subpage({config}) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={`${styles.backgroundMain}`}>
            <Navbar/>
            <Header title={config.title} subtitle={config.subtitle}/>
            <div className={`${styles.maxWidth} rounded my-10 lg:my-20 px-4`}>
                <IconSection config={config.configIconSection}/>
                <div className={`mt-12`}>
                    {config.content}
                </div>
                <div className={`flex col-span-2 justify-center my-12 lg:my-24`}>
                    <OfferIncludes content={config.offerIncludes}/>
                </div>
                <div className={`flex w-100 items-center`}>
                    <img src={config.gif} alt='/'
                         className={`w-full h-full justify-self-start p-1 lg:p-3 ${styles.shadow} border-4 border-[${color.accent}]`}/>
                </div>
                <OtherFeatures/>
            </div>
            <CTA/>
            <Footer/>
        </div>
    )
}

export default Subpage