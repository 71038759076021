import React from "react"
import styles from "../../../style";
import {Link} from "react-router-dom";
import img16 from "../../../website/images/img16.svg";
import img18 from "../../../website/images/img18.svg";

const Welcome = () => {

    return (
        <div className="flex flex-col gap-4">
            <div
                className={`${styles.roundedBox} grid grid-cols-1 lg:grid-cols-5 2xl:grid-cols-7 items-center justify-between py-10`}>
                <div className={`col-span-1 lg:col-span-3 2xl:col-start-2`}>
                    <p className="text-3xl text-[#10a7d0] font-bold flex justify-center">
                        Welcome in CryptoDeputy demo
                    </p>
                    <p className="mt-8 text-lg">
                        {/*Thank you for registering. We're glad you're interested in our systems. The examples available*/}
                        {/*in this version of the application have been generated using the CryptoDeputy program, but if*/}
                        {/*you want to use real historical tests in the FREE version, you'll need to download the*/}
                        {/*application to your computer. Please note that to access low intervals (1m, 3m, 5m, 15m, 30m),*/}
                        {/*all assets available on Binance, as well as paper and automatic trading, a higher package will*/}
                        {/*be required. We wish you fruitful exploration and high profits!*/}
                        Thank you for registering. We're glad you're interested in our systems. The examples available
                        in this version of the application have been generated using the CryptoDeputy program, but if
                        you want to use real historical tests and trading bots, you'll need to download the
                        application to your computer. As long as we remain in the Beta version, you can use all
                        functionalities. We wish you fruitful exploration and high profits!
                    </p>
                    <div className={`${styles.flexCenter} mt-8`}>
                        <a href={"/update/CryptoDeputyInstall.exe"} download>
                            <button className={styles.blueButton}>Download</button>
                        </a>
                    </div>
                </div>
                <img src={img16} alt='/'
                     className={`col-span-1 lg:col-span-2 2xl:col-span-1 lg:pl-10 2xl:pl-0 mt-10 lg:mt-0 w-[50%] lg:w-[100%] m-auto 2xl:col-start-6`}/>
            </div>
            {/*<div*/}
            {/*    className={`${styles.roundedBox} grid grid-cols-1 lg:grid-cols-5 2xl:grid-cols-7 gap-5 items-center justify-between py-10`}>*/}
            {/*    <img src={img18} alt='/'*/}
            {/*         className={`col-span-1 lg:col-span-2 2xl:col-span-1 lg:mr-10 2xl:mr-0 w-[50%] lg:w-[100%] m-auto 2xl:col-start-2`}/>*/}
            {/*    <div className={`col-span-1 lg:col-span-3 2xl:col-start-4`}>*/}
            {/*        <p className="text-3xl text-[#10a7d0] font-bold flex justify-center">Referral Program</p>*/}
            {/*        <p className="mt-8 flex text-lg justify-center">*/}
            {/*            We also invite you to take advantage of our referral program, which allows you to start earning*/}
            {/*            passive income. You will receive 20% of the profits from each paid plan of users invited by you.*/}
            {/*            In order to receive a reward, simply copy the referral link found in subscription panel and*/}
            {/*            share it with your friends. Upon their first payment for any of CryptoDeputy’s plans, you will*/}
            {/*            earn 20% of their payment, deposited directly into your referral balance.*/}
            {/*        </p>*/}
            {/*        <div className={`${styles.flexCenter} mt-8`}>*/}
            {/*            <Link to="/app/subscription">*/}
            {/*                <button className={styles.blueButton}>Subscription</button>*/}
            {/*            </Link>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

export default Welcome