import React from 'react'
import EditIcon from '@mui/icons-material/Edit'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import {useNavigate} from "react-router-dom"
import axios from "axios"
import {BASEURL} from "../../globals/Constants"
import styles from "../../style"
import ButtonWithPopup from "./ButtonWithPopup"
import {IsLocalhost} from "../../globals/auth/IsLocalhost";
import {setMessage} from "./redux/GlobalState";
import {useDispatch} from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";

const StrategyCard = ({strategy, id, fetchData, activeStrategy}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const navigateToEdit = () => {
        IsLocalhost(() => navigate(`/app/strategies/edit/${id}`, {state: {refreshData: fetchData}}),
            () => navigate(`/app/strategies/edit/${id}`))
    }

    function handleFavourite() {
        const entity = {
            name: strategy.name,
            description: strategy.description,
            strategy: strategy.strategy,
            isFavorite: !strategy.isFavorite
        }
        IsLocalhost(() => {
            axios.put(`${BASEURL}/user_strategies/${id}`, entity).then(() => {
                fetchData()
            }).catch(_ => _)
        }, () => dispatch(setMessage({
            key: 'Alert',
            value: 'You cannot set strategy as favourite in demo version'
        })))
    }

    return (
        <div
            className={`p-3 rounded-lg  ${activeStrategy === id ? 'bg-[#464545]' : "bg-[#353535]"} cursor-pointer col-span-2 shadow`}>
            <div className="flex justify-center">
                <div className="w-full">
                    <ul className={`${styles.rowBetween} ${strategy.description !== "" ? 'pb-4 border-b border-[#10a7d0]' : ''}`}>
                        <h5 className={`text-2xl font-bold ${activeStrategy === id ? 'text-[#10a7d0]' : "text-[#f3f2ee]"} `}>{strategy.name}</h5>
                        <li className="-mb-px mr-2 last:mr-0 items-center flex flex-row" key={3}>
                            <button type="button" onClick={navigateToEdit}
                                    className="text-white  focus:outline-none font-medium rounded-full text-sm p-2 text-center inline-flex items-center ">
                                <EditIcon/>
                                <span className="sr-only">Edit</span>
                            </button>
                            <ButtonWithPopup actionOnButtonYes={true}
                                             icon={<DeleteIcon style={{fontSize: '25px'}}
                                                               className="text-white focus:outline-none font-medium rounded-full -m-1"/>}
                                             title={"Do you really want to delete this strategy?"}
                                             onClick={() => IsLocalhost(() => {
                                                 axios.delete(`${BASEURL}/user_strategies/${id}`).then(() => {
                                                     fetchData()
                                                 }).catch(_ => _)
                                             }, () => dispatch(setMessage({
                                                 key: 'Alert',
                                                 value: 'You cannot delete strategies in demo version'
                                             })))}/>
                            <button id="favIcon" type="button"
                                    className="text-[#10a7d0] hover:text-white  focus:outline-none font-medium rounded-full text-sm p-2 text-center inline-flex items-center "
                                    onClick={() => handleFavourite()}>
                                {strategy.isFavorite ? (
                                    <FavoriteIcon/>
                                ) : (
                                    <FavoriteBorderIcon/>
                                )}
                                <span className="sr-only">Edit</span>
                            </button>
                        </li>
                    </ul>
                    {strategy.description !== "" &&
                        <div className="relative flex flex-col min-w-0 break-words w-full mt-3">
                            <div className="tab-content tab-space">
                                <span className={`${styles.flexCenter} break-all`}>{strategy.description}</span>
                            </div>
                        </div>}
                </div>
            </div>
        </div>
    )
}

export default StrategyCard
