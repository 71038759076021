import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom"
import StrategyCard from "../../components/StrategyCard"
import axios from "axios"
import {BASEURL} from "../../../globals/Constants"
import styles from "../../../style"
import Table from "../../components/Table"
import TextWithColor from "../../components/TextWithColor"

import user_strategies from "../../mocks/user_strategies.json"
import stats_strategies from "../../mocks/stats_strategies.json"
import stats_agg_trades from "../../mocks/stats_agg_trades.json"
import {IsLocalhost} from "../../../globals/auth/IsLocalhost"
import {backTestTradesColumns} from "../../components/tradingPanel/Trades"
import {setTradingView} from "../../components/redux/GlobalState";
import {useDispatch} from "react-redux";

export const columns = [
    {accessorKey: 'symbol', header: 'Symbol', flex: 1, size: 100},
    {accessorKey: 'intervl', header: 'Interval', flex: 1, size: 60},
    {accessorKey: 'leverage', header: 'Leverage', flex: 1, size: 60},
    {
        accessorKey: 'profit', header: 'Profit', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === null ? '-' : renderedCellValue > 0 ?
            <p className="text-green-500 text-xs">{renderedCellValue}</p> :
            <p className="text-red-500 text-xs">{renderedCellValue}</p>)
    },
    {accessorKey: 'dtFrom', header: 'From', flex: 1, size: 100},
    {accessorKey: 'dtTo', header: 'To', flex: 1, size: 100},
    {accessorKey: 'allTrades', header: 'All trades', flex: 1, size: 100},
    {accessorKey: 'effectiveness', header: 'Effectiveness', flex: 1, size: 100},
    {accessorKey: 'lowestBet', header: 'Lowest bet', flex: 1, size: 100},
    {accessorKey: 'highestBet', header: 'Highest bet', flex: 1, size: 100},
    {accessorKey: 'minBalance', header: 'Min balance', flex: 1, size: 100},
    {accessorKey: 'maxBalance', header: 'Max balance', flex: 1, size: 100},
    {accessorKey: 'wonTrades', header: 'Won trades', flex: 1, size: 100},
    {accessorKey: 'lostTrades', header: 'Lost trades', flex: 1, size: 100},
    {accessorKey: 'maxProfit', header: 'Max profit', flex: 1, size: 100},
    {accessorKey: 'maxLoss', header: 'Max loss', flex: 1, size: 100},
    {accessorKey: 'longs', header: 'Longs', flex: 1, size: 100},
    {accessorKey: 'shorts', header: 'Shorts', flex: 1, size: 100},
    {accessorKey: 'maxWinStreak', header: 'Max win streak', flex: 1, size: 100},
    {accessorKey: 'maxLossStreak', header: 'Max loss streak', flex: 1, size: 100},
    {accessorKey: 'avgLoss', header: 'Average loss', flex: 1, size: 100},
    {accessorKey: 'avgProfit', header: 'Average profit', flex: 1, size: 100},
    {accessorKey: 'avgTradesPerDay', header: 'Avg. trades per day', flex: 1, size: 100},
    {accessorKey: 'endingBalance', header: 'Ending balance', flex: 1, size: 100},
    {accessorKey: 'liquidations', header: 'Liquidations', flex: 1, size: 100},
    {
        accessorKey: 'returnOfEquity', header: 'Rtrn. of equity', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === null ? '-' : renderedCellValue > 0 ?
            <p className="text-green-500 text-xs">{renderedCellValue}</p> :
            <p className="text-red-500 text-xs">{renderedCellValue}</p>)
    },
    {
        accessorKey: 'expectedRateOfReturn', header: 'Exp. rate of return', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === null ? '-' : renderedCellValue > 0 ?
            <p className="text-green-500 text-xs">{renderedCellValue}</p> :
            <p className="text-red-500 text-xs">{renderedCellValue}</p>)
    },
    {accessorKey: 'maxBet', header: 'Max bet', flex: 1, size: 100},
    {accessorKey: 'days', header: 'Days', flex: 1, size: 100},
    {accessorKey: 'monday', header: 'Monday', flex: 1, size: 100},
    {accessorKey: 'tuesday', header: 'Tuesday', flex: 1, size: 100},
    {accessorKey: 'thursday', header: 'Thursday', flex: 1, size: 100},
    {accessorKey: 'friday', header: 'Friday', flex: 1, size: 100},
    {accessorKey: 'saturday', header: 'Saturday', flex: 1, size: 100},
    {accessorKey: 'sunday', header: 'Sunday', flex: 1, size: 100},
    {accessorKey: 'userStrategyId', header: 'Strategy id', flex: 1, size: 100},
    {accessorKey: 'market', header: 'Market', flex: 1, size: 100},
    {accessorKey: 'source', header: 'Source', flex: 1, size: 100},
]

const Strategies = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const navigateToNew = () => {
        IsLocalhost(() => navigate('/app/strategies/new', {state: {refreshData: fetchData}}),
            () => navigate('/app/strategies/new'))
    }

    const [state, setState] = useState()
    const [statistics, setStatistics] = useState([])
    const [aggTrades, setAggTrades] = useState([])
    const [sourceBox, setSourceBox] = useState("All")
    const [marketBox, setMarketBox] = useState("All")
    const [clickedRow, setClickedRow] = useState(null)
    const [activeStrategy, setActiveStrategy] = useState()

    const fetchData = async () => {
        IsLocalhost(() => axios.get(`${BASEURL}/user_strategies`)
            .then(res => setState(res.data))
            .catch(_ => _), () => setState(user_strategies))
    }

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        if (clickedRow) {
            IsLocalhost(() => axios.get(`${BASEURL}/stats_agg_trades?equals.strategyId=${clickedRow}&sort.time=desc`)
                    .then(res => {
                        if (statistics.length > 0) {
                            const row = statistics.filter(a => a.id === clickedRow)[0]
                            dispatch(setTradingView({
                                symbol: row.symbol + (row.market === "futures" ? '.P' : ''),
                                interval: row.intervl
                            }))
                        }
                        handleAll(setAggTrades, res.data)}).catch(_ => _),
                () => handleAll(setAggTrades, stats_agg_trades.filter(a => a.strategyId === clickedRow)))
        } else {
            IsLocalhost(() => axios.get(`${BASEURL}/stats_agg_trades?sort.time=desc`)
                    .then(res => handleAll(setAggTrades, res.data)).catch(_ => _),
                () => handleAll(setAggTrades, stats_agg_trades))
        }
    }, [clickedRow])

    useEffect(() => {
        IsLocalhost(() => axios.get(`${BASEURL}/stats_strategies?sort.time=desc`)
            .then(res => {
                handleAll(setStatistics, res.data)
            }).catch(_ => _), () => handleAll(setStatistics, stats_strategies))

        IsLocalhost(() => axios.get(`${BASEURL}/stats_agg_trades?sort.time=desc`)
                .then(res => handleAll(setAggTrades, res.data)).catch(_ => _),
            () => handleAll(setAggTrades, stats_agg_trades))
    }, [sourceBox, marketBox])

    function showSpecificResultsById(id, event) {
        if(activeStrategy !== id){
            setActiveStrategy(id)
            if (!event.target.closest('#favIcon')) { //not react with heart icon
                IsLocalhost(() => axios.get(`${BASEURL}/stats_strategies?equals.userStrategyId=${id}&sort.time=desc`)
                        .then(res => handleAll(setStatistics, res.data)).catch(_ => _),
                    () => handleAll(setStatistics, stats_strategies.filter(a => a.userStrategyId === id)))
                IsLocalhost(() => axios.get(`${BASEURL}/stats_agg_trades?equals.userStrategyId=${id}&sort.time=desc`)
                        .then(res => handleAll(setAggTrades, res.data)).catch(_ => _),
                    () => handleAll(setAggTrades, stats_agg_trades.filter(a => a.userStrategyId === id)))
            }
        } else {
            setActiveStrategy(undefined)
            if (!event.target.closest('#favIcon')) { //not react with heart icon
                IsLocalhost(() => axios.get(`${BASEURL}/stats_strategies?sort.time=desc`)
                        .then(res => handleAll(setStatistics, res.data)).catch(_ => _),
                    () => handleAll(setStatistics, stats_strategies))
                IsLocalhost(() => axios.get(`${BASEURL}/stats_agg_trades?sort.time=desc`)
                        .then(res => handleAll(setAggTrades, res.data)).catch(_ => _),
                    () => handleAll(setAggTrades, stats_agg_trades))
            }
        }
    }

    function handleAll(set, data) {
        const dataWithFixedMaxBet = data.map(a => !a.maxBet ? {
            ...a, maxBet: "full balance"
        } : a)
        const sourceFilter = sourceBox === "All" ? dataWithFixedMaxBet : dataWithFixedMaxBet.filter(a => a.source === sourceBox)
        const marketFilter = marketBox === "All" ? sourceFilter : sourceFilter.filter(a => a.market === marketBox.toLowerCase())
        return set(marketFilter)
    }

    function filterByStrategyIdWhenClicked(data) {
        return clickedRow ? data.filter(a => a.id === clickedRow) : data
    }


    console.log(activeStrategy)

    return <div className="flex flex-col w-full">
        <div className={`${styles.rowBetween} mb-4`}>
            <p className="text-4xl">Strategies</p>
            <button onClick={navigateToNew}
                    className={`${styles.blueButton}`}>
                Create
            </button>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-8 lg:gap-4">
            <div className="col-span-1 lg:col-span-2 lg:mt-20">
                <div className="gap-4 flex flex-col">
                    {state &&
                        state.filter(a => a.isFavorite === true).map((strategy) => {
                            return (<div onClick={(event) => showSpecificResultsById(strategy.id, event)}><StrategyCard
                                strategy={strategy} id={strategy.id} activeStrategy={activeStrategy}
                                fetchData={fetchData}/></div>)
                        })
                    }
                    {state &&
                        state.filter(a => a.isFavorite === false).map((strategy) => {
                            return (<div onClick={(event) => showSpecificResultsById(strategy.id, event)}><StrategyCard
                                strategy={strategy} id={strategy.id} activeStrategy={activeStrategy}
                                fetchData={fetchData}/></div>)
                        })
                    }
                </div>
            </div>
            <div className="col-span-6 flex flex-col mt-10 lg:mt-0 gap-4">
                <div className="grid grid-cols-2 lg:grid-cols-6 items-center">
                    <TextWithColor title={"Won"} color={"text-green-500"}
                                   description={filterByStrategyIdWhenClicked(statistics).map(a => a.wonTrades).reduce((total, num) => total + num, 0)}/>
                    <TextWithColor title={"Lost"} color={"text-red-500"}
                                   description={filterByStrategyIdWhenClicked(statistics).map(a => a.lostTrades).reduce((total, num) => total + num, 0)}/>
                    <TextWithColor title={"Liquidations"} color={"text-yellow-500"}
                                   description={filterByStrategyIdWhenClicked(statistics).map(a => a.liquidations).reduce((total, num) => total + num, 0)}/>
                    <TextWithColor title={"Effectiveness"} color={"text-red-500"}
                                   description={statistics.length === 0 ? "-" : (filterByStrategyIdWhenClicked(statistics).map(a => a.wonTrades).reduce((total, num) => total + num, 0) /
                                       filterByStrategyIdWhenClicked(statistics).map(a => a.allTrades).reduce((total, num) => total + num, 0) * 100).toFixed(2) + '%'}/>
                    <TextWithColor title={"Trades"} color={"text-yellow-500"}
                                   description={filterByStrategyIdWhenClicked(statistics).map(a => a.allTrades).reduce((total, num) => total + num, 0)}/>
                    <div className="flex-row grid grid-cols-2 gap-2">
                        <div className={`flex flex-col`}>
                            <p className={`text-xs pl-2`}>Mode</p>
                            <div className={`bg-[#353535] p-2 rounded-lg`}>
                                <select value={sourceBox}
                                        className="bg-[#353535] text-white block w-full outline-none"
                                        onChange={(e) => setSourceBox(e.target.value)}>
                                    <option value="All">All</option>
                                    <option value="BackTest">Backtest</option>
                                    <option value="PaperTrading">Paper trading</option>
                                    <option value="AutomateTrading">Automate trading</option>
                                </select>
                            </div>
                        </div>
                        <div className={`flex flex-col`}>
                            <p className={`text-xs pl-2`}>Market</p>
                            <div className={`bg-[#353535] p-2 rounded-lg`}>
                                <select value={marketBox}
                                        className="bg-[#353535] text-white block w-full outline-none"
                                        onChange={(e) => setMarketBox(e.target.value)}>
                                    <option value="All">All</option>
                                    <option value="Spot">Spot</option>
                                    <option value="Futures">Futures</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <Table columns={columns} data={statistics} clickedRow={clickedRow} color={true} click={(id) => {
                    id === clickedRow ? setClickedRow(null) : setClickedRow(id)
                }} rows={10}/>
                <Table columns={backTestTradesColumns} data={aggTrades} rows={10}/>
            </div>
        </div>
    </div>
}

export default Strategies