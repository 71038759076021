import React from "react";
import translationPL from "../../globals/assets/translations/translation.json";

const NewlineText = ({category, id}) => {
    const lang = 'en';
    const json = translationPL[category].find(a => {
        return a.id === id
    })
    return json[lang].split('</br>').map((part, index) => <p key={index} className="text-sm m-2">{part}</p>)
}

export default NewlineText