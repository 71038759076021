import {createSlice} from '@reduxjs/toolkit'
import Cookies from "../../../globals/cookies/Cookies"
import {getLastConfigurationDefaults} from "./DefaultsLastConfigurations";

const initialState = {
    symbols: [],
    backTestSymbolsFutures: [],
    backTestSymbolsSpot: [],
    strategies: [],
    longAlerts: [],
    lastConfigurations: getLastConfigurationDefaults(),
    strategyParams: {
        indicators: {},
        shortcuts: {},
        conditions: {},
        stops: {},
    },
    binanceAssets: [],
    message: {},
    activeBots: [],
    plan: "",
    tradingView: {
        symbol: "BTCUSDT",
        interval: "1H",
    },
    market: {
        dashboard: Cookies.getCookie("market-dashboard") || "futures",
        papertrading: Cookies.getCookie("market-papertrading") || "futures",
        automatetrading: Cookies.getCookie("market-automatetrading") || "futures",
        backtests: Cookies.getCookie("market-backtests") || "futures",
        monitoring: Cookies.getCookie("market-monitoring") || "futures",
    },
    language: Cookies.getCookie('language') || 'en',
}
export const globalState = createSlice({
    name: 'coins',
    initialState,
    reducers: {
        setSymbols: (state, action) => {
            state.symbols = action.payload
        },
        setBackTestSymbolsFutures: (state, action) => {
            state.backTestSymbolsFutures = action.payload
        },
        setBackTestSymbolsSpot: (state, action) => {
            state.backTestSymbolsSpot = action.payload
        },
        setStrategies: (state, action) => {
            state.strategies = action.payload
        },
        setLastConfigurations: (state, action) => {
            state.lastConfigurations = action.payload
                .map(config => fillDefaults(config, state.lastConfigurations.find(item => item.id === config.id)))
        },
        setBinanceAssets: (state, action) => {
            state.binanceAssets = action.payload
        },
        setStrategyParams: (state, action) => {
            const {key, data} = action.payload
            if (state.strategyParams.hasOwnProperty(key)) {
                state.strategyParams[key] = data
            }
        },
        setMessage: (state, action) => {
            state.message = action.payload
        },
        setLongAlerts: (state, action) => {
            state.longAlerts = action.payload
        },
        setTradingView: (state, action) => {
            state.tradingView = action.payload
        },
        setActiveBots: (state, action) => {
            state.activeBots = action.payload
        },
        setLanguage: (state, action) => {
            state.language = action.payload
        },
        setPlan: (state, action) => {
            state.plan = action.payload
        },
        setMarket: (state, action) => {
            const {key, data} = action.payload
            if (state.market.hasOwnProperty(key)) {
                state.market[key] = data
            }
        },
    },
})

function fillDefaults(config, defaults) {
    const copyConfig = {...config}
    for (const key in copyConfig) {
        if (copyConfig[key] === null) {
            copyConfig[key] = defaults[key]
        }
    }
    return copyConfig
}

export const {
    setSymbols,
    setBackTestSymbolsFutures,
    setBackTestSymbolsSpot,
    setStrategies,
    setLastConfigurations,
    setLongAlerts,
    setBinanceAssets,
    setStrategyParams,
    setMessage,
    setActiveBots,
    setLanguage,
    setTradingView,
    setPlan,
    setMarket,
} = globalState.actions

export const selectSymbols = (state) => state.coins.symbols
export const selectBackTestSymbolsFutures = (state) => state.coins.backTestSymbolsFutures
export const selectBackTestSymbolsSpot = (state) => state.coins.backTestSymbolsSpot
export const selectStrategies = (state) => state.coins.strategies
export const selectLastConfigurations = (state) => state.coins.lastConfigurations
export const selectBinanceAssets = (state) => state.coins.binanceAssets
export const selectStrategyParams = (state) => state.coins.strategyParams
export const selectMessages = (state) => state.coins.message
export const selectActiveBots = (state) => state.coins.activeBots
export const selectLanguage = (state) => state.coins.language
export const selectLongAlerts = (state) => state.coins.longAlerts
export const selectTradingView = (state) => state.coins.tradingView
export const selectPlan = (state) => state.coins.plan
export const selectMarket = (state) => state.coins.market
export default globalState.reducer
