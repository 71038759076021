import React, {useState} from "react"
import styles, {color} from "../../../../style"
import CreateIcon from '@mui/icons-material/Create'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import PinIcon from '@mui/icons-material/Pin';
import {generateUniqueId} from "./StrategyCommons"

function Condition({configCondition}) {
    const [showCandleNumberOne, setShowCandleNumberOne] = useState(false)
    const [showCandleNumberTwo, setShowCandleNumberTwo] = useState(false)
    const [inputValues, setInputValues] = useState({
        value: (configCondition.editModal.isEdit && (configCondition.editModal.openTab === 1)) ? configCondition.inputValues.value : Object.values(configCondition.condition)[0].reflectionMethod.params.map(param => param.default),
        parameter:  (configCondition.editModal.isEdit && (configCondition.editModal.openTab === 1))? configCondition.inputValues.parameter : Object.keys(configCondition.condition)[0],
        sign: '',
    })
    const handleSave = () => {
        if (inputValues.parameter && !configCondition.editModal.isEdit) {
            let keyName = configCondition.keyName
            if (configCondition.keyName === "close" && ((configCondition.closeType.buy === false) || ((configCondition.closeType.sell === false)))) {
                keyName = configCondition.closeType.buy === false ? "closeSell" : "closeBuy"
            } else {
                keyName = configCondition.keyName
            }
            const newItem = {
                id: generateUniqueId(),
                value: inputValues.value,
                parameter: inputValues.parameter
            }
            configCondition.setStrategy((prevData) => ({
                ...prevData,
                [keyName]: [...prevData[keyName], newItem],
            }))
            configCondition.setCloseType({sell: true, buy: true})
            configCondition.setShowModal(false)
        } else if (inputValues.parameter && configCondition.editModal.isEdit) {
            const updatedItems = {...configCondition.strategy}
            updatedItems[configCondition.keyName][configCondition.editModal.index] = {
                id: updatedItems[configCondition.keyName][configCondition.editModal.index].id,
                value: inputValues.value,
                parameter: inputValues.parameter
            }
            configCondition.setStrategy(updatedItems)
            configCondition.setEditModal({
                isEdit: false,
                index: -1,
            })
            configCondition.setCloseType({
                sell: true,
                buy: true,
            })
            configCondition.setCloseType({sell: true, buy: true})
            configCondition.setShowModal(false)
        }
    }

    function numeric() {
        return /^\d*(.+)/;
    }

    return (
        <div>
            <div className={`${styles.rowBetween} mb-10`}>
                <p className="text-xl">Add Condition</p>
                <button onClick={handleSave}
                        className="bg-[#10a7d0] h-[30px] text-white flex font-bold items-center rounded">Save
                </button>
            </div>
            <div className="grid grid-cols-11 gap-10">
                <div className="flex justify-between flex-col col-span-4">
                    {configCondition.selectMode ? (
                        <div className="flex-row flex justify-between rounded bg-[#1d1d1d]">
                            {showCandleNumberOne ?
                                <div className="flex flex-row gap-1 bg-[#1d1d1d] items-center h-[32px]">
                                    <PinIcon onClick={() => {
                                        setShowCandleNumberOne(false)
                                    }} className={`border-r border-[${color.accent}] cursor-pointer mt-1 ml-1 pr-1`}/>
                                    <select
                                        value={inputValues.value[0].match(/^\d+/) ? inputValues.value[0].match(/^\d+/)[0] : "0"}
                                        onChange={(e) => {
                                            const newValue = e.target.value;
                                            let currentValue;
                                            if (newValue === '0') {
                                                currentValue = inputValues.value[0].replace(/^\d+/, '');
                                            } else {
                                                currentValue = inputValues.value[0].match(/^\d+/) ? inputValues.value[0].replace(/^\d+/, newValue) : newValue + inputValues.value[0].replace(/^\d+/, '');
                                            }
                                            setInputValues({
                                                ...inputValues,
                                                value: [currentValue, ...inputValues.value.slice(1)]
                                            });
                                        }}
                                        className={`bg-[#1d1d1d] text-white text-lg w-full m-1 border-r text-left outline-none border-[${color.accent}]`}>
                                        {Array.from({length: 101}, (_, index) => index).map((value) => (
                                            <option key={value} value={value}>{value}</option>
                                        ))}
                                    </select>
                                </div> : <PinIcon onClick={() => {
                                    setShowCandleNumberOne(true)
                                }} className={`border-r border-[${color.accent}] cursor-pointer mt-1 ml-1 pr-1`}/>}
                            <div className="flex flex-row gap-1 bg-[#1d1d1d] rounded items-center pr-1 h-[32px]">
                                <select
                                    value={inputValues.value[0].match(numeric())[1]}
                                    onChange={(e) => {
                                        const updatedValue = inputValues.value[0].replace(inputValues.value[0].match(numeric())[1], e.target.value);
                                        setInputValues({
                                            ...inputValues,
                                            value: [updatedValue, ...inputValues.value.slice(1)]
                                        });
                                    }}
                                    className="bg-[#1d1d1d] text-white text-lg text-right rounded outline-none w-full p-1"
                                >
                                    {Object.entries(configCondition.shortcut).map(([key, value]) => (
                                        <option key={value.shortName}
                                                value={value.shortName}>{value.name === "Candle Close" ? (value.name + " (Current price)") : value.name}</option>
                                    ))}
                                </select>
                                <CreateIcon onClick={() => {
                                    configCondition.setSelectMode(false)
                                    setInputValues({
                                        ...inputValues,
                                        value: ["0", ...inputValues.value.slice(1)]
                                    })
                                }} className={`border-l border-[${color.accent}] cursor-pointer pl-1`}/>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-row rounded gap-1 bg-[#1d1d1d] items-center pr-1 h-[32px]">
                            <input
                                type="text"
                                maxLength="13"
                                className="bg-[#1d1d1d] text-white text-sm border-none w-full p-1 outline-none"
                                value={inputValues.value[0]}
                                onChange={(e) => {
                                    const input = e.target.value
                                    const regex = /^[-+]?\d{0,6}(\.\d{0,6})?$/

                                    if (/^\d*\.?\d*$/.test(input) && regex.test(input)) {
                                        setInputValues({
                                            ...inputValues,
                                            value: [input, ...inputValues.value.slice(1)]
                                        })
                                    }
                                }}
                                placeholder="Wprowadź liczbę zmiennoprzecinkową"
                            />
                            <FormatListBulletedIcon onClick={() => {
                                configCondition.setSelectMode(true)
                                setInputValues({
                                    ...inputValues,
                                    value: [Object.keys(configCondition.shortcut)[0], ...inputValues.value.slice(1)]
                                })
                            }} className={`border-l border-[${color.accent}] cursor-pointer pl-1`}/>
                        </div>
                    )}
                </div>
                <div className="flex items-end col-span-3">
                    <select value={inputValues.parameter}
                            onChange={(e) => setInputValues({
                                ...inputValues,
                                parameter: e.target.value
                            })}
                            className="bg-[#1d1d1d] rounded text-white text-right text-lg w-full outline-none p-1">
                        {Object.entries(configCondition.condition).map(([key, value]) => (
                            <option key={key} value={key}>{value.name} ({value.mathSymbol})</option>
                        ))}
                    </select>
                </div>
                <div className="flex justify-between flex-col col-span-4">
                    {configCondition.selectMode2 ? (
                        <div className="flex-row flex justify-between rounded bg-[#1d1d1d]">
                            {showCandleNumberTwo ?
                                <div className="flex flex-row gap-1 bg-[#1d1d1d] items-center h-[32px]">
                                    <PinIcon onClick={() => {
                                        setShowCandleNumberTwo(false)
                                    }} className={`border-r border-[${color.accent}] cursor-pointer mt-1 ml-1 pr-1`}/>
                                <select
                                    value={inputValues.value[1].match(/^\d+/) ? inputValues.value[1].match(/^\d+/)[0] : "0"}
                                    className={`bg-[#1d1d1d] text-white text-lg w-full m-1 border-r outline-none border-[${color.accent}]`}
                                    onChange={(e) => {
                                        const newValue = e.target.value;
                                        let currentValue;
                                        if (newValue === '0') {
                                            currentValue = inputValues.value[1].replace(/^\d+/, '');
                                        } else {
                                            currentValue = inputValues.value[1].match(/^\d+/) ? inputValues.value[1].replace(/^\d+/, newValue) : newValue + inputValues.value[1].replace(/^\d+/, '');
                                        }
                                        setInputValues({
                                            ...inputValues,
                                            value: [...inputValues.value.slice(0, 1), currentValue]
                                        });
                                    }}>
                                    {Array.from({length: 101}, (_, index) => index).map((value) => (
                                        <option key={value} value={value}>{value}</option>
                                    ))}
                                </select>
                            </div> : <PinIcon onClick={() => {
                                    setShowCandleNumberTwo(true)
                                }} className={`border-r border-[${color.accent}] cursor-pointer mt-1 ml-1 pr-1`}/>}
                            <div className="flex flex-row gap-1 rounded bg-[#1d1d1d] items-center pr-1 h-[32px]">
                                <select
                                    value={inputValues.value[1].match(numeric())[1]}
                                    onChange={(e) => {
                                        const updatedValue = inputValues.value[1].replace(inputValues.value[1].match(numeric())[1], e.target.value);
                                        setInputValues({
                                            ...inputValues,
                                            value: [...inputValues.value.slice(0, 1), updatedValue]
                                        });
                                    }}
                                    className="bg-[#1d1d1d] text-white text-lg rounded text-right w-full outline-none p-1">
                                    {Object.entries(configCondition.shortcut).map(([key, value]) => (
                                        <option key={value.shortName}
                                                value={value.shortName}>{value.name === "Candle Close" ? (value.name + " (Current price)") : value.name}</option>
                                    ))}
                                </select>
                                <CreateIcon onClick={() => {
                                    configCondition.setSelectMode2(false)
                                    setInputValues({
                                        ...inputValues,
                                        value: [...inputValues.value.slice(0, 1), "0"]
                                    })
                                }} className={`border-l border-[${color.accent}] cursor-pointer pl-1`}/>
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-row gap-1 bg-[#1d1d1d] items-center pr-1 h-[32px]">
                            <input
                                type="text"
                                maxLength="13"
                                className="bg-[#1d1d1d] text-white text-sm border-none rounded w-full p-1 outline-none"
                                value={inputValues.value[1]}
                                onChange={(e) => {
                                    const input = e.target.value
                                    const regex = /^[-+]?\d{0,6}(\.\d{0,6})?$/

                                    if (regex.test(input)) {
                                        setInputValues({
                                            ...inputValues,
                                            value: [...inputValues.value.slice(0, 1), input]
                                        })
                                    }
                                }}
                                placeholder="Wprowadź liczbę zmiennoprzecinkową"
                            />
                            <FormatListBulletedIcon onClick={() => {
                                configCondition.setSelectMode2(true)
                                setInputValues({
                                    ...inputValues,
                                    value: [...inputValues.value.slice(0, 1), Object.keys(configCondition.shortcut)[0]]
                                })
                            }} className={`border-l border-[${color.accent}] cursor-pointer pl-1`}/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Condition