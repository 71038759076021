import styles from "../../../style";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {deleteBots, increaseSlots} from "../trading/TradingCommons";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ButtonWithPopup from "../ButtonWithPopup";
import Table from "../Table";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {BASEURL} from "../../../globals/Constants";
import {selectStrategies, setActiveBots, setMessage} from "../redux/GlobalState";
import {IsLocalhost, isLocalhost} from "../../../globals/auth/IsLocalhost";
import activeimg from "../../../globals/assets/icons/active.png";
import inactiveimg from "../../../globals/assets/icons/nonactive.png";
import DeleteIcon from "@mui/icons-material/Delete";
import {ToggleOff, ToggleOn} from "@mui/icons-material";
import {useWebSocket} from "../../../globals/socket/Socket";
import MainTablePopup from "../MainTablePopup";

const MainTable = ({source, mainTableData, showSpecificResultsById, market}) => {
    const dispatch = useDispatch()
    const strategies = useSelector(selectStrategies)
    const filteredMainTableData = mainTableData.filter(row => row.market === market)
    const [updateSlots, setUpdateSlots] = useState(true)
    const [slots, setSlots] = useState(0)
    const [clickedRow, setClickedRow] = useState(null)
    const ws = useWebSocket()

    useEffect(() => {
        if (ws) {
            let data = JSON.parse(ws)
            if (data.key === "ActiveBots" && data.source === source) {
                dispatch(setActiveBots(JSON.parse(data.value)))
            } else if (data.key === "FullStats" && data.source === source) {
                setSlots(JSON.parse(data.value).slots)
            }
        }
    }, [ws])

    useEffect(() => {
        if (updateSlots) {
            if (isLocalhost()) {
                axios.get(`${BASEURL}/slots?market=${market}&source=${source}`)
                    .then(res => {
                        setSlots(res.data)
                        setUpdateSlots(false)
                    })
                    .catch(_ => _)
            }
        }
    }, [updateSlots])

    useEffect(() => {
        if (source === "BackTest" && filteredMainTableData.length > 0 && (clickedRow === null || !filteredMainTableData.map(a => a.id).includes(clickedRow))) {
            setClickedRow(filteredMainTableData[0].id)
        }
    }, [filteredMainTableData])

    const backtestFuturesColumns = [
        {
            accessorKey: 'userStrategyId',
            Cell: ({row, renderedCellValue}) => (
                strategies.filter(a => a.id === renderedCellValue).length === 0 ?
                    <div className="flex flex-row items-center gap-3">
                        <MainTablePopup strategy={row.original.strategy.substring(row.original.strategy.indexOf('indicators'), row.original.strategy.indexOf('&maxBet'))}/>
                        <p className="text-sm text-red-500">Undefined</p></div> :
                    <div className="flex flex-row items-center gap-3">
                        <MainTablePopup strategy={strategies.filter(a => a.id === renderedCellValue)[0].strategy}/>
                        {strategies.filter(a => a.id === renderedCellValue)[0].name}
                    </div>
            ),
            header: 'Strategy',
            flex: 1,
            size: 50,
        },
        {accessorKey: 'symbol', header: 'Symbol', flex: 1, size: 100},
        {accessorKey: 'intervl', header: 'Interval', flex: 1, size: 100},
        {accessorKey: 'leverage', header: 'Leverage', flex: 1, size: 100},
        {
            accessorKey: 'maxBet', header: 'Max Bet', flex: 1, size: 100,
            Cell: ({row, renderedCellValue}) => (
                <p className="text-sm">{renderedCellValue === null ? "full balance" : renderedCellValue}</p>
            ),
        },
        {accessorKey: 'dtFrom', header: 'From', flex: 1, size: 100},
        {accessorKey: 'dtTo', header: 'To', flex: 1, size: 100},
        {accessorKey: 'market', header: 'Market', flex: 1, size: 100},
    ]

    const backtestSpotColumns = [
        {
            accessorKey: 'userStrategyId',
            Cell: ({row, renderedCellValue}) => (
                strategies.filter(a => a.id === renderedCellValue).length === 0 ?
                    <div className="flex flex-row items-center gap-3">
                        <MainTablePopup strategy={row.original.strategy.substring(row.original.strategy.indexOf('indicators'), row.original.strategy.indexOf('&maxBet'))}/>
                        <p className="text-sm text-red-500">Undefined</p></div> :
                    <div className="flex flex-row items-center gap-3">
                        <MainTablePopup strategy={strategies.filter(a => a.id === renderedCellValue)[0].strategy}/>
                        {strategies.filter(a => a.id === renderedCellValue)[0].name}
                    </div>
            ),
            header: 'Strategy',
            flex: 1,
            size: 50,
        },
        {accessorKey: 'symbol', header: 'Symbol', flex: 1, size: 100},
        {accessorKey: 'intervl', header: 'Interval', flex: 1, size: 100},
        {
            accessorKey: 'maxBet', header: 'Max Bet', flex: 1, size: 100,
            Cell: ({row, renderedCellValue}) => (
                <p className="text-sm">{renderedCellValue === null ? "full balance" : renderedCellValue}</p>
            ),
        },
        {accessorKey: 'dtFrom', header: 'From', flex: 1, size: 100},
        {accessorKey: 'dtTo', header: 'To', flex: 1, size: 100},
        {accessorKey: 'market', header: 'Market', flex: 1, size: 100},
    ]

    const activeBotsColumns = [
        {accessorKey: 'strategyName', header: 'Strategy', flex: 1, size: 100},
        {accessorKey: 'symbol', header: 'Symbol', flex: 1, size: 100},
        {accessorKey: 'intervl', header: 'Interval', flex: 1, size: 100},
        {accessorKey: 'leverage', header: 'Leverage', flex: 1, size: 100},
        {accessorKey: 'market', header: 'Market', flex: 1, size: 100},
        {
            accessorKey: 'active', header: 'Active', flex: 1, size: 100,
            Cell: ({row, renderedCellValue}) => (
                <img src={row.original.active ? activeimg : inactiveimg} width={"20px"} alt='/'></img>
            ),
        },
        {
            accessorKey: 'id',
            Cell: ({row, renderedCellValue}) => (
                <div className={"flex flew-row space-x-1"}>
                    <ButtonWithPopup actionOnButtonYes={true} title={"Are you sure you want to delete this trade?"}
                                     icon={<DeleteIcon style={{fontSize: '25px'}}
                                                       className="text-white focus:outline-none font-medium rounded-full -m-1"/>}
                                     onClick={() => deleteActiveBot(renderedCellValue, dispatch)}/>
                    <ButtonWithPopup actionOnButtonYes={true}
                                     icon={row.original.active ? <ToggleOff style={{fontSize: '25px'}}
                                                                            className="text-white focus:outline-none font-medium rounded-full -m-1"/> :
                                         <ToggleOn style={{fontSize: '25px'}}
                                                   className="text-white focus:outline-none font-medium rounded-full -m-1"/>}
                                     title={"Are you sure you want to change activity of this trade?"}
                                     onClick={() => {
                                         IsLocalhost(() => axios.get(`${BASEURL}/botActivate?id=${renderedCellValue}`).catch(_ => _),
                                             () => dispatch(setMessage({
                                                 key: 'Alert',
                                                 value: 'You cannot change bot activity in demo version'
                                             })))
                                     }}/>
                </div>
            ),
            header: 'Actions',
            flex: 1,
            size: 50,
        },
    ]

    const deleteActiveBot = (id, dispatch) => {
        IsLocalhost(() => {
            axios.delete(`${BASEURL}/active_bots/${id}`).catch(_ => _)
        }, () => dispatch(setMessage({key: 'Alert', value: 'You cannot delete bots in demo version'})))
    }

    return (
        <div className="col-span-2 lg:col-span-8 xl:col-span-8 2xl:col-span-6 flex flex-col">
            <div className={`${styles.rowBetween} items-center mb-4`}>
                {source === "BackTest" ? <span className="text-2xl">Backtests history</span> : <>
                    <span className="text-2xl">Active bots</span>
                    <div className="flex flex-row items-center">
                        <p className="mr-3 text-xl">slots: </p>
                        <ArrowBackIosNewIcon onClick={() => {
                            increaseSlots(source, false, dispatch, market)
                            setUpdateSlots(true)
                            setUpdateSlots(true)
                        }} className="cursor-pointer hover:text-[#10a7d0]"/>
                        <p className="mx-2 text-xl">{slots}</p>
                        <ArrowForwardIosIcon onClick={() => {
                            increaseSlots(source, true, dispatch, market)
                            setUpdateSlots(true)
                        }} className="cursor-pointer hover:text-[#10a7d0]"/>
                        <div
                            className={`${filteredMainTableData.filter(bot => bot.source === source).length > 0 ? "" : "hidden"} w-[150px] ml-5`}>
                            <ButtonWithPopup buttonText={"Delete All"} actionOnButtonYes={true}
                                             title={"Do you really want to remove this note?"}
                                             onClick={() => deleteBots(source, dispatch, market)}/>
                        </div>
                    </div>
                </>}
            </div>
            <div className="flex-grow bg-[#353535] p-3 rounded-lg">
                <Table columns={source === "BackTest" ? (market === "spot" ? backtestSpotColumns : backtestFuturesColumns) : activeBotsColumns}
                       data={filteredMainTableData}
                       clickedRow={clickedRow} color={true}
                       rows={5} click={(id) => {
                    id === clickedRow && source !== "BackTest" ? setClickedRow(null) : setClickedRow(id)
                    id === clickedRow && source !== "BackTest" ? showSpecificResultsById() : showSpecificResultsById(id)
                }}/>
            </div>
        </div>
    )
}

export default MainTable



