import React from "react"
import Template from "../../components/Template"
import img1 from "../../images/stops/falling.jpg"
import Translations from "../../../components/Translations"
import style from "../../../../style"

function TimeStopLoss() {
    const Content = () => {
        return (
            <div>
                <img src={img1} alt='/' className={`${style.borderImg} mt-5 mb-10 w-[20%]`}></img>
                <p className={`text-lg mt-5`}><Translations category={"docsTimeStopLoss"} id={1}/></p>
                <p className={`text-lg mt-5`}>
                    <Translations category={"docsTimeStopLoss"} id={2}/>
                    <li><Translations category={"docsTimeStopLoss"} id={3}/></li>
                    <li><Translations category={"docsTimeStopLoss"} id={4}/></li>
                </p>
            </div>
        )
    }
    const config = {
        title: <Translations category={"docs"} id={33}/>,
        previousLink: '/docs/time-trailing-stop-loss',
        previousTitle: <Translations category={"docs"} id={32}/>,
        nextLink: '',
        nextTitle: '',
        content: Content(),
    }

    return (
        <Template config={config}/>
    )
}

export default TimeStopLoss