export const title = "Terms and conditions"
export const subtitle = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
export const terms = [
    {
        title: "1. Definitions",
        description: "CryptoDeputy: Refers to CryptoDeputy B.V., a legal entity established under Dutch law, holding registration number 70270481 at the Dutch Chamber of Commerce. The company's official address is Johan van Hasseltweg 18A, 1022 WV Amsterdam. For the sake of simplicity, we will refer to CryptoDeputy as 'we'.\n" +
            "Account: The personal user account created on our Platform, exclusively for the user's use through their login credentials.\n" +
            "Signals: Recommendations for the purchase or sale of cryptocurrencies shared by third-party 'Signal Providers,' also known as 'Hoppers,' collaborating with CryptoDeputy. Users can configure the Platform to automatically usertrading currencies based on these Signals.\n" +
            "Platform: The software developed by CryptoDeputy to facilitate user interaction with Signal Providers, allowing for cryptocurrency trading in line with provided Signals. This Platform is accessible through our Website and App.\n" +
            "Subscription Term: The initial Subscription period, plus any subsequent renewal terms.\n" +
            "User(s): Refers to individual private individuals or legal entities who utilize our Services. Throughout these terms, we will use 'you' to refer to Users."
    },
    {
        title: "2. Registration and Personal Account",
        description: "To access our Services, visit our Website and/or download our App.\n" +
            "To unlock all Platform features, registration and Account creation are mandatory. You will need to provide an email address and choose a strong password during registration, collectively known as 'Login Credentials'.\n" +
            "Please note that you can only deposit fiat currency into your Account, and once deposited, it cannot be withdrawn. Any profits generated through Hoppers can be withdrawn as fiat currency. Cryptocurrencies cannot be deposited or withdrawn through the Account.\n" +
            "You are solely responsible for your Account and must not permit third-party use. It's crucial to safeguard your Login Credentials. In the event your Account is blocked or deleted, you won't be allowed to use the Platform again, including creating a new Account.\n" +
            "You must ensure the accuracy of information provided during Account creation, and the data in your Account is your responsibility. CryptoDeputy retains the right to restrict certain Account, Platform, or Service functionalities and impose additional requirements for Account approval at any time."
    },
    {
        title: "3. Offers and Prices",
        description: "All Service offers and free trials are governed by these Terms.\n" +
            "Prices listed on our Website, App, and Platform do not include taxes and fees, unless explicitly stated otherwise.\n" +
            "CryptoDeputy reserves the right to modify prices at any time, and such changes may take immediate effect.\n" +
            "CryptoDeputy is not bound by offers or quotations that clearly contain an error or mistake."
    },
    {
        title: "4. Subscription",
        description: "Access to all Platform features requires a Subscription. Subscriptions are available for a fixed monthly or yearly fee, which you can apply for on our Website.\n" +
            "CryptoDeputy offers various Subscription packages with differences in the number of positions, selected currencies, stock market scanning frequency, and CryptoDeputy support. You can find detailed package information on our Website.\n" +
            "The Subscription begins and expires based on the start and end dates specified in the selected subscription package, unless terminated earlier by the User or by CryptoDeputy due to User violations of these Terms and applicable laws and regulations (the 'Initial Term').\n" +
            "Upon Subscription, you will receive immediate access to the Platform as per your chosen Subscription. Each User can configure one Hopper on the Platform per subscription.\n" +
            "Following the Initial Term, Subscriptions will automatically renew for the same duration as the Initial Term unless CryptoDeputy or the User gives written notice via the Website or Platform to cancel the Subscription. Termination will occur one month after this notice.\n" +
            "You have the right to cancel the Subscription within 14 days of applying for it."
    },
    {
        title: "5. Payment",
        description: "The Subscription fee will be billed to the User on a monthly basis. Payment methods, including PayPal, credit cards (such as Visa or MasterCard), and cryptocurrencies, are available and listed on the Website.\n" +
            "If CryptoDeputy is unable to collect fees or if a User fails to pay within the stipulated period, CryptoDeputy has the authority to disable specific Platform or Account functions, without affecting other rights.\n" +
            "The User's Account will remain active for the period already paid for."
    },
    {
        title: "6. Fair Usage of Our Platform",
        description: "To use our Platform, you must be at least 18 years old.\n" +
            "The Platform (and other Services) should not be used in a way that violates Dutch or any other applicable laws and regulations, including financial and tax laws.\n" +
            "By using the Platform (or other Services), you agree not to provide incorrect, inaccurate, incomplete information, or content.txt that breaches any laws or regulations or goes against general standards. Additionally, you must refrain from, among other actions:\n" +
            " - Accessing non-public/secure Platform areas\n" +
            " - Transmitting viruses, spam, chain letters, or unsolicited offers\n" +
            " - Investigating, scanning, or testing the Platform or related systems\n" +
            " - Using automated systems to collect data (screen-scraping)\n" +
            " - Making, distributing, copying, renting, or altering the Platform\n" +
            " - Attempting to sell, distribute, copy, or misuse the Platform\n" +
            "CryptoDeputy reserves the right to temporarily or permanently disable Platform functions if a User breaches this clause, other Terms, or causes harm to CryptoDeputy, Users, or Signal Providers."
    },
    {
        title: "7. Privacy",
        description: "CryptoDeputy respects your privacy and is compliant with the EU General Data Protection Regulation (GDPR). While using our Services, we collect specific personal data from you. Our Privacy Policy details the personal data we collect and the purposes for which it's used. You can review our privacy policy here: https://www.CryptoDeputy.com/privacy."
    },
    {
        title: "8. Intellectual Property",
        description: "CryptoDeputy holds exclusive licenses for all intellectual property rights related to our Services, including patents, trademarks, copyrights, and other forms of intellectual property. Subject to compliance with your obligations, you receive a non-exclusive, non-transferable, non-sublicensable, and revocable license to use our Services in line with these Terms and your Subscription. In the event of non-compliance with your obligations, CryptoDeputy can revoke this license without prior notice."
    },
    {
        title: "9. Availability of Services and Disclaimer of Warranties",
        description: "The Platform is available on various devices, including computers, iOS, and Android handheld devices. While CryptoDeputy strives to ensure uninterrupted access, due to the nature of our Services, we cannot guarantee error-free and uninterrupted access to the Platform.\n" +
            "CryptoDeputy aims to carefully select Signal Providers but is not responsible for Signal quality or content.txt. User assumes all risk associated with the Signals.\n" +
            "CryptoDeputy is not liable for Platform (or Services) unavailability, content.txt access difficulties, or communication system failures that render the Services unavailable.\n" +
            "CryptoDeputy may periodically update, modify, or adapt the Platform and its features, with an effort to minimize downtime. CryptoDeputy is not responsible for support or maintenance of the Platform or any downtime arising from these actions.\n" +
            "To the maximum extent allowed by law, CryptoDeputy disclaims all implied warranties related to Service availability. Services are provided \"as is\" and \"as available\" without warranties of any kind.\n" +
            "While CryptoDeputy takes care in compiling Services, we do not endorse third parties involved in Platform access. For third-party usage, Users are solely responsible.\n" +
            "Regarding liability, CryptoDeputy and User agree to the following:\n" +
            " - Unless there is willful misconduct or gross negligence on CryptoDeputy's part, CryptoDeputy is not liable for any direct or indirect damages or costs incurred by Users.\n" +
            " - CryptoDeputy is not liable for User damages due to incorrect or incomplete information provided by the User.\n" +
            " - In case of liability, CryptoDeputy's liability is limited to $1,000 USD or 100% of the total amount paid by the User for the Services in the previous 6 months, or the amount disbursed by CryptoDeputy's insurance company, whichever is lower.\n" +
            " - Without affecting other provisions in these Terms, CryptoDeputy is not liable for damages resulting from Platform (or other Services) access or content.txt use, including loss of profit, damage to equipment, or the actions of third parties."
    },
    {
        title: "10. Helpdesk",
        description: "CryptoDeputy operates a helpdesk where Users can seek assistance related to Services functionality.\n" +
            "CryptoDeputy explicitly does not:\n" +
            " - Provide Users with personal advice on Hopper settings\n" +
            " - Offer personal financial advice\n" +
            "CryptoDeputy may upload general tutorials and academy videos on the Website concerning Service usage.\n" +
            "All tutorials, videos, and templates provided by CryptoDeputy are general and do not constitute personal or financial advice. User usage of these materials is at their own risk.\n" +
            "Users can link their Hopper to a Signal Provider, which are not provided by CryptoDeputy but by external advisors. Usage of Signal Providers is at the User's risk."
    },
    {
        title: "11. Risks",
        description: "Our Services are suitable for Users with a full understanding of the associated risks. This includes awareness of cryptocurrency market price volatility and the potential for substantial trading or holding losses. You must only invest money that you can afford to lose.\n" +
            "You acknowledge that there may be additional risks in cryptocurrency trading that are not explicitly stated in these Terms or on our Website, App, or Platform. You are responsible for carefully assessing all risks and determining if your knowledge, financial situation, and risk tolerance are suitable for Service usage and volatile cryptocurrencies.\n" +
            "You understand and accept the risks related to Service usage, including the risk of errors, interruptions, and assume full responsibility for these risks.\n" +
            "CryptoDeputy does not provide investment, legal, or tax advice and does not consider your personal circumstances or investment objectives. If you need such advice, you must seek independent, professional guidance before using our Services."
    },
    {
        title: "12. Indemnification",
        description: "User shall indemnify, defend, and hold CryptoDeputy harmless from liabilities, damages, and costs arising from third-party claims related to User's behavior concerning Service usage and breaches of these Terms or any applicable law, regulation, or order."
    },
    {
        title: "13. Hyperlinks",
        description: "When CryptoDeputy provides hyperlinks to third-party websites, it does not imply endorsement of the products or services offered on these websites. Using such hyperlinks is entirely at your own risk. CryptoDeputy assumes no responsibility or liability regarding the content.txt, use, or availability of these websites. The accuracy, reliability, and completeness of information on these sites have not been verified by CryptoDeputy."
    },
    {
        title: "14. Miscellaneous",
        description: "CryptoDeputy retains the right to amend these Terms. In cases of significant changes, Users will be notified via newsletter (if an email address is provided) and through a notification on our Website, along with the updated Terms. By continuing to use our Services, you acknowledge the most recent version of these Terms.\n" +
            "Failure to enforce certain provisions of these Terms does not imply a waiver of the right to enforce them at a later time or against other Users.\n" +
            "Users cannot transfer rights and obligations from these Terms to third parties.\n" +
            "Invalidity or unenforceability of any provision in these Terms will not affect the validity or enforceability of other provisions. Such invalid or unenforceable provision will be replaced by one that is considered valid and enforceable, with an interpretation that aligns closely with the intent of the invalid provision.\n" +
            "These Terms are exclusively governed by Dutch law. Any disputes arising from or related to these Terms will be submitted exclusively to the competent court in Amsterdam, the Netherlands, unless an amicable resolution is possible."
    },
    {
        title: "15. Complaints, Comments, and Suggestions",
        description: "CryptoDeputy aims to provide optimal service. If you have complaints, comments, or suggestions, please contact us at legal@CryptoDeputy.com. Please provide your contact details, a clear description, and the reason for your complaint. We typically process complaints within 7 working days."
    },
];