import React from "react"
import styles, {color} from "../../style"
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import SpeedIcon from '@mui/icons-material/Speed';
import SavingsIcon from '@mui/icons-material/Savings';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import WaterfallChartIcon from '@mui/icons-material/WaterfallChart';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import HistoryIcon from '@mui/icons-material/History';
import backtestGif from '../gif/backtest.gif'
import Subpage from "../components/Subpage";
import img23 from "../images/img11.svg";
import PaidIcon from '@mui/icons-material/Paid';
import Translations from "../components/Translations";

function BacktestsWebsite() {
    const offerIncludes = [
        {description: <Translations category={"backtests"} id={13}/>},
        {description: <Translations category={"backtests"} id={14}/>},
        {description: <Translations category={"backtests"} id={15}/>},
        {description: <Translations category={"backtests"} id={16}/>},
        {description: <Translations category={"backtests"} id={17}/>},
        {description: <Translations category={"backtests"} id={18}/>},
        {description: <Translations category={"backtests"} id={19}/>},
        {description: <Translations category={"backtests"} id={20}/>},
        {description: <Translations category={"backtests"} id={21}/>},
        {description: <Translations category={"backtests"} id={22}/>},
        {description: <Translations category={"backtests"} id={23}/>},
        {description: <Translations category={"backtests"} id={24}/>},
        {description: <Translations category={"backtests"} id={25}/>},
        {description: <Translations category={"backtests"} id={26}/>},
        {description: <Translations category={"backtests"} id={27}/>},
        {description: <Translations category={"backtests"} id={28}/>},
        {description: <Translations category={"backtests"} id={29}/>},
    ];

    const configIconSection = {
        iconOne: <WaterfallChartIcon sx={{fontSize: 50}}/>,
        iconTwo: <MonetizationOnIcon sx={{fontSize: 50}}/>,
        iconThree: <HistoryIcon sx={{fontSize: 50}}/>,
        textOne: <Translations category={"backtests"} id={2}/>,
        textTwo: <Translations category={"backtests"} id={3}/>,
        textThree: <Translations category={"backtests"} id={4}/>,
    }

    const content = [
        {icon: <PanToolAltIcon sx={{fontSize: 50}}/>, text: <Translations category={"backtests"} id={6}/>},
        {icon: <SpeedIcon sx={{fontSize: 50}}/>, text: <Translations category={"backtests"} id={7}/>},
        {icon: <SavingsIcon sx={{fontSize: 50}}/>, text: <Translations category={"backtests"} id={8}/>},
        {icon: <PaidIcon sx={{fontSize: 50}}/>, text: <Translations category={"backtests"} id={9}/>},
        {icon: <AccessTimeIcon sx={{fontSize: 50}}/>, text: <Translations category={"backtests"} id={10}/>},
        {icon: <PlayCircleFilledIcon sx={{fontSize: 50}}/>, text: <Translations category={"backtests"} id={11}/>},
    ];
    const Content = () => {
        return (
            <div>
                <div className={`grid grid-cols-1 lg:grid-cols-3 gap-5`}>
                    <img src={img23} alt='/' className={`col-span-1 w-[500px] justify-center `}/>
                    <div className={`col-span-2 flex flex-col justify-between`}>
                        <div></div>
                        <div>
                            <p className={`${styles.textParagraph}`}>
                                <Translations category={"backtests"} id={5}/>
                            </p>
                            <div className={`grid grid-cols-2 md:grid-cols-6 gap-5 mt-10`}>
                                {content.map((card, index) => (
                                    <div key={index} className={`bg-[${color.base}] ${styles.shadow} p-3 rounded`}>
                                        <div className={`justify-center flex mb-1`}>
                                            {card.icon}
                                        </div>
                                        <p className={`${styles.textParagraph} text-center`}>
                                            {card.text}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <p className={`${styles.textParagraph} mt-10`}>
                    <Translations category={"backtests"} id={12}/>
                </p>
            </div>
        )
    }
    const config = {
        configIconSection: configIconSection,
        title: <Translations category={"menu"} id={9}/>,
        subtitle: <Translations category={"backtests"} id={1}/>,
        offerIncludes: offerIncludes,
        content: Content(),
        gif: backtestGif,
    }
    return (
        <Subpage config={config}/>
    )
}

export default BacktestsWebsite