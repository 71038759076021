import React from "react";
import HoverHint from "./HoverHint";

const TextWithHint = ({title, description}) => {
    return (
        <div className="flex justify-items-start items-center gap-3">
            <HoverHint tooltipText={description}/>
            <p className="text-base">{title}</p>
        </div>
    );
};

export default TextWithHint;
