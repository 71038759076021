import React from "react"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import Header from "../components/features/Header"
import styles from "../../style"
import about from "../images/about.svg";
import Translations from "../components/Translations";

function About() {
    return (
        <div>
            <Navbar/>
            <div className="PricingApp">
                <Header title={<Translations category={"menu"} id={7}/>}
                        subtitle={<Translations category={"about"} id={1}/>}/>
                <div className={`${styles.maxWidth} mt-12 px-5`}>
                    <div className={`grid grid-cols-1 lg:grid-cols-3 lg:my-24 gap-5`}>
                        <div className={`flex flex-col col-span-2`}>
                            <p className={`${styles.textParagraph} mb-5`}><Translations category={"about"} id={2}/></p>
                            <p className={`${styles.textParagraph} mb-5`}><Translations category={"about"} id={3}/></p>
                        </div>
                        <img src={about} alt='/' className={`col-span-1 w-[300px] m-auto`}/>
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    );
}

export default About;