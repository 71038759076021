import Table from "../Table";
import React, {useEffect, useState} from "react";
import {useWebSocket} from "../../../globals/socket/Socket";
import TimestampToDateTime from "../trading/TimestampToDateTime";
import {isLocalhost} from "../../../globals/auth/IsLocalhost";
import full_stats_pt_both from "../../mocks/full_stats_pt_both.json";
import full_stats_at from "../../mocks/full_stats_at.json";
import full_stats_bt_rsi from "../../mocks/full_stats_bt_rsi.json";
import full_stats_bt_minmax from "../../mocks/full_stats_bt_minmax.json";
import full_stats_bt_emac from "../../mocks/full_stats_bt_emac.json";
import full_stats_pt_mana from "../../mocks/full_stats_pt_mana.json";
import full_stats_pt_ordi from "../../mocks/full_stats_pt_ordi.json";
import EventEmitter from "../EventEmitter";

const Trades = ({source, market}) => {
    const [tradesTableData, setTradesTableData] = useState([])
    const ws = useWebSocket()

    useEffect(() => {
        if (ws) {
            let data = JSON.parse(ws)
            if (data.key === "FullStats" && data.source === source) {
                setTradesTableData(JSON.parse(data.value).aggregatedTrades)
            }
        }
    }, [ws])

    useEffect(() => {
        if (!isLocalhost()) {
            demoInitData();
            demoEventConsumer();
        }
    }, [])

    function demoEventConsumer() {
        const callback = (id) => chooseDemoData(id);
        EventEmitter.subscribe('demoFullStats', callback)
        return () => {
            EventEmitter.unsubscribe('demoFullStats', callback);
        }
    }

    function demoInitData() {
        if (source === "PaperTrading") {
            setTradesTableData(full_stats_pt_both.aggregatedTrades)
        } else if (source === "AutomateTrading") {
            setTradesTableData(full_stats_at.aggregatedTrades)
        } else if (source === "BackTest") {
            setTradesTableData(full_stats_bt_rsi.aggregatedTrades)
        }
    }

    function chooseDemoData(id) {
        if (id === 342) {
            setTradesTableData(full_stats_at.aggregatedTrades)
        } else if (id === 343) {
            setTradesTableData(full_stats_pt_mana.aggregatedTrades)
        } else if (id === 344) {
            setTradesTableData(full_stats_pt_ordi.aggregatedTrades)
        } else if (id === 347) {
            setTradesTableData(full_stats_bt_emac.aggregatedTrades)
        } else if (id === 348) {
            setTradesTableData(full_stats_bt_minmax.aggregatedTrades)
        } else if (id === 349) {
            setTradesTableData(full_stats_bt_rsi.aggregatedTrades)
        }
    }

    return (
        <>
            <p className="text-2xl mb-4 mt-3">Trades</p>
            <Table
                columns={source === "BackTest" ? backTestTradesColumns : (market === "spot" ? spotTradingTradesColumns : futuresTradingTradesColumns)}
                data={tradesTableData.slice().reverse()} rows={10}/>
        </>
    )
}

export const futuresTradingTradesColumns = [
    {
        accessorKey: 'isOpen', header: 'Open', flex: 1, size: 50,
        Cell: ({renderedCellValue}) => (renderedCellValue === true ?
            <p className="text-[#10a7d0] text-base">Yes</p> : 'No')
    },
    {accessorKey: 'symbol', header: 'Symbol', flex: 1, size: 100},
    {accessorKey: 'betSize', header: 'Bet size ($)', flex: 1, size: 100},
    {accessorKey: 'leverage', header: 'Leverage', flex: 1, size: 100},
    {
        accessorKey: 'position', header: 'Position', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === "Buy" ?
            <p className="text-green-500 text-xs">{renderedCellValue}</p> :
            <p className="text-red-500 text-xs">{renderedCellValue}</p>)
    },
    {
        accessorKey: 'entryTime', header: 'Start time', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => TimestampToDateTime(renderedCellValue)
    },
    {
        accessorKey: 'closeTime', header: 'End time', flex: 1, size: 100,
        Cell: ({
                   row,
                   renderedCellValue
               }) => (row.original.isOpen ? '-' : TimestampToDateTime(renderedCellValue))
    },
    {accessorKey: 'entryPrice', header: 'Entry price ($)', flex: 1, size: 100},
    {
        accessorKey: 'closePrice', header: 'Close price ($)', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === null ? '-' : renderedCellValue)
    },
    {
        accessorKey: 'pnl', header: 'PnL ($)', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === null ? '-' : renderedCellValue > 0 ?
            <p className="text-green-500 text-xs">{renderedCellValue}</p> :
            <p className="text-red-500 text-xs">{renderedCellValue}</p>)
    },
    {
        accessorKey: 'roe', header: 'RoE (%)', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === null ? '-' : renderedCellValue > 0 ?
            <p className="text-green-500 text-xs">{renderedCellValue}</p> :
            <p className="text-red-500 text-xs">{renderedCellValue}</p>)
    },
    {accessorKey: 'balance', header: 'Account balance ($)', flex: 1, size: 100},
    {accessorKey: 'tradingFees', header: 'Trading fee ($)', flex: 1, size: 100},
    {
        accessorKey: 'fundingFees', header: 'Funding fee ($)', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === null ? '-' : renderedCellValue)
    },
    {
        accessorKey: 'isLiquidated', header: 'Liquidated', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue ? <p className="text-red-500 text-xs">Yes</p> : 'No')
    },
]

export const spotTradingTradesColumns = [
    {
        accessorKey: 'isOpen', header: 'Open', flex: 1, size: 50,
        Cell: ({renderedCellValue}) => (renderedCellValue === true ?
            <p className="text-[#10a7d0] text-base">Yes</p> : 'No')
    },
    {accessorKey: 'symbol', header: 'Symbol', flex: 1, size: 100},
    {accessorKey: 'betSize', header: 'Bet size ($)', flex: 1, size: 100},
    {accessorKey: 'leverage', header: 'Leverage', flex: 1, size: 100},
    {
        accessorKey: 'position', header: 'Position', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === "Buy" ?
            <p className="text-green-500 text-xs">{renderedCellValue}</p> :
            <p className="text-red-500 text-xs">{renderedCellValue}</p>)
    },
    {
        accessorKey: 'entryTime', header: 'Start time', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => TimestampToDateTime(renderedCellValue)
    },
    {
        accessorKey: 'closeTime', header: 'End time', flex: 1, size: 100,
        Cell: ({
                   row,
                   renderedCellValue
               }) => (row.original.isOpen ? '-' : TimestampToDateTime(renderedCellValue))
    },
    {accessorKey: 'entryPrice', header: 'Entry price ($)', flex: 1, size: 100},
    {
        accessorKey: 'closePrice', header: 'Close price ($)', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === null ? '-' : renderedCellValue)
    },
    {
        accessorKey: 'pnl', header: 'PnL ($)', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === null ? '-' : renderedCellValue > 0 ?
            <p className="text-green-500 text-xs">{renderedCellValue}</p> :
            <p className="text-red-500 text-xs">{renderedCellValue}</p>)
    },
    {
        accessorKey: 'roe', header: 'RoE (%)', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === null ? '-' : renderedCellValue > 0 ?
            <p className="text-green-500 text-xs">{renderedCellValue}</p> :
            <p className="text-red-500 text-xs">{renderedCellValue}</p>)
    },
    {accessorKey: 'balance', header: 'Account balance ($)', flex: 1, size: 100},
    {accessorKey: 'tradingFees', header: 'Trading fee ($)', flex: 1, size: 100},
]
export const backTestTradesColumns = [
    {
        accessorKey: 'isOpen', header: 'Open', flex: 1, size: 50,
        Cell: ({renderedCellValue}) => (renderedCellValue === true ?
            <p className="text-[#10a7d0] text-base">Yes</p> : 'No')
    },
    {accessorKey: 'symbol', header: 'Symbol', flex: 1, size: 100},
    {accessorKey: 'betSize', header: 'Bet size ($)', flex: 1, size: 100},
    {accessorKey: 'leverage', header: 'Leverage', flex: 1, size: 100},
    {
        accessorKey: 'position', header: 'Position', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === "Buy" ?
            <p className="text-green-500 text-xs">{renderedCellValue}</p> :
            <p className="text-red-500 text-xs">{renderedCellValue}</p>)
    },
    {
        accessorKey: 'dateFrom', header: 'Start time', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => TimestampToDateTime(renderedCellValue)
    },
    {
        accessorKey: 'dateTo', header: 'End time', flex: 1, size: 100,
        Cell: ({
                   row,
                   renderedCellValue
               }) => (row.original.isOpen ? '-' : TimestampToDateTime(renderedCellValue))
    },
    {accessorKey: 'entryPrice', header: 'Entry price ($)', flex: 1, size: 100},
    {
        accessorKey: 'closePrice', header: 'Close price ($)', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === null ? '-' : renderedCellValue)
    },
    {
        accessorKey: 'pnl', header: 'PnL ($)', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === null ? '-' : renderedCellValue > 0 ?
            <p className="text-green-500 text-xs">{renderedCellValue}</p> :
            <p className="text-red-500 text-xs">{renderedCellValue}</p>)
    },
    {
        accessorKey: 'roe', header: 'RoE (%)', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === null ? '-' : renderedCellValue > 0 ?
            <p className="text-green-500 text-xs">{renderedCellValue}</p> :
            <p className="text-red-500 text-xs">{renderedCellValue}</p>)
    },
    {accessorKey: 'balance', header: 'Account balance ($)', flex: 1, size: 100},
    {accessorKey: 'tradingFees', header: 'Trading fee ($)', flex: 1, size: 100},
    {
        accessorKey: 'fundingFees', header: 'Funding fee ($)', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === null ? '-' : renderedCellValue)
    },
    {
        accessorKey: 'isLiquidated', header: 'Liquidated', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue ? <p className="text-red-500 text-xs">Yes</p> : 'No')
    },
]
export default Trades



