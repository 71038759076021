import * as React from 'react'
import {useEffect, useState} from 'react'
import Snackbar from '@mui/material/Snackbar'
import {useSelector} from "react-redux"
import {selectMessages} from "./redux/GlobalState"

const SnackbarPopup = () => {
    const [open, setOpen] = useState(false)
    const [timer, setTimer] = useState()
    const data = useSelector(selectMessages)
    useEffect(() => {
        if (Object.keys(data).length > 0) {
            if (data?.key === "BlockingAlert") {
                setOpen(true)
            } else {
                setOpen(Boolean(data))
                clearTimeout(timer)
                const timeout = setTimeout(() => {
                    setOpen(false)
                }, 5000)
                setTimer(timeout)
                return () => clearTimeout(timeout)
            }
        } else {
            setOpen(false)
        }
    }, [data])

    return (
        <Snackbar
            ContentProps={{
                sx: {
                    background: chooseColor(data?.key),
                    fontSize: "18px",
                    border: "2px solid #1d1d1d",
                    boxShadow: "-1px 1px 1px #353535FF"
                }
            }}
            anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            open={open}
            message={data?.value}
            onClick={() => (data?.key !== "BlockingAlert") && setOpen(false)}
        />
    )
}

export function chooseColor(key) {
    if (key === "Trading") {
        return "#1b7312"
    } else if (key === "Alert" || key === "BlockingAlert") {
        return "#B90E0A"
    } else {
        return "#10a7d0"
    }
}

export default SnackbarPopup