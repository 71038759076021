import React from 'react'
import ParticlesContainer from "../ParticlesContainer"
import styles, {color} from "../../../style"

const Header = ({title, subtitle}) => {
    return (
        <div className={`w-full ${styles.backgroundMain} text-white border-b border-[${color.accent}]`}>
            <div className="relative overflow-hidden h-3/5">
                <ParticlesContainer height={"30vh"}/>
                <div
                    className={`${styles.flexCenter} w-full mx-auto text-center max-w-[1240px] grid md:grid-cols-3 m-auto`}>
                    <div
                        className='absolute inset-0 flex flex-col items-center justify-center  left-0 right-0 mx-auto  lg:w-2/3 lg:top-0 lg:left-0'>
                        <h1 className={`text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl py-5 font-bold text-[#f3f2ee] bg-[${color.main}] bg-opacity-90 w-fit border-b-4 border-[${color.accent}]`}>
                            {title}
                        </h1>
                        <h2 className={`text-2xl md:text-3xl xl:text-4xl my-5  text-[#f3f2ee] bg-[${color.main}] bg-opacity-90`}>
                            {subtitle}
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
