import React from "react"
import Template from "../../components/Template"
import Translations from "../../../components/Translations"
import img1 from "../../images/indicators/indicators.jpg"

function MACD() {
    const Content = () => {
        return (
            <div className={`flex flex-col gap-5`}>
                <img src={img1} alt='/' className={`border border-gray-700 mt-5 mb-5 w-[20%]`}></img>
                <p className={`text-lg`}><Translations category={"docsMACD"} id={1}/></p>
                <p className={`text-lg`}><Translations category={"docsMACD"} id={2}/></p>
                <p className={`text-lg`}>
                    <Translations category={"docsMACD"} id={3}/>
                    <li><Translations category={"docsMACD"} id={4}/></li>
                    <li><Translations category={"docsMACD"} id={5}/></li>
                    <li><Translations category={"docsMACD"} id={6}/></li>
                </p>
            </div>
        )
    }
    const config = {
        title: <Translations category={"docs"} id={29}/>,
        previousLink: '/docs/percentage-price-changes',
        previousTitle: <Translations category={"docs"} id={28}/>,
        nextLink: '/docs/percentage-stop-loss',
        nextTitle: 'Stop loss',
        content: Content(),
    }

    return (
        <Template config={config}/>
    )
}

export default MACD