import React, {useEffect} from "react"
import styles, {color} from "../../style"
import InterestsIcon from '@mui/icons-material/Interests';
import PercentIcon from '@mui/icons-material/Percent';
import SearchIcon from '@mui/icons-material/Search';
import Subpage from "../components/Subpage";
import strategies from "../gif/strategies.gif";
import img25 from "../images/img13.svg";
import Translations from "../components/Translations";

function Statistics() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const offerIncludes = [
        {description: <Translations category={"statistics"} id={8}/>},
        {description: <Translations category={"statistics"} id={9}/>},
        {description: <Translations category={"statistics"} id={10}/>},
        {description: <Translations category={"statistics"} id={11}/>},
        {description: <Translations category={"statistics"} id={12}/>},
        {description: <Translations category={"statistics"} id={13}/>},
        {description: <Translations category={"statistics"} id={14}/>},
    ];

    const configIconSection = {
        iconOne: <SearchIcon sx={{fontSize: 50}}/>,
        iconTwo: <InterestsIcon sx={{fontSize: 50}}/>,
        iconThree: <PercentIcon sx={{fontSize: 50}}/>,
        textOne: <Translations category={"statistics"} id={2}/>,
        textTwo: <Translations category={"statistics"} id={3}/>,
        textThree: <Translations category={"statistics"} id={4}/>,
    }

    const Content = () => {
        return (
            <div className={`grid grid-cols-1 lg:grid-cols-6 gap-5`}>
                <img src={img25} alt='/' className={`col-span-2 w-[500px] m-auto`}/>
                <div className={`col-span-4 flex flex-col justify-between`}>
                    <div></div>
                    <div>
                        <p className={`${styles.textParagraph}`}><Translations category={"statistics"} id={5}/></p>
                        <p className={`${styles.textParagraph} my-10`}><Translations category={"statistics"} id={6}/></p>
                        <p className={`${styles.textParagraph}`}><Translations category={"statistics"} id={7}/></p>
                    </div>
                    <div></div>
                </div>
            </div>
        )
    }

    const config = {
        configIconSection: configIconSection,
        title: <Translations category={"menu"} id={15}/>,
        subtitle: <Translations category={"statistics"} id={1}/>,
        offerIncludes: offerIncludes,
        content: Content(),
        gif: strategies,
    }

    return (
        <Subpage config={config}/>
    )
}

export default Statistics