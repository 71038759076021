import React from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import styles from "../../style";
import Popup from "reactjs-popup";
import MainButton from "./MainButton";

const ButtonWithPopup = ({buttonText, title, onClick, actionOnButtonYes, color, icon}) => {
    return (
        <Popup trigger={!buttonText ? icon :
            <MainButton title={buttonText} color={color}/>}
               modal nested>
            {close => (
                <div className="fixed inset-0 flex z-[1100] items-center justify-center bg-gray-900 bg-opacity-50">
                    <div className={`${styles.roundedBox} shadow-lg`}>
                        <div className={`${styles.rowBetween} mb-5`}>
                            <div className="flex flex-row gap-4">
                                {/*<HoverHint tooltipText={"Proces usuwania jest nieodwracalny"}/>*/}
                                <p className="mb-3 text-2xl text-white">{title}</p>
                            </div>
                        </div>
                        <div className="flex items-center justify-between mb-3">
                            <button className={styles.blueButton} onClick={actionOnButtonYes ? () => {
                                onClick()
                                close()
                            } : close}>YES
                            </button>
                            <button className={styles.blueButton} onClick={actionOnButtonYes ? close : () => {
                                onClick()
                                close()
                            }}>NO
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Popup>
    );
};

export default ButtonWithPopup;