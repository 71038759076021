import React, {useEffect, useRef, useState} from "react"
import {HiMenuAlt3} from "react-icons/hi"
import CandlestickChartIcon from "@mui/icons-material/CandlestickChart"
import PercentIcon from "@mui/icons-material/Percent"
import TradingView from "./TradingView"
import {Outlet, useLocation} from "react-router-dom"
import {useMediaQuery} from "react-responsive"
import axios from "axios"
import {BASEURL} from "../../globals/Constants"
import {useWebSocket} from "../../globals/socket/Socket"
import DashboardIcon from "@mui/icons-material/Dashboard"
import CreateIcon from "@mui/icons-material/Create"
import WatchLaterIcon from "@mui/icons-material/WatchLater"
import DescriptionIcon from "@mui/icons-material/Description"
import SmartToyIcon from "@mui/icons-material/SmartToy"
import LogoutIcon from "@mui/icons-material/Logout"
import SettingsIcon from "@mui/icons-material/Settings"
import MessageIcon from '@mui/icons-material/Message'
import NoteIcon from '@mui/icons-material/Note'
import Notes from "./Notes"
import {selectLongAlerts, selectMarket, setLongAlerts, setMessage, setStrategies} from "../components/redux/GlobalState"
import {useDispatch, useSelector} from "react-redux"
import Cookies from "../../globals/cookies/Cookies"
import {useAuth} from "../../globals/auth/AuthContext"
import TimestampToDateTime from "../components/trading/TimestampToDateTime"
import Monitoring from "./Monitoring";
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import {IsLocalhost, isLocalhost} from "../../globals/auth/IsLocalhost";
import user_messages from "../mocks/user_messages.json";
import {chooseColor} from "../components/SnackbarPopup";

export const columns = [
    {
        accessorKey: 'symbol',
        header: 'Symbol',
        flex: 1,
        size: 80
    }, {
        accessorKey: 'm1',
        header: 'M1',
        flex: 1,
        Cell: ({renderedCellValue}) => (
            <p className={`${renderedCellValue > 0 ? 'text-green-500' : 'text-red-500'} text-sm`}>{renderedCellValue.toFixed(2) + '%'}</p>),
        size: 50
    },
    {
        accessorKey: 'm3',
        header: 'M3',
        flex: 1,
        Cell: ({renderedCellValue}) => (
            <p className={`${renderedCellValue > 0 ? 'text-green-500' : 'text-red-500'} text-sm`}>{renderedCellValue.toFixed(2) + '%'}</p>),
        size: 50
    },
    {
        accessorKey: 'm5',
        header: 'M5',
        flex: 1,
        Cell: ({renderedCellValue}) => (
            <p className={`${renderedCellValue > 0 ? 'text-green-500' : 'text-red-500'} text-sm`}>{renderedCellValue.toFixed(2) + '%'}</p>),
        size: 50
    },
    {
        accessorKey: 'm15',
        header: 'M15',
        flex: 1,
        Cell: ({renderedCellValue}) => (
            <p className={`${renderedCellValue > 0 ? 'text-green-500' : 'text-red-500'} text-sm`}>{renderedCellValue.toFixed(2) + '%'}</p>),
        size: 50
    },
    {
        accessorKey: 'm30',
        header: 'M30',
        flex: 1,
        Cell: ({renderedCellValue}) => (
            <p className={`${renderedCellValue > 0 ? 'text-green-500' : 'text-red-500'} text-sm`}>{renderedCellValue.toFixed(2) + '%'}</p>),
        size: 50
    },
    {
        accessorKey: 'h1',
        header: 'H1',
        flex: 1,
        Cell: ({renderedCellValue}) => (
            <p className={`${renderedCellValue > 0 ? 'text-green-500' : 'text-red-500'} text-sm`}>{renderedCellValue.toFixed(2) + '%'}</p>),
        size: 50
    },
    {
        accessorKey: 'd1',
        header: 'D1',
        flex: 1,
        Cell: ({renderedCellValue}) => (
            <p className={`${renderedCellValue > 0 ? 'text-green-500' : 'text-red-500'} text-sm`}>{renderedCellValue.toFixed(2) + '%'}</p>),
        size: 50
    },
    {
        accessorKey: 'w1',
        header: 'W1',
        flex: 1,
        Cell: ({renderedCellValue}) => (
            <p className={`${renderedCellValue > 0 ? 'text-green-500' : 'text-red-500'} text-sm`}>{renderedCellValue.toFixed(2) + '%'}</p>),
        size: 50
    },
]

const Layout = () => {
    const viewName = window.location.pathname.split("/")[2].replace("-", "");
    let isTabletMid = useMediaQuery({query: "(max-width: 768px)"})
    const [open, setOpen] = useState(isMenuOpened())
    const dispatch = useDispatch()
    const {pathname} = useLocation()
    const [messages, setMessages] = useState([])
    const [showChart, setShowChart] = useState(false)
    //const [showCalculator, setShowCalculator] = useState(false)
    const [showMonitoring, setShowMonitoring] = useState(null) //null because if true/false then request on window loading
    const [showNotes, setShowNotes] = useState(false)
    const [showMessages, setShowMessages] = useState(false)
    const longAlerts = useSelector(selectLongAlerts)
    const market = useSelector(selectMarket)

    useEffect(() => {
        closeStreamIfOutOfDashboard();
        IsLocalhost(() => axios.get(`${BASEURL}/user_messages`)
                .then(res => {
                    setMessages(res.data.sort((a, b) => b.time - a.time))
                })
                .catch(_ => _),
            () => setMessages(user_messages))
    }, [])

    const auth = useAuth()
    const ws = useWebSocket()

    useEffect(() => {
        handleSocketMessages();
    }, [ws])

    useEffect(() => {
        if (showMonitoring !== null) {
            axios.get(`${BASEURL}/stream/monitoring?market=${market["monitoring"]}&isOpen=${showMonitoring}`).catch(error => {
                dispatch(setMessage({key: 'Alert', value: error.response.data.message}))
            })
        }
    }, [showMonitoring, market["monitoring"]])

    useEffect(() => {
        //closing menu when phone or tablet mode
        if (isTabletMid) {
            setOpen(false)
        }
    }, [isTabletMid])

    useEffect(() => {
        const firstActiveAlert = longAlerts.find(alert => alert.isActive)
        const shouldAlertView = shouldAlertBeVisible(firstActiveAlert, pathname)
        const addStrategyHiddenInNewStrategyView = !(pathname === '/app/strategies/new' && firstActiveAlert?.message === "To use the application, add the first strategy");
        if (firstActiveAlert && addStrategyHiddenInNewStrategyView && shouldAlertView) {
            dispatch(setMessage({key: firstActiveAlert.type, value: firstActiveAlert.message}))
        }
    }, [longAlerts])

    useEffect(() => {
        //closing menu on phone or tablet mode when view changed
        isTabletMid && setOpen(false)
    }, [pathname])

    function closeStreamIfOutOfDashboard() {
        if (Cookies.getCookie("view") === "dashboard" && viewName !== "dashboard") {
            if (isLocalhost()) axios.get(`${BASEURL}/stream/dashboard?market=spot&source=PaperTrading&isOpen=false`).catch(_ => _)
        }
        Cookies.setCookie("view", viewName)
    }

    const menu = [
        {name: "Dashboard", link: "/app/dashboard", icon: <DashboardIcon/>, minimumPlan: "Free"},
        {name: "Strategies", link: "/app/strategies", icon: <CreateIcon/>, minimumPlan: "Free"},
        {name: "Backtests", link: "/app/backtests", icon: <WatchLaterIcon/>, minimumPlan: "Free"},
        {name: "Paper trading", link: "/app/paper-trading", icon: <DescriptionIcon/>, minimumPlan: "Beginner"},
        {name: "Automate trading", link: "/app/automate-trading", icon: <SmartToyIcon/>, minimumPlan: "Advanced"},
    ]

    function changeSidebarState() {
        setOpen(!open)
        Cookies.setCookie('menuOpened', !open)
    }

    function handleSocketMessages() {
        if (ws) {
            let data = JSON.parse(ws)
            if (["Info", "Trading", "FastInfo"].includes(data.key)) {
                dispatchMessage(dispatch, data, setMessages, messages)
            } else if ("Alert" === data.key) {
                const refreshMessages = ["You have deleted strategy"]
                if (refreshMessages.includes(data.value)) {
                    axios.get(`${BASEURL}/user_strategies`).then(res =>
                        dispatch(setStrategies(res.data))).catch(_ => _)
                }
                dispatchMessage(dispatch, data, setMessages, messages)
            } else if ("BlockingAlert" === data.key && shouldAlertBeVisible(longAlerts.find(alert => alert.isActive), pathname)) {
                dispatchMessage(dispatch, data, setMessages, messages)
            } else if (data.key === "AlertChanged") {
                axios.get(`${BASEURL}/longAlerts`).then(res =>
                    dispatch(setLongAlerts(res.data))).catch(_ => _)
            }
        }
    }

    function isMenuOpened() {
        const cookie = Cookies.getCookie('menuOpened')

        if (cookie === undefined) {
            Cookies.setCookie('menuOpened', 'true')
            return true
        } else {
            return cookie === 'true'
        }
    }

    const chartRef = useRef()
    const monitoringRef = useRef()

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (chartRef.current && !chartRef.current.contains(event.target)) {
                setShowChart(false)
            }
            if (monitoringRef.current && !monitoringRef.current.contains(event.target)) {
                setShowMonitoring(false)
            }
            // if (calculatorRef.current && !calculatorRef.current.contains(event.target)) {
            //     setShowCalculator(false)
            // }

        }
        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [chartRef, monitoringRef])

    function dispatchMessage(dispatch, data, setMessages, messages) {
        dispatch(setMessage({key: data.key, value: data.value}))
        setMessages([...[{
            key: data.key,
            value: data.value,
            source: data.source,
            time: new Date(data.time).getTime()
        }], ...messages])
    }

    function shouldAlertBeVisible(firstActiveAlert, pathname) {
        return firstActiveAlert?.views.length === 0 || firstActiveAlert?.views.some(str => pathname.includes(str));
    }

    return (
        <div className="flex">
            <nav
                className="fixed h-16 top-0 px-4 lg:px-5 lg:pl-3 z-50 w-full flex items-center justify-between border-b border-[#10a7d0] text-[#f3f2ee] bg-[#1d1d1d]">
                <div className="flex h-16 items-center justify-start">
                    <div className="flex justify-center mr-4 ml-2">
                        <HiMenuAlt3 size={26} className="cursor-pointer" onClick={() => changeSidebarState()}/>
                    </div>
                    <a href="/"
                       className="text-xl flex sm:text-2xl lg:mr-24 tracking-tighter">crypto<span
                        className="text-[#10a7d0] font-bold">deputy</span>.</a>
                </div>
                <div className="flex h-16 items-center justify-end gap-4">
                    {/*<IoIosCalculator className="cursor-pointer text-[20px]"  onClick={() => setShowCalculator(true)}/>*/}
                    <CandlestickChartIcon className="cursor-pointer" onClick={() => setShowChart(true)}/>
                    <PercentIcon className="cursor-pointer" onClick={() => setShowMonitoring(true)}/>
                    <NoteIcon className={`${showNotes ? "text-[#10a7d0]" : "text-white"} cursor-pointer`}
                              onClick={() => {
                                  setShowMessages(false)
                                  setShowNotes(!showNotes)
                              }}/>
                    <MessageIcon className={`${showMessages ? "text-[#10a7d0]" : "text-white"} cursor-pointer`}
                                 onClick={() => {
                                     setShowNotes(false)
                                     setShowMessages(!showMessages)
                                 }}/>
                </div>
            </nav>

            <div
                className={`h-screen ${open ? isTabletMid ? "w-full z-50 border-[#10a7d0] bg-[#1d1d1d] lg:border-r" : "w-60 border-[#10a7d0] lg:border-r" : isTabletMid ? "hidden" : "w-16 border-[#10a7d0] lg:border-r"} fixed mt-16`}>
                <div className="mt-4 flex flex-col relative gap-4">
                    {!isLocalhost() ? <a href="/app/welcome" key={0}
                                         className={`flex items-center ${open ? "ml-5" : "justify-center"}`}>
                        <div
                            className={`${pathname === "/app/welcome" ? "text-[#10a7d0]" : "text-white"}`}>
                            <EmojiEmotionsIcon/></div>
                        <span
                            className={` whitespace-nowrap ${open ? "ml-5" : "opacity-0 overflow-hidden w-0 ml-0"} ${pathname === "/app/welcome" ? "text-[#10a7d0]" : "text-white"}`}>Welcome</span>
                    </a> : ""}
                    {menu?.map((menu, index) => (
                        <a href={menu?.link} key={index}
                           className={`flex items-center ${open ? "ml-5" : "justify-center"}`}>
                            <div
                                className={`${pathname === menu?.link ? "text-[#10a7d0]" : "text-white"}`}>{menu?.icon}</div>
                            <span
                                className={` whitespace-nowrap ${open ? "ml-5" : "opacity-0 overflow-hidden w-0 ml-0"} ${pathname === menu?.link ? "text-[#10a7d0]" : "text-white"}`}>{menu?.name}</span>
                        </a>
                    ))}
                    <div className="border-t border-[#10a7d0]"/>
                    {/*<a href="/app/subscription" className={`flex items-center ${open ? "ml-5" : "justify-center"}`}>*/}
                    {/*    <div className={`${pathname === "/app/subscription" ? "text-[#10a7d0]" : "text-white"}`}>*/}
                    {/*        <LoyaltyIcon/></div>*/}
                    {/*    <span*/}
                    {/*        className={` whitespace-nowrap ${open ? "ml-5" : "opacity-0 overflow-hidden w-0 ml-0"} ${pathname === menu?.link ? "text-[#10a7d0]" : "text-white"}`}>Subscription</span>*/}
                    {/*</a>*/}
                    <a href="/app/settings" className={`flex items-center ${open ? "ml-5" : "justify-center"}`}>
                        <div className={`${pathname === "/app/settings" ? "text-[#10a7d0]" : "text-white"}`}>
                            <SettingsIcon/></div>
                        <span
                            className={` whitespace-nowrap ${open ? "ml-5" : "opacity-0 overflow-hidden w-0 ml-0"} ${pathname === menu?.link ? "text-[#10a7d0]" : "text-white"}`}>Setting</span>
                    </a>
                    <div onClick={() => {
                        if (isLocalhost()) axios.get(`${BASEURL}/logout`)
                        auth.authLogout()
                    }}
                         className={`flex items-center cursor-pointer ${open ? "ml-5" : "justify-center"}`}>
                        <div className="text-white"><LogoutIcon/></div>
                        <span
                            className={` whitespace-nowrap ${open ? "ml-5" : "opacity-0 overflow-hidden w-0 ml-0"} text-white`}>Logout</span>
                    </div>
                </div>
            </div>
            <div
                className={`pt-20 ${open ? isTabletMid ? "w-0" : "ml-64 w-full" : isTabletMid ? "ml-5 w-full" : "ml-20 w-full"} ${isTabletMid ? (showNotes || showMessages) ? "w-0 hidden" : "mr-0" : (showNotes || showMessages) ? "mr-80" : "mr-0"}
               text-gray-100 pr-4`}
            >
                <Outlet/>
                {/*{showCalculator && (*/}
                {/*    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-90">*/}
                {/*        <div ref={calculatorRef} className="bg-[#1f1f1f] p-4 rounded shadow-lg w-2/5 h-3/5 flex flex-col">*/}
                {/*            <Calculator/>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*)}*/}
                {showChart && (
                    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-90">
                        <div ref={chartRef}
                             className="bg-[#1f1f1f] p-4 rounded shadow-lg w-10/12 lg:w-4/5 h-4/5 flex flex-col">
                            <TradingView/>
                        </div>
                    </div>
                )}
                {showMonitoring && (
                    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-90">
                        <div ref={monitoringRef}
                             className="bg-[#1f1f1f] p-4 rounded shadow-lg w-10/12 lg:w-3/5 max-h-[80%] flex flex-col">
                            <Monitoring/>
                        </div>
                    </div>
                )}
            </div>
            <div
                className={`h-screen fixed right-0 ${isTabletMid ? (showNotes || showMessages) ? "w-[100%]" : "w-0" : (showNotes || showMessages) ? "w-80 border-[#10a7d0] border-l" : "w-0"} `}>
                {rightPanelContent(showMessages, showNotes, messages)}
            </div>
        </div>
    )
}
//chooseColor(message.key)
const rightPanelContent = (showMessages, showNotes, messages) => {
    if (showMessages) {
        return (<div className="flex flex-col  w-full h-screen px-4 gap-2">
            <p className="text-3xl pt-20 pb-2 text-white">Messages</p>
            <div className="overflow-y-auto">
                {messages.length === 0 ?
                    <p className="text-base py2 flex">no messages</p>
                    : messages.map((message) => (
                        <div key={message.id}
                             className={`flex flex-col pl-3 mb-1 mt-1 border-b border-b-gray-500 last:border-b-0 py-3 border-l-8 border-l-[${chooseColor(message.key)}]`}>
                            <div className="text-white text-base">
                                {message.value}
                            </div>
                            <div className={"flex flex-row justify-between mt-2"}>
                                <div className="text-white text-xs">
                                    {message.source}
                                </div>
                                <div className="text-white text-xs">
                                    {TimestampToDateTime(message.time)}
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </div>)
    } else if (showNotes) {
        return (<Notes/>)
    } else {
        return (<></>)
    }
}
export default Layout
