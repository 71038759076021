import React, {useEffect, useState} from "react"
import {color} from "../../style"
import Translations from "./Translations";

function OfferIncludes({content}) {
    const [activePageIndex, setActivePageIndex] = useState(0)

    const nextPage = () => {
        if (activePageIndex < (content.length - 1)) {
            setActivePageIndex(activePageIndex + 1)
        } else {
            setActivePageIndex(0)
        }
    }

    useEffect(() => {
        const interval = setInterval(nextPage, 5000)
        return () => clearInterval(interval)
    }, [activePageIndex])

    const visibleFeedback = content.slice(activePageIndex, activePageIndex + 1)
    return (
        <div className={`flex flex-col justify-center items-center border-y border-[${color.accent}] p-10 w-full`}>
            <div className="flex">
                {visibleFeedback.map((card, index) => (
                    <div
                        key={index}
                        className={`w-100`}>
                        <h2 className="font-semibold text-center text-4xl leading-[32px] text-[#f3f2ee]">
                            <Translations category={"subpage"} id={1}/>
                        </h2>
                        <h3 className={`font-normal text-xl text-[#f3f2ee] text-center mt-10`}>
                            {card.description}
                        </h3>
                    </div>
                ))}
            </div>
            <div className="flex mt-10">
                {Array.from({length: content.length}, (_, i) => (
                    <div
                        key={i}
                        className={`w-1.5 h-1.5 mx-1 rounded-full cursor-pointer ${
                            i === activePageIndex ? 'bg-[#10a7d0]' : 'bg-gray-300'
                        }`}
                        onClick={() => setActivePageIndex(i)}
                    ></div>
                ))}
            </div>
        </div>
    );
}

export default OfferIncludes