import React, {useState} from "react"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import ParticlesContainer from "../components/ParticlesContainer"
import Translations from "../components/Translations"
import styles, {color} from "../../style"
import axios from "axios";
import {SERVERURL} from "../../globals/Constants";
import {setMessage} from "../../application/components/redux/GlobalState";
import {useDispatch} from "react-redux";
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

function Contact() {
    const dispatch = useDispatch()
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
    })

    const sendMessage = () => {
        axios.post(`${SERVERURL}/message`, {
            name: formData.name,
            email: formData.email,
            message: formData.message
        }).then((response) => {
            dispatch(setMessage({key: 'Info', value: response.data.message}))
        }).catch(error => dispatch(setMessage({
            key: 'Alert',
            value: error.response.data.message
        })))
    }

    const handleChange = (e) => {
        const {name, value} = e.target
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    return (
        <div className={`${styles.backgroundMain} w-full text-white`}>
            <Navbar/>
            <div className="relative overflow-hidden h-9/10">
                <ParticlesContainer height={"90vh"}/>
                <div
                    className={`${styles.flexCenter}  mx-auto text-center max-w-[1240px] grid md:grid-cols-3 m-auto`}>
                    <div
                        className='absolute inset-0 flex flex-col items-center justify-center  left-0 right-0 mx-auto  lg:w-2/3 lg:top-0 lg:left-0'>
                        <div className={`${styles.flexCenter} h-screen`}>
                            <div className={`${styles.shadow} flex border border-[${color.accent}]`}>
                                <div className="w-1/2 py-10 px-5 bg-[#10a7d0]">
                                    <div className="flex items-center justify-center flex-col text-center h-full">
                                        <div className="h-1/5 flex justify-center items-center">
                                            <h2 className="text-2xl text-[#f3f2ee] font-bold"><Translations
                                                category={"contact"} id={1}/></h2>
                                        </div>
                                        <div className="h-3/5 flex flex-col justify-center items-center">
                                            <p className="text-lg text-[#f3f2ee] my-10"><Translations
                                                category={"contact"} id={2}/></p>
                                            <p className="text-xl text-[#f3f2ee] font-semibold mt-10">
                                                <a>cryptodeputybots</a><AlternateEmailIcon
                                                sx={{fontSize: 30}}/><a>gmail.com</a>
                                            </p>
                                        </div>
                                        <div className="h-1/5 flex justify-center items-center">
                                        </div>
                                    </div>
                                </div>
                                <div className={`w-1/2 py-10 px-5 ${styles.backgroundBase}`}>
                                    <form
                                        className="flex items-center justify-center flex-col text-center h-full"
                                        onSubmit={handleSubmit}>
                                        <div className="h-1/5 flex justify-center items-center">
                                            <p className='text-2xl font-bold'><Translations category={"contact"}
                                                                                            id={3}/></p>
                                        </div>
                                        <div className="h-3/5 flex flex-col justify-center items-center">
                                            <div className="relative bg-inherit mt-4">
                                                <p className="text-white flex justify-start text-lg"><Translations
                                                    category={"contact"} id={4}/></p>
                                                <input type="text" id="name" name="name"
                                                       onChange={handleChange}
                                                       className={`peer bg-transparent h-10 border-[${color.accent}] w-full rounded-lg placeholder-transparent outline-none`}/>
                                            </div>
                                            <div className="relative bg-inherit my-4">
                                                <p className="text-white flex justify-start text-lg">Email</p>
                                                <input type="text" id="email" name="email"
                                                       onChange={handleChange}
                                                       className={`peer bg-transparent h-10 border-[${color.accent}] w-full rounded-lg placeholder-transparent outline-none`}/>
                                            </div>
                                            <textarea
                                                id="message"
                                                name="message"
                                                spellCheck="false"
                                                value={formData.message}
                                                onChange={handleChange}
                                                className={`w-full py-2 px-4 border bg-transparent border-[${color.accent}] rounded-md outline-none text-white h-32`}
                                            />
                                        </div>
                                        <div className="h-1/5 flex justify-center items-center">
                                            <button onClick={() => sendMessage()}
                                                    className="text-xl mt-5 bg-[#10a7d0] rounded-md hover:bg-[#f3f2ee] border-solid border-2 border-[#10a7d0] hover:text-[#10a7d0] active:text-[#f3f2eef3f2ee] active:bg-[#3e7ce1] focus:outline-none ">
                                                <Translations category={"contact"} id={5}/>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Contact