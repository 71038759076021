import React from "react"
import Template from "../../components/Template"
import img1 from "../../images/chart/topmenu.png"
import img2 from "../../images/chart/chart.png"
import Translations from "../../../components/Translations"
import style from "../../../../style"

function Chart() {
    const Content = () => {
        return (
            <div>
                <img src={img1} alt='/' className={`${style.borderImg} my-5`}/>
                <Translations category={"docsChart"} id={1}/>
                <img src={img2} alt='/' className={`${style.borderImg} my-5`}/>
            </div>
        )
    }
    const config = {
        title: <Translations category={"docs"} id={15}/>,
        previousLink: '/docs/settings',
        previousTitle: <Translations category={"docs"} id={14}/>,
        nextLink: '/docs/monitoring',
        nextTitle: <Translations category={"docs"} id={16}/>,
        content: Content(),
    }

    return (
        <Template config={config}/>
    )
}

export default Chart