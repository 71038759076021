import React, {useEffect, useRef} from 'react'
import Chart from 'chart.js/auto'

const BarChart = ({axisY, axisX, isPie}) => {
    const chartContainer = useRef(null)
    const myChart = useRef(null)

    useEffect(() => {
        if (chartContainer.current) {
            const ctx = chartContainer.current.getContext('2d')
            const gradient = ctx.createLinearGradient(0, 0, 0, 300)
            gradient.addColorStop(0, 'rgb(16,166,206)')
            gradient.addColorStop(1, 'rgb(53,53,53)')

            const data = {
                labels: axisX,
                datasets: [{
                    label: '',
                    data: axisY,
                    backgroundColor: isPie
                        ? ['rgb(16,166,206)', 'rgb(53,53,53)', 'rgb(255,99,132)']
                        : gradient,
                }]
            }

            const config = {
                type: isPie ? 'pie' : 'bar',
                data: data,
                options: {
                    responsive: true,
                    plugins: {
                        legend: {
                            display: isPie,
                            position: 'right',
                            labels: {
                                color: 'white'
                            }
                        },
                        labels: {
                            render: 'value',
                            fontColor: ['green', 'white', 'red']
                        },
                        tooltip: {
                            enabled: false
                        },
                        datalabels: {
                            color: 'white',
                            anchor: 'end',
                            align: 'start',
                            offset: 10,
                        }
                    },
                    scales: isPie ? {} : {
                        x: {
                            grid: {
                                display: false
                            },
                            ticks: {
                                color: 'white'
                            }
                        },
                        y: {
                            position: 'right',
                            beginAtZero: true,
                            grid: {
                                display: false
                            },
                            ticks: {
                                precision: 0,
                                color: 'white'
                            }
                        }
                    }
                }
            }

            if (!myChart.current) {
                myChart.current = new Chart(ctx, config)
            } else {
                myChart.current.data.datasets[0].data = axisY.map(value => value === 0 ? "" : value)
                myChart.current.update()
            }
        }
    }, [axisY, axisX, isPie])

    return <canvas ref={chartContainer}/>
}

export default BarChart
