import React, {useEffect, useState} from "react"
import styles from "../../../style"
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import {BASEURL, ELECTRONURL, SERVERURL} from "../../../globals/Constants";
import encrypt from "../../../globals/Cipher";
import {selectPlan, setMessage} from "../../components/redux/GlobalState";
import ButtonWithPopup from "../../components/ButtonWithPopup";
import {IsLocalhost, isLocalhost} from "../../../globals/auth/IsLocalhost";
import {isPlanSufficient} from "../../../globals/auth/Plan";
import {Tooltip} from "react-tooltip";

const Settings = () => {
    const [dataDir, setDataDir] = useState('');
    const [binanceKey, setBinanceKey] = useState('');
    const [binanceSecret, setBinanceSecret] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmedPassword, setConfirmedPassword] = useState('');
    const dispatch = useDispatch()
    const plan = useSelector(selectPlan)

    useEffect(() => {
        checkMarketKeys()
        checkDataDir()
    }, [dataDir])

    function checkDataDir() {
        IsLocalhost(() => axios.get(`${BASEURL}/dataDir`).then((res) => {
                setDataDir(res.data)
            }).catch(_ => _), () => setDataDir("C:\\Users\\Kasia\\AppData\\Local\\CryptoDeputy\\data")
        )
    }

    function checkMarketKeys() {
        if (isLocalhost()) {
            axios.get(`${BASEURL}/marketKeys`).then((res) => {
                if (res.data === true) {
                    setBinanceSecret("****************************************")
                    setBinanceKey("****************************************")
                }
            }).catch(_ => _)
        }
    }

    function saveMarketKeys() {
        IsLocalhost(() => {
                if (isPlanSufficient("Beginner", plan)) {
                    axios.post(`${BASEURL}/marketKeys`, {
                        key: binanceKey,
                        secret: binanceSecret
                    }).catch(_ => _)
                } else {
                    dispatch(setMessage({key: 'Alert', value: 'You cannot connect exchange keys in free version'}))
                }
            },
            () => dispatch(setMessage({key: 'Alert', value: 'You cannot connect exchange keys in demo version'})))
    }

    function databaseClear() {
        IsLocalhost(() => axios.get(`${BASEURL}/statistics/clear`).catch(_ => _),
            () => dispatch(setMessage({key: 'Alert', value: 'You cannot clear data in demo version'})))
    }

    const handleFileChange = (event, sound) => {
        const acceptedTypes = ['audio/wav']
        const file = event.target.files[0];
        if (!acceptedTypes.includes(file.type)) {
            dispatch(setMessage({key: 'Alert', value: "Invalid file type. Only files of type WAV are allowed"}))
        } else {
            IsLocalhost(() => {
                const formData = new FormData();
                formData.append('file', file);
                axios.post(`${BASEURL}/sound/${sound}`, formData).catch(_ => _)
            }, () => dispatch(setMessage({key: 'Alert', value: "You cannot change sounds in demo version"})))
        }
    };

    function changePassword() {
        axios.post(`${SERVERURL}/updatePassword`, {
            currentPassword: encrypt(currentPassword, '!@#$%^&*.()'),
            newPassword: encrypt(newPassword, '!@#$%^&*.()')
        }, {withCredentials: true}).then((response) => {
            dispatch(setMessage({key: 'Info', value: response.data.message}))
        }).catch(error => {
            dispatch(setMessage({key: 'Alert', value: error.response.data.message}))
        })
    }

    return (
        <div className="flex flex-col w-full">
            <div className={styles.rowBetween}>
                <p className="text-4xl">Settings</p>
            </div>
            <div className="flex flex-col col-span-3 gap-4 mt-5">
                <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 gap-4">
                    <div className={`${styles.roundedBox} ${styles.colBetween} col-span-1`}>
                        <div className="mb-10">
                            <p className="text-xl mb-5">Change password</p>
                            <div className="flex justify-items-start items-center gap-3 mb-2">
                                <p className="text-base">Current Password *</p>
                            </div>
                            <input type="password" id="currentPassword"
                                   onChange={(event) => setCurrentPassword(event.target.value)}
                                   value={currentPassword}
                                   className={`${styles.roundedBox} border border-gray-300 text-gray-300 text-sm focus:ring-[#10a7d0] focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none`}
                                   placeholder="Current Password" required/>
                            <div className="flex justify-items-start items-center gap-3 mt-5 mb-2">
                                <p className="text-base">New Password *</p>
                            </div>
                            <input type="password" id="newPassword"
                                   onChange={(event) => setNewPassword(event.target.value)}
                                   value={newPassword}
                                   className={`${styles.roundedBox} border border-gray-300 text-gray-300 text-sm focus:ring-[#10a7d0] focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none`}
                                   placeholder="New Password" required/>
                            <div className="flex justify-items-start items-center gap-3 mt-5 mb-2">
                                <p className="text-base">Confirm Password *</p>
                            </div>
                            <input type="password" id="confirmPassword"
                                   onChange={(event) => setConfirmedPassword(event.target.value)}
                                   value={confirmedPassword}
                                   className={`${styles.roundedBox} border border-gray-300 text-gray-300 text-sm focus:ring-[#10a7d0] focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none`}
                                   placeholder="Confirm Password" required/>
                        </div>
                        <button onClick={changePassword} className={styles.blueButton}>Save</button>
                    </div>
                    <div className={`${styles.roundedBox} ${styles.colBetween} col-span-1`}>
                        <div className="mb-10">
                            <p className="text-xl mb-5">Binance Connect Settings</p>
                            <div className="flex justify-items-start items-center gap-3 mb-2">
                                <p className="text-base">Binance Api Key *</p>
                            </div>
                            <input type="text" id="binanceKey"
                                   onChange={(event) => setBinanceKey(event.target.value)}
                                   value={binanceKey}
                                   className={`${styles.roundedBox} border border-gray-300 text-gray-300 text-sm focus:ring-[#10a7d0] focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none`}
                                   placeholder="Binance Api Key" required/>
                            <div className="flex justify-items-start items-center gap-3 mt-5 mb-2">
                                <p className="text-base">Binance Api Secret *</p>
                            </div>
                            <input type="text" id="binanceSecret"
                                   onChange={(event) => setBinanceSecret(event.target.value)}
                                   value={binanceSecret}
                                   className={`${styles.roundedBox} border border-gray-300 text-gray-300 text-sm focus:ring-[#10a7d0] focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none`}
                                   placeholder="Binance Api Secret" required/>
                        </div>
                        <button onClick={saveMarketKeys} className={styles.blueButton}>Save</button>
                    </div>
                    <div className={`${styles.roundedBox} ${styles.colBetween} col-span-1`}>
                        <div className="mb-10">
                            <p className="text-xl mb-5">Clear database</p>
                            <div className="flex justify-items-start items-center gap-3 mb-2">
                                <p className="text-base">This function clears the database of ALL analytical data. You
                                    will lose information about the results of your historical tests, paper and
                                    automated trades. Use it only if you want to completely reset the application (it
                                    does not delete strategies defined in the "Strategies" panel).</p>
                            </div>
                        </div>
                        <ButtonWithPopup buttonText="Delete"
                                         actionOnButtonYes={true}
                                         title="Are you sure you want to delete all analytical data about your strategies, historical tests, and buy/sell orders?"
                                         color={"#a93b3b"}
                                         onClick={() => databaseClear()}/>
                    </div>
                    <div className={`${styles.roundedBox} ${styles.colBetween} col-span-1`}>
                        <p className="text-xl mb-5">Change sounds</p>
                        <div className={`grid grid-cols-3`}>
                            <p className={`col-span-1 text-lg`}>Open trade</p>
                            <input className={"bg-[#353535] col-span-2 border-0 p-0"} type="file" accept=".mp3, .wav"
                                   onChange={(event) => handleFileChange(event, "OpenTradeSound")}/>
                            <p className={`col-span-1 text-lg`}>Close trade</p>
                            <input className={"bg-[#353535] col-span-2 border-0 p-0"} type="file" accept=".mp3, .wav"
                                   onChange={(event) => handleFileChange(event, "CloseTradeSound")}/>
                            <p className={`col-span-1 text-lg`}>Stop loss</p>
                            <input className={"bg-[#353535] col-span-2 border-0 p-0"} type="file" accept=".mp3, .wav"
                                   onChange={(event) => handleFileChange(event, "StopLossUpdateSound")}/>
                            <p className={`col-span-1 text-lg`}>Backtest finished</p>
                            <input className={"bg-[#353535] col-span-2 border-0 p-0"} type="file" accept=".mp3, .wav"
                                   onChange={(event) => handleFileChange(event, "FinishSound")}/>
                        </div>
                        <p className="text-xl mt-8 mb-5">System configurations</p>
                        <div className={`grid grid-cols-6 gap-3`}>
                            <p className={`col-span-2 text-lg`}>Data directory</p>
                            <div className="flex col-span-2 items-center justify-center">
                                <a className="hint truncate" data-tooltip-content={dataDir}>{dataDir}</a>
                                <Tooltip
                                    anchorSelect=".hint"
                                    className="z-[1100]"
                                    style={{backgroundColor: "rgb(16,166,206)", color: "#fff", fontSize: 16}}
                                />
                            </div>
                            <button className={`bg-[#10a7d0] col-span-2 rounded-md text-md pl-2 pr-2 pt-0 pb-0 text-white`}
                                    onClick={() => IsLocalhost(axios.get(`${ELECTRONURL}/directory`).catch(_ => _),
                                        () => dispatch(setMessage({
                                            key: 'Alert',
                                            value: 'You cannot change data dir in demo version'
                                        })))}
                                    type="button">Change
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Settings