import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import React from "react"
import Translations from "../components/Translations";

export const pricing = [
    {
        title: <Translations category={"pricing"} id={5}/>,
        priceAnnually: <Translations category={"pricing"} id={6}/>,
        priceMonthly: <Translations category={"pricing"} id={6}/>,
        featuresZero: "-",
        featuresOne: <Translations category={"pricing"} id={7}/>,
        featuresTwo: <Translations category={"pricing"} id={8}/>,
        featuresThree: <Translations category={"pricing"} id={9}/>,
        featuresFour: <Translations category={"pricing"} id={10}/>,
        featuresFive: "",
    }, {
        title: <Translations category={"pricing"} id={11}/>,
        discountPercent: 10,
        priceAnnually: 216,
        priceMonthly: 20,
        featuresZero: <Translations category={"pricing"} id={12}/>,
        featuresOne: <Translations category={"pricing"} id={13}/>,
        featuresTwo: <Translations category={"pricing"} id={14}/>,
        featuresThree: <Translations category={"pricing"} id={15}/>,
        featuresFour: <Translations category={"pricing"} id={16}/>,
        featuresFive: <Translations category={"pricing"} id={17}/>,
    }, {
        title: <Translations category={"pricing"} id={18}/>,
        discountPercent: 15,
        priceAnnually: 357,
        priceMonthly: 35,
        featuresZero: <Translations category={"pricing"} id={19}/>,
        featuresOne: <Translations category={"pricing"} id={20}/>,
        featuresTwo: <Translations category={"pricing"} id={21}/>,
        featuresThree: <Translations category={"pricing"} id={22}/>,
        featuresFour: "",
        featuresFive: "",
    }, {
        title: <Translations category={"pricing"} id={23}/>,
        discountPercent: 20,
        priceAnnually: 480,
        priceMonthly: 50,
        featuresZero: <Translations category={"pricing"} id={24}/>,
        featuresOne: <Translations category={"pricing"} id={25}/>,
        featuresTwo: <Translations category={"pricing"} id={26}/>,
        featuresThree: <Translations category={"pricing"} id={27}/>,
        featuresFour: "API",
        featuresFive: "",
    }]

export const data = [
    // {
    //     category: <Translations category={"pricing"} id={31}/>,
    //     test: 0,
    //     beginner: 10,
    //     advanced: 50,
    //     expert: "∞",
    // },
   {
        category: <Translations category={"pricing"} id={32}/>,
        test: <CheckIcon/>,
        beginner: <CheckIcon/>,
        advanced: <CheckIcon/>,
        expert: <CheckIcon/>,
    },
    {category: <Translations category={"pricing"} id={33}/>},
    {category: <Translations category={"pricing"} id={34}/>},
    {category: <Translations category={"pricing"} id={35}/>},
    {category: <Translations category={"pricing"} id={36}/>},
    {category: <Translations category={"pricing"} id={37}/>},
    {category: <Translations category={"pricing"} id={38}/>},
    {category: <Translations category={"pricing"} id={39}/>},
    {category: <Translations category={"pricing"} id={40}/>},
    {category: <Translations category={"pricing"} id={41}/>},
    {category: <Translations category={"pricing"} id={42}/>},
    {category: <Translations category={"pricing"} id={43}/>},
    {
        category: <Translations category={"pricing"} id={44}/>,
        test: <CheckIcon/>,
        beginner: <CheckIcon/>,
        advanced: <CheckIcon/>,
        expert: <CheckIcon/>,
    },
    {category: <Translations category={"pricing"} id={45}/>},
    {category: <Translations category={"pricing"} id={46}/>},
    {category: <Translations category={"pricing"} id={47}/>},
    {category: <Translations category={"pricing"} id={48}/>},
    {category: <Translations category={"pricing"} id={49}/>},
    {category: <Translations category={"pricing"} id={50}/>},
    {category: <Translations category={"pricing"} id={51}/>},
    {
        category: <Translations category={"pricing"} id={52}/>,
        test: <CheckIcon/>,
        beginner: <CheckIcon/>,
        advanced: <CheckIcon/>,
        expert: <CheckIcon/>,
    },
    {category: <Translations category={"pricing"} id={53}/>},
    {category: <Translations category={"pricing"} id={54}/>},
    {category: <Translations category={"pricing"} id={55}/>},
    {category: <Translations category={"pricing"} id={56}/>},
    {category: <Translations category={"pricing"} id={57}/>},
    {
        category: <Translations category={"pricing"} id={58}/>,
        test: <CheckIcon/>,
        beginner: <CheckIcon/>,
        advanced: <CheckIcon/>,
        expert: <CheckIcon/>,
    },
    {category: <Translations category={"pricing"} id={59}/>},
    {category: <Translations category={"pricing"} id={60}/>},
    {category: <Translations category={"pricing"} id={61}/>},
    {category: <Translations category={"pricing"} id={62}/>},
    {category: <Translations category={"pricing"} id={63}/>},
    {category: <Translations category={"pricing"} id={64}/>},
    {category: <Translations category={"pricing"} id={65}/>},
    {category: <Translations category={"pricing"} id={66}/>},
    {category: <Translations category={"pricing"} id={67}/>},
    {
        category: <Translations category={"pricing"} id={68}/>,
        test: <Translations category={"pricing"} id={69}/>,
        beginner: <CheckIcon/>,
        advanced: <CheckIcon/>,
        expert: <CheckIcon/>,
    },
    {category: <Translations category={"pricing"} id={70}/>},
    {category: <Translations category={"pricing"} id={71}/>},
    {category: <Translations category={"pricing"} id={72}/>},
    {category: <Translations category={"pricing"} id={73}/>},
    {category: <Translations category={"pricing"} id={74}/>},
    {category: <Translations category={"pricing"} id={75}/>},
    {category: <Translations category={"pricing"} id={76}/>},
    {category: <Translations category={"pricing"} id={77}/>},
    {category: <Translations category={"pricing"} id={78}/>},
    {category: <Translations category={"pricing"} id={79}/>},
    {category: <Translations category={"pricing"} id={80}/>},
    {category: <Translations category={"pricing"} id={81}/>},
    {category: <Translations category={"pricing"} id={82}/>},
    {category: <Translations category={"pricing"} id={83}/>},
    {category: <Translations category={"pricing"} id={84}/>},
    {category: <Translations category={"pricing"} id={85}/>},
    {
        category: <Translations category={"pricing"} id={86}/>,
        test: <Translations category={"pricing"} id={87}/>,
        beginner: <CheckIcon/>,
        advanced: <CheckIcon/>,
        expert: <CheckIcon/>,
    },
    {category: <Translations category={"pricing"} id={88}/>},
    {category: <Translations category={"pricing"} id={89}/>},
    {category: <Translations category={"pricing"} id={90}/>},
    {category: <Translations category={"pricing"} id={91}/>},
    {category: <Translations category={"pricing"} id={92}/>},
    {category: <Translations category={"pricing"} id={93}/>},
    {category: <Translations category={"pricing"} id={94}/>},
    {category: <Translations category={"pricing"} id={95}/>},
    {category: <Translations category={"pricing"} id={96}/>},
    {category: <Translations category={"pricing"} id={97}/>},
    {category: <Translations category={"pricing"} id={98}/>},
    {category: <Translations category={"pricing"} id={99}/>},
    {category: <Translations category={"pricing"} id={100}/>},
    {category: <Translations category={"pricing"} id={101}/>},
    {category: <Translations category={"pricing"} id={102}/>},
    {category: <Translations category={"pricing"} id={103}/>},
    {category: <Translations category={"pricing"} id={104}/>},
    {category: <Translations category={"pricing"} id={105}/>},
    {
        category: <Translations category={"menu"} id={14}/>,
        test: <CloseIcon/>,
        beginner: <Translations category={"pricing"} id={87}/>,
        advanced: <CheckIcon/>,
        expert: <CheckIcon/>,
    },
    {category: <Translations category={"pricing"} id={106}/>},
    {category: <Translations category={"pricing"} id={107}/>},
    {category: <Translations category={"pricing"} id={93}/>},
    {category: <Translations category={"pricing"} id={108}/>},
    {category: <Translations category={"pricing"} id={109}/>},
    {category: <Translations category={"pricing"} id={110}/>},
    {category: <Translations category={"pricing"} id={111}/>},
    {category: <Translations category={"pricing"} id={112}/>},
    {category: <Translations category={"pricing"} id={113}/>},
    {category: <Translations category={"pricing"} id={114}/>},
    {category: <Translations category={"pricing"} id={115}/>},
    {category: <Translations category={"pricing"} id={116}/>},
    {category: <Translations category={"pricing"} id={117}/>},
    {category: <Translations category={"pricing"} id={118}/>},
    {category: <Translations category={"pricing"} id={119}/>},
    {category: <Translations category={"pricing"} id={120}/>},
    {category: <Translations category={"pricing"} id={121}/>},
    {
        category: <Translations category={"pricing"} id={122}/>,
        test: <Translations category={"pricing"} id={123}/>,
        beginner: <CheckIcon/>,
        advanced: <CheckIcon/>,
        expert: <CheckIcon/>,
    },
    {category: <Translations category={"menu"} id={9}/>},
    {category: <Translations category={"menu"} id={13}/>},
    {category: <Translations category={"menu"} id={14}/>},
    {
        category: <Translations category={"pricing"} id={124}/>,
        test: <Translations category={"menu"} id={9}/>,
        beginner: <Translations category={"menu"} id={9}/>,
        advanced: <Translations category={"pricing"} id={123}/>,
        expert: <CheckIcon/>,
    },
    {category: <Translations category={"menu"} id={9}/>},
    {category: <Translations category={"menu"} id={13}/>},
    {category: <Translations category={"menu"} id={14}/>},
    {
        category: <Translations category={"pricing"} id={125}/>,
        test: <CheckIcon/>,
        beginner: <CheckIcon/>,
        advanced: <CheckIcon/>,
        expert: <CheckIcon/>,
    },
    {category: <Translations category={"pricing"} id={126}/>},
    {category: <Translations category={"pricing"} id={127}/>},
    {category: <Translations category={"pricing"} id={128}/>},
    {category: <Translations category={"pricing"} id={129}/>},
    {category: <Translations category={"pricing"} id={130}/>},
    {
        category: "API",
        test: <CloseIcon/>,
        beginner: <CloseIcon/>,
        advanced: <CloseIcon/>,
        expert: <CheckIcon/>,
    },
    {category: <Translations category={"pricing"} id={131}/>},
    {category: <Translations category={"pricing"} id={132}/>},
]