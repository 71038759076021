import React, {createContext, useContext, useEffect, useState} from 'react';

const WebSocketContext = createContext(null);

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({children}) => {
    const [receivedData, setReceivedData] = useState(null);

    useEffect(() => {
        let socket = new WebSocket('ws://127.0.0.1:8484');
        socket.onmessage = (event) => {
            setReceivedData(event.data)
        }

        socket.onerror = (error) => {
            console.error('Socket error: ', error);
        };

        socket.onopen = (error) => {
            console.log('Socket open: ', error);
        };

        socket.onclose = (error) => {
            console.log('Socket close: ', error);
        };

        return () => {
            socket.close();
        };
    }, []);

    return (
        <WebSocketContext.Provider value={receivedData}>
            {children}
        </WebSocketContext.Provider>
    );
};