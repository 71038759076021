import React from "react"
import Template from "../../components/Template"
import img1 from "../../images/percentage/topmenu.png"
import img2 from "../../images/percentage/percentage.png"
import Translations from "../../../components/Translations"
import style from "../../../../style"

function Monitoring() {
    const Content = () => {
        return (
            <div>
                <img src={img1} alt='/' className={`${style.borderImg} my-5`}/>
                <Translations category={"docsMonitoring"} id={1}/>
                <div className={"mt-5 mb-5"}>
                    <Translations category={"docsMonitoring"} id={2}/>
                    <li><Translations category={"docsMonitoring"} id={3}/></li>
                    <li><Translations category={"docsMonitoring"} id={4}/></li>
                    <li><Translations category={"docsMonitoring"} id={5}/></li>
                </div>
                <div className={"mt-5 mb-5"}>
                    <Translations category={"docsMonitoring"} id={6}/>
                </div>
                <div className={"mt-5 mb-5 text-yellow-500"}>
                    <Translations category={"docsMonitoring"} id={7}/>
                </div>
                <img src={img2} alt='/' className={`${style.borderImg} my-5`}/>
            </div>
        )
    }
    const config = {
        title: <Translations category={"docs"} id={16}/>,
        previousLink: '/docs/chart',
        previousTitle: <Translations category={"docs"} id={15}/>,
        nextLink: '/docs/notes',
        nextTitle: <Translations category={"docs"} id={17}/>,
        content: Content(),
    }

    return (
        <Template config={config}/>
    )
}

export default Monitoring