import React from "react"
import Navbar from "../components/Navbar"
import {color} from "../../style"
import {Link, Outlet} from "react-router-dom"
import {menu} from "./content/Menu"

function Documentation() {

    return (
        <div className={`min-h-screen bg-[${color.main}]`}>
            <Navbar/>
            <div className={`flex flex-row w-full bg-[${color.main}]`}>
                <div
                    className={`w-[300px] bg-[${color.main}] py-5 h-full hidden xl:flex flex-col fixed overflow-y-auto border-r-2 border-[${color.accent}] pb-20`}>
                    {menu.map((option, index) => (
                        <div key={index}>
                            <div className={`px-5 flex justify-between items-center`}>
                                <p className={`text-lg font-semibold`}>{option.category}</p>
                            </div>
                            <div className={``}>
                                {option.subpages.map((subpage, subIndex) => (
                                    <Link to={subpage.url}>
                                        <div key={subIndex}
                                             className={`px-10 cursor-pointer hover:bg-[#29394b] ${window.location.pathname.slice(3) === subpage.url ? `bg-[${color.accent}]` : `bg-[${color.main}]`}`}>
                                            <p className={`text-lg`}>{subpage.name}</p>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
                <div className={`xl:pl-[320px] 2xl:pr-[320px] px-5 mt-5 mb-10`}>
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}

export default Documentation