import axios from "axios"
import {BASEURL} from "../../../globals/Constants"
import {setMessage} from "../redux/GlobalState"
import {IsLocalhost, isLocalhost} from "../../../globals/auth/IsLocalhost";

export const deleteBots = (source, dispatch, market) => {
    IsLocalhost(() => axios.delete(`${BASEURL}/active_bots/all?source=${source}&market=${market}`)
        .catch(_ => _), () => dispatch(setMessage({key: 'Alert', value: 'You cannot delete bots in demo version'})))
}

export const increaseSlots = (source, increase, dispatch, market) => {
    IsLocalhost(() => axios.get(`${BASEURL}/slots?market=${market}&source=${source}&increase=${increase}`).catch(_ => _),
        () => dispatch(setMessage({key: 'Alert', value: 'You cannot edit slots in demo version'})))
}

export const add = (lastConfiguration, source, dispatch) => {
    if (isLocalhost()) {
        axios.put(`${BASEURL}/user_last_strategies/${lastConfiguration.id}`, lastConfiguration).catch(_ => _)
        axios.get(`${BASEURL}/active_bots`).then(res => {
            const activeBot = res.data.find(bot => bot.symbol === lastConfiguration.symbol && bot.source === lastConfiguration.view && bot.market === lastConfiguration.market)
            const entity = {
                userStrategyId: lastConfiguration.strategyId,
                strategyName: lastConfiguration.strategyName,
                symbol: lastConfiguration.symbol,
                intervl: lastConfiguration.interval,
                leverage: lastConfiguration.leverage,
                maxBet: lastConfiguration.fullBalance ? null : lastConfiguration.maxBet,
                oneTrade: lastConfiguration.oneTrade,
                fullBalance: lastConfiguration.fullBalance,
                realTimeEntry: lastConfiguration.realTimeEntry,
                mute: lastConfiguration.mute,
                stopLossInterval: lastConfiguration.stopLossInterval,
                market: lastConfiguration.market,
                active: true,
                source: source,
            }
            activeBot ? (() => {
                axios.put(`${BASEURL}/active_bots/${activeBot.id}`, entity).catch(_ => _)
            })() : (() => {
                axios.post(`${BASEURL}/active_bots`, entity).catch(_ => _)
            })()
        })
    } else {
        dispatch(setMessage({key: 'Alert', value: 'You cannot add trading bots in demo version'}))
    }
}