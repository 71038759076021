import React, {useEffect, useRef} from 'react'
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'

const LineChart = ({axisY, axisX}) => {
    const chartContainer = useRef(null)
    const chartInstance = useRef(null)

    useEffect(() => {
        if (chartContainer && chartContainer.current) {
            const ctx = chartContainer.current.getContext('2d')

            Chart.register(ChartDataLabels)

            if (chartInstance.current) {
                chartInstance.current.data.labels = axisX
                chartInstance.current.data.datasets[0].data = axisY
                chartInstance.current.update()
            } else {
                const config = {
                    type: 'line',
                    data: {
                        labels: axisX,
                        datasets: [{
                            backgroundColor: 'rgb(16,166,206)',
                            borderColor: 'rgb(16,166,206)',
                            data: axisY,
                            datalabels: {
                                align: 'end',
                                anchor: 'end'
                            }
                        }]
                    },
                    options: {
                        plugins: {
                            datalabels: {
                                backgroundColor: function (context) {
                                    return context.dataset.backgroundColor
                                },
                                borderRadius: 4,
                                color: 'white',
                                font: {
                                    weight: 'bold'
                                },
                                formatter: function (value) {
                                    return value + ' $'
                                },
                                padding: 6
                            },
                            legend: {
                                display: false,
                            },
                        },
                        aspectRatio: 5 / 3,
                        layout: {
                            padding: {
                                top: 32,
                                right: 32,
                                bottom: 0,
                                left: 32
                            }
                        },
                        elements: {
                            line: {
                                fill: false,
                                tension: 0.4
                            }
                        },
                        scales: {
                            x: {
                                grid: {
                                    display: false
                                },
                                ticks: {
                                    color: 'white'
                                }
                            },
                            y: {
                                display: false,
                            },
                        }
                    }
                }

                chartInstance.current = new Chart(ctx, config)
            }
        }
    }, [axisY, axisX])

    return <canvas ref={chartContainer}/>
}

export default LineChart
