import React, {useEffect, useRef} from 'react';
import {useSelector} from "react-redux";
import {selectTradingView} from "../components/redux/GlobalState";

let tvScriptLoadingPromise;

export default function TradingView() {
    const onLoadScriptRef = useRef();
    const data = useSelector(selectTradingView)

    const intervalMap = {
        "1m": "1",
        "3m": "3",
        "5m": "5",
        "15m": "15",
        "30m": "30",
        "1h": "60",
        "2h": "120",
        "4h": "240",
        "6h": "240",
        "8h": "240",
        "12h": "D",
        "1d": "D",
        "3d": "D",
        'M1': "1",
        'M3': "3",
        'M5': "5",
        'M15': "15",
        'M30': "30",
        'H1': "60",
        'H2': "120",
        'H4': "240",
        'H6': "240",
        'H8': "240",
        'H12': "D",
        'D1': "D",
        'D3': "D",
        'W1': "W",
    };

    useEffect(
        () => {
            onLoadScriptRef.current = createWidget;

            if (!tvScriptLoadingPromise) {
                tvScriptLoadingPromise = new Promise((resolve) => {
                    const script = document.createElement('script');
                    script.id = 'tradingview-widget-loading-script';
                    script.src = 'https://s3.tradingview.com/tv.js';
                    script.type = 'text/javascript';
                    script.onload = resolve;

                    document.head.appendChild(script);
                });
            }

            tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

            return () => onLoadScriptRef.current = null;

            function createWidget() {
                if (document.getElementById('tradingview') && 'TradingView' in window) {
                    new window.TradingView.widget({
                        symbol: `BINANCE:${data.symbol}`,
                        interval: `${intervalMap[data.interval] || "60"}`,
                        timezone: "Etc/UTC",
                        height: "100%",
                        width: "100%",
                        theme: "dark",
                        style: "1",
                        locale: "pl",
                        enable_publishing: false,
                        hide_side_toolbar: false,
                        allow_symbol_change: true,
                        container_id: "tradingview"
                    });
                }
            }
        },
        [data.symbol]
    );

    return (
        <div className='tradingview-widget-container'>
            <div id='tradingview'/>
            <p className="text-xs">Chart created by TradingView</p>
        </div>
    );
}