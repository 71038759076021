import React from "react"
import Template from "../../components/Template"
import img1 from "../../images/firstRun/loading.png"
import img2 from "../../images/firstRun/register.png"
import img4 from "../../images/firstRun/nodeAlert.png"
import img5 from "../../images/firstRun/electronAlert.png"
import img6 from "../../images/firstRun/jdk.png"
import Translations from "../../../components/Translations"
import style from "../../../../style"

function FirstRun() {
    const Content = () => {
        return (
            <div>
                <p className={`text-lg mt-5`}><Translations category={"docsFirstRun"} id={1}/></p>
                <img src={img6} alt='/' className={`${style.borderImg} my-5`}/>
                <p className={`text-lg mt-5`}><Translations category={"docsFirstRun"} id={2}/></p>
                <p className={`text-lg mt-5`}><Translations category={"docsFirstRun"} id={3}/></p>
                <div className={"grid grid-cols-1 lg:grid-cols-2 gap-5"}>
                    <img src={img4} alt='/' className={`col-span-1 ${style.borderImg} my-5`}/>
                    <img src={img5} alt='/' className={`col-span-1 ${style.borderImg} my-5`}/>
                </div>
                <p className={`text-lg mt-5`}><Translations category={"docsFirstRun"} id={4}/></p>
                <p className={`text-lg mt-5`}>
                    <li><Translations category={"docsFirstRun"} id={5}/></li>
                    <div className={"text-yellow-500"}>C:\Users\%User%\AppData\Local\CryptoDeputy\data</div>
                    <li><Translations category={"docsFirstRun"} id={6}/></li>
                    <div className={"text-yellow-500"}> C:\Users\%User%\AppData\Local\CryptoDeputy\historical</div>
                </p>
                <p className={`text-lg mt-5`}><Translations category={"docsFirstRun"} id={7}/></p>
                <img src={img1} alt='/' className={`${style.borderImg} w-[80%] my-5`}/>
                <p className={`text-lg mt-5`}><Translations category={"docsFirstRun"} id={8}/></p>
                <p className={`text-lg mt-5`}><Translations category={"docsFirstRun"} id={9}/></p>
                <p className={`text-lg mt-5`}>
                    <Translations category={"docsFirstRun"} id={10}/>
                    <li><Translations category={"docsFirstRun"} id={11}/></li>
                    <li><Translations category={"docsFirstRun"} id={12}/></li>
                </p>
                <img src={img2} alt='/' className={`${style.borderImg} w-[80%] my-5`}/>
                <p className={`text-lg mt-5`}><Translations category={"docsFirstRun"} id={13}/></p>
            </div>
        )
    }
    const config = {
        title: <Translations category={"docs"} id={4}/>,
        previousLink: '/docs/installation',
        previousTitle: <Translations category={"docs"} id={3}/>,
        nextLink: '/docs/how-to-connect',
        nextTitle: <Translations category={"docs"} id={5}/>,
        content: Content(),
    }

    return (
        <Template config={config}/>
    )
}

export default FirstRun