import React, {useState} from "react"
import {Link} from "react-router-dom"
import {links} from "./Mylinks"
import styles, {color} from "../../style"

const NavLinks = () => {
    const [heading, setHeading] = useState("")
    return (
        <>
            {links.map((link) => (
                <div className="px-3 text-left md:cursor-pointer group">
                    <h1 className="flex justify-between items-center md:pr-0 pr-5 group p-4 text-xl text-[#f3f2ee] tracking-tighter cursor-pointer"
                        onClick={() => {
                            heading !== link.name ? setHeading(link.name) : setHeading("")
                        }}>
                        {link.name}
                        <span className="text-xl md:hidden inline">
                                <ion-icon name={`${heading === link.name ? "chevron-up" : "chevron-down"}`}/>
                            </span>
                        <span
                            className="text-xl md:mt-1 md:ml-2 md:block hidden group-hover:rotate-180 group-hover:-mt-2">
                                <ion-icon name="chevron-down"/>
                            </span>
                    </h1>
                    {link.submenu && (
                        <div className="absolute top-9 hidden group-hover:md:block hover:md:block">
                            <div className="py-3">
                                <div className={`w-4 h-4 left-3 absolute mt-1 ${styles.backgroundAccent} rotate-45`}/>
                            </div>
                            <div className={`${styles.backgroundBase} p-4 border border-[#10a7d0]`}>
                                {link.sublinks.map((mysublinks) => (
                                    <div>
                                        {mysublinks.sublink.map((slink) => (
                                            <div className={`text-lg text-[${color.accent}] pl-3 border-l-4 border-[#10a7d0] my-3`}>
                                                <Link to={slink.link} className="hover:text-primary">
                                                    {slink.name}
                                                </Link>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </>
    )
}

export default NavLinks