import React from "react"
import {color} from "../../../style"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {Link} from "react-router-dom";
function Template({config}) {

    return (
        <div>
            <h1 className={`text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl pb-5 font-bold text-[#f3f2ee] bg-[${color.main}] bg-opacity-90 w-fit border-b-4 border-[${color.accent}]`}>
                {config.title}
            </h1>
            <div className={`mt-10`}>
                {config.content}
            </div>
            <div className={`flex justify-between mt-16`}>
                {config.previousLink ? <Link to={config.previousLink} className={`flex flex-row items-center`}>
                    <ArrowBackIosIcon/>
                    <p>{config.previousTitle}</p>
                </Link> : <div></div>}

                {config.nextLink ? <Link to={config.nextLink} className={`flex flex-row items-center`}>
                    <p>{config.nextTitle}</p>
                    <ArrowForwardIosIcon/>
                </Link> : <div></div>}
            </div>
        </div>
    )
}

export default Template