import React, {useState} from "react"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import styles, {color} from "../../../../style"
import HoverHint from "../../../components/HoverHint"
import {Reorder} from "framer-motion"
import Pattern from "./Pattern"
import Condition from "./Condition"
import Sltp from "./Sltp"
import {generateUniqueId} from "./StrategyCommons"
import Time from "./Time"
import {Checkbox, FormControlLabel} from "@mui/material"

function StrategyCondition({isOpen, title, shortcut, condition, setStrategy, strategy, keyName, stops}) {
    const [selectMode, setSelectMode] = useState(true)
    const [selectMode2, setSelectMode2] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [openTab, setOpenTab] = useState(1)
    const [inputValues, setInputValues] = useState({
        value: [],
        parameter: '',
        sign: '',
    })
    const [editModal, setEditModal] = useState({
        isEdit: false,
        index: -1,
        openTab: 0,
    })

    const [closeType, setCloseType] = useState({
        buy: true,
        sell: true,
    })
    const handleAddItem = () => {
        setShowModal(true)
        setInputValues({
            sign: '',
            value: [Object.keys(shortcut)[0], Object.keys(shortcut)[0]],
            parameter: Object.keys(condition)[0],
        })
    }

    const setValue = (item) => {
        const newItem = {
            id: generateUniqueId(),
            value: [],
            sign: item,
            parameter: '',
        }
        setStrategy((prevData) => ({
            ...prevData,
            [keyName]: [...prevData[keyName], newItem],
        }))
    }

    const handleDeleteItem = (item) => {
        const updatedStrategy = strategy[keyName]
            .filter((i) => i !== item)
        setStrategy((prevData) => ({
            ...prevData,
            [keyName]: updatedStrategy,
        }))
    }

    const changeItem = (item, index) => {
        const updatedItems = {...strategy}
        updatedItems[keyName][index] = {
            id: updatedItems[keyName][index].id,
            value: ["", ""],
            sign: item.sign === "OR" ? "AND" : "OR",
            parameter: ''
        }
        setStrategy(updatedItems)
    }

    const handleClosePopup = () => {
        setEditModal({
            isEdit: false,
            index: -1,
        })
        setCloseType({
            sell: true,
            buy: true,
        })
        setShowModal(false)
    }

    const editItem = (item, index) => {
        setSelectMode(isNaN(item.value[0]))
        setSelectMode2(isNaN(item.value[1]))
        setInputValues({
            sign: '',
            value: item.value,
            parameter: item.parameter,
        })
        if (item.parameter === "pat") {
            setEditModal({
                isEdit: true,
                index: index,
                openTab: 2,
            })
            setOpenTab(2)
        } else if (Object.keys(stops).includes(item.parameter)) {
            setEditModal({
                isEdit: true,
                index: index,
                openTab: 3,
            })
            setOpenTab(3)
        } else if (Object.values(shortcut).filter(a => a.type.includes("TimeType")).filter(a => a.shortName.includes(item.value[0])).length > 0) {
            setEditModal({
                isEdit: true,
                index: index,
                openTab: 4,
            })
            setOpenTab(4)
        } else {
            setEditModal({
                isEdit: true,
                index: index,
                openTab: 1,
            })
            setOpenTab(1)
        }
        setShowModal(true)
    }

    const configSltp = {
        editModal: editModal,
        inputValues: inputValues,
        setEditModal: setEditModal,
        setStrategy: setStrategy,
        setShowModal: setShowModal,
        keyName: keyName,
        stops: stops,
        strategy: strategy,
        closeType: closeType,
        setCloseType: setCloseType,
    }

    const configCondition = {
        setShowModal: setShowModal,
        inputValues: inputValues,
        setInputValues: setInputValues,
        shortcut: shortcut.length > 0 && shortcut.filter(item => item.type !== 'TimeType'),
        condition: condition,
        setStrategy: setStrategy,
        strategy: strategy,
        keyName: keyName,
        setSelectMode: setSelectMode,
        setSelectMode2: setSelectMode2,
        selectMode: selectMode,
        selectMode2: selectMode2,
        setEditModal: setEditModal,
        editModal: editModal,
        closeType: closeType,
        setCloseType: setCloseType,
    }

    const configTime = {
        setShowModal: setShowModal,
        inputValues: inputValues,
        setInputValues: setInputValues,
        shortcut: shortcut,
        condition: condition,
        setStrategy: setStrategy,
        strategy: strategy,
        keyName: keyName,
        setSelectMode: setSelectMode,
        setSelectMode2: setSelectMode2,
        selectMode: selectMode,
        selectMode2: selectMode2,
        setEditModal: setEditModal,
        editModal: editModal,
        closeType: closeType,
        setCloseType: setCloseType,
    }

    function convertToUserFriendlyValue(item) {
        let result
        if (item.value[0] === "d") {
            const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
            return daysOfWeek[item.value[1] - 1]
        } else if (item.value[0] === "dtm") {
            result = new Date(parseInt(item.value[1])).toLocaleString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            })
        } else if (item.value[0] === "dt") {
            const date = new Date(parseInt(item.value[1]))
            const year = date.getFullYear()
            const month = ('0' + (date.getMonth() + 1)).slice(-2)
            const day = ('0' + date.getDate()).slice(-2)
            result = `${year}-${month}-${day}`
        }else{
            result = item.value[1]
        }
        return result
    }

    return (
        <div className={`${isOpen && "mb-4"}`}>
            {(keyName !== "closeSell" && keyName !== "closeBuy") && <div className={styles.rowBetween}>
                <div className="flex justify-items-start items-center gap-3 text-2xl">
                    <HoverHint visibleText={title} tooltipText="Dodaj Close"/>
                </div>
                <div className="flex gap-3">
                    {strategy[keyName].length >= 2 && <>
                        <button onClick={() => setValue('AND')}
                                className="bg-[#10a7d0] text-white p-2 rounded">AND
                        </button>
                        <button onClick={() => setValue('OR')}
                                className="bg-[#10a7d0] text-white p-2 rounded">OR
                        </button>
                    </>}
                    {strategy[keyName].length >= 3 &&
                        <button onClick={() => {
                            setValue('(')
                            setValue(')')
                        }} className="bg-[#10a7d0] text-white p-2 rounded">BRACKET
                        </button>}
                    <button onClick={handleAddItem}
                            className="bg-[#10a7d0] flex justify-end rounded-md font-medium py-3 text-[#f3f2ee] ml-5">Add
                    </button>
                </div>
            </div>}
            {(((keyName === "closeSell") || (keyName === "closeBuy")) && (strategy[keyName].length >= 2)) &&
                <div className="flex flex-row justify-end">
                    <div className="flex gap-3 mt-4">
                        <button onClick={() => setValue('AND')}
                                className="bg-[#10a7d0] text-white p-2 rounded">AND
                        </button>
                        <button onClick={() => setValue('OR')}
                                className="bg-[#10a7d0] text-white p-2 rounded">OR
                        </button>
                        {strategy[keyName].length >= 3 &&
                            <button onClick={() => {
                                setValue('(')
                                setValue(')')
                            }} className="bg-[#10a7d0] text-white p-2 rounded">BRACKET
                            </button>}
                    </div>
                </div>}
            <div
                className={`${styles.roundedBox} h-[70px] items-center flex flex-row gap-2 mt-4`}>
                {keyName === "closeSell" && <p className="text-sm w-[80px]">only for sell:</p>}
                {keyName === "closeBuy" && <p className="text-sm w-[80px]">only for buy:</p>}
                <Reorder.Group axis="x"
                               onReorder={(newIndicators) => setStrategy((prevData) => ({
                                   ...prevData,
                                   [keyName]: newIndicators,
                               }))}
                               values={strategy[keyName]}
                               className="flex flex-row gap-4">
                    {strategy[keyName]
                        .map((item, index) => (
                            <Reorder.Item value={item} key={item.id}>
                                <div key={item.id}
                                     className="relative text-black bg-gray-300 text-center py-2 px-4 flex items-center rounded">
                                    {item.sign ?
                                        <p className="text-base">{item.sign}</p> :
                                        (condition[item.parameter] ?
                                            (item.value[0] + "  " + condition[item.parameter].mathSymbol + "  " + convertToUserFriendlyValue(item)) :
                                            (item.parameter + `(` + item.value + `)`))}
                                    <DeleteIcon onClick={() => handleDeleteItem(item)}
                                                className="absolute top-0 right-0 -mt-2 -mr-2 rounded-full bg-red-500 text-white p-1"/>
                                    {item.sign ? item.sign === "AND" || item.sign === "OR" ?
                                            <EditIcon onClick={() => changeItem(item, index)}
                                                      className="absolute bottom-0 right-0 -mb-2 -mr-2 rounded-full bg-green-500 text-white p-1"/> : <></> :
                                        <EditIcon onClick={() => editItem(item, index)}
                                                  className="absolute bottom-0 right-0 -mb-2 -mr-2 rounded-full bg-green-500 text-white p-1"/>}
                                </div>
                            </Reorder.Item>
                        ))}
                </Reorder.Group>
            </div>
            {showModal && (
                <div className="fixed inset-0 flex z-[1100] items-center justify-center bg-black bg-opacity-90">
                    <div className={`lg:w-1/2 flex flex-col`}>
                        <div className="flex-row grid grid-cols-9 gap-1 mt-2">
                            <div data-toggle="tab" role="tablist"
                                 className={`col-span-2 rounded-t-lg justify-center cursor-pointer flex pt-1 uppercase items-center text-base ${openTab === 1 ? `bg-[#353535] font-bold border-x border-t border-[${color.accent}]` : "bg-[#424040]"}`}
                                 onClick={e => {
                                     e.preventDefault()
                                     setOpenTab(1)
                                 }}>
                                Condition
                            </div>
                            <div onClick={e => {
                                e.preventDefault()
                                setOpenTab(2)
                            }}
                                 data-toggle="tab" role="tablist"
                                 className={`col-span-2 rounded-t-lg justify-center uppercase cursor-pointer items-center flex pt-1 text-base ${openTab === 2 ? `bg-[#353535] font-bold border-x border-t border-[${color.accent}]` : "bg-[#424040]"}`}>
                                Candle
                            </div>
                            {!isOpen && <div onClick={e => {
                                e.preventDefault()
                                setOpenTab(3)
                            }}
                                             data-toggle="tab" role="tablist"
                                             className={`col-span-2 rounded-t-lg justify-center uppercase cursor-pointer items-center flex pt-1 text-base ${openTab === 3 ? `bg-[#353535] font-bold border-x border-t border-[${color.accent}]` : "bg-[#424040]"}`}>
                                SL / TP
                            </div>}
                            <div onClick={e => {
                                e.preventDefault()
                                setOpenTab(4)
                            }}
                                 data-toggle="tab" role="tablist"
                                 className={`col-span-2 rounded-t-lg justify-center uppercase cursor-pointer items-center flex pt-1 text-base ${openTab === 4 ? `bg-[#353535] font-bold border-x border-t border-[${color.accent}]` : "bg-[#424040]"}`}>
                                Time
                            </div>
                            <button onClick={handleClosePopup}
                                    className={`${isOpen ? 'col-span-3' : 'col-span-1'} rounded-full w-[30px] h-[30px] bg-[#10a7d0] text-white font-bold ml-auto mb-1 p-1`}>X
                            </button>
                        </div>
                        <div
                            className={`flex flex-grow justify-center bg-[#353535] border border-[${color.accent}] p-3 rounded-b-lg rounded-tr-lg `}>
                            <div className="flex flex-col w-full">
                                <div className={openTab === 1 ? "block" : "hidden"}>
                                    <Condition configCondition={configCondition}/>
                                </div>
                                <div className={openTab === 2 ? "block" : "hidden"}>
                                    <Pattern setStrategy={setStrategy} strategy={strategy}
                                             inputValues={inputValues} setEditModal={setEditModal}
                                             setInputValues={setInputValues} editModal={editModal}
                                             setShowModal={setShowModal} keyName={keyName} closeType={closeType}
                                             setCloseType={setCloseType}/>
                                </div>
                                {!isOpen && <div className={openTab === 3 ? "block" : "hidden"}>
                                    <Sltp configSltp={configSltp}/>
                                </div>}
                                <div className={openTab === 4 ? "block" : "hidden"}>
                                    <Time configTime={configTime}/>
                                </div>
                                {keyName === "close" && <div className="mt-10">
                                    <FormControlLabel control={<Checkbox style={{color: color.accent}}/>}
                                                      className="text-white" label="Buy"
                                                      checked={closeType.buy}
                                                      onChange={() => setCloseType({
                                                          ...closeType,
                                                          buy: !closeType.buy,
                                                      })}/>
                                    <FormControlLabel control={<Checkbox style={{color: color.accent}}/>}
                                                      className="text-white" label="Sell"
                                                      checked={closeType.sell}
                                                      onChange={() => setCloseType({
                                                          ...closeType,
                                                          sell: !closeType.sell,
                                                      })}
                                    />
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default StrategyCondition