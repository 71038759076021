import React, {useState} from 'react'
import HelpIcon from "@mui/icons-material/Help"
import styles, {color} from "../../style"
import EditIcon from "@mui/icons-material/Edit"
import {useNavigate} from "react-router-dom"
import {Popover} from "@mui/material";

const StrategyPopup = ({strategyName, strategies}) => {
    const lastConfigStrategy = strategies.find(a => a.name === strategyName)
    const strategy = lastConfigStrategy ?? strategies[0]
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState(null)
    const navigateToEdit = () => {
        navigate(`/app/strategies/edit/${strategy.id}`)

    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div>
            <HelpIcon className={"mr-2"} onClick={handleClick}/>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={`bg-[#353535] py-3 px-5 text-white border-2 border-[${color.accent}] max-w-[500px]`}>
                    <div className={`${styles.rowBetween} pb-2 border-b items-center border-[#10a7d0] mb-2`}>
                        <h5 className="text-2xl font-bold text-[#f3f2ee]">{strategy.name}</h5>
                        <button type="button" onClick={navigateToEdit}
                                className="-mb-px mr-2 hover:bg-[#10a7d0] rounded-full text-sm p-2 items-center ">
                            <EditIcon/>
                        </button>
                    </div>
                    <span className={`${styles.flexCenter} break-all pb-2 border-b border-[#10a7d0]`}>
                        {strategy ? strategy.description : ''}
                    </span>
                    <div className="break-words w-full" style={{listStyleType: 'none', padding: 0}}>
                        {strategy ? formatString(strategy.strategy) : ""}
                    </div>
                </div>
            </Popover>
        </div>
    )
}
const formatString = (input) => {
    const parts = input.split('&')

    return (
        <>
            {parts.map((part, index) => {
                const [key, value] = part.split('=')
                return (
                    <li key={index}
                        className="my-2 last:m-0 pb-2 last:p-0 border-b last:border-0 border-[#10a7d0] flex flex-col">
                        <span className={`${styles.flexCenter} font-bold text-sm`}>{key}</span>
                        <span className={`${styles.flexCenter} text-lg break-all`}>{value}</span>
                    </li>
                )
            })}
        </>
    )
}
export default StrategyPopup