import React, {useState} from "react"
import Statistic from "./Statistics"
import {useDispatch, useSelector} from "react-redux";
import {selectMarket, setMarket} from "../../components/redux/GlobalState";
import Cookies from "../../../globals/cookies/Cookies";


const Dashboard = () => {
    const dispatch = useDispatch()
    const viewName = window.location.pathname.split("/")[2].replace("-", "")
    const market = useSelector(selectMarket)
    const [selectMode, setSelectMode] = useState(Cookies.getCookie("dashboard-mode") ?? "PaperTrading")
    const [timeCombobox, setTimeCombobox] = useState("100000")

    return (
        <div className="flex flex-col w-full">
            <div className="flex flex-col sm:flex-row sm:items-center justify-between">
                <div className={`flex justify-center items-start sm:items-center flex-col sm:flex-row`}>
                    <p className="text-4xl">Dashboard</p>
                </div>
                <div className="flex place-content-between content-center">
                    <div className={"flex flex-row items-center gap-2"}>
                        <div className={`flex flex-col`}>
                            <p className={`text-xs pl-2`}>Mode</p>
                            <div className={`bg-[#353535] p-2 rounded-lg`}>
                                <select value={selectMode} id="states"
                                        className="bg-[#353535] text-white block w-full outline-none"
                                        onChange={(e) => changeMode(setSelectMode, e.target.value)}>
                                    <option value="PaperTrading">Paper Trading</option>
                                    <option value="AutomateTrading">Automate Trading</option>
                                </select>
                            </div>
                        </div>
                        <div className={`flex flex-col`}>
                            <p className={`text-xs pl-2`}>Market</p>
                            <div className={`bg-[#353535] p-2 rounded-lg`}>
                                <select value={market[viewName]} id="states"
                                        className="bg-[#353535] text-white block w-full outline-none"
                                        onChange={(market) => changeMarket(market.target.value)}>
                                    <option key={"spot"} value={"spot"}>{"Spot"}</option>
                                    <option key={"futures"} value={"futures"}>{"Futures"}</option>
                                </select>
                            </div>
                        </div>
                        <div className={`flex flex-col`}>
                            <p className={`text-xs pl-2`}>Time</p>
                            <div className={`bg-[#353535] p-2 rounded-lg`}>
                                <select value={timeCombobox} id="states"
                                        className="bg-[#353535] text-white block w-full outline-none"
                                        onChange={(e) => setTimeCombobox(e.target.value)}>
                                    <option value="1">1 day</option>
                                    <option value="7">1 week</option>
                                    <option value="30">1 month</option>
                                    <option value="90">3 months</option>
                                    <option value="180">6 month</option>
                                    <option value="365">1 year</option>
                                    <option value="100000">All</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-2">


                <Statistic timeCombobox={timeCombobox} market={market[viewName]} source={selectMode}/>
            </div>
        </div>
    )

    function changeMode(setSelectMode, value) {
        setSelectMode(value)
        Cookies.setCookie("dashboard-mode", value)
    }

    function changeMarket(market) {
        dispatch(setMarket({key: viewName, data: market}))
        Cookies.setCookie("market-" + viewName, market)
    }
}

export default Dashboard