import React from "react"
import Template from "../../components/Template"
import img1 from "../../images/systemRequirement/windows.png"
import Translations from "../../../components/Translations"

function SystemRequirements() {
    const Content = () => {
        return (
            <div>
                <div className={"flex flex-row items-center"}>
                    <img src={img1} alt='/' className={`w-[10%] mt-5 mb-5`}/>
                    <div className={"m-5"}>
                        <div className={`flex flex-row items-center`}>
                            <p className={`text-base mr-2`}>
                                <Translations category={"docsSystemRequirements"} id={1}/>
                            </p>
                            <p className={`text-lg font-semibold`}>
                                <Translations category={"docsSystemRequirements"} id={2}/>
                            </p>
                        </div>
                        <div className={`flex flex-row items-center`}>
                            <p className={`text-base mr-2`}>
                                <Translations category={"docsSystemRequirements"} id={3}/>
                            </p>
                            <p className={`text-lg font-semibold`}>
                                <Translations category={"docsSystemRequirements"} id={4}/>
                            </p>
                        </div>
                        <div className={`flex flex-row items-center`}>
                            <p className={`text-base mr-2`}>
                                <Translations category={"docsSystemRequirements"} id={5}/>
                            </p>
                            <p className={`text-lg font-semibold`}>
                                <Translations category={"docsSystemRequirements"} id={6}/>
                            </p>
                        </div>
                        <div className={`flex flex-row items-center`}>
                            <p className={`text-base mr-2`}>
                                <Translations category={"docsSystemRequirements"} id={7}/>
                            </p>
                            <p className={`text-lg font-semibold`}>
                                <Translations category={"docsSystemRequirements"} id={8}/>
                            </p>
                        </div>
                    </div>
                </div>
                <p className={`text-base mt-5`}><Translations category={"docsSystemRequirements"} id={9}/></p>
                <p className={`text-base mt-5`}><Translations category={"docsSystemRequirements"} id={10}/></p>
                <p className={`text-base mt-5`}><Translations category={"docsSystemRequirements"} id={11}/></p>
                <p className={`text-base mt-5 text-yellow-500`}>
                    <Translations category={"docsSystemRequirements"} id={12}/>
                </p>
            </div>
        );
    }
    const config = {
        title: <Translations category={"docs"} id={2}/>,
        previousLink: '',
        previousTitle: '',
        nextLink: '/docs/installation',
        nextTitle: <Translations category={"docs"} id={3}/>,
        content: Content(),
    }

    return (
        <Template config={config}/>
    )
}

export default SystemRequirements