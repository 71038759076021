import React from "react"
import Template from "../../components/Template"
import img1 from "../../images/alerts/topmenu.png"
import img2 from "../../images/alerts/alerts.png"
import img3 from "../../images/alerts/infobox.png"
import Translations from "../../../components/Translations"
import style from "../../../../style"

function TextAlerts() {
    const Content = () => {
        return (
            <div>
                <img src={img1} alt='/' className={`${style.borderImg} my-5`}/>
                <Translations category={"docsTextAlerts"} id={1}/>
                <div className={"mt-5 mb-5"}>
                    <Translations category={"docsTextAlerts"} id={2}/>
                    <li><Translations category={"docsTextAlerts"} id={3}/></li>
                    <li><Translations category={"docsTextAlerts"} id={4}/></li>
                    <li><Translations category={"docsTextAlerts"} id={5}/></li>
                </div>
                <div className={`grid grid-cols-6 mb-5`}>
                    <div className={`col-span-1`}>
                        <img src={img2} alt='/' className={`${style.borderImg} my-5`}/>
                    </div>
                    <div className={`col-span-5`}>
                        <img src={img3} alt='/' className={`${style.borderImg} my-5`}/>
                    </div>
                </div>
            </div>
        )
    }
    const config = {
        title: <Translations category={"docs"} id={18}/>,
        previousLink: '/docs/notes',
        previousTitle: <Translations category={"docs"} id={17}/>,
        nextLink: '/docs/sound-alerts',
        nextTitle: <Translations category={"docs"} id={19}/>,
        content: Content(),
    }

    return (
        <Template config={config}/>
    )
}

export default TextAlerts