import React, {useEffect, useState} from "react"
import axios from "axios"
import {BASEURL} from "../../../../globals/Constants"
import Indicators from "../components/Indicators"
import {useLocation, useNavigate, useParams} from "react-router-dom"
import styles from "../../../../style"
import {useDispatch, useSelector} from "react-redux"
import {selectStrategyParams, setMessage} from "../../../components/redux/GlobalState"
import {generateUniqueId} from "../components/StrategyCommons"
import StrategyCondition from "../components/StrategyCondition"
import {IsLocalhost, isLocalhost} from "../../../../globals/auth/IsLocalhost";
import user_strategies from "../../../mocks/user_strategies.json"

const EditStrategy = () => {
    const {id} = useParams()
    const location = useLocation()
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [strategy, setStrategy] = useState(
        {
            indicators: [],
            openBuy: [],
            openSell: [],
            close: [],
            closeBuy: [],
            closeSell: [],
            name: '',
            description: '',
            isFavorite: true,
        }
    )
    const strategyData = useSelector(selectStrategyParams)
    const [shortcutsAndIndicatorParams, setShortcutsAndIndicatorParams] = useState([])
    const openShortcuts = Object.keys(shortcutsAndIndicatorParams).length > 0 && Object.values(shortcutsAndIndicatorParams).filter(item => !item.name.includes("Open Trade"))

    function processConditions(data, category) {
        if (data[0] !== "") {
            data.forEach(condition => {
                if (condition !== "AND" && condition !== "OR" && condition !== "" && condition !== "(" && condition !== ")") {
                    const [parameter, values] = condition.split('(')
                    const id = generateUniqueId()
                    const value = values.replace(')', '').split(',')
                    const sign = ''
                    editStrategy[category].push({id, parameter, value, sign})
                } else {
                    editStrategy[category].push({
                        id: generateUniqueId(),
                        parameter: '',
                        value: '',
                        sign: condition
                    })
                }
            })
        }
    }

    const editStrategy = {
        id: [],
        indicators: [],
        openBuy: [],
        openSell: [],
        close: [],
        closeBuy: [],
        closeSell: [],
    }

    function convertTime(seconds) {
        var day = seconds / (60 * 60 * 24)
        var hour = seconds / (60 * 60)
        var minute = seconds / 60

        if (Number.isInteger(day)) {
            return day + 'd';
        } else if (Number.isInteger(hour)) {
            return hour + 'h';
        } else if (Number.isInteger(minute)) {
            return minute + 'm';
        } else {
            return seconds +'s';
        }
    }


    useEffect(() => {
        if (Object.keys(strategyData.conditions).length > 0) {
            IsLocalhost(() => axios.get(`${BASEURL}/user_strategies/${id}`)
                .then(res => handleStrategy(res.data))
                .catch(_ => _), () => handleStrategy(user_strategies.find(a => a.id === parseInt(id))))
        }
    }, [strategyData.conditions])

    function setCloseCondition(data, category) {
        data.forEach(condition => {
            if (condition.split('(')[0] === "ttsl") {
                const [parameter, values] = condition.split('(')
                const id = generateUniqueId()
                const value = values.replace(')', '').split(',')
                const sign = ''
                value[1] = convertTime(value[1])
                editStrategy[category].push({id, parameter, value, sign})
            } else if (condition.split('(')[0] === "tmsl") {
                const [parameter, values] = condition.split('(')
                const id = generateUniqueId()
                const value = values.replace(')', '').split(',')
                const sign = ''
                value[0] = convertTime(value[0])
                editStrategy[category].push({id, parameter, value, sign})
            }else if (condition !== "AND" && condition !== "OR" && condition !== "" && condition !== "(" && condition !== ")") {
                const [parameter, values] = condition.split('(')
                const id = generateUniqueId()
                const value = values.replace(')', '').split(',')
                const sign = ''
                editStrategy[category].push({id, parameter, value, sign})
            }else {
                editStrategy[category].push({
                    id: generateUniqueId(),
                    parameter: '',
                    value: '',
                    sign: condition
                })
            }
        })
    }
    function handleStrategy(strategyData) {
        if (strategyData.indicators[0] !== "") {
            strategyData.indicators.forEach(indicator => {
                if (indicator !== "AND") {
                    const [indicatorType, indicatorValue] = indicator.split('(')
                    const valueArray = indicatorValue.replace(')', '').split(',')
                    editStrategy.indicators.push({indicator: indicatorType, value: valueArray})
                }
            })
        }
        processConditions(strategyData.openBuy, "openBuy")
        processConditions(strategyData.openSell, "openSell")
        if (strategyData.close[0] !== "") {
            setCloseCondition(strategyData.close, "close")
        }
        if (strategyData.closeBuy[0] !== "") {
            setCloseCondition(strategyData.closeBuy,"closeBuy")
        }
        if (strategyData.closeSell[0] !== "") {
            setCloseCondition(strategyData.closeSell,"closeSell")
        }

        setStrategy({
            ...strategy,
            indicators: editStrategy.indicators,
            openBuy: editStrategy.openBuy,
            openSell: editStrategy.openSell,
            close: editStrategy.close,
            closeBuy: editStrategy.closeBuy,
            closeSell: editStrategy.closeSell,
            name: strategyData.name,
            description: strategyData.description,
            isFavorite: strategyData.isFavorite
        })
        return ''
    }

    useEffect(() => {
        setShortcutsAndIndicatorParams(strategyData.shortcuts)
        strategy.indicators.forEach(indicator => {
            const indicatorInnerValues = Object.keys(strategyData.indicators[indicator.indicator].innerValues)

            const clearedShortcuts = indicatorInnerValues.length === 0 ? [{
                shortName: `${indicator.indicator}`,
                name: `${strategyData.indicators[indicator.indicator].name} (${indicator.indicator})`,
                description: strategyData.indicators[indicator.indicator].description,
                type: "Indicator"
            }] : indicatorInnerValues.map(a => (
                {
                    shortName: `${indicator.indicator}.${a}`,
                    name: `${strategyData.indicators[indicator.indicator].innerValues[a].userFriendlyName} (${indicator.indicator})`,
                    description: strategyData.indicators[indicator.indicator].innerValues[a].description,
                    type: "Indicator"
                }
            ))

            setShortcutsAndIndicatorParams(prevState => {
                const newState = [...prevState]
                clearedShortcuts.forEach((item, index) => {
                    newState[index + Object.keys(prevState).length] = item
                })
                return newState
            })
        })
    }, [strategyData.shortcuts, strategy.indicators])

    function valueInSeconds(value) {
        const number = parseInt(value.substring(0, value.length - 1))
        const unit = value.substring(value.length - 1)
        let seconds
        if (unit === "d") {
            seconds = number * 24 * 60 * 60
        } else if (unit === "h") {
            seconds = number * 60 * 60
        } else if (unit === "m") {
            seconds = number * 60
        } else if (unit === "s") {
            seconds = number
        }
        return `${seconds}`
    }

    function closeValue(param) {
        if(param.parameter === "ttsl"){
            return (param.parameter + "(" + param.value[0] + "," + valueInSeconds(param.value[1]) + ")")
        } else if(param.parameter === "tmsl") {
            return (param.parameter + "(" + valueInSeconds(param.value[0]) + ")")
        } else {
            return param.parameter + "(" + param.value + ")"
        }
    }

    function submit() {
        if (!isLocalhost()) {
            dispatch(setMessage({key: 'Alert', value: 'You cannot edit strategy in demo version'}))
        } else {
            const indicators = strategy.indicators.map(a => (a.indicator + "(" + a.value.toString() + ")")).join('^')
            const openBuy = strategy.openBuy.map(a => (a.parameter ? a.parameter + "(" + a.value + ")" : a.sign)).join('').replaceAll("AND", "^").replaceAll("OR", "|")
            const openSell = strategy.openSell.map(a => (a.parameter ? a.parameter + "(" + a.value + ")" : a.sign)).join('').replaceAll("AND", "^").replaceAll("OR", "|")
            const close = strategy.close.map(a => a.sign ? a.sign : closeValue(a)).join('').replaceAll("AND", "^").replaceAll("OR", "|")
            const closeBuy = strategy.closeBuy.map(a => a.sign ? a.sign : closeValue(a)).join('').replaceAll("AND", "^").replaceAll("OR", "|")
            const closeSell = strategy.closeSell.map(a => a.sign ? a.sign : closeValue(a)).join('').replaceAll("AND", "^").replaceAll("OR", "|")
            let params = []
            let closeCondition = []
            if (indicators !== '') {
                params.push(`indicators=${indicators}`)
            }
            if (openBuy !== '') {
                params.push(`openBuy=${openBuy}`)
            }
            if (openSell !== '') {
                params.push(`openSell=${openSell}`)
            }

            if (((close !== '') && (closeBuy === '') && (closeSell === ''))) {
                params.push(`close=${close}`)
            } else if (((close === '') && (closeBuy !== '') && (closeSell === ''))) {
                params.push(`close=${closeBuy}`)
            } else if (((close === '') && (closeBuy === '') && (closeSell !== ''))) {
                params.push(`close=${closeSell}`)
            } else {
                if (close !== '') {
                    closeCondition.push(`(${close})`)
                }
                if (closeBuy !== '') {
                    closeCondition.push(`(ltb()^(${closeBuy}))`)
                }
                if (closeSell !== '') {
                    closeCondition.push(`(lts()^(${closeSell}))`)
                }
                if (closeCondition.length > 0) {
                    params.push(`close=${closeCondition.join("|")}`)
                }
            }

            axios.put(`${BASEURL}/user_strategies/${id}`, {
                strategy: params.join('&'),
                name: strategy.name,
                description: strategy.description,
                isFavorite: strategy.isFavorite
            }).then(() => {
                if (location.state && location.state.refreshData) {
                    location.state.refreshData()
                }
                navigate('/app/strategies')
            }).catch(error => {
                dispatch(setMessage({key: 'Alert', value: error.response.data}))
            })
        }
    }

    return (
        <div className="flex flex-col w-full gap-2">
            <div className={styles.rowBetween}>
                <p className="text-4xl">Edit Strategy</p>
                <button className={styles.blueButton} onClick={() => submit()}>Save</button>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-4 lg:grid-cols-7 2xl:grid-cols-9 gap-4">
                <div className={`${styles.roundedBox} col-span-1`}>
                    <p className="text-xl mb-1">Name *</p>
                    <input type="text" id="name" maxLength={100}
                           value={strategy.name}
                           className={`${styles.roundedBox} border border-gray-300 text-gray-300 text-sm focus:ring-[#10a7d0] focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none`}
                           placeholder="Name of your stategy" required
                           onChange={(e) => setStrategy({...strategy, name: e.target.value})}/>
                    <p className="text-xl mb-1 mt-5">Description</p>
                    <textarea id="description" rows="4" maxLength={500}
                              spellCheck="false"
                              value={strategy.description}
                              className={`${styles.roundedBox} block w-full text-sm text-gray-300 border border-gray-300 focus:ring-[#10a7d0] focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 outline-none`}
                              placeholder="Description of your stategy"
                              onChange={(e) => setStrategy({...strategy, description: e.target.value})}/>
                </div>
                <div className="col-span-1 sm:col-span-3 lg:col-span-6 2xl:col-span-8">
                    <Indicators strategy={strategy} setStrategy={setStrategy} strategyData={strategyData}
                                setShortcutsAndIndicatorParams={setShortcutsAndIndicatorParams}/>
                    <StrategyCondition isOpen={true} title={"Long"} shortcut={openShortcuts} keyName={"openBuy"}
                                       condition={strategyData.conditions} strategy={strategy} setStrategy={setStrategy}
                                       stops={strategyData.stops}/>
                    <StrategyCondition isOpen={true} title={"Short"} shortcut={openShortcuts} keyName={"openSell"}
                                       condition={strategyData.conditions} strategy={strategy} setStrategy={setStrategy}
                                       stops={strategyData.stops}/>
                    <StrategyCondition isOpen={false} title={"Close"} stops={strategyData.stops}
                                       condition={strategyData.conditions}
                                       shortcut={shortcutsAndIndicatorParams} keyName={"close"} strategy={strategy}
                                       setStrategy={setStrategy}/>
                    {strategy.closeBuy.length > 0 &&
                        <StrategyCondition isOpen={false} title={"Close Buy"} stops={strategyData.stops}
                                           condition={strategyData.conditions}
                                           shortcut={shortcutsAndIndicatorParams} keyName={"closeBuy"}
                                           strategy={strategy}
                                           setStrategy={setStrategy}/>}
                    {strategy.closeSell.length > 0 &&
                        <StrategyCondition isOpen={false} title={"Close Sell"} stops={strategyData.stops}
                                           condition={strategyData.conditions}
                                           shortcut={shortcutsAndIndicatorParams} keyName={"closeSell"}
                                           strategy={strategy}
                                           setStrategy={setStrategy}/>}
                </div>
            </div>
        </div>
    )
}

export default EditStrategy