import React from "react"
import Template from "../../components/Template"
import img1 from "../../images/notes/topmenu.png"
import img2 from "../../images/notes/notes.png"
import Translations from "../../../components/Translations"
import style from "../../../../style"

function Notes() {
    const Content = () => {
        return (
            <div>
                <img src={img1} alt='/' className={`${style.borderImg} my-5`}/>
                <Translations category={"docsNotes"} id={1}/>
                <img src={img2} alt='/' className={`${style.borderImg} my-5`}/>
                <div className={"mt-5 mb-5"}>
                    <Translations category={"docsNotes"} id={2}/>
                </div>
            </div>
        )
    }
    const config = {
        title: <Translations category={"docs"} id={17}/>,
        previousLink: '/docs/monitoring',
        previousTitle: <Translations category={"docs"} id={16}/>,
        nextLink: '/docs/text-alerts',
        nextTitle: <Translations category={"docs"} id={18}/>,
        content: Content(),
    }

    return (
        <Template config={config}/>
    )
}

export default Notes