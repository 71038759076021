import React from "react"
import Template from "../../components/Template"
import img1 from "../../images/stops/falling.jpg"
import Translations from "../../../components/Translations"
import style from "../../../../style"

function TakeProfit() {
    const Content = () => {
        return (
            <div>
                <img src={img1} alt='/' className={`${style.borderImg} mt-5 mb-10 w-[20%]`}/>
                <p className={`text-lg mt-5`}><Translations category={"docsTakeProfit"} id={1}/></p>
                <p className={`text-lg mt-5`}>
                    <Translations category={"docsTakeProfit"} id={2}/>
                    <li><Translations category={"docsTakeProfit"} id={3}/></li>
                    <li><Translations category={"docsTakeProfit"} id={4}/></li>
                </p>
                <p className={`text-lg mt-5`}><Translations category={"docsTakeProfit"} id={5}/></p>
                <p className={`text-lg mt-5`}>
                    <Translations category={"docsTakeProfit"} id={6}/>
                    <li><Translations category={"docsTakeProfit"} id={7}/></li>
                </p>
                <p className={`mt-10`}><Translations category={"docsTakeProfit"} id={8}/></p>
                <p className={`text-lg mt-5 text-yellow-500`}><Translations category={"docsTakeProfit"} id={9}/></p>
                <p className={`text-lg mt-5 text-yellow-500`}><Translations category={"docsTakeProfit"} id={10}/></p>
            </div>
        )
    }
    const config = {
        title: "Take profit",
        previousLink: '/docs/percentage-stop-loss',
        previousTitle: 'Stop loss',
        nextLink: '/docs/trailing-stop-loss',
        nextTitle: <Translations category={"docs"} id={31}/>,
        content: Content(),
    }

    return (
        <Template config={config}/>
    )
}

export default TakeProfit