import React from "react"
import Template from "../../components/Template"
import img1 from "../../images/stops/falling.jpg"
import Translations from "../../../components/Translations"
import style from "../../../../style"

function TimeTrailingStopLoss() {
    const Content = () => {
        return (
            <div>
                <img src={img1} alt='/' className={`${style.borderImg} mt-5 mb-10 w-[20%]`}></img>
                <p className={`text-lg mt-5`}><Translations category={"docsTimeTrailingStopLoss"} id={1}/></p>
                <p className={`text-lg mt-5`}>
                    <Translations category={"docsTimeTrailingStopLoss"} id={2}/>
                    <li><Translations category={"docsTimeTrailingStopLoss"} id={3}/></li>
                    <li><Translations category={"docsTimeTrailingStopLoss"} id={4}/></li>
                    <li><Translations category={"docsTimeTrailingStopLoss"} id={5}/></li>
                    <li><Translations category={"docsTimeTrailingStopLoss"} id={6}/></li>
                    <li><Translations category={"docsTimeTrailingStopLoss"} id={7}/></li>
                </p>
                <p className={`mt-10`}><Translations category={"docsTimeTrailingStopLoss"} id={8}/></p>
                <p className={`text-lg mt-5 text-yellow-500`}><Translations category={"docsTimeTrailingStopLoss"}
                                                                            id={9}/></p>
                <p className={`text-lg mt-5`}>
                    <Translations category={"docsTimeTrailingStopLoss"} id={10}/>
                    <li><Translations category={"docsTimeTrailingStopLoss"} id={11}/></li>
                    <li><Translations category={"docsTimeTrailingStopLoss"} id={12}/></li>
                    <li><Translations category={"docsTimeTrailingStopLoss"} id={13}/></li>
                </p>
                <p className={`text-lg mt-5 text-yellow-500`}><Translations category={"docsTimeTrailingStopLoss"}
                                                                            id={14}/></p>
                <p className={`text-lg mt-5 text-yellow-500`}><Translations category={"docsTimeTrailingStopLoss"}
                                                                            id={15}/></p>
                <p className={`mt-10`}><Translations category={"docsTimeTrailingStopLoss"} id={16}/></p>
                <p className={`text-lg mt-5`}><Translations category={"docsTimeTrailingStopLoss"} id={17}/></p>
            </div>
        )
    }
    const config = {
        title: <Translations category={"docs"} id={32}/>,
        previousLink: '/docs/trailing-stop-loss',
        previousTitle: <Translations category={"docs"} id={31}/>,
        nextLink: '/docs/time-stop-loss',
        nextTitle: <Translations category={"docs"} id={33}/>,
        content: Content(),
    }

    return (
        <Template config={config}/>
    )
}

export default TimeTrailingStopLoss