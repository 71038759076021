import React from "react"
import {color} from "../../style"

function IconSection({config}) {

    return (
        <div className={`grid md:grid-cols-2 lg:grid-cols-3 gap-10`}>
            <div
                className={`flex flex-row items-center`}>
                {config.iconOne}
                <div className={`pr-5 mr-7 h-[60px] border-r-4 border-[${color.accent}]`}></div>
                <h3 className={`text-xl lg:text-2xl`}>
                    {config.textOne}
                </h3>
            </div>
            <div
                className={`flex flex-row items-center`}>
                {config.iconTwo}
                <div className={`pr-5 mr-7 h-[60px] border-r-4 border-[${color.accent}]`}></div>
                <h3 className={`text-xl lg:text-2xl`}>
                    {config.textTwo}
                </h3>
            </div>
            <div
                className={`flex flex-row items-center`}>
                {config.iconThree}
                <div className={`pr-5 mr-7 h-[60px] border-r-4 border-[${color.accent}]`}></div>
                <h3 className={`text-xl lg:text-2xl`}>
                    {config.textThree}
                </h3>
            </div>
        </div>
    )
}

export default IconSection