import React from "react"
import styles, {color} from "../../../style"
import {Link} from "react-router-dom"
import {menu} from "../content/Menu"

const DefaultDocumentationContent = () => {

    return (
        <div className={`${styles.backgroundMain}`}>
            {menu.map((option, index) => (
                <div className={`mb-10`}>
                    <h1 className={`text-3xl md:text-4xl lg:text-5xl pb-2 font-bold text-[#f3f2ee] bg-[${color.main}] bg-opacity-90 w-fit border-b-4 border-[${color.accent}]`}>
                        {option.category}
                    </h1>
                    <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-4 mt-4`}>
                        {option.subpages.map((option, index) => (
                            <Link to={option.url} className={`bg-[${color.base}] flex flex-row items-center p-3`}>
                                {option.icon}
                                <div className={`pr-3 mr-3 h-[45px] border-r-4 border-[${color.accent}]`}></div>
                                <h3 className={`text-xl lg:text-xl`}>
                                    {option.name}
                                </h3>
                            </Link>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    )

}

export default DefaultDocumentationContent