import React, {useState} from "react"
import HoverHint from "../../../components/HoverHint"
import styles from "../../../../style"
import TextFieldBox from "../../../components/TextFieldBox"
import {generateUniqueId} from "./StrategyCommons"
import TextWithHint from "../../../components/TextWithHint"

function Sltp({configSltp}) {
    const [selectedNumber, setSelectedNumber] = useState(findNumber())
    const [selectedUnit, setSelectedUnit] = useState(findUnit())
    const [inputValues, setInputValues] = useState({
        value: (configSltp.inputValues.parameter === "ttsl" && configSltp.editModal) ? [configSltp.inputValues.value[0], `${selectedNumber}${selectedUnit}`] : (configSltp.editModal.isEdit && (configSltp.editModal.openTab === 3)) ? configSltp.inputValues.value : Object.values(configSltp.stops)[0].reflectionMethod.params.map(param => param.default),
        parameter: (configSltp.editModal.isEdit && (configSltp.editModal.openTab === 3)) ? configSltp.inputValues.parameter : Object.keys(configSltp.stops)[0],
        sign: '',
    })

    function findNumber() {
        if (configSltp.inputValues.parameter === "ttsl" && configSltp.editModal.isEdit) {
            return parseInt(configSltp.inputValues.value[1].substring(0, configSltp.inputValues.value[1].length - 1))
        } else if(configSltp.inputValues.parameter === "tmsl" && configSltp.editModal.isEdit) {
            return parseInt(configSltp.inputValues.value[0].substring(0, configSltp.inputValues.value[0].length - 1))
        } else {
            return 1
        }
    }

    function findUnit() {
        if (configSltp.inputValues.parameter === "ttsl" && configSltp.editModal.isEdit) {
            return configSltp.inputValues.value[1].substring(configSltp.inputValues.value[1].length - 1)
        } else if (configSltp.inputValues.parameter === "tmsl" && configSltp.editModal.isEdit) {
            return configSltp.inputValues.value[0].substring(configSltp.inputValues.value[0].length - 1)
        }else {
            return "d"
        }
    }

    const handleSave = () => {
        if (inputValues.parameter && !configSltp.editModal.isEdit) {
            let keyName = configSltp.keyName
            if (configSltp.keyName === "close" && ((configSltp.closeType.buy === false) || ((configSltp.closeType.sell === false)))) {
                keyName = configSltp.closeType.buy === false ? "closeSell" : "closeBuy"
            } else {
                keyName = configSltp.keyName
            }
            const newItem = {
                id: generateUniqueId(),
                value: inputValues.value,
                parameter: inputValues.parameter
            }
            configSltp.setStrategy((prevData) => ({
                ...prevData,
                [keyName]: [...prevData[keyName], newItem],
            }))
            configSltp.setCloseType({sell: true, buy: true})
            configSltp.setShowModal(false)
        } else if (inputValues.parameter && configSltp.editModal.isEdit) {
            const updatedItems = {...configSltp.strategy}
            updatedItems[configSltp.keyName][configSltp.editModal.index] = {
                id: updatedItems[configSltp.keyName][configSltp.editModal.index].id,
                value: inputValues.value,
                parameter: inputValues.parameter
            }
            configSltp.setStrategy(updatedItems)
            configSltp.setEditModal({
                isEdit: false,
                index: -1,
            })
            configSltp.setCloseType({
                sell: true,
                buy: true,
            })
            configSltp.setCloseType({sell: true, buy: true})
            configSltp.setShowModal(false)
        }
    }

    return (
        <div>
            <div className={`${styles.rowBetween} mb-10`}>
                <p className="text-xl">Add Condition Close</p>
                <button onClick={handleSave}
                        className="bg-[#10a7d0] h-[30px] text-white flex font-bold items-center rounded">Save
                </button>
            </div>
            <div className="flex flex-col gap-4">
                <p className="text-lg">Select Close:</p>
                <div className="flex flex-row gap-1">
                    <HoverHint tooltipText="123"/>
                    <select value={inputValues.parameter}
                            onChange={(e) => {
                                setInputValues({
                                    parameter: e.target.value,
                                    value: configSltp.stops[e.target.value].reflectionMethod.params.map(param => param.default)
                                })
                            }}
                            className="bg-[#1d1d1d] rounded text-white text-right text-lg w-full outline-none p-1">
                        {Object.entries(configSltp.stops).map(([key, value]) => (
                            <option key={key} value={key}>{value.name} ({key})</option>
                        ))}
                    </select>
                </div>
                <p className="text-lg">Complete the parameters:</p>
                <div className="grid grid-cols-2 gap-5">
                    {configSltp.stops[inputValues.parameter].reflectionMethod.params.map((item, index) => (
                        <div className="flex flex-row col-span-1 gap-1" key={index}>
                            <TextWithHint
                                description={"The maximum value for days is 365, for hours it's 8760, and for minutes it's 525600"}
                                title={item.name}/>
                            {((inputValues.parameter === "ttsl" && index === 1) || (inputValues.parameter === "tmsl")) ?
                                <div className="flex flex-row gap-2">
                                    <TextFieldBox
                                        onChangeCallback={(e) => {
                                            let maxValue
                                            let maxNumbers
                                            if (selectedUnit === "d") {
                                                maxValue = 365
                                                maxNumbers = /^\d{0,3}$/
                                            } else if (selectedUnit === "h") {
                                                maxValue = 8760
                                                maxNumbers = /^\d{0,4}$/
                                            } else if (selectedUnit === "m") {
                                                maxValue = 525600
                                                maxNumbers = /^\d{0,6}$/
                                            } else if (selectedUnit === "s") {
                                                maxValue = 31536000
                                                maxNumbers = /^\d{0,8}$/
                                            }

                                            const inputValue = parseInt(e.target.value);
                                            if(inputValues.parameter === "ttsl") {
                                                if (maxNumbers.test(e.target.value) && inputValue <= maxValue) {
                                                    setSelectedNumber(inputValue);
                                                    setInputValues({
                                                        ...inputValues,
                                                        value: [...inputValues.value.slice(0, 1), `${inputValue}${selectedUnit}`]
                                                    });
                                                }
                                            }else if(inputValues.parameter === "tmsl") {
                                                if (maxNumbers.test(e.target.value) && inputValue <= maxValue) {
                                                    setSelectedNumber(inputValue);
                                                    setInputValues({
                                                        ...inputValues,
                                                        value: [`${inputValue}${selectedUnit}`]
                                                    });
                                                }
                                            }
                                        }}
                                        def={selectedNumber}
                                        placeholder={"1"}/>
                                    <select value={selectedUnit}
                                            onChange={(e) => {
                                                if(inputValues.parameter === "ttsl") {
                                                    setSelectedUnit(e.target.value);
                                                    setInputValues({
                                                        ...inputValues,
                                                        value: [...inputValues.value.slice(0, 1), `${selectedNumber}${e.target.value}`]
                                                    })
                                                }else if(inputValues.parameter === "tmsl"){
                                                    setSelectedUnit(e.target.value);
                                                    setInputValues({
                                                        ...inputValues,
                                                        value: [`${selectedNumber}${e.target.value}`]
                                                    })
                                                }
                                            }}
                                            className="bg-[#1d1d1d] text-white rounded text-right text-lg w-fit outline-none ml-0 pl-0 py-1">
                                        <option value="d">days</option>
                                        <option value="h">hours</option>
                                        <option value="m">minutes</option>
                                        <option value="s">seconds</option>
                                    </select>
                                </div>
                                :
                                <TextFieldBox
                                    onChangeCallback={(e) => {
                                        const maxLength = item.precision.split(',')[0]
                                        const regex = new RegExp(`^\\d{0,${maxLength}}?$`)
                                        regex.test(e.target.value) && (e.target.value <= item.max) && setInputValues(prevState => {
                                            if (index > 0 && index < prevState.value.length) {
                                                return {
                                                    ...prevState,
                                                    value: [
                                                        ...prevState.value.slice(0, index),
                                                        e.target.value,
                                                        ...prevState.value.slice(index + 1)
                                                    ]
                                                }
                                            } else if (index === 0) {
                                                return {
                                                    ...prevState,
                                                    value: [e.target.value, ...prevState.value.slice(1)]
                                                }
                                            } else {
                                                return {
                                                    ...prevState,
                                                    value: [...prevState.value, e.target.value]
                                                }
                                            }
                                        })
                                    }
                                    }
                                    def={inputValues.value[index]}
                                    placeholder={configSltp.stops[inputValues.parameter].reflectionMethod.params.map(param => param.default)[index]}/>}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Sltp