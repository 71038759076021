import React, {useEffect, useState} from "react"
import TextFieldBox from "../TextFieldBox"
import MainButton from "../MainButton"
import {useDispatch, useSelector} from "react-redux"
import {
    selectBackTestSymbolsFutures, selectBackTestSymbolsSpot,
    selectBinanceAssets,
    selectLastConfigurations,
    selectMessages,
    selectPlan,
    selectStrategies,
    setLastConfigurations
} from "../redux/GlobalState"
import ComboBox from "../ComboBox"
import styles from "../../../style"
import DatePicker from "react-datepicker"
import ButtonWithPopup from "../ButtonWithPopup"
import HoverHint from "../HoverHint"
import {Checkbox, FormControlLabel, Switch} from "@mui/material"
import axios from "axios";
import {BASEURL} from "../../../globals/Constants";
import {useWebSocket} from "../../../globals/socket/Socket";
import loading from "../../views/backtests/loading.gif"
import {IsLocalhost, isLocalhost} from "../../../globals/auth/IsLocalhost";
import exchangeInfo from "../../mocks/exchangeInfo.json";

const StrategyPanel = ({config, title, source, mainButtonLabel, mainButtonFunction, market}) => {
    const dispatch = useDispatch()
    const [symbols, setSymbols] = useState([])
    const configurations = useSelector(selectLastConfigurations)
    const backtestSymbolsFutures = useSelector(selectBackTestSymbolsFutures)
    const backtestSymbolsSpot = useSelector(selectBackTestSymbolsSpot)
    const binanceAssets = useSelector(selectBinanceAssets)
    const blockingAlert = useSelector(selectMessages)
    const strategies = useSelector(selectStrategies)
    const plan = useSelector(selectPlan)
    const lastConfiguration = configurations.find(a => a.view === source && a.market === market)
    const actualSymbolAssets = binanceAssets.filter(a => a.market === market).filter(a => a.symbol === lastConfiguration.symbol)
    const oldestDate = actualSymbolAssets.length > 0 ? actualSymbolAssets[0].oldestDate : "2024-01-01"
    const symbolsEnriched = source === "BackTest" ? (plan === "Free" ? ['BTCUSDT', 'ETHUSDT', 'BNBUSDT', 'XRPUSDT', 'DOGEUSDT'] : (market === "spot" ? backtestSymbolsSpot : backtestSymbolsFutures)) : [...["All"], ...(handleSelector(symbols, "symbol"))]
    const intervals = plan === "Free" ? ['1h', '2h', '4h', '6h', '8h', '12h', '1d', '3d', '1w'] : ['1m', '3m', '5m', '15m', '30m', '1h', '2h', '4h', '6h', '8h', '12h', '1d', '3d', '1w']
    const currentStrategy = strategies.find(strategy => strategy.name === lastConfiguration.strategyName) || strategies?.[0]
    const strategy = currentStrategy ? currentStrategy.strategy : ""
    const userStrategyId = currentStrategy ? currentStrategy.id : 0
    const strategyName = currentStrategy ? currentStrategy.name : ""
    const strategyNames = handleSelector(strategies, "name")
    const [showCancelButton, setShowCancelButton] = useState(false)
    const [openTab, setOpenTab] = useState(1)
    const [configPanel, setConfigPanel] = useState(null)
    const [loadingVisible, setLoadingVisible] = useState(false)
    const formatDate = (date) => {
        if (!date) return null
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        return `${year}-${month}-${day}`
    }

    useEffect(() => {
        IsLocalhost(() => axios.get(`${BASEURL}/sources?output=Json&market=${market}`).then(res => {
                setSymbols(res.data)
            }).catch(_ => _),
            () => setSymbols(exchangeInfo.symbols.map(sym => {
                return {symbol: sym.symbol}
            })))

        if (isLocalhost()) {
            axios.get(`${BASEURL}/active_bots/adding`)
                .then(res => setShowCancelButton(res.data[market + source].started))
                .catch(_ => _)
        }
    }, [market])

    useEffect(() => {
        dispatch(setLastConfigurations(updateConfigurationsMulti(source, configurations, market, [{
            name: "strategyId", value: userStrategyId
        }, {name: "strategyName", value: strategyName}])))
    }, [userStrategyId])

    useEffect(() => {
        if (new Date(lastConfiguration.from) < new Date(oldestDate)) {
            dispatch(setLastConfigurations(updateConfigurations(oldestDate, source, configurations, "from", market)))
        }
    }, [lastConfiguration.symbol])

    useEffect(() => {
        if (!intervals.includes(lastConfiguration.interval)) {
            dispatch(setLastConfigurations(updateConfigurations(intervals[0], source, configurations, "interval", market)))
        }
    }, [lastConfiguration.interval])

    const ws = useWebSocket();
    useEffect(() => {
        if (ws) {
            let data = JSON.parse(ws)
            if (data.value.includes("Backtesting finished") || data.value.includes("The data for the given historical test is unavailable on the exchange")) {
                setLoadingVisible(false)
            } else if (data.value.includes("All bots have been added") && data.source === config.source) {
                setShowCancelButton(false)
            }
        }
    }, [ws])

    useEffect(() => {
        setConfigPanel(
            <div className="flex flex-row gap-4 items-center">
                {source !== "BackTest" && <div className={`${styles.rowBetween} items-center`}>
                    <div className="w-[100px]">
                        <ButtonWithPopup buttonText={mainButtonLabel} actionOnButtonYes={true}
                                         title={popupQuestion(lastConfiguration, config.configStrategy, strategyNames)}
                                         color={"#10a7d0"}
                                         onClick={() => {
                                             mainButtonFunction(lastConfiguration, source, dispatch)
                                             lastConfiguration.symbol === "All" && setShowCancelButton(true)
                                         }}/>
                    </div>
                    {showCancelButton && <div className="w-[100px] ml-2">
                        <MainButton title={"Cancel"}
                                    onClick={() => axios.get(`${BASEURL}/active_bots/cancel?source=${source}&market=${market}`)
                                        .then(setShowCancelButton(false))
                                        .catch(_ => _)}/>
                    </div>}
                </div>}
                {source === "BackTest" &&
                    <div className={`${styles.rowBetween} items-center`}>
                        {loadingVisible === true ? <div className="mr-2">
                            <img width={"38px"} height={"38px"} src={loading} alt={"/"}></img>
                        </div> : ""}
                        <div className="w-[100px]">
                            <MainButton title={mainButtonLabel}
                                        color={"#10a7d0"}
                                        onClick={() => {
                                            setLoadingVisible(true)
                                            mainButtonFunction(lastConfiguration, strategy)
                                        }}/>
                        </div>
                        <div className="w-[100px] ml-2">
                            <MainButton title={"Cancel"}
                                        onClick={() => {
                                            setLoadingVisible(false)
                                            config.buttonDelete()
                                        }}/>
                        </div>
                    </div>
                }
            </div>)

        const blockingPanel = <div className="flex flex-row gap-4 items-center">
            {source !== "BackTest" &&
                <div
                    className="w-[100px] cursor-not-allowed bg-[#1d1d1d] h-[40px] flex items-center justify-center uppercase rounded-md border-2 border-red-500 text-red-500">
                    <HoverHint visibleText={mainButtonLabel} tooltipText={"No strategy to use"}
                               color={"rgb(237,67,67)"}/>
                </div>}
            {source === "BackTest" &&
                <div className={`${styles.rowBetween} items-center gap-2`}>
                    <div
                        className="w-[100px] cursor-not-allowed bg-[#1d1d1d] h-[40px] flex items-center justify-center uppercase rounded-md border-2 border-red-500 text-red-500">
                        <HoverHint visibleText={mainButtonLabel} tooltipText={"No strategy to use"}
                                   color={"rgb(237,67,67)"}/>
                    </div>
                    <div
                        className="w-[100px] cursor-not-allowed bg-[#1d1d1d] h-[40px] flex items-center justify-center uppercase rounded-md border-2 border-red-500 text-red-500">
                        <HoverHint visibleText={"Cancel"} tooltipText={"No strategy to use"} color={"rgb(237,67,67)"}/>
                    </div>
                </div>
            }
        </div>

        if (blockingAlert?.key === "BlockingAlert") setConfigPanel(blockingPanel)

    }, [strategies, configurations, blockingAlert, loadingVisible])


    return (
        <>
            <div className="col-span-2 lg:col-span-4 2xl:col-span-3 flex flex-col">
                <div className={`${styles.rowBetween} items-center`}><span
                    className="text-2xl">{title}</span>
                    {configPanel}
                </div>
                <div className="flex-row grid grid-cols-6 gap-1 mt-2">
                    <div data-toggle="tab" href="#link1" role="tablist"
                         className={`col-span-2 rounded-t-lg justify-center cursor-pointer flex pt-1 uppercase items-center text-base ${openTab === 1 ? "bg-[#353535] font-bold" : "bg-[#424040]"}`}
                         onClick={e => {
                             e.preventDefault()
                             setOpenTab(1)
                         }}>
                        Basic
                    </div>
                    <div onClick={e => {
                        e.preventDefault()
                        setOpenTab(2)
                    }}
                         data-toggle="tab" href="#link2" role="tablist"
                         className={`col-span-2 rounded-t-lg justify-center uppercase cursor-pointer items-center flex pt-1 text-base ${openTab === 2 ? "bg-[#353535] bold" : "bg-[#424040]"}`}>
                        Advanced
                    </div>
                </div>

                <div className="flex flex-grow justify-center bg-[#353535] p-3 rounded-b-lg rounded-tr-lg ">
                    <div className="flex flex-col w-full">
                        <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                            <div className="flex-grow grid grid-cols-6 gap-2 items-center">
                                <ComboBox title={"Strategy"} values={strategyNames}
                                          strategies={strategies}
                                          def={lastConfiguration.strategyName}
                                          onChangeCallback={(e) => {
                                              dispatch(setLastConfigurations(updateConfigurationsMulti(source, configurations, market, [{
                                                  name: "strategyId",
                                                  value: strategies.find(strategy => strategy.name === e.target.value).id
                                              }, {name: "strategyName", value: e.target.value}])))
                                          }}></ComboBox>
                                <ComboBox title={"Symbol"} values={symbolsEnriched}
                                          strategies={strategies}
                                          def={lastConfiguration.symbol}
                                          onChangeCallback={(e) => {
                                              dispatch(setLastConfigurations(updateConfigurations(e.target.value, source, configurations, "symbol", market)))
                                          }}></ComboBox>
                                <p className="col-span-1 text-base">Interval</p>
                                <select
                                    value={lastConfiguration.interval}
                                    id="states"
                                    className="bg-[#1d1d1d] col-span-2 text-white text-right text-lg rounded outline-none w-full p-1"
                                    onChange={(e) => {
                                        dispatch(setLastConfigurations(updateConfigurations(e.target.value, source, configurations, "interval", market)))
                                    }}>
                                    {intervals.map(value => <option key={value}
                                                                    value={value}>{value}</option>)}
                                </select>
                                {market === "futures" ? <TextFieldBox
                                    onChangeCallback={(e) => (/^\d{0,3}?$/).test(e.target.value) && dispatch(setLastConfigurations(updateConfigurations(e.target.value, source, configurations, "leverage", market)))}
                                    def={lastConfiguration.leverage}
                                    title={'Leverage'}
                                    placeholder={"1"}/> : <></>}
                                {config.visibility.account && <TextFieldBox
                                    onChangeCallback={(e) => (/^\d{0,10}(\.\d{0,2})?$/).test(e.target.value) && dispatch(setLastConfigurations(updateConfigurations(e.target.value, source, configurations, "balance", market)))}
                                    title={'Balance'}
                                    def={lastConfiguration.balance}
                                    placeholder={"1000"}/>}
                                {lastConfiguration.fullBalance ? <></> :
                                    <TextFieldBox
                                        onChangeCallback={(e) => (/^\d{0,10}(\.\d{0,2})?$/).test(e.target.value) && dispatch(setLastConfigurations(updateConfigurations(e.target.value, source, configurations, "maxBet", market)))}
                                        def={lastConfiguration.maxBet}
                                        title={'Max bet'}
                                        placeholder={"10"}/>}
                                {config.visibility.date &&
                                    <div className="col-span-6 grid grid-cols-6 gap-2 items-center">
                                        <p className="col-span-1 text-base">From</p>
                                        <div className="col-span-2">
                                            <DatePicker
                                                selected={new Date(lastConfiguration.from)}
                                                onChange={(date) => dispatch(setLastConfigurations(updateConfigurations(formatDate(date), source, configurations, "from", market)))}
                                                dateFormat="yyyy-MM-dd"
                                                minDate={new Date(oldestDate)}
                                                placeholderText="yyyy-MM-dd"
                                                className="bg-[#1d1d1d] text-lg rounded border-0 pr-2 py-0 w-full text-right"
                                                calendarClassName="bg-black text-white border border-[#10a7d0] flex justify-end absolute mt-1 top-full left-0"
                                            />
                                        </div>
                                        <p className="col-span-1 text-base">To</p>
                                        <div className="col-span-2">
                                            <DatePicker
                                                icon="fa fa-calendar"
                                                selected={new Date(lastConfiguration.to)}
                                                onChange={(date) => dispatch(setLastConfigurations(updateConfigurations(formatDate(date), source, configurations, "to", market)))}

                                                minDate={new Date(oldestDate)}
                                                dateFormat="yyyy-MM-dd"
                                                className="bg-[#1d1d1d] text-lg rounded border-0 pr-2 py-0 w-full text-right"
                                                calendarClassName="bg-black text-white border border-[#10a7d0] flex justify-end absolute mt-1 top-full left-0"
                                            />
                                        </div>
                                    </div>}
                            </div>
                        </div>
                        <div className={openTab === 2 ? "block" : "hidden"} id="link2">
                            <div className="flex-row items-center grid grid-cols-6  gap-2">
                                <p className="text-base col-span-1">Entry</p>
                                <div className="col-span-3 flex flex-row items-center justify-end">
                                    <p className="text-base col-span-1">candle close</p>
                                    {source === "BackTest" ? <Switch disabled={true}/> :
                                        <Switch
                                            checked={lastConfiguration.realTimeEntry}
                                            onChange={() => dispatch(setLastConfigurations(updateConfigurations(!lastConfiguration.realTimeEntry, source, configurations, "realTimeEntry", market)))}/>}
                                    <p className="text-base col-span-1">real time</p></div>
                                <div className="col-span-2 flex flex-col">
                                    <FormControlLabel control={<Checkbox/>} label="One Trade"
                                                      checked={lastConfiguration.oneTrade}
                                                      onChange={() => dispatch(setLastConfigurations(updateConfigurations(!lastConfiguration.oneTrade, source, configurations, "oneTrade", market)))}/>
                                    <FormControlLabel control={<Checkbox/>} label="Full Balance"
                                                      checked={lastConfiguration.fullBalance}
                                                      onChange={() => dispatch(setLastConfigurations(updateConfigurations(!lastConfiguration.fullBalance, source, configurations, "fullBalance", market)))}/>
                                </div>
                            </div>
                            <div className="grid grid-cols-5 gap-2 items-center">
                                <p className="col-span-1 text-base">Mute</p>
                                <input type="text"
                                       maxLength="3"
                                       value={lastConfiguration.mute.match(/^\d+/)[0]}
                                       placeholder={"1"}
                                       onChange={(e) => (/^\d{0,3}?$/).test(e.target.value) && dispatch(setLastConfigurations(updateConfigurations(e.target.value + (lastConfiguration.mute.match(/^\d*(.+)/)[1]), source, configurations, "mute", market)))}
                                       className="col-span-2 bg-[#1d1d1d] text-lg rounded border-0 text-right py-0 px-1 w-full outline-none"/>
                                <div className={`p-1 rounded bg-[#1d1d1d] w-30 col-span-2`}>
                                    <select value={lastConfiguration.mute.match(/(\d+)([a-zA-Z]+)/)[2]}
                                            className="bg-[#1d1d1d] text-white text-sm block outline-none w-full"
                                            onChange={(e) => dispatch(setLastConfigurations(updateConfigurations((lastConfiguration.mute.match(/^\d+/)[0]) + e.target.value, source, configurations, "mute", market)))}>
                                        <option value="s">seconds</option>
                                        <option value="m">minutes</option>
                                        <option value="h">hours</option>
                                        <option value="d">days</option>
                                    </select>
                                </div>
                            </div>
                            {source !== "BackTest" &&
                                <div className="grid grid-cols-5 gap-2 items-center">
                                    <p className="col-span-1 text-base">Stop loss interval</p>
                                    <input type="text"
                                           maxLength="3"
                                           value={lastConfiguration.stopLossInterval.match(/^\d+/)[0]}
                                           placeholder={"1"}
                                           onChange={(e) => (/^\d{0,3}?$/).test(e.target.value) && dispatch(setLastConfigurations(updateConfigurations(e.target.value + (lastConfiguration.stopLossInterval.match(/^\d*(.+)/)[1]), source, configurations, "stopLossInterval", market)))}
                                           className="col-span-2 bg-[#1d1d1d] text-lg rounded border-0 text-right py-0 px-1 w-full outline-none"/>
                                    <div className={`p-1 rounded bg-[#1d1d1d] w-30 col-span-2`}>
                                        <select
                                            value={lastConfiguration.stopLossInterval.match(/(\d+)([a-zA-Z]+)/)[2]}
                                            className="bg-[#1d1d1d] text-white outline-none text-sm block w-full"
                                            onChange={(e) => dispatch(setLastConfigurations(updateConfigurations((lastConfiguration.stopLossInterval.match(/^\d+/)[0]) + e.target.value, source, configurations, "stopLossInterval", market)))}>
                                            <option value="s">seconds</option>
                                            <option value="m">minutes</option>
                                            <option value="h">hours</option>
                                            <option value="d">days</option>
                                        </select>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const popupQuestion = (lastConfiguration, configStrategy, strategyNames) => {
    const intervalValue = ['1m', '3m', '5m', '15m', '30m', '1h', '2h', '4h', '6h', '8h', '12h', '1d', '3d', '1w']
    const strategyName = lastConfiguration?.strategyName ? lastConfiguration.strategyName : strategyNames[0]
    const symbol = lastConfiguration?.symbol ? lastConfiguration.symbol : configStrategy?.symbols.length > 0 ? configStrategy.symbols[0] : "BTCUSDT"
    const leverage = lastConfiguration?.leverage ? lastConfiguration.leverage : "1"
    const interval = lastConfiguration?.interval ? lastConfiguration.interval : intervalValue.length > 0 ? intervalValue[0] : "1m"
    const maxBet = lastConfiguration?.maxBet ? lastConfiguration.maxBet : "10"
    const fullBalance = lastConfiguration?.fullBalance
    const oneTrade = lastConfiguration?.oneTrade
    return `Do you really want to add strategy ${strategyName}? Symbol: ${symbol}, leverage: ${leverage}, interval: ${interval}` +
        `${oneTrade ? ", One trade: The strategy will not enter more than one transaction" : ""}` +
        `${fullBalance ? ", Full balance: You will be playing with the entire balance" : `, maxBet: ${maxBet}`}`
}

export function handleSelector(entities, fieldName) {
    return entities === undefined ? [] : entities.map(symbol => symbol[fieldName])
}

function updateConfigurations(value, source, configurations, name, market) {
    return [...configurations.map(conf => conf.view !== source && conf.market !== market ? conf : {
        ...conf,
        [name]: value
    })]
}

function updateConfigurationsMulti(source, configurations, market, fields) {
    return [...configurations.map(conf => conf.view !== source && conf.market !== market ? conf : {
        ...conf,
        [fields[0].name]: fields[0].value,
        [fields[1].name]: fields[1].value,
    })]
}

export default StrategyPanel