import React from "react"
import Template from "../../components/Template"
import img1 from "../../images/strategyCreator/create.png"
import img2 from "../../images/strategyCreator/strategyCreator.png"
import img3 from "../../images/strategyCreator/indicators.png"
import img4 from "../../images/strategyCreator/indicators2.png"
import img5 from "../../images/strategyCreator/indicators3.png"
import img6 from "../../images/strategyCreator/condition1.png"
import img7 from "../../images/strategyCreator/condition2.png"
import img8 from "../../images/strategyCreator/condition3.png"
import img9 from "../../images/strategyCreator/condition4.png"
import img10 from "../../images/strategyCreator/candle.png"
import img11 from "../../images/strategyCreator/time.png"
import img12 from "../../images/strategyCreator/time2.png"
import img13 from "../../images/strategyCreator/close.png"
import img14 from "../../images/strategyCreator/stoploss.png"
import img15 from "../../images/strategyCreator/close2.png"
import img16 from "../../images/strategyCreator/stoploss2.png"
import Translations from "../../../components/Translations"
import styles from "../../../../style"

function StrategyCreator() {
    const Content = () => {
        return (
            <div>
                <p className={`text-lg mt-5`}><Translations category={"docsStrategyCreator"} id={1}/></p>
                <img src={img1} alt='/' className={`${styles.borderImg} my-10`}/>
                <p className={`text-lg mt-5`}><Translations category={"docsStrategyCreator"} id={2}/></p>
                <img src={img2} alt='/' className={`${styles.borderImg} my-10`}/>
                <p className={`text-lg mt-5`}><Translations category={"docsStrategyCreator"} id={3}/></p>
                <div className={`text-lg mt-5`}>
                    <li><Translations category={"docsStrategyCreator"} id={4}/></li>
                    <li><Translations category={"docsStrategyCreator"} id={5}/></li>
                    <li><Translations category={"docsStrategyCreator"} id={6}/></li>
                    <li><Translations category={"docsStrategyCreator"} id={7}/></li>
                </div>
                <p className={`text-lg mt-5`}><Translations category={"docsStrategyCreator"} id={8}/></p>
                <p className={`text-lg mt-5 text-yellow-500`}><Translations category={"docsStrategyCreator"} id={9}/>
                </p>
                <p className={`text-lg text-yellow-500 `}><Translations category={"docsStrategyCreator"} id={10}/></p>
                <p className={`mt-10 text-3xl`}><Translations category={"docsStrategyCreator"} id={11}/></p>
                <Translations category={"docsStrategyCreator"} id={12}/>
                <img src={img3} alt='/' className={`${styles.borderImg} my-10`}/>
                <Translations category={"docsStrategyCreator"} id={13}/>
                <img src={img4} alt='/' className={`${styles.borderImg} my-10`}/>
                <Translations category={"docsStrategyCreator"} id={14}/>
                <img src={img5} alt='/' className={`${styles.borderImg} my-10`}/>
                <p className={`text-lg mt-5 mb-5 text-yellow-500`}><Translations category={"docsStrategyCreator"}
                                                                                 id={15}/></p>
                <p className={`mt-10 mb-10 text-3xl`}><Translations category={"docsStrategyCreator"} id={16}/></p>
                <Translations category={"docsStrategyCreator"} id={17}/>
                <img src={img6} alt='/' className={`${styles.borderImg} my-10`}/>
                <Translations category={"docsStrategyCreator"} id={18}/>
                <img src={img7} alt='/' className={`${styles.borderImg} my-10`}/>
                <Translations category={"docsStrategyCreator"} id={19}/>
                <img src={img8} alt='/' className={`${styles.borderImg} my-10`}/>
                <Translations category={"docsStrategyCreator"} id={20}/>
                <li><Translations category={"docsStrategyCreator"} id={21}/></li>
                <li><Translations category={"docsStrategyCreator"} id={22}/></li>
                <li><Translations category={"docsStrategyCreator"} id={23}/></li>
                <li><Translations category={"docsStrategyCreator"} id={24}/></li>
                <li><Translations category={"docsStrategyCreator"} id={25}/></li>
                <li><Translations category={"docsStrategyCreator"} id={26}/></li>
                <li><Translations category={"docsStrategyCreator"} id={27}/></li>
                <p className={`text-lg mt-5 mb-5 text-yellow-500`}><Translations category={"docsStrategyCreator"}
                                                                                 id={28}/></p>
                <Translations category={"docsStrategyCreator"} id={29}/>
                <img src={img9} alt='/' className={`${styles.borderImg} my-10`}/>
                <Translations category={"docsStrategyCreator"} id={30}/>
                <img src={img10} alt='/' className={`${styles.borderImg} my-10`}/>
                <Translations category={"docsStrategyCreator"} id={31}/>
                <img src={img11} alt='/' className={`${styles.borderImg} my-10`}/>
                <Translations category={"docsStrategyCreator"} id={32}/>
                <img src={img12} alt='/' className={`${styles.borderImg} my-10`}/>
                <p className={`mt-10 mb-10 text-3xl`}><Translations category={"docsStrategyCreator"} id={33}/></p>
                <Translations category={"docsStrategyCreator"} id={34}/>
                <img src={img14} alt='/' className={`${styles.borderImg} my-10`}/>
                <Translations category={"docsStrategyCreator"} id={35}/>
                <img src={img16} alt='/' className={`${styles.borderImg} my-10`}/>
                <Translations category={"docsStrategyCreator"} id={36}/>
                <img src={img13} alt='/' className={`${styles.borderImg} my-10`}/>
                <Translations category={"docsStrategyCreator"} id={37}/>
                <img src={img15} alt='/' className={`${styles.borderImg} my-10`}/>
            </div>
        )
    }
    const config = {
        title: <Translations category={"docs"} id={10}/>,
        previousLink: '/docs/strategies',
        previousTitle: <Translations category={"docs"} id={9}/>,
        nextLink: '/docs/backtests',
        nextTitle: <Translations category={"docs"} id={11}/>,
        content: Content(),
    }

    return (
        <Template config={config}/>
    )
}

export default StrategyCreator