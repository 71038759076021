import React from "react"
import Template from "../../components/Template"
import img1 from "../../images/stops/falling.jpg"
import Translations from "../../../components/Translations"
import style from "../../../../style"

function TrailingStopLoss() {
    const Content = () => {
        return (
            <div>
                <img src={img1} alt='/' className={`${style.borderImg} mt-5 mb-10 w-[20%]`}></img>
                <p className={`text-lg mt-5`}><Translations category={"docsTrailingStopLoss"} id={1}/></p>
                <p className={`text-lg mt-5`}>
                    <Translations category={"docsTrailingStopLoss"} id={2}/>
                    <li><Translations category={"docsTrailingStopLoss"} id={3}/></li>
                    <li><Translations category={"docsTrailingStopLoss"} id={4}/></li>
                    <li><Translations category={"docsTrailingStopLoss"} id={5}/></li>
                </p>
                <p className={`mt-10`}><Translations category={"docsTrailingStopLoss"} id={6}/></p>
                <p className={`text-lg mt-5 text-yellow-500`}><Translations category={"docsTrailingStopLoss"} id={7}/>
                </p>
                <p className={`text-lg mt-5`}>
                    <Translations category={"docsTrailingStopLoss"} id={8}/>
                    <li><Translations category={"docsTrailingStopLoss"} id={9}/></li>
                    <li><Translations category={"docsTrailingStopLoss"} id={10}/></li>
                    <li><Translations category={"docsTrailingStopLoss"} id={11}/></li>
                </p>
                <p className={`text-lg mt-5 text-yellow-500`}><Translations category={"docsTrailingStopLoss"} id={12}/>
                </p>
                <p className={`text-lg mt-5 text-yellow-500`}><Translations category={"docsTrailingStopLoss"} id={13}/>
                </p>
                <p className={`mt-10`}><Translations category={"docsTrailingStopLoss"} id={14}/></p>
                <p className={`text-lg mt-5`}><Translations category={"docsTrailingStopLoss"} id={15}/></p>
            </div>
        )
    }
    const config = {
        title: <Translations category={"docs"} id={31}/>,
        previousLink: '/docs/percentage-take-profit',
        previousTitle: 'Take profit',
        nextLink: '/docs/time-trailing-stop-loss',
        nextTitle: <Translations category={"docs"} id={32}/>,
        content: Content(),
    }

    return (
        <Template config={config}/>
    )
}

export default TrailingStopLoss