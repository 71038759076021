import * as React from "react";
import DownloadIcon from "@mui/icons-material/Download";
import Button from "@mui/material/Button";

const MainButton = ({title, onClick, icon, color}) => {

    return (
        <Button type="submit" variant="outlined"
                style={{backgroundColor: (color != null) ? color : "#353535", color: "#fff", borderColor: (color != null) ? color : "#374151"}}
                fullWidth fullheight={"true"} sx={{fontSize: 16}}
                startIcon={(icon != null) ? <DownloadIcon/> : <></>}
                onClick={onClick}>{title}</Button>
    );
};
export default MainButton;