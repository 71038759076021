import React, {useEffect, useState} from "react"
import Table from "../../components/Table"
import axios from "axios"
import {BASEURL} from "../../../globals/Constants"
import Chart from "../../components/charts/Chart"
import styles from "../../../style"
import LineChart from "../../components/charts/LineChart";
import PieChart from "../../components/charts/PieChart";
import TextWithColor from "../../components/TextWithColor";
import {isLocalhost} from "../../../globals/auth/IsLocalhost";
import stats_agg_trades from "../../mocks/stats_agg_trades.json";
import aggregated_all_pt from "../../mocks/aggregated_all_pt.json";
import aggregated_all_at from "../../mocks/aggregated_all_at.json";
import position_active_pt from "../../mocks/position_active_pt.json";
import position_active_at from "../../mocks/position_active_at.json";
import {useWebSocket} from "../../../globals/socket/Socket";
import {setTradingView} from "../../components/redux/GlobalState";
import {useDispatch} from "react-redux";

function convertTimestampToFormattedDate(timestamp) {
    const date = new Date(timestamp)

    const year = date.getFullYear()
    const month = (`0${date.getMonth() + 1}`).slice(-2)
    const day = (`0${date.getDate()}`).slice(-2)
    const hours = (`0${date.getHours()}`).slice(-2)
    const minutes = (`0${date.getMinutes()}`).slice(-2)

    return `${year}-${month}-${day} ${hours}:${minutes}`
}

const closeTradesColumns = [
    {accessorKey: 'symbol', header: 'Symbol', flex: 1, size: 100},
    {accessorKey: 'betSize', header: 'Bet size ($)', flex: 1, size: 100},
    {
        accessorKey: 'pnl', header: 'PnL ($)', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === null ? '-' : renderedCellValue > 0 ?
            <p className="text-green-500 text-xs">{renderedCellValue}</p> :
            <p className="text-red-500 text-xs">{renderedCellValue}</p>)
    },
    {
        accessorKey: 'roe', header: 'RoE (%)', flex: 1, size: 70,
        Cell: ({renderedCellValue}) => (renderedCellValue === null ? '-' : renderedCellValue > 0 ?
            <p className="text-green-500 text-xs">{renderedCellValue}</p> :
            <p className="text-red-500 text-xs">{renderedCellValue}</p>)
    },
    {accessorKey: 'position', header: 'Position', flex: 1, size: 70},
    {accessorKey: 'entryPrice', header: 'Entry price ($)', flex: 1, size: 100},
    {accessorKey: 'closePrice', header: 'Close price ($)', flex: 1, size: 100},
    {accessorKey: 'balance', header: 'Account balance ($)', flex: 1, size: 100},
    {accessorKey: 'tradingFees', header: 'Trading fee ($)', flex: 1, size: 100},
    {accessorKey: 'percentTradingFees', header: 'Trading fee (%)', flex: 1, size: 100},
    {
        accessorKey: 'fundingFees', header: 'Funding fee ($)', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === null ? '-' : renderedCellValue)
    },
    {
        accessorKey: 'isLiquidated', header: 'Liquidated', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue ? <p className="text-red-500 text-xs">Yes</p> : 'No')
    },
    {
        accessorKey: 'entryTime', header: 'Start time', flex: 1,
        Cell: ({renderedCellValue}) => (
            <span>{convertTimestampToFormattedDate(renderedCellValue)}</span>
        ), size: 150
    },
    {
        accessorKey: 'closeTime', header: 'End time', flex: 1,
        Cell: ({renderedCellValue}) => (
            <span>{convertTimestampToFormattedDate(renderedCellValue)}</span>
        ), size: 150
    },
]

const openTradesFuturesColumns = [
    {accessorKey: 'symbol', header: 'Symbol', flex: 1, size: 100},
    {accessorKey: 'leverage', header: 'Leverage', flex: 1, size: 100},
    {accessorKey: 'entryPrice', header: 'Entry price ($)', flex: 1, size: 100},
    {accessorKey: 'currentPrice', header: 'Current price ($)', flex: 1, size: 100},
    {
        accessorKey: 'pnl', header: 'PnL ($)', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === null ? '-' : renderedCellValue > 0 ?
            <p className="text-green-500 text-xs">{renderedCellValue}</p> :
            <p className="text-red-500 text-xs">{renderedCellValue}</p>)
    },
    {
        accessorKey: 'roe', header: 'RoE (%)', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === null ? '-' : renderedCellValue > 0 ?
            <p className="text-green-500 text-xs">{renderedCellValue}</p> :
            <p className="text-red-500 text-xs">{renderedCellValue}</p>)
    },
    {accessorKey: 'betSize', header: 'Bet size', flex: 1, size: 100},
    {accessorKey: 'positionSize', header: 'Position Size', flex: 1, size: 100},
    {accessorKey: 'side', header: 'Position', flex: 1, size: 100},
    {accessorKey: 'liquidationPrice', header: 'Liquidation', flex: 1, size: 100},
    {
        accessorKey: 'stopLossPrice', header: 'SL', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === null || renderedCellValue === 0 ? '-' : renderedCellValue.toFixed(6))
    },
    {
        accessorKey: 'takeProfitPrice', header: 'TP', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === null || renderedCellValue === 0 ? '-' : renderedCellValue.toFixed(6))
    },
]

const openTradesSpotColumns = [
    {accessorKey: 'symbol', header: 'Symbol', flex: 1, size: 100},
    {accessorKey: 'entryPrice', header: 'Entry price ($)', flex: 1, size: 100},
    {accessorKey: 'currentPrice', header: 'Current price ($)', flex: 1, size: 100},
    {
        accessorKey: 'pnl', header: 'PnL ($)', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === null ? '-' : renderedCellValue > 0 ?
            <p className="text-green-500 text-xs">{renderedCellValue}</p> :
            <p className="text-red-500 text-xs">{renderedCellValue}</p>)
    },
    {
        accessorKey: 'roe', header: 'RoE (%)', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === null ? '-' : renderedCellValue > 0 ?
            <p className="text-green-500 text-xs">{renderedCellValue}</p> :
            <p className="text-red-500 text-xs">{renderedCellValue}</p>)
    },
    {accessorKey: 'betSize', header: 'Bet size', flex: 1, size: 100},
    {accessorKey: 'side', header: 'Position', flex: 1, size: 100},
    {
        accessorKey: 'stopLossPrice', header: 'SL', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === null || renderedCellValue === 0 ? '-' : renderedCellValue)
    },
    {
        accessorKey: 'takeProfitPrice', header: 'TP', flex: 1, size: 100,
        Cell: ({renderedCellValue}) => (renderedCellValue === null || renderedCellValue === 0 ? '-' : renderedCellValue)
    },
]

const Statistic = ({timeCombobox, market, source}) => {
    const ws = useWebSocket()
    const dispatch = useDispatch()
    const [value, setValue] = useState({
        statsAggTrades: [],
        openPosition: [],
        aggTrades: [],
    });
    const isEmpty = value.aggTrades && value.aggTrades.sumLost === 0 && value.aggTrades.sumWon === 0
    const [clickedRow, setClickedRow] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    useEffect(() => {
        if (isLocalhost()) {
            axios.get(`${BASEURL}/position/active?market=${market}&source=${source}`)
            axios.get(`${BASEURL}/fullStats?market=${market}&source=${source}&from=${getFromDate()}`).catch(_ => _)
            axios.get(`${BASEURL}/stream/dashboard?market=${market}&source=${source}&isOpen=true`).catch(_ => _)
        }
    }, [timeCombobox, source, market])

    useEffect(() => {
        if (!isLocalhost()) {
            setValue({
                statsAggTrades: stats_agg_trades.filter(a => a.source === source),
                aggTrades: source === "PaperTrading" ? aggregated_all_pt : aggregated_all_at,
                openPosition: source === "PaperTrading" ? position_active_pt : position_active_at
            })
        }
    }, [])

    useEffect(() => {
        if (ws) {
            let data = JSON.parse(ws)
            if (data.key === "Dashboard") {
                setValue({...value, openPosition: JSON.parse(data.value)})
            } else if (data.key === "FullStats" && data.source === source && data.market === market) {
                const json = JSON.parse(data.value)
                setValue({
                    ...value,
                    statsAggTrades: json.aggregatedTrades,
                    aggTrades: json.strategy
                })
            }
        }
    }, [ws])

    useEffect(() => {
        if (clickedRow) {
            const data = isOpen ? value.openPosition : value.statsAggTrades
            if (data.length > 0) {
                dispatch(setTradingView({
                    symbol: data.filter(a => a.id === clickedRow)[0].symbol + (market === "futures" ? '.P' : ''),
                    interval: data.filter(a => a.id === clickedRow)[0].intervl
                }))
            }
        }
    }, [clickedRow])

    const [backtestLogs] = useState([{
        isOpen: '-',
        openTime: '-',
        closeTime: '-',
        entryPrice: '-',
        closePrice: '-',
        pnl: '-',
        roe: '-',
        position: '-',
        account: '-',
        tradingFee: '-',
        percentTradingFee: '-',
    }])

    const chartConfig = {
        data: value.statsAggTrades.filter(a => !a.isOpen),
        axisY: "closeTime",
        axisX: "balance",
    }

    function getFromDate() {
        const today = new Date()
        today.setDate(today.getDate() - timeCombobox)
        const year = today.getFullYear()
        const month = (today.getMonth() + 1).toString().padStart(2, '0')
        const day = today.getDate().toString().padStart(2, '0')
        return `${year}-${month}-${day}`
    }

    return (
        <div className="flex flex-col gap-2">
            <div className="grid grid-cols-1 2xl:grid-cols-3 gap-4 my-2">
                <div className=" flex flex-col col-span-1 2xl:col-span-2 gap-4">
                    <div className={`${styles.roundedBox} ${styles.colBetween}`}>
                        <span className="text-2xl">Open trades</span>
                        {backtestLogs &&
                            <Table columns={market === "futures" ? openTradesFuturesColumns : openTradesSpotColumns}
                                   data={value.openPosition} rows={10} clickedRow={clickedRow} click={(id) => {
                                {
                                    if (id === clickedRow) {
                                        setClickedRow(null);
                                        setIsOpen(true);
                                    } else {
                                        setClickedRow(id);
                                    }
                                }
                            }}></Table>}
                    </div>
                    <div className={`${styles.roundedBox} ${styles.colBetween}`}>
                        <span className="text-2xl">Close trades</span>
                        {backtestLogs &&
                            <Table columns={closeTradesColumns}
                                   data={value.statsAggTrades.filter(a => a.isOpen !== true).sort((a, b) => b.time - a.time)}
                                   rows={10} clickedRow={clickedRow} click={(id) => {
                                {
                                    if (id === clickedRow) {
                                        setClickedRow(null);
                                        setIsOpen(false);
                                    } else {
                                        setClickedRow(id);
                                    }
                                }
                            }}></Table>}
                    </div>
                </div>
                <div className="gap-4 flex flex-col col-span-1 2xl:col-span-1">
                    <div className={`${styles.roundedBox}`}>
                        <span className="text-xl flex justify-center">Account Balance</span>
                        <Chart chartConfig={chartConfig}/>
                    </div>
                    <div className="flex-row grid grid-cols-1 md:grid-cols-5 gap-4">
                        <div className={`${styles.roundedBox} ${styles.colBetween} col-span-1 md:col-span-3`}>
                            <div>
                                <p className="text-xl mb-5 flex justify-center">Balance</p>
                                <p className="text-xl lg:text-3xl text-[#10a7d0] font-bold mb-5 flex justify-center"> {value.aggTrades.currentBalance || '0'} $</p>
                            </div>
                            {value.aggTrades ? <LineChart
                                axisY={[value.aggTrades.startingBalance, value.aggTrades.maxBalance, value.aggTrades.minBalance, value.aggTrades.endingBalance]}
                                axisX={['Starting', 'Max', 'Min', 'Ending']}
                            /> : <LineChart axisY={[0, 0, 0, 0]} axisX={['Starting', 'Max', 'Min', 'Ending']}/>}
                        </div>
                        <div className={`${styles.roundedBox} ${styles.colBetween} col-span-1 md:col-span-2`}>
                            <div>
                                <p className="text-xl mb-5 flex justify-center">Profit</p>
                                <p className="text-xl lg:text-3xl text-[#10a7d0] font-bold mb-5 flex justify-center"> {value.aggTrades.profit || '0'} $</p>
                            </div>
                            <div className="flex justify-center items-center flex-col">
                                {isEmpty === undefined || isEmpty ?
                                    <PieChart formatter={
                                        function () {
                                            return ''
                                        }
                                    } config={{
                                        labels: ['No data'],
                                        datasets: [
                                            {
                                                data: [1],
                                                backgroundColor: ['rgba(0,0,0,0)'],
                                                borderWidth: 2,
                                                borderColor: 'rgb(16,166,206)',
                                            },
                                        ],
                                    }} labelBackground={''}/> :
                                    <PieChart formatter={
                                        function (value) {
                                            return `${value} $`
                                        }} config={{
                                        labels: ['Losing Trades', 'Winning Trades'],
                                        datasets: [
                                            {
                                                data: [value.aggTrades.sumLost, value.aggTrades.sumWon],
                                                backgroundColor: [
                                                    'rgb(208,57,53)',
                                                    'rgb(35,193,87)',
                                                ],
                                                borderWidth: 0,
                                            },
                                        ],
                                    }} labelBackground={'rgb(53,53,53,0.7)'}/>}
                            </div>
                        </div>
                    </div>
                    <div className="flex-row grid grid-cols-5 gap-4">
                        <div className={`${styles.roundedBox} col-span-3`}>
                            <TextWithColor title={"Effectiveness"} color={"text-yellow-500"}
                                           description={value.aggTrades ? value.aggTrades.effectiveness + " %" : '-'}/>
                            <TextWithColor title={"Return of equity"}
                                           description={value.aggTrades ? value.aggTrades.returnOfEquity + " %" : '-'}
                                           color={value.aggTrades?.returnOfEquity > 0 ? "text-green-500" : "text-red-500"}/>
                        </div>
                        <div className={`${styles.roundedBox} col-span-2`}>
                            <TextWithColor title={"Winnings"} color={"text-green-500"}
                                           description={value.aggTrades ? value.aggTrades.wonTrades : '-'}/>
                            <TextWithColor title={"Losing"} color={"text-red-500"}
                                           description={value.aggTrades ? value.aggTrades.lostTrades : '-'}/>
                            <TextWithColor title={"Liquidations"} color={"text-yellow-500"}
                                           description={value.aggTrades ? value.aggTrades.liquidations : '-'}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Statistic