import React from "react";

const TextWithColor = ({title, description, color, revers}) => {
    return (
        <div className={`flex flex-row gap-2 ${revers ? "justify-end" : "justify-start"}`}>
            {revers ? <></> : <p className="text-base">{title}</p>}
            <p className={`${color} text-base gap-2 flex items-center`}>{description}</p>
            {revers ? <p className="text-base">{title}</p> : <></>}
        </div>
    );
};

export default TextWithColor;
