import Translations from "./Translations";
import React from "react";

export const links = [
    {
        name: <Translations category={"menu"} id={8}/>,
        submenu: true,
        sublinks: [
            {
                sublink: [
                    {name: <Translations category={"menu"} id={9}/>, link: "/backtests"},
                    {name: <Translations category={"menu"} id={13}/>, link: "/paper-trading"},
                    {name: <Translations category={"menu"} id={14}/>, link: "/automate-trading"},
                    {name: <Translations category={"menu"} id={15}/>, link: "/statistics"},
                ],
            },
        ],
    },
    {
        name: <Translations category={"menu"} id={4}/>,
        submenu: true,
        sublinks: [
            {
                sublink: [
                    {name: <Translations category={"menu"} id={16}/>, link: "/strategy-creator"},
                    {name: <Translations category={"menu"} id={17}/>, link: "/indicators"},
                    {name: <Translations category={"menu"} id={18}/>, link: "/trailing-stop"},
                ],
            },
        ],
    },
    {
        name: <Translations category={"menu"} id={10}/>,
        submenu: true,
        sublinks: [
            {
                sublink: [
                    {name: <Translations category={"menu"} id={7}/>, link: "/about"},
                    // {name: <Translations category={"menu"} id={11}/>, link: "/terms"},
                    {name: <Translations category={"menu"} id={12}/>, link: "/contact"},
                    {name: <Translations category={"menu"} id={19}/>, link: "/docs"},
                ],
            },
        ],
    },
    // {
    //     name: "learn",
    //     submenu: true,
    //     sublinks: [
    //         {
    //             Head: "Documentation",
    //             sublink: [
    //                 { name: "Documentation", link: "/" },
    //             ],
    //         },
    //     ],
    // },
];