import React, {useState} from "react"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from '@mui/icons-material/Edit'
import styles, {color} from "../../../../style"
import HoverHint from "../../../components/HoverHint"
import TextWithHint from "../../../components/TextWithHint"
import {Reorder} from "framer-motion"
import TextFieldBox from "../../../components/TextFieldBox"
import NewlineText from "../../../components/NewlineText";

function Indicators({strategyData, setStrategy, strategy, setShortcutsAndIndicatorParams}) {
    const [showModal, setShowModal] = useState(false)
    const [inputValues, setInputValues] = useState({
        indicator: '',
        value: [],
    })
    const [editModal, setEditModal] = useState({
        isEdit: false,
        index: -1,
    })
    const handleAddItem = () => {
        setShowModal(true)
        setInputValues({
            indicator: filteredIndicators[0],
            value: strategyData.indicators[filteredIndicators[0]].params.map(param => param.default)
        })
    }
    const indicatorDetails = strategyData.indicators[inputValues.indicator]
    const indicatorDetailsOrNotExists = strategyData.indicators?.[inputValues.indicator]
    const indicatorsParamsLength = inputValues.indicator ? indicatorDetails.params.map(param => param.name).length : 0
    const handleSave = () => {
        if (inputValues.value.length === indicatorsParamsLength && !editModal.isEdit) {
            const newItem = {
                indicator: inputValues.indicator,
                value: inputValues.value,
            }
            setStrategy((prevData) => ({
                ...prevData,
                indicators: [...prevData.indicators, newItem],
            }))

            const indicatorInnerValues = Object.keys(indicatorDetails.innerValues)

            const clearedShortcuts = indicatorInnerValues.length === 0 ? [{
                shortName: `${inputValues.indicator}`,
                name: `${indicatorDetails.name} (${inputValues.indicator})`,
                description: indicatorDetails.description,
                type: "Indicator"
            }] : indicatorInnerValues.map(a => (
                {
                    shortName: `${inputValues.indicator}.${a}`,
                    name: `${indicatorDetails.innerValues[a].userFriendlyName} (${inputValues.indicator})`,
                    description: indicatorDetails.innerValues[a].description,
                    type: "Indicator"
                }
            ))

            setShortcutsAndIndicatorParams(prevState => {
                const newState = [...prevState]
                clearedShortcuts.forEach((item, index) => {
                    newState[index + Object.keys(prevState).length] = item
                })
                return newState
            })
            setShowModal(false)
        } else if (inputValues.value.length === indicatorsParamsLength && editModal.isEdit) {
            const updatedItems = {...strategy}
            updatedItems.indicators[editModal.index] = {
                indicator: inputValues.indicator,
                value: inputValues.value,
            }
            setStrategy(updatedItems)
            setEditModal({
                isEdit: false,
                index: -1,
            })
            setShowModal(false)
        }
    }

    const handleDeleteItem = (item) => {
        const updatedStrategy = strategy.indicators
            .filter((i) => i !== item)
        setStrategy((prevData) => ({
            ...prevData,
            indicators: updatedStrategy,
        }))
        setShortcutsAndIndicatorParams(prevState => {
            const newState = {...prevState}

            for (const key in newState) {
                if (newState[key].shortName.startsWith(item.indicator)) {
                    delete newState[key]
                }
            }

            return newState
        })
    }

    const editItem = (item, index) => {
        setInputValues({indicator: item.indicator, value: item.value})
        setEditModal({
            isEdit: true,
            index: index
        })
        setShowModal(true)
    }
    const handleClosePopup = () => {
        setShowModal(false)
    }

    const filteredIndicators = editModal.isEdit ? Object.keys(strategyData.indicators).filter(key => !Object.values(strategy.indicators.filter(a => a.indicator !== inputValues.indicator)).map(item => item.indicator).includes(key)) : Object.keys(strategyData.indicators).filter(key => !Object.values(strategy.indicators).map(item => item.indicator).includes(key))
    return (
        <div className="mb-4">
            <div className={styles.rowBetween}>
                <div className="flex justify-items-start items-center gap-3 text-2xl">
                    <HoverHint visibleText={"Indicator"} tooltipText={"Dodaj indykator"}/>
                </div>
                <div className="w-[200px] flex justify-end">
                    {filteredIndicators.length !== 0 ? <button onClick={handleAddItem}
                                                               className="bg-[#10a7d0] rounded-md font-medium py-3 text-[#f3f2ee]">Add</button> :
                        <button
                            className="bg-gray-500 rounded-md font-medium py-3 text-[#f3f2ee] cursor-not-allowed">Add</button>}
                </div>
            </div>
            <div
                className={`${styles.roundedBox} h-[70px] items-center grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-2 mt-4`}>
                <Reorder.Group axis="x" onReorder={(newIndicators) =>
                    setStrategy({...strategy, indicators: newIndicators})} values={strategy.indicators}
                               className="flex flex-row gap-4">
                    {strategy.indicators
                        .map((item, index) => (
                            <div key={item.indicator}>
                                <Reorder.Item value={item} id={item.indicator}>
                                    <div key={item.id}
                                         className="relative text-black bg-gray-300 text-center py-2 px-4 flex items-center rounded">
                                        <p className="text-base">{item.indicator + `(` + item.value + `)`}</p>
                                        <DeleteIcon onClick={() => handleDeleteItem(item)}
                                                    className="absolute top-0 right-0 -mt-2 -mr-2 rounded-full bg-red-500 text-white p-1"/>
                                        <EditIcon onClick={() => editItem(item, index)}
                                                  className="absolute bottom-0 right-0 -mb-2 -mr-2 rounded-full bg-green-500 text-white p-1"/>
                                    </div>

                                </Reorder.Item>
                            </div>
                        ))}
                </Reorder.Group>
            </div>
            {showModal && (
                <div className="fixed inset-0 flex z-[1100] items-center justify-center bg-black bg-opacity-90">
                    <div className={`sm:w-1/2 xl:w-2/4 justify-center flex flex-col`}>
                        <div className="flex-row grid grid-cols-7 gap-1 mt-2">
                            <div data-toggle="tab" role="tablist"
                                 className={`col-span-2 rounded-t-lg justify-center flex pt-1 uppercase items-center text-base bg-[#353535] font-bold border-x border-t border-[${color.accent}]`}>
                                Indicators
                            </div>
                            <button onClick={handleClosePopup}
                                    className="col-span-5 rounded-full w-[30px] h-[30px] bg-[#10a7d0] text-white font-bold ml-auto mb-1 p-1">X
                            </button>
                        </div>
                        <div
                            className={`flex-grow flex flex-col w-full bg-[#353535] border border-[${color.accent}] p-3 rounded-b-lg rounded-tr-lg `}>
                            <div className="flex flex-col gap-4">
                                <div className={`${styles.rowBetween}`}>
                                    <p className="text-lg">Select Indicator:</p>
                                    {inputValues.value.length === indicatorsParamsLength ?
                                        <button onClick={handleSave}
                                                className="bg-[#10a7d0] h-[30px] text-white flex font-bold items-center rounded">Save</button> :
                                        <button
                                            className="bg-gray-500 h-[30px] text-white flex font-bold items-center rounded cursor-not-allowed">Save</button>
                                    }
                                </div>
                                <div className="flex flex-row">
                                    <select value={inputValues.indicator}
                                            onChange={(e) => {
                                                setInputValues({
                                                    indicator: e.target.value,
                                                    value: strategyData.indicators[e.target.value].params.map(param => param.default)
                                                })
                                            }}
                                            className="bg-[#1d1d1d] text-white text-lg rounded text-right w-full outline-none p-1">
                                        {filteredIndicators.map(key => (
                                            <option key={key}
                                                    value={key}>{strategyData.indicators[key].name} ({key})</option>
                                        ))}
                                    </select>
                                </div>
                                {indicatorDetailsOrNotExists.params.map(param => param.name).length === 0 ?
                                    <></> : <p className="text-base">Complete the parameters:</p>}
                                <div className="grid grid-cols-2 gap-5">
                                    {indicatorDetailsOrNotExists.params.map((item, index) => (
                                        <div className="col-span-1 grid grid-cols-3 items-center" key={index}>
                                            <div className="col-span-1">
                                                <TextWithHint
                                                    description={indicatorDetails.params.map(param => param.description)[index]}
                                                    title={item.name}/>
                                            </div>
                                            <div className="col-span-2">
                                                <TextFieldBox
                                                    onChangeCallback={(e) =>
                                                    {
                                                        const maxLength = item.precision.split(',')[0]
                                                        const regex = new RegExp(`^\\d{0,${maxLength}}?$`)
                                                        regex.test(e.target.value) && (e.target.value <= item.max) && setInputValues(prevState => {
                                                        if (index > 0 && index < prevState.value.length) {
                                                            return {
                                                                ...prevState,
                                                                value: [
                                                                    ...prevState.value.slice(0, index),
                                                                    e.target.value,
                                                                    ...prevState.value.slice(index + 1)
                                                                ]
                                                            }
                                                        } else if (index === 0) {
                                                            return {
                                                                ...prevState,
                                                                value: [e.target.value, ...prevState.value.slice(1)]
                                                            }
                                                        } else {
                                                            return {
                                                                ...prevState,
                                                                value: [...prevState.value, e.target.value]
                                                            }
                                                        }
                                                    })}
                                                    }
                                                    def={inputValues.value[index]}
                                                    placeholder={indicatorDetails.params.map(param => param.default)[index]}/>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div>
                                    <NewlineText category={"appIndicators"} id={indicatorDetails.descriptionId}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Indicators