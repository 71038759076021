import React, {useEffect, useState} from 'react';
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import {BASEURL} from "../../globals/Constants";
import {useWebSocket} from "../../globals/socket/Socket";
import {isLocalhost, IsLocalhost} from "../../globals/auth/IsLocalhost";
import monitoring_json from "../mocks/monitoring.json";
import Cookies from "../../globals/cookies/Cookies";
import {selectMarket, setMarket} from "../components/redux/GlobalState";
import {useDispatch, useSelector} from "react-redux";

export default function Monitoring() {
    const dispatch = useDispatch()
    const market = useSelector(selectMarket)
    const [monitoring, setMonitoring] = useState(isLocalhost() ? [] : monitoring_json)
    const [monitoringOrder, setMonitoringOrder] = useState("asc")
    const [monitoringSort, setMonitoringSort] = useState({key: "sort", column: "symbol", value: monitoringOrder})
    const [monitoringFilter, setMonitoringFilter] = useState({key: "eq", column: "symbol", value: ""})
    const ws = useWebSocket()

    useEffect(() => {
        if (ws) {
            let data = JSON.parse(ws)
            if (data.key === "Monitoring") {
                setMonitoring(JSON.parse(data.value))
            }
        }
    }, [ws])

    function updateMonitoring(key, column, value) {
        if (key === "sort") {
            var order = monitoringOrder === "asc" ? "desc" : "asc";
            setMonitoringOrder(order)
            setMonitoringSort({
                key: key,
                column: column,
                value: order
            })
            IsLocalhost(() => axios.get(`${BASEURL}/monitoring?output=Json&market=${market["monitoring"]}&${key}.${column}=${order}&${monitoringFilter.key}.${monitoringFilter.column}=${monitoringFilter.value}`)
                    .then(res => setMonitoring(JSON.parse(res.data)))
                    .catch(_ => _),
                () => setMonitoring(monitoring_json))
        } else if (key === "eq") {
            setMonitoringFilter({key: key, column: column, value: value})
            IsLocalhost(() => axios.get(`${BASEURL}/monitoring?output=Json&market=${market["monitoring"]}&${monitoringSort.key}.${monitoringSort.column}=${monitoringSort.value}&${key}.${column}=${value}`)
                    .then(res => setMonitoring(JSON.parse(res.data)))
                    .catch(_ => _),
                () => setMonitoring(monitoring_json))
        }
    }

    function monitoringRow(item) {
        return <td
            className={`${item.toFixed(2) > 0 ? "text-green-500" : "text-red-500"} py-2 text-sm whitespace-nowrap`}>
            {item.toFixed(2)}%
        </td>;
    }

    return (
        <div className="flex h-1/3 overflow-y-auto flex-col">
            <div className={"flex flex-row justify-end items-center mb-1"}>
                <div className="bg-[#353535] rounded p-1">
                    <input
                        type="text"
                        maxLength="20"
                        className="bg-[#353535] text-white text-sm border-none outline-none focus:border-transparent w-30 p-1"
                        onChange={(e) => {
                            updateMonitoring("eq", "symbol", e.target.value)
                        }}
                        placeholder="Search"
                    />
                    <SearchIcon/>
                </div>
                <select value={market["monitoring"]} id="states"
                        className={"bg-[#353535] rounded outline-none p-2 ml-2"}
                        onChange={(market) => {
                            dispatch(setMarket({key: "monitoring", data: market.target.value}))
                            Cookies.setCookie("market-monitoring", market.target.value)
                        }}>
                    <option key={"spot"} value={"spot"}>{"Spot"}</option>
                    <option key={"futures"} value={"futures"}>{"Futures"}</option>
                </select>
            </div>
            <div
                className="grid grid-cols-9 text-sm font-bold text-white bg-[#343434] border-b border-[#4f4f4f] rounded-t">
                <div className="col-span-2 text-left tracking-wider pl-4 py-2"
                     onClick={() => updateMonitoring("sort", "symbol", monitoringOrder ? "asc" : "desc")}>Symbol
                </div>
                <div className="text-left tracking-wider py-2"
                     onClick={() => updateMonitoring("sort", "m1", monitoringOrder ? "asc" : "desc")}>M1
                </div>
                <div className="text-left tracking-wider py-2"
                     onClick={() => updateMonitoring("sort", "m3", monitoringOrder ? "asc" : "desc")}>M3
                </div>
                <div className="text-left tracking-wider py-2"
                     onClick={() => updateMonitoring("sort", "m5", monitoringOrder ? "asc" : "desc")}>M5
                </div>
                <div className="text-left tracking-wider py-2"
                     onClick={() => updateMonitoring("sort", "m15", monitoringOrder ? "asc" : "desc")}>M15
                </div>
                <div className="text-left tracking-wider py-2"
                     onClick={() => updateMonitoring("sort", "m30", monitoringOrder ? "asc" : "desc")}>M30
                </div>
                <div className="text-left tracking-wider py-2"
                     onClick={() => updateMonitoring("sort", "h1", monitoringOrder ? "asc" : "desc")}>H1
                </div>
                <div className="text-left tracking-wider py-2"
                     onClick={() => updateMonitoring("sort", "d1", monitoringOrder ? "asc" : "desc")}>D1
                </div>
            </div>
            <div className="overflow-y-auto bg-[#353535] border-[#474a4d] rounded-b">
                {monitoring.map((item, index) => {
                    return (
                        <div className="grid grid-cols-9  last:border-b-0 border-b border-[#4f4f4f] flex flex-row">
                            <div className={`col-span-2 py-2 pl-4 text-sm whitespace-nowrap`}>{item.symbol}</div>
                            {monitoringRow(item.m1)}
                            {monitoringRow(item.m3)}
                            {monitoringRow(item.m5)}
                            {monitoringRow(item.m15)}
                            {monitoringRow(item.m30)}
                            {monitoringRow(item.h1)}
                            {monitoringRow(item.d1)}
                            {/*{monitoringRow(item.w1)}*/}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}