import React, {useState} from "react"
import styles from "../../../../style"
import {generateUniqueId} from "./StrategyCommons"
import DatePicker from "react-datepicker";

function Time({configTime}) {
    const shortcut = Object.values(configTime.shortcut).filter(a => a.type?.includes("TimeType"))
    const [inputValues, setInputValues] = useState({
        value: (configTime.editModal.isEdit && (configTime.editModal.openTab === 4) && shortcut.filter(a => a.shortName === configTime.inputValues.value[0]).length > 0) ? configTime.inputValues.value : ["dt", 1704063600000],
        parameter: (configTime.editModal.isEdit && (configTime.editModal.openTab === 4) && shortcut.filter(a => a.shortName === configTime.inputValues.value[0]).length > 0) ? configTime.inputValues.parameter : Object.entries(configTime.condition).filter(([key]) => key !== 'cfa' && key !== 'cfb')[0][0],
        sign: '',
    })
    const handleSave = () => {
        if (inputValues.parameter && !configTime.editModal.isEdit) {
            let keyName = configTime.keyName
            if( configTime.keyName === "close" && ((configTime.closeType.buy === false) || ((configTime.closeType.sell === false)))){
                keyName = configTime.closeType.buy === false ? "closeSell" : "closeBuy"
            }else {
                keyName = configTime.keyName
            }
            const newItem = {
                id: generateUniqueId(),
                value: inputValues.value,
                parameter: inputValues.parameter
            }
            configTime.setStrategy((prevData) => ({
                ...prevData,
                [keyName]: [...prevData[keyName], newItem],
            }))
            configTime.setCloseType({sell: true, buy: true})
            configTime.setShowModal(false)
        } else if (inputValues.parameter && configTime.editModal.isEdit) {
            const updatedItems = {...configTime.strategy}
            updatedItems[configTime.keyName][configTime.editModal.index] = {
                id: updatedItems[configTime.keyName][configTime.editModal.index].id,
                value: inputValues.value,
                parameter: inputValues.parameter
            }
            configTime.setStrategy(updatedItems)
            configTime.setEditModal({
                isEdit: false,
                index: -1,
            })
            configTime.setCloseType({sell: true, buy: true})
            configTime.setShowModal(false)
        }
    }

    function timeValueField() {
        if (inputValues.value[0] === "dt") {
            return (
                <DatePicker
                    selected={inputValues.value[1]}
                    onChange={(date) => {
                        setInputValues({
                            ...inputValues,
                            value: [...inputValues.value.slice(0, 1), new Date(date).getTime()]
                        })
                    }}
                    dateFormat="yyyy-MM-dd"
                    placeholderText="yyyy-MM-dd"
                    className="bg-[#1d1d1d] text-lg rounded border-0 pr-2 pt-1 pb-0 w-full text-right"
                />
            )
        } else if (inputValues.value[0] === "dtm") {
            return (
                <DatePicker
                    selected={inputValues.value[1]}
                    onChange={(date) => setInputValues({
                        ...inputValues,
                        value: [...inputValues.value.slice(0, 1), new Date(date).getTime()]
                    })}
                    dateFormat="MM/dd/yyyy hh:mm aa"
                    placeholderText="MM/dd/yyyy hh:mm aa"
                    showTimeInput
                    className="bg-[#1d1d1d] text-lg border-0 pr-2 rounded pt-1 pb-0 w-full text-right"
                />
            )
        } else if (inputValues.value[0] === "d") {
            return (
                <select value={inputValues.value[1]}
                        onChange={(e) => {
                            setInputValues({
                                ...inputValues,
                                value: [...inputValues.value.slice(0, 1), e.target.value]
                            })
                        }}
                        className="bg-[#1d1d1d] text-white rounded text-right text-lg w-full outline-none p-1">
                    <option value="1">Monday</option>
                    <option value="2">Tuesday</option>
                    <option value="3">Wednesday</option>
                    <option value="4">Thursday</option>
                    <option value="5">Friday</option>
                    <option value="6">Saturday</option>
                    <option value="7">Sunday</option>
                </select>
            )
        } else if (inputValues.value[0] === "hr") {
            return (
                <select value={inputValues.value[1]}
                        onChange={(e) => {
                            setInputValues({
                                ...inputValues,
                                value: [...inputValues.value.slice(0, 1), e.target.value]
                            })
                        }}
                        className="bg-[#1d1d1d] text-white rounded text-lg text-right w-full outline-none p-1">
                    {Array.from({length: 24}, (_, index) => (
                        <option key={index} value={index}>{index}</option>
                    ))}
                </select>
            )
        }
    }

    return (
        <div>
            <div className={`${styles.rowBetween} mb-10`}>
                <p className="text-xl">Add Time Condition</p>
                <button onClick={handleSave}
                        className="bg-[#10a7d0] h-[30px] text-white flex font-bold items-center rounded">Save
                </button>
            </div>
            <div className="grid grid-cols-3 gap-4">
                <div className="flex justify-between flex-col col-span-1">
                    <div className="flex flex-row gap-1 bg-[#1d1d1d] rounded items-center pr-1 h-[32px]">
                        <select
                            value={inputValues.value[0]}
                            onChange={(e) => setInputValues({
                                ...inputValues,
                                value: [e.target.value, ((e.target.value === "dt") || e.target.value === "dtm") ? 1704063600000 : e.target.value === "d" ? "2" : '12']
                            })}
                            className="bg-[#1d1d1d] rounded text-white text-lg w-full text-right outline-none p-1"
                        >
                            {Object.entries(shortcut).map(([key, value]) => (
                                <option key={value.shortName}
                                        value={value.shortName}>{value.name === "Candle Close" ? (value.name + " (Current price)") : value.name}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="flex items-end col-span-1">
                    <select value={inputValues.parameter}
                            onChange={(e) => setInputValues({
                                ...inputValues,
                                parameter: e.target.value
                            })}
                            className="bg-[#1d1d1d] text-white text-lg rounded text-right w-full outline-none p-1">
                        {Object.entries(configTime.condition).filter(([key]) => key !== 'cfa' && key !== 'cfb').map(([key, value]) => (
                            <option key={key} value={key}>{value.name} ({value.mathSymbol})</option>
                        ))}
                    </select>
                </div>
                <div className="flex justify-between flex-col col-span-1">
                    {timeValueField()}
                </div>
            </div>
        </div>
    )
}

export default Time