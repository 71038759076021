import React from "react"
import Template from "../../components/Template"
import Translations from "../../../components/Translations"
import img1 from "../../images/stops/falling.jpg"
import style from "../../../../style"

function StopLoss() {
    const Content = () => {
        return (
            <div>
                <img src={img1} alt='/' className={`${style.borderImg} mt-5 mb-10 w-[20%]`}></img>
                <p className={`text-lg mt-5`}><Translations category={"docsStopLoss"} id={1}/></p>
                <p className={`text-lg mt-5`}>
                    <Translations category={"docsStopLoss"} id={2}/>
                    <li><Translations category={"docsStopLoss"} id={3}/></li>
                    <li><Translations category={"docsStopLoss"} id={4}/></li>
                </p>
                <p className={`mt-10`}><Translations category={"docsStopLoss"} id={5}/></p>
                <p className={`text-lg mt-5 text-yellow-500`}><Translations category={"docsStopLoss"} id={6}/></p>
                <p className={`text-lg mt-5 text-yellow-500`}><Translations category={"docsStopLoss"} id={7}/></p>
            </div>
        )
    }
    const config = {
        title: "Stop loss",
        previousLink: '/docs/moving-average-convergence-divergence',
        previousTitle: <Translations category={"docs"} id={29}/>,
        nextLink: '/docs/percentage-take-profit',
        nextTitle: 'Take profit',
        content: Content(),
    }

    return (
        <Template config={config}/>
    )
}

export default StopLoss