import {createTheme, ThemeProvider, useTheme} from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import * as React from "react";
import {useMemo} from "react";

const Table = ({data, columns, rows, click, clickedRow, color}) => {
    const globalTheme = useTheme();
    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: "dark",
                    primary: globalTheme.palette.secondary,
                },
                typography: {
                    button: {
                        textTransform: 'none',
                        fontSize: '1rem',
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: '1.1rem',
                            },
                        },
                    },
                },
            }),
        [globalTheme],
    );
    return (
        <ThemeProvider theme={tableTheme}>
            <MaterialReactTable columns={columns} data={data}
                                enableRowActions={false}
                                muiTablePaginationProps={{
                                    rowsPerPageOptions: [rows],
                                }}
                                muiTableBodyRowProps={(row) => ({
                                    onClick: () => click(row.row.original.id),
                                    sx: {
                                        backgroundColor: (color && row.row.original.id === clickedRow) ? "#464545" : "#353535",
                                        '&:hover': {
                                            backgroundColor: (color && row.row.original.id === clickedRow) ? "#464545" : '#2d2c2c',
                                        },
                                    },
                                    hover: false
                                })}
                                enableColumnActions={false}
                                enableSorting={true}
                                enableTopToolbar={false}
                                muiTablePaperProps={{
                                    elevation: 0,
                                    sx: {
                                        backgroundColor: "#353535",
                                        borderRadius: '10px',
                                        padding: '5px',
                                    },
                                }}
                                initialState={{
                                    density: 'compact',
                                    pagination: {
                                        pageSize: rows
                                    },
                                }}
                                muiTableHeadProps={{
                                    sx: (theme) => ({
                                        '& tr': {
                                            backgroundColor: "#353535",
                                        },
                                    }),
                                }}
                                muiBottomToolbarProps={{
                                    sx: (theme) => ({
                                        backgroundColor: "#353535",
                                        minHeight: '2rem',
                                    }),
                                }}
            />
        </ThemeProvider>
    );
};
export default Table;