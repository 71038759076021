const RepairTimesToBeUnique = (array) => {
    const counter = {}

    return array.map((element) => {
        const {time} = element

        if (counter[time] === undefined) {
            counter[time] = 0
        } else {
            counter[time]++
        }

        const repairedTimestamp = time + counter[time]
        return {...element, time: repairedTimestamp}
    })
}

export default RepairTimesToBeUnique