import React, {useEffect, useState} from 'react'

import styles from "../../../style"
import HoverHint from "../../components/HoverHint"
import axios from "axios";
import {SERVERURL} from "../../../globals/Constants";
import Cookies from "../../../globals/cookies/Cookies";
import {IsLocalhost} from "../../../globals/auth/IsLocalhost";
import referrals_json from "../../mocks/referrals.json"

export const data = [
    {
        date: '10.02.2023',
        plan: 'Premium',
        amount: '50$',
        card: 'Visa **** **** **** **56',
    },
    {
        date: '10.03.2023',
        plan: 'Premium',
        amount: '50$',
        card: 'Visa **** **** **** **56',
    },
    {
        date: '13.11.2023',
        plan: 'Beginner',
        amount: '30$',
        card: 'Visa **** **** **** **56',
    },
]

export default function Subscription() {
    const [referrals, setReferrals] = useState({
        refLink: Cookies.getCookie("refLink"),
        invitedUsers: Cookies.getCookie("invitedUsers"),
        earnedMoney: Cookies.getCookie("earnedMoney")
    });
    useEffect(() => {
        IsLocalhost(() => axios.post(`${SERVERURL}/referrals`, {}, {withCredentials: true})
                .then((response) => {
                    Cookies.setCookie('refLink', response.data.refLink)
                    Cookies.setCookie('invitedUsers', response.data.invitedUsers)
                    Cookies.setCookie('earnedMoney', response.data.earnedMoney)
                    setReferrals(response.data)
                })
                .catch(_ => _),
            () => setReferrals(referrals_json))
    }, []);

    return (
        <div className="flex flex-col gap-2 mb-2">
            <div className={styles.rowBetween}>
                <p>Subscription</p>
            </div>
            <div className=" flex flex-col col-span-3 gap-4">
                <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 gap-4">
                    <div className={`${styles.roundedBox} ${styles.colBetween} col-span-1`}>
                        <span className="text-2xl">Your Plan</span>
                        <p className="text-3xl text-[#10a7d0] font-bold mt-10 mb-5 flex justify-center">Beginner for
                            $15.99/month</p>
                        <div className="col-span-1 gap-1">
                            <div
                                className={`${styles.flexCenter} py-2 border-y border-[#10a7d0] flex-col`}>
                                <span className="mr-5 font-bold text-sm">Your next bill 13.12.2023</span>
                                <span className="mr-1 text-lg">Visa  **** **** **** **56 </span>
                            </div>
                            <div className="flex flex-row gap-4 justify-center mt-5">
                                <button className={styles.blueButton}>Cancel</button>
                                <button className={styles.blueButton}>Change</button>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.roundedBox} ${styles.colBetween} col-span-1 2xl:col-span-2`}>
                        <div className="flex justify-items-start items-center gap-3">
                            <HoverHint tooltipText={"We only show up to 1 year of billing history"}/>
                            <span className="text-2xl">Billing history</span>
                        </div>
                        <table className="min-w-full">
                            <thead>
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Date</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Plan</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Amount</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-white uppercase tracking-wider">Card</th>
                            </tr>
                            </thead>
                            <tbody>
                            {data.map((item, index) => (
                                <tr
                                    key={index}
                                    className="border-t-2 border-t-gray-700"
                                >
                                    <td className="px-6 py-1 whitespace-nowrap">{item.date}</td>
                                    <td className="px-6 py-1 whitespace-nowrap">{item.plan}</td>
                                    <td className="px-6 py-1 whitespace-nowrap">{item.amount}</td>
                                    <td className="px-6 py-1 whitespace-nowrap">{item.card}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    <div className={`${styles.roundedBox} col-span-1`}>
                        <span className="text-2xl">Referral panel</span>
                        <p className="text-3xl text-[#10a7d0] font-bold mt-4 flex justify-center">Reflink</p>
                        <div className={`${styles.flexCenter} py-2 mb-4 flex-col`}>
                            <span
                                className="font-bold text-sm">{`https://cryptodeputy.com?ref=${referrals.refLink}`}</span>
                        </div>
                        <div className="col-span-1 gap-1">
                            <div
                                className={`${styles.flexCenter} py-4 border-y border-[#10a7d0] grid grid-cols-2`}>
                                <span
                                    className="col-span-1 flex justify-center font-bold text-sm">{`Invited users: ${referrals.invitedUsers}`}</span>
                                <span
                                    className="col-span-1 flex justify-center font-bold text-sm">{`Earned money: ${referrals.earnedMoney}$`}</span>
                            </div>
                            <div className={`${styles.flexCenter} mt-5`}>
                                <button className={styles.blueButton}>Withdraw</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}