import React, {useEffect, useState} from "react"
import axios from "axios"
import {BASEURL} from "../../../../globals/Constants"
import Indicators from "../components/Indicators"
import {useLocation, useNavigate} from "react-router-dom"
import styles from "../../../../style"
import {useDispatch, useSelector} from "react-redux"
import {selectStrategyParams, setMessage, setStrategies} from "../../../components/redux/GlobalState"
import StrategyCondition from "../components/StrategyCondition"
import {isLocalhost} from "../../../../globals/auth/IsLocalhost";

const NewStrategy = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [strategy, setStrategy] = useState(
        {
            indicators: [],
            openBuy: [],
            openSell: [],
            close: [],
            closeBuy: [],
            closeSell: [],
            name: '',
            description: '',
        }
    )
    const dispatch = useDispatch()
    const strategyData = useSelector(selectStrategyParams)
    const [shortcutsAndIndicatorParams, setShortcutsAndIndicatorParams] = useState([])
    const openShortcuts = shortcutsAndIndicatorParams.length > 0 && shortcutsAndIndicatorParams.filter(item => !item.name.includes("Open Trade"))
    useEffect(() => {
        setShortcutsAndIndicatorParams(strategyData.shortcuts)
    }, [strategyData.shortcuts])

    function valueInSeconds(value) {
        const number = parseInt(value.substring(0, value.length - 1))
        const unit = value.substring(value.length - 1)
        let seconds
        if (unit === "d") {
            seconds = number * 24 * 60 * 60
        } else if (unit === "h") {
            seconds = number * 60 * 60
        } else if (unit === "m") {
            seconds = number * 60
        }else if (unit === "s") {
            seconds = number
        }
        return `${seconds}`
    }

    function closeValue(param) {
        if(param.parameter === "ttsl"){
            return (param.parameter + "(" + param.value[0] + "," + valueInSeconds(param.value[1]) + ")")
        } else if(param.parameter === "tmsl") {
            return (param.parameter + "(" + valueInSeconds(param.value[0]) + ")")
        } else {
            return param.parameter + "(" + param.value + ")"
        }
    }

    function submit() {
        if (!isLocalhost()) {
            dispatch(setMessage({key: 'Alert', value: 'You cannot create strategy in demo version'}))
        } else {
            const indicators = strategy.indicators.map(a => (a.indicator + "(" + a.value.toString() + ")")).join('^')
            const openBuy = strategy.openBuy.map(a => (a.parameter ? a.parameter + "(" + a.value + ")" : a.sign)).join('').replaceAll("AND", "^").replaceAll("OR", "|")
            const openSell = strategy.openSell.map(a => (a.parameter ? a.parameter + "(" + a.value + ")" : a.sign)).join('').replaceAll("AND", "^").replaceAll("OR", "|")
            const close = strategy.close.map(a => a.sign ? a.sign : closeValue(a)).join('').replaceAll("AND", "^").replaceAll("OR", "|")
            const closeBuy = strategy.closeBuy.map(a => a.sign ? a.sign : closeValue(a)).join('').replaceAll("AND", "^").replaceAll("OR", "|")
            const closeSell = strategy.closeSell.map(a => a.sign ? a.sign : closeValue(a)).join('').replaceAll("AND", "^").replaceAll("OR", "|")
            let params = []
            let closeCondition = []
            if (indicators !== '') {
                params.push(`indicators=${indicators}`)
            }
            if (openBuy !== '') {
                params.push(`openBuy=${openBuy}`)
            }
            if (openSell !== '') {
                params.push(`openSell=${openSell}`)
            }

            if (((close !== '') && (closeBuy === '') && (closeSell === ''))) {
                params.push(`close=${close}`)
            } else if (((close === '') && (closeBuy !== '') && (closeSell === ''))) {
                params.push(`close=${closeBuy}`)
            } else if (((close === '') && (closeBuy === '') && (closeSell !== ''))) {
                params.push(`close=${closeSell}`)
            } else {
                if (close !== '') {
                    closeCondition.push(`(${close})`)
                }
                if (closeBuy !== '') {
                    closeCondition.push(`(ltb()^(${closeBuy}))`)
                }
                if (closeSell !== '') {
                    closeCondition.push(`(lts()^(${closeSell}))`)
                }
                if (closeCondition.length > 0) {
                    params.push(`close=${closeCondition.join("|")}`)
                }
            }

            axios.post(`${BASEURL}/user_strategies`, {
                strategy: params.join('&'),
                name: strategy.name,
                description: strategy.description,
                isFavorite: false
            }).then(() => {
                if (location.state && location.state.refreshData) {
                    location.state.refreshData()
                }
                axios.get(`${BASEURL}/user_strategies`).then(res => dispatch(setStrategies(res.data))).catch(_ => _)
                navigate('/app/strategies')
            }).catch(error => {
                dispatch(setMessage({key: 'Alert', value: error.response.data}))
            })
        }
    }

    return (
        <div className="flex flex-col w-full gap-2">
            <div className={styles.rowBetween}>
                <p className="text-4xl">New Strategy</p>
                <button className={styles.blueButton} onClick={() => submit()}>Save</button>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-4 lg:grid-cols-7 2xl:grid-cols-9 gap-4">
                <div className={`${styles.roundedBox} col-span-1`}>
                    <p className="text-xl mb-1">Name *</p>
                    <input type="text" maxLength={100}
                           className={`${styles.roundedBox} border border-gray-300 text-gray-300 text-sm w-full outline-none`}
                           placeholder="Name of your stategy" required
                           onChange={(e) => setStrategy({...strategy, name: e.target.value})}/>
                    <p className="text-xl mb-1 mt-5">Description</p>
                    <textarea rows="15" maxLength={500}
                              spellCheck="false"
                              className={`${styles.roundedBox} w-full text-sm text-gray-300 border border-gray-300 outline-none`}
                              placeholder="Description of your stategy"
                              onChange={(e) => setStrategy({...strategy, description: e.target.value})}></textarea>
                </div>
                <div className="col-span-1 sm:col-span-3 lg:col-span-6 2xl:col-span-8">
                    <Indicators strategy={strategy} setStrategy={setStrategy} strategyData={strategyData}
                                setShortcutsAndIndicatorParams={setShortcutsAndIndicatorParams}/>
                    <StrategyCondition isOpen={true} title={"Long"} shortcut={openShortcuts} keyName={"openBuy"}
                                       condition={strategyData.conditions} strategy={strategy} setStrategy={setStrategy}
                                       stops={strategyData.stops}/>
                    <StrategyCondition isOpen={true} title={"Short"} shortcut={openShortcuts} keyName={"openSell"}
                                       condition={strategyData.conditions} strategy={strategy} setStrategy={setStrategy}
                                       stops={strategyData.stops}/>
                    <StrategyCondition isOpen={false} title={"Close"} stops={strategyData.stops}
                                       condition={strategyData.conditions}
                                       shortcut={shortcutsAndIndicatorParams} keyName={"close"} strategy={strategy}
                                       setStrategy={setStrategy}/>
                    {strategy.closeBuy.length > 0 && <StrategyCondition isOpen={false} title={"Close Buy"} stops={strategyData.stops}
                                                                        condition={strategyData.conditions}
                                                                        shortcut={shortcutsAndIndicatorParams} keyName={"closeBuy"} strategy={strategy}
                                                                        setStrategy={setStrategy}/>}
                    {strategy.closeSell.length > 0 && <StrategyCondition isOpen={false} title={"Close Sell"} stops={strategyData.stops}
                                                                         condition={strategyData.conditions}
                                                                         shortcut={shortcutsAndIndicatorParams} keyName={"closeSell"} strategy={strategy}
                                                                         setStrategy={setStrategy}/>}
                </div>
            </div>
        </div>
    )
}


export default NewStrategy