import React from "react"
import Template from "../../components/Template"
import img1 from "../../images/backtests/backtest-basic.png"
import img2 from "../../images/backtests/backtest-advanced.png"
import img3 from "../../images/backtests/backtest-table.png"
import img4 from "../../images/backtests/statistics.png"
import img5 from "../../images/backtests/trades.png"
import img6 from "../../images/backtests/backtest-data.png"
import img7 from "../../images/backtests/strategy.png"
import backtestGif from '../../../gif/backtest.gif'
import Translations from "../../../components/Translations"
import styles from "../../../../style"

function Dashboard() {
    const Content = () => {
        return (
            <div>
                <Translations category={"docsBacktests"} id={1}/>
                <div className={`grid grid-cols-1 lg:grid-cols-4 gap-5 mb-5`}>
                    <div className={`col-span-1 mt-5 mb-5`}>
                        <img src={img1} alt='/' className={`${styles.borderImg}`}/>
                    </div>
                    <div className={`col-span-3 mt-5 mb-5`}>
                        <Translations category={"docsBacktests"} id={2}/>
                        <li><Translations category={"docsBacktests"} id={3}/></li>
                        <li><Translations category={"docsBacktests"} id={4}/></li>
                        <li><Translations category={"docsBacktests"} id={5}/></li>
                        <li><Translations category={"docsBacktests"} id={6}/></li>
                        <li><Translations category={"docsBacktests"} id={7}/></li>
                        <li><Translations category={"docsBacktests"} id={8}/></li>
                        <li><Translations category={"docsBacktests"} id={9}/></li>
                        <li><Translations category={"docsBacktests"} id={10}/></li>
                    </div>
                    <div className={`col-span-1`}>
                        <img src={img2} alt='/' className={`${styles.borderImg} my-5`}/>
                    </div>
                    <div className={`col-span-3  mt-5 mb-5`}>
                        <Translations category={"docsBacktests"} id={11}/>
                        <li><Translations category={"docsBacktests"} id={12}/></li>
                        <li><Translations category={"docsBacktests"} id={13}/></li>
                        <li><Translations category={"docsBacktests"} id={14}/></li>
                        <li><Translations category={"docsBacktests"} id={15}/></li>
                    </div>
                </div>
                <Translations category={"docsBacktests"} id={16}/>
                <img src={img7} alt='/' className={`${styles.borderImg} my-5`}/>
                <Translations category={"docsBacktests"} id={17}/>
                <img src={img6} alt='/' className={`${styles.borderImg} my-5`}/>
                <Translations category={"docsBacktests"} id={18}/>
                <img src={backtestGif} alt='/' className={`${styles.borderImg} my-5 w-[80%]`}/>
                <Translations category={"docsBacktests"} id={19}/>
                <img src={img3} alt='/' className={`${styles.borderImg} my-5`}/>
                <Translations category={"docsBacktests"} id={20}/>
                <img src={img4} alt='/' className={`${styles.borderImg} my-5`}/>
                <Translations category={"docsBacktests"} id={21}/>
                <img src={img5} alt='/' className={`${styles.borderImg} my-5`}/>
            </div>
        )
    }
    const config = {
        title: <Translations category={"docs"} id={11}/>,
        previousLink: '/docs/strategy-creator',
        previousTitle: <Translations category={"docs"} id={10}/>,
        nextLink: '/docs/paper-trading',
        nextTitle: <Translations category={"docs"} id={12}/>,
        content: Content(),
    }

    return (
        <Template config={config}/>
    )
}

export default Dashboard