import React from "react"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import ParticlesContainer from "../components/ParticlesContainer"
import {title} from "./content"
import styles from "../../style"

function Terms() {


    return (
        <div className={`${styles.backgroundMain}`}>
            <Navbar/>
            <div className='w-full text-white'>
                <div className="relative overflow-hidden h-1/5">
                    <ParticlesContainer height={"30vh"}/>
                    <div
                        className={`${styles.flexCenter} w-full mx-auto text-center max-w-[1240px] grid md:grid-cols-3 m-auto`}>
                        <div
                            className='absolute inset-0 flex flex-col items-center justify-center  left-0 right-0 mx-auto  lg:w-2/3 lg:top-0 lg:left-0'>
                            <h1 className='md:text-5xl my-4 sm:text-3xl textgg-2xl font-bold text-[#f3f2ee] md:my-6 bg-[#1d1d1d] bg-opacity-70'>
                                {title}
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.maxWidth}>
                <div className="flex flex-col items-center justify-center">
                    {/*{terms.map(a => (*/}
                    {/*    <div className="mb-5">*/}
                    {/*        <h2 className={`text-2xl mb-2 text-[${color.accent}] font-semibold`}>{a.title}</h2>*/}
                    {/*        <p className="text-sm">{a.description}</p>*/}
                    {/*    </div>*/}
                    {/*))}*/}
                </div>
            </div>

            <Footer/>
        </div>

    )
}

export default Terms