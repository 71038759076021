import React, {useEffect} from 'react';
import StrategyPanel from "../../components/tradingPanel/StrategyPanel";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveBots, selectMarket, setTradingView} from "../../components/redux/GlobalState";
import {add} from "../../components/trading/TradingCommons";
import axios from "axios";
import {BASEURL} from "../../../globals/Constants";
import Header from "../../components/tradingPanel/Header";
import MainTable from "../../components/tradingPanel/MainTable";
import TradingResult from "../../components/tradingPanel/TradingResult";
import Balance from "../../components/tradingPanel/Balance";
import Trades from "../../components/tradingPanel/Trades";
import {isLocalhost} from "../../../globals/auth/IsLocalhost";
import EventEmitter from "../../components/EventEmitter";

const AutoTrading = () => {
    const viewName = window.location.pathname.split("/")[2].replace("-", "")
    const market = useSelector(selectMarket)
    const source = "AutomateTrading";
    const dispatch = useDispatch();
    const activeBots = useSelector(selectActiveBots)
    const configStrategy = {
        visibility: {
            date: false,
            account: false,
        },
    }

    useEffect(() => {
        if (isLocalhost()) {
            if (activeBots.filter(bot => bot.source === source).length > 0) {
                const id = activeBots.filter(bot => bot.source === source).sort((a, b) => b.time - a.time)[0].strategyId
                dispatch(setTradingView({
                    symbol: activeBots.filter(a => a.strategyId === id)[0].symbol,
                    interval: activeBots.filter(a => a.strategyId === id)[0].intervl
                }))
            }
            axios.get(`${BASEURL}/fullStats?market=${market[viewName]}&source=${source}`).catch(_ => _)
        }
    }, [activeBots, market])

    function showSpecificResultsById(id = null) {
        if (isLocalhost()) {
            if (id) {
                const strategyId = activeBots.filter(a => a.id === id)[0].strategyId
                axios.get(`${BASEURL}/fullStats?id=${strategyId}&market=${market[viewName]}&source=${source}`).catch(_ => _)
            } else {
                axios.get(`${BASEURL}/fullStats?market=${market[viewName]}&source=${source}`).catch(_ => _)
            }
            const row = activeBots.filter(a => a.id === id)[0]
            dispatch(setTradingView({
                symbol: row.symbol + (market[viewName] === "futures" ? '.P' : ''),
                interval: row.intervl
            }))
        } else {
            EventEmitter.emit('demoFullStats', id)
        }
    }

    return (
        <div className="flex flex-col w-full gap-2">
            <Header title={"Automate Trading"} source={source}/>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-12 2xl:grid-cols-12 gap-4">
                <StrategyPanel config={configStrategy} title={'Add trading bots'} source={source}
                               mainButtonLabel={"Add"} mainButtonFunction={add} market={market[viewName]}/>
                <MainTable source={source}
                           mainTableData={activeBots.filter(bot => bot.source === source).sort((a, b) => b.time - a.time)}
                           showSpecificResultsById={showSpecificResultsById} market={market[viewName]}/>
                <Balance source={source} market={market[viewName]}/>
                <TradingResult source={source}/>
            </div>
            <div className="grid grid-cols-1">
                <Trades source={source} market={market[viewName]}/>
            </div>
        </div>
    )
}
export default AutoTrading;