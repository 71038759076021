import React from "react"
import Template from "../../components/Template"
import img1 from "../../images/strategy/strategies.png"
import img2 from "../../images/strategy/clickedStrategy.png"
import img3 from "../../images/strategy/clickedStrategyRow.png"
import img4 from "../../images/strategy/filters.png"
import Translations from "../../../components/Translations"
import style from "../../../../style"

function Strategies() {
    const Content = () => {
        return (
            <div className={`flex flex-col gap-5`}>
                <p className={`text-lg`}><Translations category={"docsStrategies"} id={1}/></p>
                <img src={img1} alt='/' className={`${style.borderImg}`}/>
                <p className={`text-lg`}><Translations category={"docsStrategies"} id={2}/></p>
                <img src={img2} alt='/' className={`${style.borderImg}`}/>
                <p className={`text-lg`}><Translations category={"docsStrategies"} id={3}/></p>
                <img src={img3} alt='/' className={`${style.borderImg}`}/>
                <p className={`text-lg`}><Translations category={"docsStrategies"} id={4}/></p>
                <img src={img4} alt='/' className={`${style.borderImg}`}/>
            </div>
        )
    }
    const config = {
        title: <Translations category={"docs"} id={9}/>,
        previousLink: '/docs/dashboard',
        previousTitle: <Translations category={"docs"} id={8}/>,
        nextLink: '/docs/strategy-creator',
        nextTitle: <Translations category={"docs"} id={10}/>,
        content: Content(),
    }

    return (
        <Template config={config}/>
    )
}

export default Strategies