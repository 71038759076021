import React from "react";
import StrategyPopup from "./StrategyPopup";

const ComboBox = ({title, values, strategies, def, onChangeCallback}) => {
    return (
        <>
            <p className="col-span-1 text-base">{title}</p>
            <div className="col-span-5 flex flex-row rounded items-center gap-1 bg-[#1d1d1d] pl-1">
                {title === "Strategy" && values.length > 0 && strategies.length > 0 ?
                    <StrategyPopup strategyName={def} strategies={strategies}/> : <></>}
                <select value={def} id="states"
                        className="bg-[#1d1d1d] text-right text-white text-base rounded outline-none w-full p-1"
                        onChange={onChangeCallback} disabled={values.length === 0}>
                    {values.map(value => <option key={value} value={value}>{value}</option>)}
                </select>
            </div>
        </>);
}

export default ComboBox;
