import React from "react"
import Template from "../../components/Template"
import Translations from "../../../components/Translations"
import img1 from "../../images/indicators/indicators.jpg"

function RSI() {
    const Content = () => {
        return (
            <div className={`flex flex-col gap-5`}>
                <img src={img1} alt='/' className={`border border-gray-700 mt-5 mb-5 w-[20%]`}></img>
                <p className={"text-lg"}><Translations category={"docsRSI"} id={1}/></p>
                <p className={"text-lg"}><Translations category={"docsRSI"} id={2}/></p>
                <p className={"text-lg"}><Translations category={"docsRSI"} id={3}/></p>
            </div>
        )
    }
    const config = {
        title: <Translations category={"docs"} id={21}/>,
        previousLink: '/docs/sound-alerts',
        previousTitle: <Translations category={"docs"} id={19}/>,
        nextLink: '/docs/average-candle-length',
        nextTitle: <Translations category={"docs"} id={22}/>,
        content: Content(),
    }

    return (
        <Template config={config}/>
    )
}

export default RSI