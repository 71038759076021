import React, {useEffect} from 'react'
import './App.css'
import Dashboard from './application/views/dashboard'
import {Navigate, Route, Routes} from 'react-router-dom'
import Settings from './application/views/settings'
import Layout from './application/layout'
import Pricing from './website/pricing'
import LandingPage from './website/landingPage'
import 'react-datepicker/dist/react-datepicker.css';
import AutomatedTrading from './website/automatedTrading'
import PaperTrading from './website/paperTrading'
import Indicators from './website/indicators'
import TrailingStop from './website/trailingStop'
import BacktestsWebsite from './website/backtests'
import Strategies from './application/views/strategies'
import NewStrategy from './application/views/strategies/createStrategy'
import Papertrading from './application/views/papertrading'
import Automatetrading from './application/views/automatedtrading'
import Backtests from './application/views/backtests/index'
import EditStrategy from './application/views/strategies/editStrategy'
import {useDispatch, useSelector} from 'react-redux'
import About from './website/about'
import shortcut from './application/mocks/shortcut.json';
import stops from './application/mocks/stops.json';
import conditions from './application/mocks/conditions.json';
import indicators from './application/mocks/indicators.json';
import exchangeInfo from './application/mocks/exchangeInfo.json';
import user_strategies from './application/mocks/user_strategies.json';
import user_last_strategies from './application/mocks/user_last_strategies.json';
import long_alerts from './application/mocks/long_alerts.json';
import active_bots from './application/mocks/active_bots.json';
import {
    selectLanguage,
    setActiveBots,
    setBackTestSymbolsFutures,
    setBackTestSymbolsSpot,
    setBinanceAssets,
    setLanguage,
    setLastConfigurations,
    setLongAlerts,
    setPlan,
    setStrategies,
    setStrategyParams
} from './application/components/redux/GlobalState'
import axios from 'axios'
import {BASEURL} from './globals/Constants'
import Contact from './website/contact'
import Terms from './website/terms'
import {useAuth} from './globals/auth/AuthContext'
import SnackbarPopup from "./application/components/SnackbarPopup"
import Cookies from "./globals/cookies/Cookies"
import {isLocalhost, IsLocalhost} from "./globals/auth/IsLocalhost"
import SigninWebsite from "./website/signin/SigninWebsite"
import Signin from "./website/signin/Signin"
import PageNotFound from "./website/pageNotFound"
import Welcome from "./application/views/welcome"
import Subscription from "./application/views/subscription"
import Statistics from "./website/statistics"
import StrategyCreator from "./website/strategyCreator"
import Documentation from "./website/documentation"
import HowToConnect from "./website/documentation/content/start/HowToConnect"
import SystemRequirements from "./website/documentation/content/start/SystemRequirements"
import Installation from "./website/documentation/content/start/Installation"
import FirstRun from "./website/documentation/content/start/FirstRun"
import DashboardDocs from "./website/documentation/content/application/Dashboard"
import StrategiesDocs from "./website/documentation/content/application/Strategies"
import StrategyCreatorDocs from "./website/documentation/content/application/StrategyCreator"
import BacktestsDocs from "./website/documentation/content/application/Backtest"
import PaperTradingDocs from "./website/documentation/content/application/PaperTrading"
import AutomateTradingDocs from "./website/documentation/content/application/AutomateTrading"
import SettingsDocs from "./website/documentation/content/application/Settings"
import ChartDocs from "./website/documentation/content/application/Chart"
import SecurityDocs from "./website/documentation/content/start/Security"
import MonitoringDocs from "./website/documentation/content/application/Monitoring"
import NotesDocs from "./website/documentation/content/application/Notes"
import TextAlertsDocs from "./website/documentation/content/application/TextAlerts"
import SoundAlertsDocs from "./website/documentation/content/application/SoundAlerts"
import DefaultDocumentationContent from "./website/documentation/components/DefaultDocumentationContent"
import MACD from "./website/documentation/content/indicators/MACD";
import EMA from "./website/documentation/content/indicators/EMA";
import HMM from "./website/documentation/content/indicators/HMM";
import EMACross from "./website/documentation/content/indicators/EMACross";
import RSI from "./website/documentation/content/indicators/RSI";
import PercantageDiff from "./website/documentation/content/indicators/PercantageDiff";
import ACL from "./website/documentation/content/indicators/ACL";
import AV from "./website/documentation/content/indicators/AV";
import StopLoss from "./website/documentation/content/stops/StopLoss";
import TakeProfit from "./website/documentation/content/stops/TakeProfit";
import TrailingStopLoss from "./website/documentation/content/stops/TrailingStopLoss";
import TimeTrailingStopLoss from "./website/documentation/content/stops/TimeTrailingStopLoss";
import TimeStopLoss from "./website/documentation/content/stops/TimeStopLoss";
import PV from "./website/documentation/content/indicators/PV";

function App() {
    const dispatch = useDispatch()
    const auth = useAuth()
    const localhost = isLocalhost()
    const lang = useSelector(selectLanguage)

    useEffect(() => {
        checkReferral()
        setCookieLang(dispatch)
        checkPlan(dispatch)
        getBinanceAssets(dispatch)
        getBackTestSymbolsFutures(dispatch)
        getBackTestSymbolsSpot(dispatch)
        getAllStrategies(dispatch)
        getAllLastStrategies(dispatch)
        getAllActiveBots(dispatch)
        getStrategyParams(dispatch)
        getAllLongAlerts(dispatch)
    }, [])

    const isLogged = auth.isLogged ? <Navigate replace={true} to="/app/dashboard"/> : <Signin/>
    const signin = localhost ? <Signin/> : <SigninWebsite/>

    const routesInputs = [
        {path: '', element: localhost ? isLogged : <LandingPage/>},
        {path: 'signin', element: auth.isLogged ? <LandingPage/> : signin},
        {path: 'about', element: localhost ? isLogged : <About/>},
        {path: 'terms', element: localhost ? isLogged : <Terms/>},
        {path: 'contact', element: localhost ? isLogged : <Contact/>},
        {path: 'pricing', element: localhost ? isLogged : <Pricing/>},
        {path: 'statistics', element: localhost ? isLogged : <Statistics/>},
        {path: 'strategy-creator', element: localhost ? isLogged : <StrategyCreator/>},
        {path: 'backtests', element: localhost ? isLogged : <BacktestsWebsite/>},
        {path: 'automate-trading', element: localhost ? isLogged : <AutomatedTrading/>},
        {path: 'indicators', element: localhost ? isLogged : <Indicators/>},
        {path: 'trailing-stop', element: localhost ? isLogged : <TrailingStop/>},
        {path: 'paper-trading', element: localhost ? isLogged : <PaperTrading/>},
    ]

    const documentation = [
        {path: 'how-to-connect', element: <HowToConnect/>},
        {path: 'system-requirements', element: <SystemRequirements/>},
        {path: 'installation', element: <Installation/>},
        {path: 'first-run', element: <FirstRun/>},
        {path: 'dashboard', element: <DashboardDocs/>},
        {path: 'strategies', element: <StrategiesDocs/>},
        {path: 'strategy-creator', element: <StrategyCreatorDocs/>},
        {path: 'backtests', element: <BacktestsDocs/>},
        {path: 'paper-trading', element: <PaperTradingDocs/>},
        {path: 'automate-trading', element: <AutomateTradingDocs/>},
        {path: 'settings', element: <SettingsDocs/>},
        {path: 'chart', element: <ChartDocs/>},
        {path: 'security', element: <SecurityDocs/>},
        {path: 'monitoring', element: <MonitoringDocs/>},
        {path: 'notes', element: <NotesDocs/>},
        {path: 'text-alerts', element: <TextAlertsDocs/>},
        {path: 'sound-alerts', element: <SoundAlertsDocs/>},
        {path: 'moving-average-convergence-divergence', element: <MACD/>},
        {path: 'exponential-moving-average', element: <EMA/>},
        {path: 'historical-min-max', element: <HMM/>},
        {path: 'exponential-moving-average-crossover', element: <EMACross/>},
        {path: 'relative-strength-index', element: <RSI/>},
        {path: 'percentage-price-changes', element: <PercantageDiff/>},
        {path: 'average-candle-length', element: <ACL/>},
        {path: 'average-volume', element: <AV/>},
        {path: 'pump-average-volume', element: <PV/>},
        {path: 'percentage-stop-loss', element: <StopLoss/>},
        {path: 'percentage-take-profit', element: <TakeProfit/>},
        {path: 'trailing-stop-loss', element: <TrailingStopLoss/>},
        {path: 'time-trailing-stop-loss', element: <TimeTrailingStopLoss/>},
        {path: 'time-stop-loss', element: <TimeStopLoss/>},
        {path: '', element: <DefaultDocumentationContent/>},
    ]
    const documentationRoutes = documentation.map((route) =>
        <Route path='docs' element={<Documentation/>}>
            landingPage.map((route) => ( <Route path={route.path} element={route.element}/>))
        </Route>
    )
    const redirectDocRoutes = documentation.map((route) => (
        <Route path={`/docs/${route.path}`} element={
            <Navigate replace={true} to={`../${lang}/docs${route.path === '' ? route.path : '/' + route.path}`}/>
        }/>
    ))
    const standardRoutes = routesInputs.map((route) => (
        <Route path={route.path} element={route.element}/>
    ))
    const redirectRoutes = routesInputs.map((route) => (
        <Route path={`/${route.path}`} element={
            <Navigate replace={true} to={`../${lang}${route.path === '' ? route.path : '/' + route.path}`}/>
        }/>
    ))

    return (
        <div>
            <Routes>
                {redirectRoutes}
                {redirectDocRoutes}
                {standardRoutes}
                {documentationRoutes}
                <Route path="pl">
                    {standardRoutes}
                    {documentationRoutes}
                </Route>
                <Route path="en">
                    {standardRoutes}
                    {documentationRoutes}
                </Route>
                <Route path='app' element={auth.isLogged ? <Layout/> : signin}>
                    //nie działa wykres na klikniecia w main table :(
                    //zaktuwalizować gify paper i automate trading na stronie i w dokumentacji bo aktywność nie jest
                    widoczna
                    //obsługa demo na spocie + dane
                    <Route path='welcome' element={<Welcome/>}/>
                    <Route path='dashboard' element={<Dashboard/>}/>
                    <Route path='strategies' element={<Strategies/>}/>
                    <Route path='strategies/new' element={<NewStrategy/>}/>
                    //opis
                    <Route path='strategies/edit/:id' element={<EditStrategy/>}/>
                    <Route path='backtests' element={<Backtests/>}/>
                    //kiedy robie nowy backtest to daty w rowku są błędne, po odswiezeniu z bazy sa juz poprawne
                    //w module backtest nie dziala ze jak klikniesz w testa to ten coin sie pojawi na wykresie
                    <Route path='paper-trading' element={<Papertrading/>}/>
                    //***w strategy panelu powinny byc tez commissions (i moze funding fees?)
                    <Route path='automate-trading' element={<Automatetrading/>}/>
                    <Route path='subscription' element={<Subscription/>}/>
                    //jeśli nie ma planu to guzik zakup subskrypcję => przenosi do widoku wszystkich planów (jeśli
                    jest coś kupione to ten pakiet jest zaznaczony i jest guzik kup
                    //anuluj subskrypcję
                    //zmień plan subskrypcji
                    //sposoby płatności
                    <Route path='settings' element={<Settings/>}/>
                </Route>
                <Route path={'*'} element={localhost ? isLogged : <PageNotFound/>}/>
            </Routes>
            <SnackbarPopup/>
        </div>
    )
}

function setCookieLang(dispatch) {
    if (window.location.pathname.startsWith("/pl")) {
        Cookies.setCookie('language', 'pl')
        dispatch(setLanguage("pl"))
    } else {
        Cookies.setCookie('language', 'en')
        dispatch(setLanguage("en"))
    }
}

function checkReferral() {
    const params = new URLSearchParams(window.location.search);

    if (params.has('ref')) {
        const nowPlus30min = new Date().getTime() + 1800000
        Cookies.setCookie("referralExpiry", nowPlus30min)
        Cookies.setCookie("referral", params.get('ref'))
    }
}

function getAllActiveBots(dispatch) {
    IsLocalhost(() => axios.get(`${BASEURL}/active_bots`).then(res =>
            dispatch(setActiveBots(res.data))).catch(_ => _),
        () => dispatch(setActiveBots(active_bots)))
}

function getBinanceAssets(dispatch) {
    IsLocalhost(() =>
            axios.get(`${BASEURL}/binance_assets`).then(res =>
                dispatch(setBinanceAssets(res.data))).catch(_ => _),
        () => dispatch(setBinanceAssets(user_last_strategies))
    )
}

function getBackTestSymbolsFutures(dispatch) {
    IsLocalhost(() => axios.get(`https://fapi.binance.com/fapi/v1/exchangeInfo`).then(res => {
        const symbols = res.data.symbols.map(sym => sym.symbol);
        return dispatch(setBackTestSymbolsFutures(sortSymbols(symbols)));
    }).catch(_ => _), () => dispatch(setBackTestSymbolsFutures(sortSymbols(exchangeInfo.symbols.map(sym => sym.symbol)))))
}

function getBackTestSymbolsSpot(dispatch) {
    IsLocalhost(() => axios.get(`https://api.binance.com/api/v3/exchangeInfo`).then(res => {
        const symbols = res.data.symbols.map(sym => sym.symbol).filter(a => a.endsWith("USDT") || a.endsWith("USDC"));
        return dispatch(setBackTestSymbolsSpot(sortSymbols(symbols)));
    }).catch(_ => _), () => dispatch(setBackTestSymbolsSpot(sortSymbols(exchangeInfo.symbols.map(sym => sym.symbol)))))
}

function getAllStrategies(dispatch) {
    IsLocalhost(() =>
            axios.get(`${BASEURL}/user_strategies`).then(res => {
                dispatch(setStrategies(res.data))
            }), () => {
            dispatch(setStrategies(user_strategies))
        }
    )
}

function getAllLastStrategies(dispatch) {
    IsLocalhost(() =>
            axios.get(`${BASEURL}/user_last_strategies`).then(res =>
                dispatch(setLastConfigurations(res.data))).catch(_ => _),
        () => dispatch(setLastConfigurations(user_last_strategies))
    )
}

function getAllLongAlerts(dispatch) {
    IsLocalhost(() =>
            axios.get(`${BASEURL}/longAlerts`).then(res =>
                dispatch(setLongAlerts(res.data))).catch(_ => _),
        () => dispatch(setLongAlerts(long_alerts))
    )
}

function checkPlan(dispatch) {
    IsLocalhost(() =>
            axios.get(`${BASEURL}/plan`).then(res => {
                Cookies.setCookie("subscription", res.data)
                dispatch(setPlan(res.data))
            }).catch(_ => _),
        () => {
            Cookies.setCookie("subscription", "Expert")
            dispatch(setPlan("Expert"))
        }
    )
}

function getStrategyParams(dispatch) {
    IsLocalhost(() =>
            axios.get(`${BASEURL}/strategy/indicators`).then(res =>
                dispatch(setStrategyParams({key: 'indicators', data: res.data}))).catch(_ => _),
        () => dispatch(setStrategyParams({key: 'indicators', data: indicators}))
    )
    IsLocalhost(() =>
            axios.get(`${BASEURL}/strategy/conditions`).then(res =>
                dispatch(setStrategyParams({key: 'conditions', data: res.data}))).catch(_ => _),
        () => dispatch(setStrategyParams({key: 'conditions', data: conditions}))
    )
    IsLocalhost(() =>
            axios.get(`${BASEURL}/strategy/stops`).then(res =>
                dispatch(setStrategyParams({key: 'stops', data: res.data}))).catch(_ => _),
        () => dispatch(setStrategyParams({key: 'stops', data: stops}))
    )

    IsLocalhost(() =>
            axios.get(`${BASEURL}/strategy/shortcuts`).then(res => {
                const shortcutsAndIndicatorParams = Object.keys(res.data).map(key => ({
                    shortName: key,
                    name: res.data[key].name,
                    description: res.data[key].description,
                    type: res.data[key].type
                }))
                dispatch(setStrategyParams({key: 'shortcuts', data: shortcutsAndIndicatorParams}))
            }).catch(_ => _),
        () => dispatch(setStrategyParams({
            key: 'shortcuts', data: Object.keys(shortcut).map(key => ({
                shortName: key,
                name: shortcut[key].name,
                description: shortcut[key].description,
                type: shortcut[key].type
            }))
        }))
    )
}

function sortSymbols(symbols) {
    const sortedWithoutUnderscore = symbols.filter(item => !item.includes('_')).sort();
    const sortedWithUnderscore = symbols.filter(item => item.includes('_')).sort();
    return [...sortedWithoutUnderscore, ...sortedWithUnderscore];
}

export default App