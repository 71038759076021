export const IsLocalhost = (localhostMethod, noLocalhostMethod) => {
    if (isLocalhost()) {
        return localhostMethod()
    } else {
        return noLocalhostMethod()
    }
}

export function isLocalhost() {
    return window.location.hostname === 'localhost';
}