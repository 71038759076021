import React from "react"
import Template from "../../components/Template"
import Translations from "../../../components/Translations"
import img1 from "../../images/indicators/indicators.jpg"

function PV() {
    const Content = () => {
        return (
            <div className={`flex flex-col gap-5`}>
                <img src={img1} alt='/' className={`border border-gray-700 mt-5 mb-5 w-[20%]`}></img>
                <p className={`text-lg`}><Translations category={"docsPV"} id={1}/></p>
                <p className={`text-lg`}>
                    <Translations category={"docsPV"} id={2}/>
                    <li><Translations category={"docsPV"} id={3}/></li>
                    <li><Translations category={"docsPV"} id={4}/></li>
                    <li><Translations category={"docsPV"} id={5}/></li>
                    <li><Translations category={"docsPV"} id={6}/></li>
                    <li><Translations category={"docsPV"} id={7}/></li>
                </p>
                <p className={`text-lg`}><Translations category={"docsPV"} id={8}/></p>
            </div>
        )
    }
    const config = {
        title: <Translations category={"docs"} id={24}/>,
        previousLink: '/docs/average-volume',
        previousTitle: <Translations category={"docs"} id={23}/>,
        nextLink: '/docs/exponential-moving-average',
        nextTitle: <Translations category={"docs"} id={25}/>,
        content: Content(),
    }

    return (
        <Template config={config}/>
    )
}

export default PV