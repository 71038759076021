import React from "react"
import Template from "../../components/Template"
import Translations from "../../../components/Translations"
import img1 from "../../images/indicators/indicators.jpg"

function HMM() {
    const Content = () => {
        return (
            <div className={`flex flex-col gap-5`}>
                <img src={img1} alt='/' className={`border border-gray-700 mt-5 mb-5 w-[20%]`}></img>
                <p className={`text-lg`}><Translations category={"docsHMM"} id={1}/></p>
                <p className={`text-lg`}><Translations category={"docsHMM"} id={2}/></p>
                <p className={`text-lg`}>
                    <Translations category={"docsHMM"} id={3}/>
                    <li><Translations category={"docsHMM"} id={4}/></li>
                    <li><Translations category={"docsHMM"} id={5}/></li>
                    <li><Translations category={"docsHMM"} id={6}/></li>
                </p>
                <p className={`text-lg`}><Translations category={"docsHMM"} id={7}/></p>
            </div>
        )
    }
    const config = {
        title: <Translations category={"docs"} id={27}/>,
        previousLink: '/docs/exponential-moving-average-crossover',
        previousTitle: <Translations category={"docs"} id={26}/>,
        nextLink: '/docs/percentage-price-changes',
        nextTitle: <Translations category={"docs"} id={28}/>,
        content: Content(),
    }

    return (
        <Template config={config}/>
    )
}

export default HMM