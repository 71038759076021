import React, {useEffect, useState} from 'react'
import {BASEURL} from "../../../globals/Constants"
import axios from "axios"
import Header from "../../components/tradingPanel/Header";
import Trades from "../../components/tradingPanel/Trades";
import Balance from "../../components/tradingPanel/Balance";
import MainTable from "../../components/tradingPanel/MainTable";
import StrategyPanel from "../../components/tradingPanel/StrategyPanel"
import TradingResult from "../../components/tradingPanel/TradingResult";
import {useDispatch, useSelector} from "react-redux";
import {selectMarket, setMessage, setTradingView} from "../../components/redux/GlobalState";
import {IsLocalhost, isLocalhost} from "../../../globals/auth/IsLocalhost";
import stats_strategies from "../../mocks/stats_strategies.json"
import EventEmitter from "../../components/EventEmitter";

export default function BackTestInterface() {
    const dispatch = useDispatch()
    const viewName = window.location.pathname.split("/")[2].replace("-", "")
    const market = useSelector(selectMarket)
    const source = "BackTest";
    const [mainTableData, setMainTableData] = useState([])

    useEffect(() => {
        if (isLocalhost()) {
            axios.get(`${BASEURL}/stats_strategies?equals.market=${market[viewName]}&equals.source=${source}&sort.time=desc`)
                .then(res => {
                    if (res.data.length === 0) {
                        axios.get(`${BASEURL}/fullStats?market=${market[viewName]}&source=${source}`).catch(_ => _) //to fill 0s if empty
                    } else {
                        setMainTableData(res.data)
                        showSpecificResultsById(res.data[0].id)
                    }
                }).catch(_ => _)
        } else {
            if (stats_strategies.length !== 0) {
                setMainTableData(stats_strategies.sort((a, b) => b.time - a.time).filter(a => a.source === "BackTest"))
                showSpecificResultsById(stats_strategies.filter(a => a.source === "BackTest")[0].id)
            }
        }
    }, [market])

    const configStrategy = {
        buttonDelete: () => deleteBacktest(),
        visibility: {
            date: true,
            account: true,
        },
    }

    function showSpecificResultsById(id) {
        if (isLocalhost()) {
            axios.get(`${BASEURL}/fullStats?id=${id}&market=${market[viewName]}&source=${source}`).catch(_ => _)
            if (mainTableData.length > 0) {
                const row = mainTableData.filter(a => a.id === id)[0]
                dispatch(setTradingView({
                    symbol: row.symbol + (market[viewName] === "futures" ? '.P' : ''),
                    interval: row.intervl
                }))
            }
        } else {
            EventEmitter.emit('demoFullStats', id)
        }
    }

    function test(lastConfiguration, strategy) {
        IsLocalhost(() => {
            dispatch(setTradingView({symbol: lastConfiguration.symbol, interval: lastConfiguration.interval}))
            const maxBetField = lastConfiguration.fullBalance ? "" : `&maxBet=${lastConfiguration.maxBet}`
            axios.put(`${BASEURL}/user_last_strategies/${lastConfiguration.id}`, {
                view: source,
                strategyName: lastConfiguration.strategyName,
                strategyId: lastConfiguration.strategyId,
                symbol: lastConfiguration.symbol,
                interval: lastConfiguration.interval,
                leverage: market[viewName] === "spot" ? 1 : lastConfiguration.leverage,
                maxBet: lastConfiguration.fullBalance ? null : lastConfiguration.maxBet,
                balance: lastConfiguration.balance,
                oneTrade: lastConfiguration.oneTrade,
                fullBalance: lastConfiguration.fullBalance,
                realTimeEntry: false,
                mute: lastConfiguration.mute,
                from: lastConfiguration.from,
                to: lastConfiguration.to,
                id: lastConfiguration.id,
                market: lastConfiguration.market,
            }).catch(_ => _)

            axios.get(`${BASEURL}/backtest?id=1&symbol=${lastConfiguration.symbol}&interval=${lastConfiguration.interval}&leverage=${lastConfiguration.leverage}&from=${lastConfiguration.from}&to=${lastConfiguration.to}${maxBetField}&balance=${lastConfiguration.balance}&mute=${lastConfiguration.mute}&${strategy}&oneTrade=${lastConfiguration.oneTrade}&market=${market[viewName]}&output=json&socket=true`)
                .then(res2 => {
                        axios.get(`${BASEURL}/stats_strategies?equals.market=${market[viewName]}&equals.source=${source}&sort.time=desc`)
                            .then(res => setMainTableData(res.data)).catch(_ => _)
                    }
                ).catch(_ => _)
        }, () => dispatch(setMessage({key: 'Alert', value: 'You cannot backtest in demo version'})))
    }

    function deleteBacktest() {
        IsLocalhost(() => axios.delete(`${BASEURL}/backtest/1`).catch(_ => _),
            () => dispatch(setMessage({key: 'Alert', value: 'There is nothing to cancel in demo version'})))
    }

    return (
        <div className="flex flex-col w-full gap-2">
            <Header title={"Backtests"} source={source}/>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-12 2xl:grid-cols-12 gap-4">
                <StrategyPanel config={configStrategy} title={'Backtest strategy'} source={source}
                               mainButtonLabel={"Test"} mainButtonFunction={test} market={market[viewName]}/>
                <MainTable source={source} mainTableData={mainTableData}
                           showSpecificResultsById={showSpecificResultsById} market={market[viewName]}/>
                <Balance source={source} market={market[viewName]}/>
                <TradingResult source={source}/>
            </div>
            <div className="grid grid-cols-1">
                <Trades source={source}/>
            </div>
        </div>
    )
}