import React from "react"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import styles from "../../style"
import Signin from "./Signin"

function SigninWebsite() {
    return (
        <div className={`w-full ${styles.backgroundMain} text-white`}>
            <Navbar/>
            <Signin/>
            <Footer/>
        </div>
    )
}

export default SigninWebsite