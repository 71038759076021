function setCookie(cookieName, cookieValue) {
    document.cookie = `${cookieName}=${cookieValue}; path=/`
}

function getCookie(cookieName) {
    return document.cookie
        .split('; ')
        .find((cookie) => cookie.startsWith(cookieName + '='))
        ?.split('=')[1]
}

function deleteCookie(cookieName) {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`
}

export default {setCookie, getCookie, deleteCookie};