const styles = {
    roundedBox: "bg-[#353535] p-3 rounded-lg",
    flexCenter: "flex justify-center items-center",
    blueButton: `bg-[#10a7d0] rounded-md text-2xl py-3 text-white font-semibold flex items-center justify-center h-[40px] min-w-[100px]`,
    redButton: `bg-[#a93b3b] rounded-md text-2xl py-3 text-[#f3f2ee] flex items-center justify-center h-[40px] min-w-[100px]`,
    rowBetween: "flex flex-row justify-between",
    colBetween: "flex flex-col justify-between",
    maxWidth: "max-w-[1536px] mx-auto",
    heading2: "font-semibold xs:text-[48px] text-[40px] text-white xs:leading-[76.8px] leading-[66.8px] w-full",
    paragraph: "font-normal text-[18px] leading-[30.8px]",
    flexStart: "flex justify-center",
    paddingX: "sm:px-16 px-6",
    paddingY: "sm:py-16 py-6",
    padding: "sm:px-16 px-6 sm:py-12 py-4",
    backgroundMain: "bg-[#161d26]",
    backgroundBase: "bg-[#1b232d]",
    backgroundAccent: "bg-[#10a7d0]",
    textHeading: "text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl py-10 lg:py-12 2xl:py-16",
    textSubheading: "text-2xl md:text-3xl xl:text-4xl mb-5 md:my-5",
    textParagraph: "text-base xl:text-xl",
    blueLineLeft: "pl-5 border-l-4 border-[#10a7d0]",
    shadow: "shadow-black shadow-md",
    borderImg: "border border-gray-700"
}

export const color = {
    main: `#161d26`,
    base: `#29394b`,
    accent: `#10a7d0`,
}

export default styles
