import React from "react"
import styles from "../../style"
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ParticlesContainer from "../components/ParticlesContainer";
import error from "../images/404(1).svg";

function PageNotFound() {
    return (
        <div className={`w-full ${styles.backgroundMain} text-white`}>
            <Navbar/>
            <div className={`${styles.backgroundMain}`}>
                <ParticlesContainer height={"100vh"}/>
                <div
                    className='absolute inset-0 flex flex-col items-center justify-center  left-0 right-0 mx-auto  lg:w-2/3 lg:top-0 lg:left-0'>
                    <img src={error} alt='/' className={`h-[500px]`}/>
                    <p className="text-6xl font-bold bg-[#161d26] bg-opacity-90">Page Not Found</p>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default PageNotFound