import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {selectLanguage, setLanguage} from "../../application/components/redux/GlobalState"
import Cookies from "../../globals/cookies/Cookies"
import styles from "../../style"

const LanguageSwitcher = () => {
    const dispatch = useDispatch()
    const language = useSelector(selectLanguage)

    const changeLanguage = (event) => {
        const lang = event.target.value;
        const pathname = window.location.pathname.replace(/^\/[a-z]{2}/, `/${lang}`)
        window.history.pushState({}, '', pathname)

        dispatch(setLanguage(lang))
        Cookies.setCookie('language', lang)
    }

    return (
        <select value={language} onChange={changeLanguage}
                className={`p-4 text-xl text-[#f3f2ee] tracking-tighter outline-none cursor-pointer ${styles.backgroundMain}`}>
            <option value="en">EN</option>
            <option value="pl">PL</option>
            {/*<option value="de">DE</option>*/}
            {/*<option value="es">ES</option>*/}
            {/*<option value="br">BR</option>*/}
            {/*<option value="jp">JP</option>*/}
            {/*<option value="zh">ZH</option>*/}
            {/*<option value="ko">KO</option>*/}
            {/*<option value="fr">FR</option>*/}
            {/*<option value="ru">RU</option>*/}
            {/*<option value="fr">FR</option>*/}
        </select>
    )
}

export default LanguageSwitcher