import React, {useState} from "react"
import DeleteIcon from "@mui/icons-material/Delete"
import {Reorder} from "framer-motion"
import styles from "../../../../style"
import {generateUniqueId} from "./StrategyCommons"

function Pattern({setStrategy, strategy, setShowModal, keyName, inputValues, setInputValues, editModal, setEditModal,closeType, setCloseType}) {

    const [pattern, setPattern] = useState(inputValues.parameter === "pat" ? inputValues.value.map(a => ({
        id: generateUniqueId(),
        value: a
    })) : [])

    const deletePattern = (item) => {
        setPattern(pattern.filter((i) => i !== item))
    }
    const addPattern = (symbol) => {
        const newItem = {
            id: generateUniqueId(),
            value: symbol,
        }
        setPattern([...pattern, newItem])

    }
    const addPatternToCondition = () => {
        if (pattern && !editModal.isEdit) {
            let keyName1 = keyName
            if(keyName === "close" && ((closeType.buy === false) || ((closeType.sell === false)))){
                keyName1 = closeType.buy === false ? "closeSell" : "closeBuy"
            }else {
                keyName1 = keyName
            }
            const newItem = {
                id: generateUniqueId(),
                value: pattern.map(item => item.value),
                parameter: "pat"
            }
            setStrategy((prevData) => ({
                ...prevData,
                [keyName1]: [...prevData[keyName1], newItem],
            }))
        } else if (pattern && editModal.isEdit) {
            const updatedItems = {...strategy}
            updatedItems[keyName][editModal.index] = {
                id: updatedItems[keyName][editModal.index].id,
                value: pattern.map(item => item.value),
                parameter: "pat"
            }
            setStrategy(updatedItems)
            setEditModal({
                isEdit: false,
                index: -1,
            })
        }
        setPattern([])
        setCloseType({sell: true, buy: true})
        setShowModal(false)
    }
    return (
        <div>
            <div className={`${styles.rowBetween} mb-10`}>
                <p className="text-xl">Add Candle Pattern</p>
                <div className="flex flex-row gap-2">
                    <div onClick={() => addPattern("g")}
                         className={`relative cursor-pointer bg-green-600 w-[30px] h-[30px] rounded`}/>
                    <div onClick={() => addPattern("r")}
                         className={`relative cursor-pointer bg-red-700 w-[30px] h-[30px] rounded`}/>
                    <button onClick={() => addPatternToCondition()}
                            className="bg-[#10a7d0] h-[30px] text-white flex font-bold items-center rounded">Save
                    </button>
                </div>
            </div>
            <div
                className={`bg-[#1d1d1d] px-2 rounded-lg h-[60px] flex pt-2 items-center mt-4`}>
                <Reorder.Group axis="x"
                               onReorder={(newIndicators) => setPattern(newIndicators)}
                               values={pattern}
                               className="flex flex-row gap-3">
                    {pattern.map((item, index) => (
                        <Reorder.Item value={item} key={item.id}>
                            <div key={item.id}
                                 className={`relative ${item.value === "g" ? "bg-green-600" : "bg-red-700"} w-[30px] h-[30px] flex  rounded`}>
                                <DeleteIcon onClick={() => deletePattern(item)}
                                            className="absolute top-0 right-0 -mt-3 -mr-3 rounded-full bg-red-500 text-white p-1"/>
                            </div>
                        </Reorder.Item>
                    ))}
                </Reorder.Group>
            </div>
        </div>

    )
}

export default Pattern