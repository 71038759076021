import React from "react"
import Template from "../../components/Template"
import img1 from "../../images/howToConnect/icon.png"
import img2 from "../../images/howToConnect/settings_en.png"
import img3 from "../../images/howToConnect/binanceAccount.png"
import img6 from "../../images/howToConnect/addLabel.png"
import img5 from "../../images/howToConnect/addKeyOptions.png"
import img7 from "../../images/howToConnect/verify.png"
import img8 from "../../images/howToConnect/addApiKey.png"
import img9 from "../../images/howToConnect/editRestrictions.png"
import img10 from "../../images/howToConnect/management.png"
import img11 from "../../images/howToConnect/deputyKeys.png"
import Translations from "../../../components/Translations"
import style from "../../../../style"

function HowToConnect() {
    const Content = () => {
        return (
            <div>
                <p className={`text-lg`}><Translations category={"docsHowToConnect"} id={3}/></p>
                <p className={`mt-5 text-yellow-500`}><Translations category={"docsHowToConnect"} id={4}/></p>
                <p className={`text-lg mt-5`}><Translations category={"docsHowToConnect"} id={5}/></p>
                <p className={`mt-5 text-yellow-500`}><Translations category={"docsHowToConnect"} id={6}/></p>
                <p className={`text-lg mt-5`}><Translations category={"docsHowToConnect"} id={7}/></p>
                <p className={`mt-5`}><Translations category={"docsHowToConnect"} id={8}/></p>
                <div className={`text-lg my-5 flex flex-row`}>
                    <p className={"text-lg"}>
                        <Translations category={"docsHowToConnect"} id={9}/>
                        <a href={"https://binance.com"} className={"text-[#10a7d0]"}>Binance</a>
                    </p>
                </div>
                <p className={"text-lg"}><Translations category={"docsHowToConnect"} id={10}/></p>
                <a href={"https://www.binance.com/en/my/settings/api-management"} className={"text-[#10a7d0]"}>
                    https://www.binance.com/en/my/settings/api-management
                </a>
                <p className={"flex flex-row items-center text-lg mt-5"}>
                    <Translations category={"docsHowToConnect"} id={11}/>
                    <img src={img1} alt='/'/>
                    <Translations category={"docsHowToConnect"} id={12}/>
                </p>
                <p className={"text-lg"}><Translations category={"docsHowToConnect"} id={13}/></p>
                <div className={"grid grid-cols-1 md:grid-cols-2 gap-5 items-start"}>
                    <img src={img2} alt='/' className={`col-span-1 ${style.borderImg} my-5`}/>
                    <img src={img3} alt='/' className={`col-span-1 ${style.borderImg} my-5`}/>
                </div>
                <p className={`text-lg mt-5`}><Translations category={"docsHowToConnect"} id={14}/></p>
                <img src={img8} alt='/' className={`${style.borderImg} my-5`}/>
                <p className={`text-lg mt-5`}><Translations category={"docsHowToConnect"} id={15}/></p>
                <img src={img5} alt='/' className={`${style.borderImg} my-5`}/>
                <p className={`text-lg mt-5`}><Translations category={"docsHowToConnect"} id={16}/></p>
                <img src={img6} alt='/' className={`${style.borderImg} my-5`}/>
                <p className={`text-lg mt-5`}><Translations category={"docsHowToConnect"} id={17}/></p>
                <img src={img7} alt='/' className={`${style.borderImg} my-5`}/>
                <p className={`text-lg mt-5`}><Translations category={"docsHowToConnect"} id={18}/></p>
                <img src={img9} alt='/' className={`${style.borderImg} my-5`}/>
                <p className={`text-lg mt-5`}><Translations category={"docsHowToConnect"} id={19}/></p>
                <p className={`text-lg mt-5`}><Translations category={"docsHowToConnect"} id={20}/></p>
                <div className={`text-lg mt-5 flex flex-row`}>
                    <Translations category={"docsHowToConnect"} id={21}/>
                    <a href={"https://www.showmyip.com/"} className={"text-[#10a7d0] ml-1"}>showmyip.com</a>.
                </div>
                <p className={"text-lg"}><Translations category={"docsHowToConnect"} id={22}/></p>
                <img src={img10} alt='/' className={`${style.borderImg} my-5`}/>
                <div className={"flex flex-row"}>
                    <p className={"text-lg"}>
                        <Translations category={"docsHowToConnect"} id={23}/>
                        <span className={"text-lg text-[#10a7d0]"}>
                            "Binance market keys updated. Paper and automated trading ready to use"
                        </span>
                    </p>
                </div>
                <img src={img11} alt='/' className={`${style.borderImg} my-5`}/>
            </div>
        )
    }
    const config = {
        title: <Translations category={"docs"} id={5}/>,
        previousLink: '/docs/first-run',
        previousTitle: <Translations category={"docs"} id={4}/>,
        nextLink: '/docs/security',
        nextTitle: <Translations category={"docs"} id={6}/>,
        content: Content(),
    }

    return (
        <Template config={config}/>
    )
}

export default HowToConnect