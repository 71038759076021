import React from 'react'
import 'react-tooltip/dist/react-tooltip.css'
import HelpIcon from "@mui/icons-material/Help"
import {Tooltip} from 'react-tooltip'

const HoverHint = ({visibleText, tooltipText, color}) => {
    return (
        <div className="flex items-center justify-center">
            <a className="hint" data-tooltip-content={tooltipText}>{visibleText ? visibleText : <HelpIcon/>}</a>
            <Tooltip
                anchorSelect=".hint"
                className="z-[1100]"
                style={{backgroundColor: color ? color : "rgb(16,166,206)", color: "#fff", fontSize: 16, maxWidth: 500}}
            />
        </div>
    )
}

export default HoverHint