import React, {createContext, useContext, useState} from 'react'
import Cookies from "../cookies/Cookies"
import axios from "axios"
import {BASEURL, HKEY} from "../Constants"
import encrypt from "../Cipher";
import {TokenRefresher} from "./TokenRefresher";
import {isLocalhost} from "./IsLocalhost";

const AuthContext = createContext()

export const AuthProvider = ({children}) => {
    const [isLogged, setLogged] = useState(parseInt(Cookies.getCookie("accTime")) > new Date().getTime())
    const authLogin = (accessToken, refreshToken) => {
        const nowPlus30min = new Date().getTime() + 1800000
        const nowPlus14d = new Date().getTime() + 1209600000
        Cookies.setCookie("accTime", nowPlus30min)
        Cookies.setCookie("refTime", nowPlus14d)
        setLogged(true)
        if (isLocalhost()) {
            axios.post(`${BASEURL}/tokens`, {
                accessToken: btoa(encrypt(accessToken, HKEY)),
                refreshToken: btoa(encrypt(refreshToken, HKEY))
            })
        }
    }

    const authLogout = () => {
        Cookies.deleteCookie("accTime")
        Cookies.deleteCookie("refTime")
        setLogged(false)
    }

    TokenRefresher(authLogin, setLogged)

    return (
        <AuthContext.Provider value={{isLogged, authLogin, authLogout}}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext)