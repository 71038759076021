import React, {useEffect} from "react"
import styles, {color} from "../../style"
import StarIcon from '@mui/icons-material/Star'
import SyncAltIcon from '@mui/icons-material/SyncAlt'
import SwipeLeftIcon from '@mui/icons-material/SwipeLeft'
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import ShuffleIcon from '@mui/icons-material/Shuffle'
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart'
import Subpage from "../components/Subpage"
import UndoIcon from '@mui/icons-material/Undo'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat'
import strategiescreator from "../gif/strategiescreator.gif";
import img27 from "../images/img15.svg"
import Translations from "../components/Translations";

function StrategyCreator() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const offerIncludes = [
        {description: <Translations category={"strategyCreator"} id={25}/>},
        {description: <Translations category={"strategyCreator"} id={26}/>},
        {description: <Translations category={"strategyCreator"} id={27}/>},
        {description: <Translations category={"strategyCreator"} id={28}/>},
        {description: <Translations category={"strategyCreator"} id={29}/>},
        {description: <Translations category={"strategyCreator"} id={30}/>},
        {description: <Translations category={"strategyCreator"} id={31}/>},
        {description: <Translations category={"strategyCreator"} id={32}/>},
        {description: <Translations category={"strategyCreator"} id={33}/>},
        {description: <Translations category={"strategyCreator"} id={34}/>},
        {description: <Translations category={"strategyCreator"} id={35}/>},
    ];

    const configIconSection = {
        iconOne: <SwipeLeftIcon sx={{fontSize: 50}}/>,
        iconTwo: <StarIcon sx={{fontSize: 50}}/>,
        iconThree: <SyncAltIcon sx={{fontSize: 50}}/>,
        textOne: <Translations category={"strategyCreator"} id={2}/>,
        textTwo: <Translations category={"strategyCreator"} id={3}/>,
        textThree: <Translations category={"strategyCreator"} id={4}/>,
    }

    const markets = [
        {
            market: <Translations category={"strategyCreator"} id={7}/>,
            content: [<Translations category={"strategyCreator"} id={8}/>,
                <Translations category={"strategyCreator"} id={9}/>,
                <Translations category={"strategyCreator"} id={10}/>,
                <Translations category={"strategyCreator"} id={11}/>]
        },
        {
            market: <Translations category={"strategyCreator"} id={12}/>,
            content: [<Translations category={"strategyCreator"} id={13}/>,
                <Translations category={"strategyCreator"} id={14}/>,
                <Translations category={"strategyCreator"} id={15}/>,
                <Translations category={"strategyCreator"} id={16}/>,
                <Translations category={"strategyCreator"} id={17}/>
            ]
        }
    ]

    const parameters = [
        {
            icon: <><TrendingFlatIcon sx={{fontSize: 20}}/><CandlestickChartIcon sx={{fontSize: 40}}/></>,
            text: <Translations category={"strategyCreator"} id={19}/>,
        },
        {
            icon: <><UndoIcon sx={{fontSize: 20}}/><CandlestickChartIcon sx={{fontSize: 40}}/></>,
            text: <Translations category={"strategyCreator"} id={20}/>,
        },
        {icon: <StarIcon sx={{fontSize: 40}}/>, text: <Translations category={"strategyCreator"} id={21}/>,},
        {
            icon: <CandlestickChartIcon sx={{fontSize: 40}}/>,
            text: <Translations category={"strategyCreator"} id={22}/>,
        },
        {icon: <AccessTimeIcon sx={{fontSize: 40}}/>, text: <Translations category={"strategyCreator"} id={23}/>,},
        {icon: <ShuffleIcon sx={{fontSize: 40}}/>, text: <Translations category={"strategyCreator"} id={24}/>,},
    ];

    const Content = () => {
        return (
            <div>
                <div className={`grid grid-cols-1 lg:grid-cols-6 gap-5`}>
                    <div className={`col-span-4 flex flex-col justify-between`}>
                        <div></div>
                        <div>
                            <p className={`${styles.textParagraph}`}>
                                <Translations category={"strategyCreator"} id={5}/>
                            </p>
                            <p className={`${styles.textParagraph} mt-10`}>
                                <Translations category={"strategyCreator"} id={6}/>
                            </p>
                        </div>
                        <div></div>
                    </div>
                    <img src={img27} alt='/' className={`col-span-2 w-[500px] m-auto`}/>
                </div>
                <div className={`grid md:grid-cols-2 gap-5 mt-10`}>
                    {markets.map((card, index) => (
                        <div className={`bg-[${color.base}] p-8 rounded ${styles.shadow}`}>
                            <h2 className={`text-2xl md:text-3xl xl:text-4xl mb-10`}>
                                {card.market}
                            </h2>
                            {card.content.map((content, index) => (
                                <p className={`${styles.textParagraph} ${styles.blueLineLeft} mb-3`} key={index}>
                                    {content}
                                </p>
                            ))}
                        </div>
                    ))}
                </div>
                <p className={`${styles.textParagraph} mt-10 lg:mt-32`}>
                    <Translations category={"strategyCreator"} id={18}/>
                </p>
                <div className={`grid sm:grid-cols-2 lg:grid-cols-3 gap-5 mt-10`}>
                    {parameters.map((card, index) => (
                        <div key={index} className={`bg-[${color.base}] ${styles.shadow} p-8 rounded`}>
                            <div className={`justify-center flex mb-8 items-center`}>
                                {card.icon}
                            </div>
                            <p className={`${styles.textParagraph} text-center`}>
                                {card.text}
                            </p>
                        </div>
                    ))}
                </div>
            </div>
        )
    }

    const config = {
        configIconSection: configIconSection,
        title: <Translations category={"menu"} id={16}/>,
        subtitle: <Translations category={"strategyCreator"} id={1}/>,
        offerIncludes: offerIncludes,
        content: Content(),
        gif: strategiescreator,
    }

    return (
        <Subpage config={config}/>
    )
}

export default StrategyCreator