import styles, {color} from "../../../style"
import {feedback} from "./content"
import {Link} from "react-router-dom"
import React, {useEffect, useState} from "react"
import Translations from "../Translations";

function OtherFeatures() {
    const [activePageIndex, setActivePageIndex] = useState(0)
    const itemsPerPage = 3
    const totalPages = Math.ceil(feedback.length / itemsPerPage)

    const nextPage = () => {
        if (activePageIndex < totalPages - 1) {
            setActivePageIndex(activePageIndex + 1)
        } else {
            setActivePageIndex(0)
        }
    }

    useEffect(() => {
        const interval = setInterval(nextPage, 5000)
        return () => clearInterval(interval)
    }, [activePageIndex])

    const startIndex = activePageIndex * itemsPerPage
    const endIndex = startIndex + itemsPerPage
    const visibleFeedback = feedback.slice(startIndex, endIndex)

    return <div className={`w-full mb-16 lg:mb-32 text-[#f3f2ee]`}>
        <section id="clients"
                 className={`${styles.flexCenter} flex-col relative text-[#f3f2ee]`}>
            <div
                className={`${styles.colBetween} mt-10 lg:mt-32 w-full items-center md:flex-row sm:mb-16 mb-6 relative z-[1]`}>
                <h2 className="font-semibold xs:text-[48px] text-[40px] text-center text-[#f3f2ee] xs:leading-[76.8px] leading-[66.8px] w-full">
                    <Translations category={"subpage"} id={2}/>
                </h2>
            </div>
            <div className="relative overflow-hidden w-full">
                <div className="grid lg:grid-cols-3 gap-5">
                    {visibleFeedback.map((card, index) => (
                        <div
                            key={index}
                            className={`${styles.shadow} p-4 lg:p-8 rounded bg-[${color.base}]`}>
                            <Link to={card.link}>
                                <div
                                    className={`rounded flex flex-row items-center`}>
                                    {card.icon}
                                    <div className={`pr-5 mr-7 h-[60px] border-r-4 border-[${color.accent}]`}></div>
                                    <h2 className={`text-xl lg:text-2xl font-semibold`}>
                                        {card.title}
                                    </h2>
                                </div>
                                <p className={`font-normal text-[18px] leading-[32.4px] text-[#f3f2ee] mt-8`}>
                                    {card.description}
                                </p>
                            </Link>
                        </div>
                    ))}
                </div>
                <div className="flex justify-center mt-4">
                    {Array.from({length: totalPages}, (_, i) => (
                        <div
                            key={i}
                            className={`w-3 h-3 mx-1 rounded-full cursor-pointer ${
                                i === activePageIndex ? 'bg-[#10a7d0]' : 'bg-gray-300'
                            }`}
                            onClick={() => setActivePageIndex(i)}
                        ></div>
                    ))}
                </div>
            </div>
        </section>
    </div>
}

export default OtherFeatures