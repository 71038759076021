import React, {useEffect} from "react"
import styles, {color} from "../../style"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import MovingIcon from '@mui/icons-material/Moving'
import LogoutIcon from '@mui/icons-material/Logout'
import Subpage from "../components/Subpage"
import stops from "../gif/stops.gif";
import img26 from "../images/img14.svg";
import Translations from "../components/Translations";

function TrailingStop() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const offerIncludes = [
        {description: <Translations category={"trailingStop"} id={14}/>},
        {description: <Translations category={"trailingStop"} id={15}/>},
        {description: <Translations category={"trailingStop"} id={16}/>},
        {description: <Translations category={"trailingStop"} id={17}/>},
        {description: <Translations category={"trailingStop"} id={18}/>},
    ];

    const configIconSection = {
        iconOne: <LogoutIcon sx={{fontSize: 50}}/>,
        iconTwo: <MovingIcon sx={{fontSize: 50}}/>,
        iconThree: <AccessTimeIcon sx={{fontSize: 50}}/>,
        textOne: <Translations category={"trailingStop"} id={2}/>,
        textTwo: <Translations category={"trailingStop"} id={3}/>,
        textThree: <Translations category={"trailingStop"} id={4}/>,
    }

    const closeTypes = [
        <Translations category={"trailingStop"} id={7}/>,
        <Translations category={"trailingStop"} id={8}/>,
        <Translations category={"trailingStop"} id={9}/>,
        <Translations category={"trailingStop"} id={10}/>,
        <Translations category={"trailingStop"} id={11}/>,
        <Translations category={"trailingStop"} id={12}/>,
    ]

    const Content = () => {
        return (
            <div>
                <div className={`grid grid-cols-1 lg:grid-cols-6 gap-5`}>
                    <div className={`col-span-4 flex flex-col justify-between`}>
                        <div></div>
                        <div>
                            <p className={`${styles.textParagraph}`}>
                                <Translations category={"trailingStop"} id={5}/>
                            </p>
                            <p className={`${styles.textParagraph} mt-10`}>
                                <Translations category={"trailingStop"} id={6}/>
                            </p>
                        </div>
                    </div>
                    <img src={img26} alt='/' className={`col-span-2 w-[500px] m-auto`}/>
                </div>
                <div className={`grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-5 mt-10`}>
                    {closeTypes.map((type, index) => (
                        <div
                            className={`bg-[${color.base}] ${styles.shadow} p-8 flex flex-col items-center justify-center`}>
                            <p className={`text-xl text-center`}>{type}</p>
                        </div>
                    ))}
                </div>
                <p className={`${styles.textParagraph} my-10`}>
                    <Translations category={"trailingStop"} id={13}/>
                </p>
            </div>
        )
    }

    const config = {
        configIconSection: configIconSection,
        title: <Translations category={"menu"} id={18}/>,
        subtitle: <Translations category={"trailingStop"} id={1}/>,
        offerIncludes: offerIncludes,
        content: Content(),
        gif: stops,
    }

    return (
        <Subpage config={config}/>
    )
}

export default TrailingStop