import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {store} from './application/components/redux/Store';
import {Provider} from "react-redux";
import {ProSidebarProvider} from "react-pro-sidebar";
import {BrowserRouter} from "react-router-dom";
import {WebSocketProvider} from "./globals/socket/Socket";
import {AuthProvider} from "./globals/auth/AuthContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <WebSocketProvider>
        <ProSidebarProvider>
            <Provider store={store}>
                <BrowserRouter>
                    <AuthProvider>
                        <App/>
                    </AuthProvider>
                </BrowserRouter>
            </Provider>
        </ProSidebarProvider>
    </WebSocketProvider>
);