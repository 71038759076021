import styles, {color} from "../../../style";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {Checkbox, FormControlLabel, Popover} from "@mui/material";
import axios from "axios";
import {BASEURL} from "../../../globals/Constants";
import React, {useEffect, useState} from "react";
import {isLocalhost} from "../../../globals/auth/IsLocalhost";
import {selectMarket, setMarket, setMessage} from "../redux/GlobalState";
import {useDispatch, useSelector} from "react-redux";
import Cookies from "../../../globals/cookies/Cookies";

const Header = ({title, source}) => {
    const viewName = window.location.pathname.split("/")[2].replace("-", "")
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState(null)
    const market = useSelector(selectMarket)
    const [sounds, setSounds] = useState({
        Finish: false,
        OpenTrade: false,
        CloseTrade: false,
        StopLossUpdate: false
    })

    useEffect(() => {
        if (isLocalhost()) {
            axios.get(`${BASEURL}/sound?source=${source}`).then((res) => setSounds(res.data)).catch(_ => _)
        }
    }, [])

    function changeMarket(market) {
        if (title === "Automate Trading") {
            dispatch(setMarket({key: viewName, data: "futures"}))
            dispatch(setMessage({
                key: 'Alert',
                value: 'Automating trading for the spot market is not yet available. It is currently under implementation'
            }))
        } else {
            dispatch(setMarket({key: viewName, data: market}))
            Cookies.setCookie("market-" + viewName, market)
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    function setSound(type, on) {
        if (isLocalhost()) {
            axios.get(`${BASEURL}/sound?source=${source}&type=${type}&on=${!on}`).then((res) => setSounds(prevState => ({
                ...prevState,
                [type]: res.data
            }))).catch(_ => _)
        } else {
            setSounds(prevState => ({
                ...prevState,
                [type]: !sounds[type]
            }))
        }
    }

    return (
        <div className={styles.rowBetween}>
            <p className="text-4xl">{title}</p>
            <div>
                <div className={"flex flex-row items-center"}>
                    <NotificationsIcon className={"mr-2"} onClick={handleClick}/>
                    <div className={`bg-[#353535] p-2 rounded-lg`}>
                        <select value={market[viewName]}
                                className="bg-[#353535] text-white block w-full outline-none"
                                onChange={(market) => changeMarket(market.target.value)}>
                            <option key={"spot"} value={"spot"}>{"Spot"}</option>
                            <option key={"futures"} value={"futures"}>{"Futures"}</option>
                        </select>
                    </div>
                </div>
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <div className={`bg-[#353535] py-3 px-5 border-2 border-[${color.accent}]`}>
                        {source === "BackTest" ?
                            <FormControlLabel control={<Checkbox style={{color: color.accent}}/>} label="Finish"
                                              className="text-white"
                                              checked={sounds?.Finish}
                                              onChange={() => setSound("Finish", sounds?.Finish)}/> :
                            <>
                                <FormControlLabel control={<Checkbox style={{color: color.accent}}/>}
                                                  className="text-white" label="Open Trade"
                                                  checked={sounds?.OpenTrade}
                                                  onChange={() => setSound("OpenTrade", sounds?.OpenTrade)}/>
                                <FormControlLabel control={<Checkbox style={{color: color.accent}}/>}
                                                  className="text-white" label="Close Trade"
                                                  checked={sounds?.CloseTrade}
                                                  onChange={() => setSound("CloseTrade", sounds?.CloseTrade)}/>
                                <FormControlLabel control={<Checkbox style={{color: color.accent}}/>}
                                                  className="text-white" label="Stop Loss"
                                                  checked={sounds?.StopLossUpdate}
                                                  onChange={() => setSound("StopLossUpdate", sounds?.StopLossUpdate)}/>
                            </>
                        }
                    </div>
                </Popover>
            </div>
        </div>
    )
}

export default Header



