import React, {useEffect, useState} from "react"
import Navbar from "../components/Navbar"
import Card from "../components/Card"
import "./PricingApp.css"
import Footer from "../components/Footer"
import {data, pricing} from "./content"
import Header from "../components/features/Header"
import styles, {color} from "../../style"
import Translations from "../components/Translations";

function Pricing() {
    const [selectMonthly, setSelectMonthly] = useState(true)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <Navbar/>
            <div className="PricingApp">
                <Header title={<Translations category={"pricing"} id={1}/>} subtitle={<Translations category={"pricing"} id={2}/>}/>
                <div className={`${styles.maxWidth} px-5 flex flex-col justify-center`}>
                    {/*<div className={`mt-16 ${styles.flexCenter} gap-2 text-white`}>*/}
                    {/*    <p><Translations category={"pricing"} id={3}/></p>*/}
                    {/*    <label className="block w-[50px] h-[25px] rounded-full relative cursor-pointer">*/}
                    {/*        <input*/}
                    {/*            className="price-checkbox outline-none"*/}
                    {/*            onChange={() => {*/}
                    {/*                setSelectMonthly((prev) => !prev)*/}
                    {/*            }}*/}
                    {/*            checked={selectMonthly}*/}
                    {/*            type="checkbox"*/}
                    {/*        />*/}
                    {/*        <div className="switch-slider"></div>*/}
                    {/*    </label>*/}
                    {/*    <p><Translations category={"pricing"} id={4}/></p>*/}
                    {/*</div>*/}
                    {/*<div className='mt-10 lg:mt-20 grid md:grid-cols-2 xl:grid-cols-4 gap-4'>*/}
                    {/*    {pricing.map((feature) => {*/}
                    {/*        return (<Card price={selectMonthly ? feature.priceMonthly : feature.priceAnnually}*/}
                    {/*                      feature={feature} isMonthly={selectMonthly}/>)*/}
                    {/*    })}*/}
                    {/*</div>*/}
                    {/*<p className='text-xs text-center mt-5 text-white'>*/}
                    {/*    <Translations category={"pricing"} id={29}/>*/}
                    {/*</p>*/}
                    <div className={`${styles.maxWidth} mb-10 hidden lg:flex flex-col`}>
                        {/*<p className={`${styles.textSubheading} text-center py-10`}><Translations category={"pricing"} id={30}/></p>*/}
                        <div className={`min-w-full mt-20 border border-[${color.accent}] ${styles.shadow}`}>
                            <div className={`text-sm font-bold text-[${color.accent}] grid grid-cols-7`}>
                                <div className="px-6 py-3 text-center uppercase tracking-wider col-span-3"></div>
                                {/*<h2 className="px-6 py-3 text-center uppercase tracking-wider"><Translations category={"pricing"} id={5}/></h2>*/}
                                {/*<h2 className="px-6 py-3 text-center uppercase tracking-wider"><Translations category={"pricing"} id={11}/></h2>*/}
                                {/*<h2 className="px-6 py-3 text-center uppercase tracking-wider"><Translations category={"pricing"} id={18}/></h2>*/}
                                {/*<h2 className="px-6 py-3 text-center uppercase tracking-wider"><Translations category={"pricing"} id={23}/></h2>*/}
                            </div>
                            {data.map((item, index) => (
                                <div
                                    key={index}
                                    className={`${shouldIndentRow(item)} hover:bg-gray-700 hover:bg-opacity-75 text-white grid grid-cols-7`}
                                >
                                    <p className={`px-6 py-1 col-span-3 ${isCategory(item) ? 'pl-20 text-xs' : ''}`}>
                                            <span
                                                className={isCategory(item) ? 'text-sm' : `text-xl font-bold text-[${color.accent}]`}>{item.category}</span>
                                    </p>
                                    {/*<p className="px-6 py-1 text-center flex justify-center">{item.test}</p>*/}
                                    {/*<p className="px-6 py-1 text-center flex justify-center">{item.beginner}</p>*/}
                                    {/*<p className="px-6 py-1 text-center flex justify-center">{item.advanced}</p>*/}
                                    {/*<p className="px-6 py-1 text-center flex justify-center">{item.expert}</p>*/}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className={`${styles.maxWidth} mb-10 lg:hidden flex flex-col`}>
                        <p className={`${styles.textSubheading} text-center py-10`}><Translations category={"pricing"} id={30}/></p>
                        <div className={`min-w-full border-4 border-[${color.accent}] ${styles.shadow}`}>
                            <div className={`text-sm font-bold text-[${color.accent}] grid grid-cols-4`}>
                                <h2 className="pt-3 uppercase flex justify-center"><Translations category={"pricing"} id={5}/></h2>
                                <h2 className="pt-3 uppercase flex justify-center"><Translations category={"pricing"} id={11}/></h2>
                                <h2 className="pt-3 uppercase flex justify-center"><Translations category={"pricing"} id={18}/></h2>
                                <h2 className="pt-3 uppercase flex justify-center"><Translations category={"pricing"} id={23}/></h2>
                            </div>
                            {data.map((item, index) => (
                                <div key={index}
                                     className={`${shouldIndentRow(item)} hover:bg-gray-700 hover:bg-opacity-75 text-white`}>
                                    <span
                                        className={isCategory(item) ? 'text-sm' : `text-xl font-bold text-[${color.accent}] flex justify-center pt-5`}>{item.category}</span>
                                    <div
                                        className={`text-sm font-bold grid grid-cols-4 ${item.expert ? "pt-3 pb-5" : "py-0"}`}>
                                        <p className="uppercase flex justify-center">{item.test}</p>
                                        <p className="uppercase flex justify-center">{item.beginner}</p>
                                        <p className="uppercase flex justify-center">{item.advanced}</p>
                                        <p className="uppercase flex justify-center">{item.expert}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
    )
}

function shouldIndentRow(item) {
    if (isCategory(item)) {
        return 'indented-row'
    }
    return ''
}

function isCategory(item) {
    return item.category && !item.test && !item.beginner && !item.advanced && !item.expert
}

export default Pricing