import React, {useEffect, useRef} from 'react'
import {createChart} from 'lightweight-charts'
import RepairTimesToBeUnique from "../UniqueRowsRepair";

const Chart = ({chartConfig}) => {
    const containerRef = useRef(null)
    const chartRef = useRef(null)
    const seriesRef = useRef(null)
    const updateChart = () => {
        if (!chartRef.current) {
            return
        }

        chartRef.current.resize(containerRef.current.clientWidth, 300)
        chartRef.current.timeScale().fitContent()

        if (!seriesRef.current) {
            const series = chartRef.current.addAreaSeries({
                topColor: 'rgb(16,166,206)',
                bottomColor: 'rgb(53,53,53)',
                lineColor: 'rgb(16,166,206)',
                priceLineVisible: false,
                lineWidth: 3,
            })
            seriesRef.current = series
        }

        seriesRef.current.setData([])
        const transformedData = chartConfig.data.length > 0
            ? chartConfig.data.map(item => ({
                time: new Date(item[chartConfig.axisY]).getTime(),
                value: item[chartConfig.axisX],
            }))
            : [
                {time: new Date().getTime(), value: 0},
                {time: new Date().getTime() + 1000, value: 0},
            ]
        const repairedAndSortedData = RepairTimesToBeUnique(transformedData.sort((a, b) => a.time - b.time));
        seriesRef.current.setData(repairedAndSortedData)
    }

    useEffect(() => {
        const container = document.createElement('div')
        containerRef.current.appendChild(container)
        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const userLanguage = navigator.language || navigator.userLanguage

        const dateFormatter = (time, tickMarkType, locale) => {
            const date = new Date(time)
            const dt = date.toLocaleString(userLanguage, {
                timeZone: userTimeZone,
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
            })
            return dt
        }

        chartRef.current = createChart(container, {
            rightPriceScale: {
                scaleMargins: {
                    top: 0.2,
                    bottom: 0.2,
                },
                borderVisible: false,
                mode: 0,
                title: 'Cena',
            },
            timeScale: {
                borderVisible: false,
                borderColor: '#fff000',
                timeVisible: true,
                secondsVisible: false,
                tickMarkFormatter: dateFormatter,
                fitContent: true,
            },
            layout: {
                background: {
                    type: 'solid',
                    color: '#353535',
                },
                lineColor: '#353535',
                textColor: '#D9D9D9',
            },
            grid: {
                vertLines: {
                    color: '#353535',
                },
                horzLines: {
                    color: '#353535',
                },
            },
            crosshair: {
                vertLine: {
                    labelVisible: false,
                },
            },
        })

        chartRef.current.applyOptions({
            handleScroll: {
                mouseWheel: false,
                pressedMouseMove: false,
                horzTouchDrag: false,
                vertTouchDrag: false
            },
            handleScale: {
                axisPressedMouseMove: false,
                mouseWheel: false,
                pinch: false,
            },
        });
    }, [])

    useEffect(() => {
        updateChart();

        const resizeObserver = new ResizeObserver(() => {
            updateChart()
        })

        resizeObserver.observe(containerRef.current)

        return () => {
            resizeObserver.disconnect()
        }
    }, [chartConfig.data])

    return <div ref={containerRef}></div>
}

export default Chart
