import CreateIcon from "@mui/icons-material/Create";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Translations from "../Translations";
import React from "react";
export const feedback = [
    {
        id: "feedback-1",
        icon: <PersonIcon sx={{fontSize: 50}}/>,
        title: <Translations category={"menu"} id={9}/>,
        description: <Translations category={"backtests"} id={1}/>,
        link: "/backtests",
    },
    {
        id: "feedback-2",
        icon: <LogoutIcon sx={{fontSize: 50}}/>,
        title: <Translations category={"menu"} id={13}/>,
        description: <Translations category={"paperTrading"} id={1}/>,
        link: "/paper-trading",
    },
    {
        id: "feedback-3",
        icon: <SmartToyIcon sx={{fontSize: 50}}/>,
        title: <Translations category={"menu"} id={14}/>,
        description: <Translations category={"automateTrading"} id={1}/>,
        link: "/automate-trading",
    },
    {
        id: "feedback-4",
        icon: <PersonIcon sx={{fontSize: 50}}/>,
        title: <Translations category={"menu"} id={15}/>,
        description: <Translations category={"statistics"} id={1}/>,
        link: "/statistics",
    },
    {
        id: "feedback-5",
        icon: <CreateIcon sx={{fontSize: 50}}/>,
        title: <Translations category={"menu"} id={16}/>,
        description: <Translations category={"strategyCreator"} id={1}/>,
        link: "/strategy-creator",
    },
    {
        id: "feedback-6",
        icon: <PersonIcon sx={{fontSize: 50}}/>,
        title: <Translations category={"menu"} id={17}/>,
        description: <Translations category={"indicators"} id={1}/>,
        link: "/indicators",
    },
    {
        id: "feedback-7",
        icon: <DashboardIcon sx={{fontSize: 50}}/>,
        title: <Translations category={"menu"} id={18}/>,
        description: <Translations category={"trailingStop"} id={1}/>,
        link: "/trailing-stop",
    },
];