import React from 'react';
import {Pie} from 'react-chartjs-2';

const PieChart = ({formatter, config, labelBackground}) => {
    const options = {
        responsive: true,

        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    color: 'white',
                },
            },
            labels: {
                render: 'value',
                fontColor: ['green', 'white', 'red'],
            },
            tooltip: {
                enabled: false
            },
            datalabels: {
                color: 'white',
                anchor: 'end',
                align: 'start',
                offset: 0,
                font: {
                    size: 14, // Zmiana rozmiaru czcionki na datalabels
                },
                backgroundColor: labelBackground,
                borderRadius: 4,
                padding: {
                    top: 2,
                    right: 6,
                    bottom: 2,
                    left: 6,
                },
                formatter
            },
        },
    };

    return <Pie data={config} options={options}/>;
};

export default PieChart;
