import React from "react"
import styles, {color} from "../../style"
import {Link} from "react-router-dom"
import Translations from "./Translations"
import img10 from "../images/img6.svg";

const CTA = () => (
    <div className={`border-y border-[${color.accent}] ${styles.shadow} mb-5 lg:mb-16`}>
        <div className={`${styles.maxWidth}`}>
            <div className={`grid lg:grid-cols-3 items-center`}>
                <h2 className={`text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl col-span-2 text-center mx-10 mt-12 lg:mt-0 lg:text-left`}>
                    <Translations category={"landingPage"} id={15}/>
                </h2>
                <div className={`flex flex-row items-center justify-center`}>
                    <Link to="/app/dashboard">
                        <button className={`bg-[${color.accent}] ml-[100px] text-2xl w-[300px]`}>
                            <Translations category={"header"} id={3}/>
                        </button>
                    </Link>
                    <img src={img10} alt='/' className={`w-[100px] relative -left-16 pt-32`}/>
                </div>
            </div>
        </div>
    </div>
)

export default CTA
