import React, {useEffect, useState} from "react";
import {color} from "../../../style";


const SlideBox = ({content}) => {
    const [activePageIndex, setActivePageIndex] = useState(0)

    const nextPage = () => {
        if (activePageIndex < (content.length - 1)) {
            setActivePageIndex(activePageIndex + 1)
        } else {
            setActivePageIndex(0)
        }
    }

    useEffect(() => {
        const interval = setInterval(nextPage, 10000)
        return () => clearInterval(interval)
    }, [activePageIndex])

    const visibleFeedback = content.slice(activePageIndex, activePageIndex + 1)
    return (
        <div className={`flex flex-col w-full h-full justify-center col-span-1 items-center border border-[${color.accent}] p-5`}>
                {visibleFeedback.map((card, index) => (
                    <div key={index}>
                        <h2 className={`text-lg text-center`}>
                            {card.description}
                        </h2>
                    </div>
                ))}
            <div className="flex mt-5">
                {Array.from({length: content.length}, (_, i) => (
                    <div
                        key={i}
                        className={`w-2 h-2 mx-1 rounded-full cursor-pointer ${
                            i === activePageIndex ? 'bg-[#10a7d0]' : 'bg-gray-300'
                        }`}
                        onClick={() => setActivePageIndex(i)}
                    ></div>
                ))}
            </div>
        </div>
    );
};

export default SlideBox;
