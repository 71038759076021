import translationPL from "../../globals/assets/translations/translation.json";
import {useSelector} from "react-redux";
import {selectLanguage} from "../../application/components/redux/GlobalState";

const Translations = ({category, id}) => {
    const lang = useSelector(selectLanguage);
    const json = translationPL[category].find(a => {
        return a.id === id
    })

    return json[lang]
};

export default Translations;
