import React from "react"
import Template from "../../components/Template"
import img1 from "../../images/automatedtrading/att-basic.png"
import img2 from "../../images/automatedtrading/att-advanced.png"
import img7 from "../../images/backtests/strategy.png"
import img4 from "../../images/automatedtrading/add.png"
import automatetrading from "../../../gif/automatetrading.gif"
import img3 from "../../images/automatedtrading/activebots.png"
import img6 from "../../images/automatedtrading/statistics.png"
import img5 from "../../images/automatedtrading/trades.png"
import Translations from "../../../components/Translations"
import style from "../../../../style"

function AutomateTrading() {
    const Content = () => {
        return (
            <div>
                <div className={`mt-5 mb-5 text-yellow-500`}>
                    <Translations category={"docsAutomateTrading"} id={2}/>
                </div>
                <Translations category={"docsAutomateTrading"} id={1}/>
                <div className={`grid grid-cols-1 lg:grid-cols-4 gap-5 mb-5`}>
                    <div className={`col-span-1 mt-5 mb-5`}>
                        <img src={img1} alt='/' className={`${style.borderImg}`}/>
                    </div>
                    <div className={`col-span-3 mt-5 mb-5`}>
                        <Translations category={"docsAutomateTrading"} id={3}/>
                        <li><Translations category={"docsAutomateTrading"} id={4}/></li>
                        <li><Translations category={"docsAutomateTrading"} id={5}/></li>
                        <li><Translations category={"docsAutomateTrading"} id={6}/></li>
                        <li><Translations category={"docsAutomateTrading"} id={7}/></li>
                        <li><Translations category={"docsAutomateTrading"} id={8}/></li>
                        <li><Translations category={"docsAutomateTrading"} id={9}/></li>
                    </div>
                    <div className={`col-span-1`}>
                        <img src={img2} alt='/' className={`${style.borderImg} my-5`}/>
                    </div>
                    <div className={`col-span-3  mt-5 mb-5`}>
                        <Translations category={"docsAutomateTrading"} id={10}/>
                        <li><Translations category={"docsAutomateTrading"} id={11}/></li>
                        <li><Translations category={"docsAutomateTrading"} id={12}/></li>
                        <li><Translations category={"docsAutomateTrading"} id={13}/></li>
                        <li><Translations category={"docsAutomateTrading"} id={14}/></li>
                    </div>
                </div>
                <Translations category={"docsAutomateTrading"} id={15}/>
                <img src={img7} alt='/' className={`${style.borderImg} my-5`}/>
                <Translations category={"docsAutomateTrading"} id={16}/>
                <img src={img4} alt='/' className={`${style.borderImg} my-5`}/>
                <div className={"mt-5 mb-5 text-yellow-500"}><Translations category={"docsAutomateTrading"} id={17}/>
                </div>
                <div className={"mt-5 mb-5 text-yellow-500"}><Translations category={"docsAutomateTrading"} id={18}/>
                </div>
                <Translations category={"docsAutomateTrading"} id={19}/>
                <img src={automatetrading} alt='/' className={`${style.borderImg} my-5 w-[80%]`}/>
                <Translations category={"docsAutomateTrading"} id={20}/>
                <img src={img3} alt='/' className={`${style.borderImg} my-5`}/>
                <Translations category={"docsAutomateTrading"} id={21}/>
                <img src={img6} alt='/' className={`${style.borderImg} my-5`}/>
                <Translations category={"docsAutomateTrading"} id={22}/>
                <img src={img5} alt='/' className={`border border-gray-700 mt-5 mb-5`}/>
            </div>
        )
    }
    const config = {
        title: <Translations category={"docs"} id={13}/>,
        previousLink: '/docs/paper-trading',
        previousTitle: <Translations category={"docs"} id={12}/>,
        nextLink: '/docs/settings',
        nextTitle: <Translations category={"docs"} id={14}/>,
        content: Content(),
    }

    return (
        <Template config={config}/>
    )
}

export default AutomateTrading