import React, {useState} from "react"
import * as Components from './Components'
import ParticlesContainer from "../components/ParticlesContainer"
import Translations from "../components/Translations"
import styles, {color} from "../../style"
import axios from "axios"
import {SERVERURL} from "../../globals/Constants"
import encrypt from "../../globals/Cipher"
import {useAuth} from "../../globals/auth/AuthContext"
import {useNavigate} from "react-router-dom"
import {setMessage} from "../../application/components/redux/GlobalState"
import {useDispatch} from "react-redux"

function Signin() {
    const auth = useAuth()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [signIn, setSignIn] = useState(true)
    const [loginEmail, setLoginEmail] = useState('')
    const [loginPassword, setLoginPassword] = useState('')
    const [registerEmail, setRegisterEmail] = useState('')
    const [registerPassword, setRegisterPassword] = useState('')
    const handleChange = (event) => {
        if (event.target.id === "email") {
            setRegisterEmail(event.target.value)
        } else if (event.target.id === "password") {
            setRegisterPassword(event.target.value)
        } else if (event.target.id === "login_email") {
            setLoginEmail(event.target.value)
        } else if (event.target.id === "login_password") {
            setLoginPassword(event.target.value)
        }
    }

    const register = () => {
        const registerEntity = {email: registerEmail, password: encrypt(registerPassword, '!@#$%^&*.()')}
        axios.post(`${SERVERURL}/register`, registerEntity, {withCredentials: true}).then(_ => setSignIn(true)).catch(error => dispatch(setMessage({
            key: 'Alert',
            value: error.response.data.message
        })))
    }

    const login = () => {
        const loginEntity = {email: loginEmail.toLowerCase(), password: encrypt(loginPassword, '!@#$%^&*.()')}
        axios.post(`${SERVERURL}/login`, loginEntity, {withCredentials: true})
            .then(async result => {
                auth.authLogin(result.data.accessToken, result.data.refreshToken)
                navigate('/app/dashboard')
            })
            .catch(error => {
                dispatch(setMessage({key: 'Alert', value: error.response.data.message}))
            })
    }

    return (
        <div className={`${styles.backgroundMain}`}>
            <ParticlesContainer height={"100vh"}/>
            <div
                className={`${styles.flexCenter} w-full mx-auto text-center max-w-[1240px] grid md:grid-cols-3 m-auto`}>
                <div
                    className={`absolute inset-0 flex flex-col items-center justify-center left-0 right-0 mx-auto lg:w-2/3 lg:top-0 lg:left-0`}>
                    <Components.Container>
                        <Components.SignUpContainer signinIn={signIn}>
                            <div
                                className={`flex ${styles.backgroundBase} items-center justify-center flex-col text-center h-full`}>
                                <div className="h-1/5 flex justify-center items-center">
                                    <p className='text-2xl text-white font-bold'>
                                        <Translations category={"login"} id={9}/>
                                    </p>
                                </div>
                                <div className="h-2/5 flex flex-col justify-center items-center">
                                    <div className="relative bg-inherit mb-4">
                                        <p className="text-white flex justify-start text-lg">email</p>
                                        <input type="email" id="email" name="email" onChange={handleChange}
                                               placeholder="Email"
                                               className={`peer bg-transparent h-10 w-40 md:w-72 border-[${color.accent}] rounded-lg text-white placeholder-transparent px-2 outline-none`}/>
                                    </div>
                                    <div className="relative bg-inherit mb-4">
                                        <p className="text-white flex justify-start text-lg">password</p>
                                        <input type="password" id="password" name="password" onChange={handleChange}
                                               placeholder="Password"
                                               className={`peer bg-transparent h-10 w-40 md:w-72 border-[${color.accent}] rounded-lg text-white placeholder-transparent px-2 outline-none`}/>
                                    </div>
                                </div>
                                <div className="h-1/5 flex justify-center items-center">
                                    <button onClick={register}
                                            className={`text-xl bg-[${color.accent}] text-[#161d26] font-semibold rounded-md`}>
                                        <Translations category={"login"} id={8}/>
                                    </button>
                                </div>
                            </div>
                        </Components.SignUpContainer>
                        <Components.SignInContainer signinIn={signIn}>
                            <div
                                className={`flex ${styles.backgroundBase} items-center justify-center flex-col text-center h-full`}>
                                <div className="h-1/5 flex justify-center items-center">
                                    <p className='text-2xl text-white font-bold'>
                                        <Translations category={"login"} id={1}/>
                                    </p>
                                </div>
                                <div className="h-2/5 flex flex-col justify-center items-center">
                                    <div className="relative bg-inherit my-4">
                                        <p className="text-white flex justify-start text-lg">email</p>
                                        <input onChange={handleChange} type="email" id="login_email" name="username"
                                               className={`peer bg-transparent h-10 w-40 md:w-72 border-[${color.accent}] rounded-lg text-white placeholder-transparent px-2 outline-none`}
                                               placeholder="Email"/>
                                    </div>
                                    <div className="relative bg-inherit mb-4">
                                        <p className="text-white flex justify-start text-lg">password</p>
                                        <input onChange={handleChange} type="password" id="login_password"
                                               name="username"
                                               className={`peer bg-transparent h-10 w-40 md:w-72 border-[${color.accent}] rounded-lg text-white placeholder-transparent px-2 outline-none`}
                                               placeholder="Password"/>
                                    </div>
                                </div>
                                <div className="h-1/5 flex justify-center items-center">
                                    <button onClick={login}
                                            className={`text-xl bg-[${color.accent}] text-[#161d26] font-semibold rounded-md`}>
                                        <Translations category={"login"} id={1}/>
                                    </button>
                                </div>
                                <a href='/' className='text-xs absolute bottom-2 text-gray-400'>
                                    <Translations category={"login"} id={5}/>
                                </a>
                            </div>
                        </Components.SignInContainer>
                        <Components.OverlayContainer signinIn={signIn}>
                            <Components.Overlay signinIn={signIn}>
                                <Components.LeftOverlayPanel signinIn={signIn}>
                                    <div className="h-1/5 flex justify-center items-center">
                                        <p className='text-2xl text-[#f3f2ee] font-bold'>
                                            <Translations category={"login"} id={10}/>
                                        </p>
                                    </div>
                                    <div className="h-2/5 flex flex-col justify-center items-center">
                                        <p className='text-lg text-[#f3f2ee] my-10'>
                                            <Translations category={"login"} id={11}/>
                                        </p>
                                    </div>
                                    <div className="h-1/5 flex justify-center items-center">
                                        <button onClick={() => setSignIn(true)}
                                                className={`text-xl bg-[${color.accent}] text-[#29394b] font-semibold rounded-md`}>
                                            <Translations category={"login"} id={1}/>
                                        </button>
                                    </div>
                                </Components.LeftOverlayPanel>
                                <Components.RightOverlayPanel signinIn={signIn}>
                                    <div className="h-1/5 flex justify-center items-center">
                                        <p className='text-2xl text-[#f3f2ee] font-bold'>
                                            <Translations category={"login"} id={6}/>
                                        </p>
                                    </div>
                                    <div className="h-2/5 flex flex-col justify-center items-center">
                                        <p className='text-lg text-[#f3f2ee] my-10'>
                                            <Translations category={"login"} id={7}/>
                                        </p>
                                    </div>
                                    <div className="h-1/5 flex justify-center items-center">
                                        <button onClick={() => setSignIn(false)}
                                                className={`text-xl bg-[${color.accent}] text-[#29394b] font-semibold rounded-md`}>
                                            <Translations category={"login"} id={8}/>
                                        </button>
                                    </div>
                                </Components.RightOverlayPanel>
                            </Components.Overlay>
                        </Components.OverlayContainer>
                    </Components.Container>
                </div>
            </div>
        </div>
    )
}

export default Signin