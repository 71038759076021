import BuildIcon from "@mui/icons-material/Build"
import React from "react"
import InstallDesktopIcon from "@mui/icons-material/InstallDesktop"
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo"
import CableIcon from "@mui/icons-material/Cable"
import DashboardIcon from "@mui/icons-material/Dashboard"
import CreateIcon from "@mui/icons-material/Create"
import WatchLaterIcon from "@mui/icons-material/WatchLater"
import DescriptionIcon from "@mui/icons-material/Description"
import SmartToyIcon from "@mui/icons-material/SmartToy"
import SettingsIcon from "@mui/icons-material/Settings"
import CandlestickChartIcon from "@mui/icons-material/CandlestickChart"
import PercentIcon from "@mui/icons-material/Percent"
import NoteIcon from "@mui/icons-material/Note"
import MessageIcon from "@mui/icons-material/Message"
import NotificationsIcon from "@mui/icons-material/Notifications"
import ShieldIcon from '@mui/icons-material/Shield'
import Translations from "../../components/Translations"

export const menu = [
    {
        category: <Translations category={"docs"} id={1}/>, url: '',
        subpages: [
            {
                name: <Translations category={"docs"} id={2}/>,
                url: '/docs/system-requirements',
                icon: <BuildIcon sx={{fontSize: 30}}/>
            },
            {
                name: <Translations category={"docs"} id={3}/>,
                url: '/docs/installation',
                icon: <InstallDesktopIcon sx={{fontSize: 30}}/>
            },
            {
                name: <Translations category={"docs"} id={4}/>,
                url: '/docs/first-run',
                icon: <OndemandVideoIcon sx={{fontSize: 30}}/>
            },
            {
                name: <Translations category={"docs"} id={5}/>,
                url: '/docs/how-to-connect',
                icon: <CableIcon sx={{fontSize: 30}}/>
            },
            {
                name: <Translations category={"docs"} id={6}/>,
                url: '/docs/security',
                icon: <ShieldIcon sx={{fontSize: 30}}/>
            }
        ]
    },
    {
        category: <Translations category={"docs"} id={7}/>, url: '',
        subpages: [
            {
                name: <Translations category={"docs"} id={8}/>,
                url: '/docs/dashboard',
                icon: <DashboardIcon sx={{fontSize: 30}}/>
            },
            {
                name: <Translations category={"docs"} id={9}/>,
                url: '/docs/strategies',
                icon: <CreateIcon sx={{fontSize: 30}}/>
            },
            {
                name: <Translations category={"docs"} id={10}/>,
                url: '/docs/strategy-creator',
                icon: <CreateIcon sx={{fontSize: 30}}/>
            },
            {
                name: <Translations category={"docs"} id={11}/>,
                url: '/docs/backtests',
                icon: <WatchLaterIcon sx={{fontSize: 30}}/>
            },
            {
                name: <Translations category={"docs"} id={12}/>,
                url: '/docs/paper-trading',
                icon: <DescriptionIcon sx={{fontSize: 30}}/>
            },
            {
                name: <Translations category={"docs"} id={13}/>,
                url: '/docs/automate-trading',
                icon: <SmartToyIcon sx={{fontSize: 30}}/>
            },
            {
                name: <Translations category={"docs"} id={14}/>,
                url: '/docs/settings',
                icon: <SettingsIcon sx={{fontSize: 30}}/>
            },
            {
                name: <Translations category={"docs"} id={15}/>,
                url: '/docs/chart',
                icon: <CandlestickChartIcon sx={{fontSize: 30}}/>
            },
            {
                name: <Translations category={"docs"} id={16}/>,
                url: '/docs/monitoring',
                icon: <PercentIcon sx={{fontSize: 30}}/>
            },
            {
                name: <Translations category={"docs"} id={17}/>,
                url: '/docs/notes',
                icon: <NoteIcon sx={{fontSize: 30}}/>
            },
            {
                name: <Translations category={"docs"} id={18}/>,
                url: '/docs/text-alerts',
                icon: <MessageIcon sx={{fontSize: 30}}/>
            },
            {
                name: <Translations category={"docs"} id={19}/>,
                url: '/docs/sound-alerts',
                icon: <NotificationsIcon sx={{fontSize: 30}}/>
            }
        ]
    }, {
        category: <Translations category={"docs"} id={20}/>, url: '',
        subpages: [
            {
                name: <Translations category={"docs"} id={21}/>,
                url: '/docs/relative-strength-index',
                icon: <p className={`text-base font-semibold`}>RSI</p>
            },
            {
                name: <Translations category={"docs"} id={22}/>,
                url: '/docs/average-candle-length',
                icon: <p className={`text-base font-semibold`}>ACL</p>
            },
            {
                name: <Translations category={"docs"} id={23}/>,
                url: '/docs/average-volume',
                icon: <p className={`text-base font-semibold`}>AV</p>
            },
            {
                name: <Translations category={"docs"} id={24}/>,
                url: '/docs/pump-average-volume',
                icon: <p className={`text-base font-semibold`}>PAV</p>
            },
            {
                name: <Translations category={"docs"} id={25}/>,
                url: '/docs/exponential-moving-average',
                icon: <p className={`text-base font-semibold`}>EMA</p>
            },
            {
                name: <Translations category={"docs"} id={26}/>,
                url: '/docs/exponential-moving-average-crossover',
                icon: <p className={`text-base font-semibold`}>EMA cross</p>
            },
            {
                name: <Translations category={"docs"} id={27}/>,
                url: '/docs/historical-min-max',
                icon: <p className={`text-base font-semibold`}>HMM</p>
            },
            {
                name: <Translations category={"docs"} id={28}/>,
                url: '/docs/percentage-price-changes',
                icon: <p className={`text-base font-semibold`}>PPC</p>
            },
            {
                name: <Translations category={"docs"} id={29}/>,
                url: '/docs/moving-average-convergence-divergence',
                icon: <p className={`text-base font-semibold`}>MACD</p>
            },
        ]
    }, {
        category: <Translations category={"docs"} id={30}/>, url: '',
        subpages: [
            {
                name: 'Stop loss',
                url: '/docs/percentage-stop-loss',
                icon: <p className={`text-base font-semibold`}>SL</p>
            },
            {
                name: 'Take profit',
                url: '/docs/percentage-take-profit',
                icon: <p className={`text-base font-semibold`}>TP</p>
            },
            {
                name: <Translations category={"docs"} id={31}/>,
                url: '/docs/trailing-stop-loss',
                icon: <p className={`text-base font-semibold`}>TSL</p>
            },
            {
                name: <Translations category={"docs"} id={32}/>,
                url: '/docs/time-trailing-stop-loss',
                icon: <p className={`text-base font-semibold`}>TTSL</p>
            },
            {
                name: <Translations category={"docs"} id={33}/>,
                url: '/docs/time-stop-loss',
                icon: <p className={`text-base font-semibold`}>TMSL</p>
            },
        ]
    }
];