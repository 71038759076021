import React from "react";

const TextFieldBox = ({title, placeholder, def, onChangeCallback}) => {
    return (
        <>
            <p className="col-span-1 text-base">{title}</p>
            <input type="text"
                   maxLength="13"
                   value={def ?? placeholder}
                   placeholder={placeholder}
                   onChange={onChangeCallback}
                   className="col-span-2 bg-[#1d1d1d] text-lg rounded border-0 text-right py-0 px-1 w-full outline-none"/>
        </>
    );
};

export default TextFieldBox;