import React, {useEffect, useState} from 'react'
import ParticlesContainer from "./ParticlesContainer"
import Translations from "./Translations"
import styles, {color} from "../../style"
import {Link} from "react-router-dom";

const Header = () => {
    const [isLowHeight, setIsLowHeight] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            const windowHeight = window.innerHeight
            setIsLowHeight(windowHeight < 650)
        };
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        };
    }, []);

    return (
        <div
            className={`${styles.backgroundMain} text-white lg:snap-start flex-shrink-0 -top-14 relative overflow-hidden border-b border-[${color.accent}]`}>
            <ParticlesContainer height={isLowHeight ? "700px" : "100vh"}/>
            <div
                className={`absolute inset-0 flex flex-col left-0 items-center justify-center right-0 mx-auto lg:w-2/3 lg:left-0`}>
                <h1 className={`text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl mb-12 font-bold text-[#f3f2ee] md:mb-10 text-center bg-[${color.main}] bg-opacity-90 w-fit pb-12 border-b-4 border-[${color.accent}]`}>
                    <Translations category={"header"} id={1}/>
                </h1>
                <h2 className={`${styles.textSubheading} text-[#f3f2ee] text-center bg-[${color.main}] bg-opacity-90`}>
                    <Translations category={"header"} id={4}/>
                </h2>
                <div className={`flex flex-col lg:flex-row items-center justify-center lg:mt-12 gap-5`}>
                    <a href={"/update/CryptoDeputyInstall.exe"} download>
                        <button className={`bg-[${color.accent}] w-[300px] text-2xl`}>
                            <Translations category={"header"} id={5}/>
                        </button>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Header
