import React from "react"
import Template from "../../components/Template"
import img1 from "../../images/papertrading/ppt-basic.png"
import img2 from "../../images/papertrading/ppt-advanced.png"
import img3 from "../../images/papertrading/activebots.png"
import img4 from "../../images/papertrading/add.png"
import papertrading from "../../../gif/papertrading.gif"
import img5 from "../../images/papertrading/trades.png"
import img7 from "../../images/backtests/strategy.png"
import img6 from "../../images/papertrading/statistics.png"
import Translations from "../../../components/Translations"
import style from "../../../../style"

function PaperTrading() {
    const Content = () => {
        return (
            <div>
                <div className={`mt-5 mb-5 text-yellow-500`}>
                    <Translations category={"docsPaperTrading"} id={1}/>
                </div>
                <Translations category={"docsPaperTrading"} id={2}/>
                <div className={`grid grid-cols-1 lg:grid-cols-4 gap-5 mb-5`}>
                    <div className={`col-span-1 mt-5 mb-5`}>
                        <img src={img1} alt='/' className={`${style.borderImg}`}/>
                    </div>
                    <div className={`col-span-3 mt-5 mb-5`}>
                        <Translations category={"docsPaperTrading"} id={3}/>
                        <li><Translations category={"docsPaperTrading"} id={4}/></li>
                        <li><Translations category={"docsPaperTrading"} id={5}/></li>
                        <li><Translations category={"docsPaperTrading"} id={6}/></li>
                        <li><Translations category={"docsPaperTrading"} id={7}/></li>
                        <li><Translations category={"docsPaperTrading"} id={8}/></li>
                        <li><Translations category={"docsPaperTrading"} id={9}/></li>
                    </div>
                    <div className={`col-span-1`}>
                        <img src={img2} alt='/' className={`${style.borderImg} my-5`}/>
                    </div>
                    <div className={`col-span-3  mt-5 mb-5`}>
                        <Translations category={"docsPaperTrading"} id={10}/>
                        <li><Translations category={"docsPaperTrading"} id={11}/></li>
                        <li><Translations category={"docsPaperTrading"} id={12}/></li>
                        <li><Translations category={"docsPaperTrading"} id={13}/></li>
                        <li><Translations category={"docsPaperTrading"} id={14}/></li>
                    </div>
                </div>
                <Translations category={"docsPaperTrading"} id={15}/>
                <img src={img7} alt='/' className={`${style.borderImg} my-5`}/>
                <Translations category={"docsPaperTrading"} id={16}/>
                <img src={img4} alt='/' className={`${style.borderImg} my-5`}/>
                <div className={"mt-5 mb-5 text-yellow-500"}><Translations category={"docsPaperTrading"} id={17}/></div>
                <div className={"mt-5 mb-5 text-yellow-500"}><Translations category={"docsPaperTrading"} id={18}/></div>
                <Translations category={"docsPaperTrading"} id={19}/>
                <img src={papertrading} alt='/' className={`${style.borderImg} my-5 w-[80%]`}/>
                <Translations category={"docsPaperTrading"} id={20}/>
                <img src={img3} alt='/' className={`${style.borderImg} my-5`}/>
                <Translations category={"docsPaperTrading"} id={21}/>
                <img src={img6} alt='/' className={`${style.borderImg} my-5`}/>
                <Translations category={"docsPaperTrading"} id={22}/>
                <img src={img5} alt='/' className={`${style.borderImg} my-5`}/>
            </div>
        )
    }
    const config = {
        title: <Translations category={"docs"} id={12}/>,
        previousLink: '/docs/backtests',
        previousTitle: <Translations category={"docs"} id={11}/>,
        nextLink: '/docs/automate-trading',
        nextTitle: <Translations category={"docs"} id={13}/>,
        content: Content(),
    }

    return (
        <Template config={config}/>
    )
}

export default PaperTrading