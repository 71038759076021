import React, {useState} from 'react'
import HelpIcon from "@mui/icons-material/Help"
import styles, {color} from "../../style"
import {Popover} from "@mui/material";

const MainTablePopup = ({strategy, size = null, breakAll = true}) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <div>
            <HelpIcon className={"mr-2 cursor-pointer"} sx={{fontSize: size}} onClick={handleClick}/>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={`bg-[#353535] p-5 text-white border-2 border-[${color.accent}] max-w-[800px]`}>
                    <span className={`${styles.flexCenter} text-lg ${breakAll ? "break-all" : ""}`}>
                        {strategy}
                    </span>
                </div>
            </Popover>
        </div>
    )
}
export default MainTablePopup