import React, {useState} from 'react'
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai'
import {Link} from "react-router-dom"
import NavLinks from "./NavLinks"
import LanguageSwitcher from "./LanguageSwitcher"
import Translations from "./Translations"
import styles, {color} from "../../style"
import Cookies from "../../globals/cookies/Cookies"
import {useAuth} from "../../globals/auth/AuthContext"

const Navbar = () => {
    const [nav, setNav] = useState(false)

    const handleNav = () => {
        setNav(!nav)
    }

    const mobileMenu = [
        {link: "/backtests", category: "menu", id: 9},
        {link: "/paper-trading", category: "menu", id: 13},
        {link: "/automate-trading", category: "menu", id: 14},
        {link: "/statistics", category: "menu", id: 15},
        {link: "/strategy-creator", category: "menu", id: 16},
        {link: "/indicators", category: "menu", id: 17},
        {link: "/trailing-stop", category: "menu", id: 18},
        {link: "/pricing", category: "menu", id: 1},
        {link: "/about", category: "menu", id: 7},
        // {link: "/terms", category: "menu", id: 11},
        {link: "/contact", category: "menu", id: 12},
        {link: "/docs", category: "menu", id: 19},
    ]
    const cookieAccTime = Cookies.getCookie("accTime")
    const auth = useAuth()
    return (
        <div className={`${styles.backgroundMain} z-[1100] sticky top-0 border-b border-[${color.accent}]`}>
            <div className={`${styles.rowBetween} ${window.location.pathname.includes("docs") ? `` : "max-w-[1536px] mx-auto"} px-5 items-center h-15`}>
                <a href="/" className="text-4xl my-2 sm:ml-0 sm:my-0 sm:text-3xl tracking-tighter">crypto<span
                    className={`text-[#10a7d0] font-bold`}>deputy</span>.</a>
                <div className='flex'>
                    <ul className='hidden md:flex'>
                        <li className='p-4 text-xl text-[#f3f2ee] tracking-tighter cursor-pointer'><Link
                            to="/pricing"><Translations category={"menu"} id={1}/></Link></li>
                    </ul>
                    <div className='hidden md:flex'>
                        <NavLinks/>
                    </div>
                </div>
                <ul className='hidden md:flex'>
                    {
                        (!cookieAccTime) || cookieAccTime <= new Date().getTime() ?
                            <li className='p-4 text-xl text-[#f3f2ee] tracking-tighter cursor-pointer'>
                                <Link to="/signin"><
                                    Translations category={"login"} id={1}/>
                                </Link>
                            </li> :
                            <>
                                <li className='p-4 text-xl text-[#f3f2ee] tracking-tighter cursor-pointer'>
                                    <Link to="/app/welcome">
                                        <Translations category={"login"} id={13}/>
                                    </Link>
                                </li>
                                <li className='p-4 text-xl text-[#f3f2ee] tracking-tighter cursor-pointer'
                                    onClick={() => {
                                        auth.authLogout()
                                    }}>
                                    <Link to="/signin">
                                        <Translations category={"login"} id={12}/>
                                    </Link>
                                </li>
                            </>
                    }
                    <li><LanguageSwitcher/></li>
                </ul>
                <div onClick={handleNav} className='block md:hidden'>
                    <AiOutlineMenu size={35}/>
                </div>
                <ul className={`fixed left-0 top-0 w-full ${nav ? `${styles.backgroundMain} ease-in-out duration-500 h-full` : 'ease-in-out duration-500 fixed left-[-150%] h-full'}`}>
                    <div className={`w-full justify-between m-3 flex h-15 items-center`}>
                        {
                            (!cookieAccTime) || cookieAccTime <= new Date().getTime() ?
                                <div
                                    className={`bg-[${color.accent}] w-32 text-2xl rounded text-center mt-1 ml-3`}>
                                    <Link to="/signin"><
                                        Translations category={"login"} id={1}/>
                                    </Link>
                                </div> :
                                <div className={`flex flex-row`}>
                                    <div
                                        className={`bg-[${color.accent}] w-32 text-2xl rounded text-center mt-1 ml-3`}
                                        onClick={() => {
                                            auth.authLogout()
                                        }}>
                                        <Link to="/signin">
                                            <Translations category={"login"} id={12}/>
                                        </Link>
                                    </div>
                                    <div
                                        className={`border-2 border-[${color.accent}] w-32 text-2xl rounded text-center mt-1 ml-3`}>
                                        <Link to="/app/welcome">
                                            <Translations category={"login"} id={13}/>
                                        </Link>
                                    </div>
                                </div>
                        }
                        <div onClick={handleNav} className='block md:hidden mr-6'><AiOutlineClose size={35}/></div>
                    </div>
                    <div className={`mx-5 mt-16`}>
                        {mobileMenu.map((link, index) => (
                            <div key={index}
                                 className={`my-3 ${styles.blueLineLeft} ${link.link === "/pricing" && "mt-16"}`}>
                                <Link to={link.link}><Translations category={link.category} id={link.id}/></Link>
                            </div>
                        ))}
                    </div>
                    <div className="mb-4 absolute bottom-0"><LanguageSwitcher/></div>
                </ul>
            </div>
        </div>
    )
}

export default Navbar
