export function getLastConfigurationDefaults() {
    return [{
        id: 1,
        strategyId: null,
        strategyName: null,
        symbol: "BTCUSDT",
        interval: "1d",
        leverage: 1,
        balance: 1000,
        maxBet: 100,
        from: "2024-01-01",
        to: "2024-01-01",
        stopLossInterval: "1m",
        realTimeEntry: false,
        fullBalance: false,
        oneTrade: false,
        mute: "1m",
        time: null,
        market: "futures",
        view: "BackTest",
    }, {
        id: 2,
        strategyId: null,
        strategyName: null,
        symbol: "BTCUSDT",
        interval: "1d",
        leverage: 1,
        balance: 1000,
        maxBet: 100,
        from: "2024-01-01",
        to: "2024-01-01",
        stopLossInterval: "1m",
        realTimeEntry: false,
        fullBalance: false,
        oneTrade: false,
        mute: "1m",
        time: null,
        market: "futures",
        view: "PaperTrading",
    }, {
        id: 3,
        strategyId: null,
        strategyName: null,
        symbol: "BTCUSDT",
        interval: "1d",
        leverage: 1,
        balance: 1000,
        maxBet: 100,
        from: "2024-01-01",
        to: "2024-01-01",
        stopLossInterval: "1m",
        realTimeEntry: false,
        fullBalance: false,
        oneTrade: false,
        mute: "1m",
        time: null,
        market: "futures",
        view: "AutomateTrading",
    }, {
        id: 4,
        strategyId: null,
        strategyName: null,
        symbol: "BTCUSDT",
        interval: "1d",
        leverage: 1,
        balance: 1000,
        maxBet: 100,
        from: "2024-01-01",
        to: "2024-01-01",
        stopLossInterval: "1m",
        realTimeEntry: false,
        fullBalance: false,
        oneTrade: false,
        mute: "1m",
        time: null,
        market: "spot",
        view: "BackTest",
    }, {
        id: 5,
        strategyId: null,
        strategyName: null,
        symbol: "BTCUSDT",
        interval: "1d",
        leverage: 1,
        balance: 1000,
        maxBet: 100,
        from: "2024-01-01",
        to: "2024-01-01",
        stopLossInterval: "1m",
        realTimeEntry: false,
        fullBalance: false,
        oneTrade: false,
        mute: "1m",
        time: null,
        market: "spot",
        view: "PaperTrading",
    }, {
        id: 6,
        strategyId: null,
        strategyName: null,
        symbol: "BTCUSDT",
        interval: "1d",
        leverage: 1,
        balance: 1000,
        maxBet: 100,
        from: "2024-01-01",
        to: "2024-01-01",
        stopLossInterval: "1m",
        realTimeEntry: false,
        fullBalance: false,
        oneTrade: false,
        mute: "1m",
        time: null,
        market: "spot",
        view: "AutomateTrading",
    }];
}