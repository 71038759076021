import styles from "../../../style";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import Chart from "../charts/Chart";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {BASEURL} from "../../../globals/Constants";
import {setMessage} from "../redux/GlobalState";
import {useDispatch} from "react-redux";
import {useWebSocket} from "../../../globals/socket/Socket";
import {isLocalhost} from "../../../globals/auth/IsLocalhost";
import full_stats_pt_both from "../../mocks/full_stats_pt_both.json";
import full_stats_at from "../../mocks/full_stats_at.json";
import full_stats_bt_rsi from "../../mocks/full_stats_bt_rsi.json";
import EventEmitter from "../EventEmitter";
import full_stats_pt_mana from "../../mocks/full_stats_pt_mana.json";
import full_stats_pt_ordi from "../../mocks/full_stats_pt_ordi.json";
import full_stats_bt_emac from "../../mocks/full_stats_bt_emac.json";
import full_stats_bt_minmax from "../../mocks/full_stats_bt_minmax.json";

const Balance = ({source, market}) => {
    const dispatch = useDispatch()
    const [editBalance, setEditBalance] = useState(false)
    const [fullTrades, setFullTrades] = useState([])
    const [balance, setBalance] = useState(0)
    const ws = useWebSocket()

    const chartConfig = {
        data: fullTrades.filter(a => !a.isOpen),
        axisY: source === "BackTest" ? 'dateTo' : 'closeTime',
        axisX: 'balance',
    }

    useEffect(() => {
        if (isLocalhost()) {
            if (source !== "BackTest") {
                axios.get(`${BASEURL}/balance?market=${market}`).then((res) => source === "AutomateTrading" ? setBalance(res.data.automatedTrading) : setBalance(res.data.paperTrading)).catch(_ => _)
            }
        } else {
            demoInitData()
            demoEventConsumer();
        }
    }, [market])

    useEffect(() => {
        if (ws) {
            let data = JSON.parse(ws)
            if (data.key === "FullStats" && data.source === source) {
                if (data.source === source) {
                    setFullTrades(JSON.parse(data.value).aggregatedTrades)
                }
                if (data.source === "BackTest") {
                    setBalance(JSON.parse(data.value).strategy.endingBalance ?? 0)
                }
            } else if (data.key === "BalanceChanged" && data.source === source) {
                const event = data.value.split(":")
                if (event[0] === market) setBalance(event[1])
            }
        }
    }, [ws])

    function demoEventConsumer() {
        const callback = (id) => chooseDemoData(id);
        EventEmitter.subscribe('demoFullStats', callback)
        return () => {
            EventEmitter.unsubscribe('demoFullStats', callback);
        }
    }

    function demoInitData() {
        if (source === "PaperTrading") {
            setFullTrades(full_stats_pt_both.aggregatedTrades)
            setBalance(full_stats_pt_both.aggregatedTrades[full_stats_pt_both.aggregatedTrades.length - 1].balance)
        } else if (source === "AutomateTrading") {
            setFullTrades(full_stats_at.aggregatedTrades)
            setBalance(full_stats_at.aggregatedTrades[full_stats_at.aggregatedTrades.length - 1].balance)
        } else if (source === "BackTest") {
            setFullTrades(full_stats_bt_rsi.aggregatedTrades)
            setBalance(full_stats_bt_rsi.aggregatedTrades[full_stats_bt_rsi.aggregatedTrades.length - 1].balance)
        }
    }

    function chooseDemoData(id) {
        if (id === 342) {
            setFullTrades(full_stats_at.aggregatedTrades)
            setBalance(full_stats_at.aggregatedTrades[full_stats_at.aggregatedTrades.length - 1].balance)
        } else if (id === 343) {
            setFullTrades(full_stats_pt_mana.aggregatedTrades)
            setBalance(full_stats_pt_mana.aggregatedTrades[full_stats_pt_mana.aggregatedTrades.length - 1].balance)
        } else if (id === 344) {
            setFullTrades(full_stats_pt_ordi.aggregatedTrades)
            setBalance(full_stats_pt_ordi.aggregatedTrades[full_stats_pt_ordi.aggregatedTrades.length - 1].balance)
        } else if (id === 347) {
            setFullTrades(full_stats_bt_emac.aggregatedTrades)
            setBalance(full_stats_bt_emac.aggregatedTrades[full_stats_bt_emac.aggregatedTrades.length - 1].balance)
        } else if (id === 348) {
            setFullTrades(full_stats_bt_minmax.aggregatedTrades)
            setBalance(full_stats_bt_minmax.aggregatedTrades[full_stats_bt_minmax.aggregatedTrades.length - 1].balance)
        } else if (id === 349) {
            setFullTrades(full_stats_bt_rsi.aggregatedTrades)
            setBalance(full_stats_bt_rsi.aggregatedTrades[full_stats_bt_rsi.aggregatedTrades.length - 1].balance)
        }
    }


    function changeBalance(balance) {
        if (isLocalhost()) {
            axios.get(`${BASEURL}/balance?market=${market}&balance=${balance}`).then((res) => dispatch(setMessage({
                key: 'Info',
                value: res.data
            }))).catch(_ => _)
        } else {
            dispatch(setMessage({
                key: 'Info',
                value: "Papertrading balance changed to " + balance + "$"
            }))
        }
    }

    return (
        <div className="col-span-2 lg:col-span-6 2xl:col-span-3 flex flex-col">
            <div className={`${styles.rowBetween} items-center justify-between mb-4`}>
                <span className="text-2xl">Account Balance</span>
                <div className="flex flex-row items-center gap-2">
                    {source === "PaperTrading" ? editBalance ? <> <input type="text"
                                                                         value={balance}
                                                                         onChange={(e) => setBalance(e.target.value)}
                                                                         className="col-span-2 bg-[#1d1d1d] text-2xl m-0 rounded border-1 text-right py-0 w-[150px] outline-none"/>
                            <p>$</p>
                            <SaveIcon className="cursor-pointer"
                                      onClick={() => {
                                          setEditBalance(false)
                                          changeBalance(balance)
                                      }}/></> : <><p>{balance} $</p>
                            <EditIcon className="cursor-pointer" onClick={() => setEditBalance(true)}/></> :
                        <p>{balance}$</p>}
                </div>
            </div>
            <div id="chart" className="flex-grow bg-[#353535] p-3 rounded-lg">
                {<Chart chartConfig={chartConfig}/>}
            </div>
        </div>
    )
}

export default Balance



