import React from "react"
import Template from "../../components/Template"
import img1 from "../../images/dashboard/dashboard.png"
import Translations from "../../../components/Translations"
import style from "../../../../style"

function Dashboard() {
    const Content = () => {
        return (
            <div className={`flex flex-col gap-5 text-lg`}>
                <p className={`text-lg`}><Translations category={"docsDashboard"} id={1}/></p>
                <img src={img1} alt='/' className={`${style.borderImg}`}></img>
                <p className={`text-lg`}><Translations category={"docsDashboard"} id={2}/></p>
                <div className={`text-lg`}>
                    <Translations category={"docsDashboard"} id={3}/>
                    <li><Translations category={"docsDashboard"} id={4}/></li>
                    <li><Translations category={"docsDashboard"} id={5}/></li>
                    <li><Translations category={"docsDashboard"} id={6}/></li>
                </div>
                <p className={`text-lg`}><Translations category={"docsDashboard"} id={7}/></p>
                <p className={`text-lg`}>
                    <Translations category={"docsDashboard"} id={8}/>
                    <li><Translations category={"docsDashboard"} id={9}/></li>
                    <li><Translations category={"docsDashboard"} id={10}/></li>
                </p>
            </div>
        )
    }
    const config = {
        title: <Translations category={"docs"} id={8}/>,
        previousLink: '/docs/security',
        previousTitle: <Translations category={"docs"} id={6}/>,
        nextLink: '/docs/strategies',
        nextTitle: <Translations category={"docs"} id={9}/>,
        content: Content(),
    }

    return (
        <Template config={config}/>
    )
}

export default Dashboard