import {useEffect} from 'react';
import axios from "axios";
import {SERVERURL} from "../Constants";
import Cookies from "../cookies/Cookies";

export const TokenRefresher = (authLogin, setLogged) => {
    useEffect(() => {
        checkIfTokenShouldRefresh()

        const refreshInterval = setInterval(() => {
            checkIfTokenShouldRefresh();
        }, 30 * 1000);

        function checkIfTokenShouldRefresh() {
            const cookieAccTime = Cookies.getCookie("accTime")
            if (cookieAccTime && cookieAccTime < new Date().getTime() + 2 * 60 * 1000) {
                refreshToken();
            }
        }

        function refreshToken() {
            axios.post(`${SERVERURL}/refresh`, {}, {withCredentials: true})
                .then(result => authLogin(result.data.accessToken, result.data.refreshToken))
                .catch(error => {
                    setLogged(false)
                    console.log(error.response.data)
                })
        }

        return () => clearInterval(refreshInterval);
    }, []);
}
