import React from "react"
import Template from "../../components/Template"
import img1 from "../../images/settings/changePassword.png"
import img2 from "../../images/settings/connectKeys.png"
import img3 from "../../images/settings/clear.png"
import img4 from "../../images/settings/config.png"
import Translations from "../../../components/Translations"
import style from "../../../../style"

function Settings() {
    const Content = () => {
        return (
            <div>
                <Translations category={"docsSettings"} id={1}/>
                <div className={`grid grid-cols-1 lg:grid-cols-4 gap-5 mb-5`}>
                    <div className={`col-span-1 mt-5 mb-5`}>
                        <img src={img1} alt='/' className={`${style.borderImg}`}/>
                    </div>
                    <div className={`col-span-3 mt-5 mb-5`}><Translations category={"docsSettings"} id={2}/></div>
                    <div className={`col-span-1`}><img src={img2} alt='/' className={`${style.borderImg} my-5`}/></div>
                    <div className={`col-span-3  mt-5 mb-5`}><Translations category={"docsSettings"} id={3}/></div>
                    <div className={`col-span-1 mt-5 mb-5`}>
                        <img src={img3} alt='/' className={`${style.borderImg}`}/>
                    </div>
                    <div className={`col-span-3 mt-5 mb-5`}><Translations category={"docsSettings"} id={4}/></div>
                    <div className={`col-span-1`}><img src={img4} alt='/' className={`${style.borderImg} my-5`}/></div>
                    <div className={`col-span-3  mt-5 mb-5`}>
                        <li><Translations category={"docsSettings"} id={5}/></li>
                        <li><Translations category={"docsSettings"} id={6}/></li>
                    </div>
                </div>
            </div>
        )
    }
    const config = {
        title: <Translations category={"docs"} id={14}/>,
        previousLink: '/docs/automate-trading',
        previousTitle: <Translations category={"docs"} id={13}/>,
        nextLink: '/docs/chart',
        nextTitle: <Translations category={"docs"} id={15}/>,
        content: Content(),
    }

    return (
        <Template config={config}/>
    )
}

export default Settings