import React from "react"
import Template from "../../components/Template"
import img1 from "../../images/installation/uac.png"
import img2 from "../../images/installation/licenceAgreement.png"
import img3 from "../../images/installation/destination.png"
import img4 from "../../images/installation/readyToInstall.png"
import img5 from "../../images/installation/install.png"
import img6 from "../../images/installation/finished.png"
import img7 from "../../images/installation/icon.png"
import Translations from "../../../components/Translations"

function Installation() {
    const Content = () => {
        return (
            <div>
                <p><Translations category={"docsInstallation"} id={1}/>
                    <a className={"text-[#10a7d0]"} href={"/update/CryptoDeputyInstall.exe"} download>
                        <Translations category={"docsInstallation"} id={2}/>
                    </a>
                </p>
                <p className={`text-lg mt-5`}><Translations category={"docsInstallation"} id={3}/></p>
                <li>Java development kit (21)</li>
                <li>Node (binary)</li>
                <li>PostgreSQL (binary)</li>
                <p className={`text-lg mt-5`}><Translations category={"docsInstallation"} id={4}/></p>
                <img src={img1} alt='/' className={`col-span-2 w-[500px] mt-5 mb-5`}/>
                <p className={`text-lg mt-5 `}><Translations category={"docsInstallation"} id={5}/></p>
                <img src={img2} alt='/' className={`col-span-2 w-[500px] mt-5 mb-5`}/>
                <p className={`text-lg mt-5`}><Translations category={"docsInstallation"} id={6}/></p>
                <img src={img3} alt='/' className={`col-span-2 w-[500px] mt-5 mb-5`}/>
                <p className={`text-lg mt-5`}><Translations category={"docsInstallation"} id={7}/></p>
                <img src={img4} alt='/' className={`col-span-2 w-[500px] mt-5 mb-5`}/>
                <p className={`text-lg mt-5`}><Translations category={"docsInstallation"} id={8}/></p>
                <img src={img5} alt='/' className={`col-span-2 w-[500px] mt-5 mb-5`}/>
                <p className={`text-lg mt-5`}><Translations category={"docsInstallation"} id={9}/></p>
                <img src={img6} alt='/' className={`col-span-2 w-[500px] mt-5 mb-5`}/>
                <p className={`text-lg mt-5`}><Translations category={"docsInstallation"} id={10}/></p>
                <img src={img7} alt='/' className={`col-span-2 mt-5 mb-5`}/>
            </div>
        )
    }
    const config = {
        title: <Translations category={"docs"} id={3}/>,
        previousLink: '/docs/system-requirements',
        previousTitle: <Translations category={"docs"} id={2}/>,
        nextLink: '/docs/first-run',
        nextTitle: <Translations category={"docs"} id={4}/>,
        content: Content(),
    }

    return (
        <Template config={config}/>
    )
}

export default Installation