export function isPlanSufficient(minimumPlan, currentPlan) {
    const planOrder = ["Free", "Beginner", "Advanced", "Expert"]
    const minimumIndex = planOrder.indexOf(minimumPlan)
    const currentIndex = planOrder.indexOf(currentPlan)

    if (minimumIndex !== -1 && currentIndex !== -1) {
        return currentIndex >= minimumIndex;
    }

    return false;
}